import { Location } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { NazioneItem, OrganizationItem, OrgDetail, ProvinciaItem, UserOrgItem } from 'src/app/models/dataModels';
import { APIService } from 'src/app/services/APIService';
import { AuctionService } from 'src/app/services/auction.service';
import { CommonService } from 'src/app/services/common.service';
import { LocalUtilService } from 'src/app/services/local-util.service';
import { MessagesService } from 'src/app/services/messages.service';

@Component({
  selector: 'app-azienda-anagrafica',
  templateUrl: './azienda-anagrafica.component.html',
  styleUrls: ['./azienda-anagrafica.component.css']
})
export class AziendaAnagraficaComponent extends APIService implements OnInit {
  
  constructor(localUtil: LocalUtilService,
    public auctionService: AuctionService,
    private modalService: NgbModal,
    private messageService: MessagesService,
    location: Location, 
    router: Router,
    private route: ActivatedRoute,
    public commonService: CommonService) {
    super(localUtil, location, router);
    console.log('AziendaAnagraficaComponent()');
    this.loadOrganizationDetail();
  }

  orgDetail: OrganizationItem;
  userDetail: UserOrgItem;

  ngOnInit(): void {

  }

  loadOrganizationDetail() {
    console.log('loadOrganizationDetail()');
    let authParams = this.localUtil.authParams;
    this.orgDetail = this.localUtil.authParams.organization_detail;
    this.userDetail = this.localUtil.authParams.user_detail;    
    console.log('userDetail:');
    console.log(this.userDetail);
  }

}