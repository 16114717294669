<div id="azienda-operatori" class="content bottom80"> <!-- ngIf="isActiveComponent()"-->
    <div class="add-item-section add-item-section-user"> 
        <div class="add-operator-panel" [ngClass]="{'hidden': !isNewEditing(), 'show': isNewEditing()}">
            <h3 translate>azienda-operatori.nuovo-operatore</h3>
            <form id="form_createOperator" [formGroup]="operatorForm" class="form-group">
                <div class="d-flex">
                    <div class="input me-2" [ngClass]="{'error': controlHasError('nome')}"
                        (mouseleave)="validateField('nome')">
                        <div class="input-wrapper full">
                            <input required formControlName="nome" type="text" id="nome" maxlength="75"
                                class="form-control">
                            <label for="newOperator--name" translate>azienda-operatori.nome</label>
                        </div>
                    </div>
                    <div class="input" [ngClass]="{'error': controlHasError('cognome')}">
                        <div class="input-wrapper full">
                            <input required formControlName="cognome" type="text" id="cognome" maxlength="75">
                            <label for="newOperator--surname" translate>azienda-operatori.cognome</label>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="input half-width" [ngClass]="{'error': controlHasError('email')}">
                        <div class="input-wrapper full">
                            <input required formControlName="email" type="text" id="email" maxlength="75" email="true">
                            <label for="newOperator--email" translate>azienda-operatori.indirizzo-email</label>
                        </div>
                    </div>
                </div>
                <div class="d-flex">
                    <div class="input me-2" [ngClass]="{'error': controlHasError('password')}">
                        <div class="input-wrapper full">
                            <input required formControlName="password" type="password" class="form-control"
                                id="password" maxlength="75">
                            <label for="newOperator--password" translate>azienda-operatori.password</label>
                        </div>
                        <span class="error"
                            *ngIf="controlHasError('password')">{{getControlErrorMessage('password')}}</span>
                    </div>
                    <div class="input" [ngClass]="{'error': controlHasError('confermaPassword')}">
                        <div class="input-wrapper full">
                            <input required formControlName="confermaPassword" type="password" class="form-control"
                                id="confermaPassword" maxlength="75">
                            <label for="newOperator--password-confirm"
                                translate>azienda-operatori.conferma-password</label>
                        </div>
                        <span class="error"
                            *ngIf="controlHasError('confermaPassword')">{{getControlErrorMessage('confermaPassword')}}</span>
                    </div>
                </div>
                <div class="d-flex">
                    <div class="input me-2" [ngClass]="{'error': controlHasError('telefono')}">
                        <div class="input-wrapper full">
                            <input required formControlName="telefono" type="text" id="telefono" maxlength="75">
                            <label for="newOperator--phone" translate>azienda-operatori.telefono</label>
                        </div>
                    </div>
                    <div class="input" [ngClass]="{'error': controlHasError('mobile')}">
                        <div class="input-wrapper full">
                            <input required type="text" formControlName="mobile" id="mobile" maxlength="75">
                            <label for="newOperator--mobile" translate>azienda-operatori.telefono-mobile</label>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="input half-width" [ngClass]="{'error': controlHasError('lingua')}">
                        <div class="input-wrapper full">
                            <select required formControlName="lingua" id="lingua">
                                <option value="IT">Italiano</option>
                                <option value="EN">Inglese</option>
                            </select>
                            <label for="newOperator--language" translate>azienda-operatori.lingua</label>
                        </div>
                    </div>
                </div>
            </form>
        </div>
        <div class="add-operator-actions" [ngClass]="{'hidden': !isNewEditing(), 'show': isNewEditing()}">
            <div class="pb-5 text-end">
                <button class="btn" id="btn_cancelCreate" (click)="annullaNuovoOperatore()"><span
                        translate>azienda-operatori.annulla</span></button>
                <button class="btn btn-outline-primary button-color" type="submit" (click)="salvaNuovoOperatore()"
                    id="btn_createOperator"><i class="fal fa-save"></i> <span
                        translate>azienda-operatori.salva</span></button>
            </div>
        </div>
        <button class="add add-operator new-operator-toggler" (click)="toggleNuovoOperatore()">
            <article>
                <div class="icon"><i class="fal fa-plus-circle"></i></div>
                <div class="text"><span translate>azienda-operatori.aggiungi-operatore</span></div>
            </article>
        </button>

    </div>
    <h2 translate>azienda-operatori.operatori</h2>
    <div class="">

    </div>
    <div id="operators-list" class="operators-list">
        <dx-data-grid id="gridContainer" [dataSource]="operators_list" keyExpr="id" [allowColumnReordering]="false"
            [allowColumnResizing]="false" [showBorders]="true" [columnHidingEnabled]="false"
            (onRowPrepared)="onRowPrepared($event)" (onContentReady)="onContentReady($event)">

            <dxo-load-panel [enabled]="true"></dxo-load-panel>
            <dxo-filter-row [visible]="true"></dxo-filter-row>

            <dxo-search-panel [visible]="true" [width]="250" [highlightCaseSensitive]="false" placeholder="{{'search'|translate}}">
            </dxo-search-panel>

            <!-- edit form and columns -->
            <dxi-column dataField="id" [allowEditing]="false" [width]="100" caption="Id" alignment="left"
                [visible]="false">
            </dxi-column>
            <dxi-column dataField="userId" caption="UserId" dataType="string" showEditorAlways="true" [visible]="false">
            </dxi-column>
            <dxi-column dataField="dataCreazione" caption="Data creazione" [width]="150" dataType="date"
                format="dd/MM/yyyy" showEditorAlways="true" [visible]="false">
            </dxi-column>
            <dxi-column dataField="dataModifica" caption="Data modifica" dataType="date" format="dd/MM/yyyy"
                showEditorAlways="true" [visible]="false">
            </dxi-column>
            <dxi-column dataField="nome" caption="Nome" dataType="string" [visible]="true"
                cellTemplate="lotRowTemplate">
            </dxi-column>
            <dxi-column dataField="cognome" caption="Cognome" dataType="string" [visible]="false">
            </dxi-column>
            <dxi-column dataField="email" caption="Email" dataType="string" [visible]="false">
            </dxi-column>
            <dxi-column dataField="lingua" caption="Lingua" dataType="string" [visible]="false">
            </dxi-column>
            <dxi-column dataField="telefono" caption="Telefono" dataType="string" [visible]="false">
            </dxi-column>
            <dxi-column dataField="mobile" caption="Mobile" dataType="string" [visible]="false">
            </dxi-column>
            <dxi-column dataField="role" caption="Ruolo" dataType="string" [visible]="false">
            </dxi-column>
            <dxi-column dataField="stato" caption="Stato" dataType="string" [visible]="false">
            </dxi-column>
            <dxi-column dataField="dataRegistrazione" caption="Data registrazione" dataType="string" [visible]="false">
            </dxi-column>

            <dxo-toolbar>
                <dxi-item location="before">
                    <div *dxTemplate>
                        <div class="mt-2">
                            <div class="input full form-check form-check-inline">
                                <input class="form-check-input" type="checkbox" id="flexCheckChecked" class="filter"
                                    value="Attivi" [(ngModel)]="attivi" (click)="attiviCheckBox()">
                                <label class="form-check-label" for="flexCheckChecked" translate>Attivi</label>
                            </div>
                            <div class="input full form-check form-check-inline">
                                <input class="form-check-input" type="checkbox" id="flexCheckDefault" class="filter"
                                    value="Disabilitati" [(ngModel)]="disabilitati" (click)="disabilitatiCheckBox()">
                                <label class="form-check-label" for="flexCheckDefault" translate>Disabilitati</label>
                            </div>
                        </div>
                    </div> 
                </dxi-item>
                <dxi-item location="before" name="searchPanel"></dxi-item>
            </dxo-toolbar>

            <div *dxTemplate="let operatorDetail of 'lotRowTemplate'">
                <div class="operator operator-{{operatorDetail.data.statusClass}}">
                    <article id="{{operatorDetail.data.id}}">
                        <ul class="operator-header card-header">
                            <li name="name" class="name">
                                {{operatorDetail.data.nome}}&nbsp;{{operatorDetail.data.cognome}}</li>
                            <li name="createDate" class="creation-date">utente dal
                                {{operatorDetail.data.dataRegistrazione}}</li>
                            <li name="role" class="role">{{operatorDetail.data.roleDescr}}</li>
                            <li name="status" class="status {{operatorDetail.data.statusClass}}">
                                {{operatorDetail.data.status}}</li>
                            <!--li name="organization" class="company"><a href="operatore-aziende-dettaglio.html?id={{idOrganization}}">{{ragioneSociale}}</a></li-->
                            <li class="actions" [ngClass]="{'menu-open': isMenuOpened(operatorDetail.data)}">
                                <div class="actions-icons">
                                    <div class="card-menu-toggler"
                                        [ngClass]="{'open': isMenuOpened(operatorDetail.data)}"
                                        (click)="toggleMenu(operatorDetail.data)">
                                        <!-- <i class="fal fa-bars"></i> -->
                                    </div>
                                    <div class="card-body-toggler"
                                        [ngClass]="{'open': isFormOpened(operatorDetail.data)}"
                                        (click)="toggleDettaglio(operatorDetail.data)">
                                        <!-- <i class="fal fa-expand-alt"></i> -->
                                    </div>
                                </div>
                                <div class="operator-menu operator-{{operatorDetail.data.stato}}">
                                    <ul>
                                        <li [ngClass]="{'hidden': !isEditing(operatorDetail.data)}"
                                            (click)="annullaModificaOperatore(operatorDetail.data)">
                                            <div class="icon"><i class="fal fa-undo"></i></div>
                                            <div class="text operator-btn-cancelmodify">Annulla
                                                modifica</div>
                                        </li>
                                        <li [ngClass]="{'hidden': isEditing(operatorDetail.data)}"
                                            (click)="modificaOperatore(operatorDetail.data)">
                                            <div class="icon"><i class="fal fa-edit"></i></div>
                                            <div class="text operator-btn-modify">Modifica</div>
                                        </li>
                                        <li class="operator-btn-disable-container"
                                            (click)="disabilitaOperatore(operatorDetail.data)">
                                            <div class="icon"><i class="fal fa-user-slash"></i></div>
                                            <div class="text operator-btn-disable">Disabilita</div>
                                        </li>
                                        <li class="operator-btn-enable-container"
                                            (click)="abilitaOperatore(operatorDetail.data)">
                                            <div class="icon"><i class="fal fa-user"></i></div>
                                            <div class="text operator-btn-enable">Abilita</div>
                                        </li>
                                        <li (click)="eliminaOperatore(operatorDetail.data)">
                                            <div class="icon"><i class="fal fa-times"></i></div>
                                            <div class="text operator-btn-delete">Elimina</div>
                                        </li>
                                    </ul>
                                </div>
                            </li>
                        </ul>
                        <form>
                            <div class="operator-body card-body">
                                <div class="body-wrapper"
                                    [ngClass]="{'form-visible': isFormOpened(operatorDetail.data)}">
                                    <div class="d-flex">
                                        <div class="input mx-2">
                                            <div class="input-wrapper full">
                                                <input [(ngModel)]="operator.nome" type="text" name="operator-name"
                                                    value="{{operatorDetail.data.nome}}">
                                                <label for="operator-name" translate>azienda-operatori.nome</label>
                                            </div>
                                        </div>
                                        <div class="input">
                                            <div class="input-wrapper full">
                                                <input [(ngModel)]="operator.cognome" type="text"
                                                    name="operator-surname" value="{{operatorDetail.data.cognome}}">
                                                <label for="operator-surname"
                                                    translate>azienda-operatori.cognome</label>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="d-flex">
                                        <div class="input mx-2">
                                            <div class="input-wrapper full">
                                                <input [(ngModel)]="operator.email" type="email" disabled
                                                    name="operator-email" value="{{operatorDetail.data.email}}">
                                                <label for="operator-email" translate>azienda-operatori.email</label>
                                            </div>
                                        </div>
                                        <div class="input">
                                            <div class="input-wrapper full">
                                                <select [(ngModel)]="operator.lingua" name="operator-language" id="">
                                                    <option value=""></option>
                                                    <option value="IT" [selected]="operatorDetail.data.lingua === 'IT'">
                                                        Italiana</option>
                                                    <option value="EN" [selected]="operatorDetail.data.lingua === 'EN'">
                                                        Inglese</option>
                                                </select>
                                                <label for="operator-language"
                                                    translate>azienda-operatori.lingua</label>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="d-flex">
                                        <div class="input mx-2">
                                            <div class="input-wrapper full">
                                                <input [(ngModel)]="operator.telefono" type="text" name="operator-phone"
                                                    value="{{operatorDetail.data.telefono}}">
                                                <label for="operator-phone"
                                                    translate>azienda-operatori.telefono-fisso</label>
                                            </div>
                                        </div>
                                        <div class="input">
                                            <div class="input-wrapper full">
                                                <input [(ngModel)]="operator.mobile" type="text" name="operator-mobile"
                                                    value="{{operatorDetail.data.mobile}}">
                                                <label for="operator-mobile"
                                                    translate>azienda-operatori.telefono-cellulare</label>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="row" [ngClass]="{'hidden': !isEditing(operatorDetail.data)}">
                                        <div class="input">
                                            <div class="input-wrapper full">
                                                <button type="button" class="btn btn-primary update-user"
                                                    (click)="aggiornaOperatore(operatorDetail.data)"><span
                                                        translate>azienda-operatori.salva</span></button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </article>
                </div>
            </div>

        </dx-data-grid>
    </div>
</div>