import { Location } from '@angular/common';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import { Component, NgModule, OnInit } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { Route, Router, Routes } from '@angular/router';
import { NgbModal, NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { AppRoutingModule } from '../app-routing.module';
import { CommonsModule } from '../commons/commons.component';
import { APIService } from '../services/APIService';
import { LocalUtilService } from '../services/local-util.service';
import { AsteChiuseComponent } from './components/aste/aste-chiuse/aste-chiuse.component';
import { AsteInCorsoComponent } from './components/aste/aste-in-corso/aste-in-corso.component';
import { AstePubblicheComponent } from './components/aste/aste-pubbliche/aste-pubbliche.component';
import { AsteVinteComponent } from './components/aste/aste-vinte/aste-vinte.component';
import { DashboardComponent } from './components/dashboard/dashboard.component';
import { DxBulletModule, DxButtonModule, DxDataGridModule, DxDateBoxModule, DxFormModule, DxLoadIndicatorModule, DxSelectBoxModule, DxTemplateModule, DxTextAreaModule, DxTooltipModule, DxTextBoxModule, DxDropDownBoxModule, DxAccordionModule, DxNumberBoxModule, DxHtmlEditorModule, DxToolbarModule, DxListModule } from 'devextreme-angular';
import { AsteVinteDettaglioComponent } from './components/aste/aste-vinte/dettaglio/aste-vinte-dettaglio.component';
import { AsteChiuseDettaglioComponent } from './components/aste/aste-chiuse/dettaglio/aste-chiuse-dettaglio.component';
import { AstePubblicheDettaglioComponent } from './components/aste/aste-pubbliche/dettaglio/aste-pubbliche-dettaglio.component';
import { AziendaOperatoriComponent } from './components/azienda/operatori/azienda-operatori.component';
import { AziendaImpiantiComponent } from './components/azienda/impianti/azienda-impianti.component';
import { AziendaAnagraficaComponent } from './components/azienda/anagrafica/azienda-anagrafica.component';
import { AsteInCorsoBoxComponent } from './components/aste/aste-in-corso-box/aste-in-corso-box.component';
import { AppModule } from '../app.module';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';

@Component({
  selector: 'app-user',
  templateUrl: './user.component.html',
  styleUrls: ['./user.component.css']
})
export class UserComponent extends APIService implements OnInit {

  constructor(public localService: LocalUtilService,
    location: Location, 
    router: Router) { 
    super(localService, location, router);
  }

  ngOnInit(): void {
    
  }

}

@NgModule({
  declarations: [
    DashboardComponent,
    AstePubblicheComponent,
    AstePubblicheDettaglioComponent,
    AsteChiuseComponent,
    AsteChiuseDettaglioComponent,
    AsteInCorsoComponent,
    AsteVinteComponent,
    AsteVinteDettaglioComponent,
    AziendaOperatoriComponent,
    AziendaImpiantiComponent,
    AziendaAnagraficaComponent,
    AsteInCorsoBoxComponent,
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,
    NgbModule,
    CommonsModule,
    AppRoutingModule,
    DxDataGridModule, DxButtonModule, DxBulletModule,
    DxSelectBoxModule, DxLoadIndicatorModule, DxTemplateModule, DxFormModule,
    DxTextAreaModule,
    DxTextBoxModule,
    DxDropDownBoxModule,
    DxDateBoxModule,
    DxFormModule,
    DxTooltipModule, DxTemplateModule,
    DxAccordionModule,
    DxHtmlEditorModule,
    DxToolbarModule,
    DxTextAreaModule,
    DxNumberBoxModule,
    DxListModule ,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    }),  
  ],
  exports: [
    DashboardComponent,
    AstePubblicheComponent,
    AstePubblicheDettaglioComponent,
    AsteChiuseComponent,
    AsteChiuseDettaglioComponent,
    AsteInCorsoComponent,
    AsteVinteComponent,
    AsteVinteDettaglioComponent,
    AziendaOperatoriComponent,
    AziendaImpiantiComponent,
    AziendaAnagraficaComponent,
    AsteInCorsoBoxComponent,
  ],
  providers: [],
  bootstrap: [UserComponent]
})
export class UserModule { }

export function HttpLoaderFactory(http: HttpClient): TranslateHttpLoader {
  return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}