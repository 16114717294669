import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HttpClientModule, HTTP_INTERCEPTORS, HttpClient } from '@angular/common/http';
import { LoaderInterceptor } from './interceptors/LoaderInterceptor';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { UserComponent, UserModule } from './user/user.component';
import { CoripetComponent, CoripetModule } from './coripet/coripet.component';
import { AdminComponent, AdminModule } from './admin/admin.component';
import { CommonsComponent, CommonsModule } from './commons/commons.component';
import { DxBulletModule, DxButtonModule, DxDataGridModule, DxDateBoxModule, DxFormModule, DxLoadIndicatorModule, DxSelectBoxModule, DxTemplateModule, DxTextAreaModule, DxTooltipModule, DxTextBoxModule, DxDropDownBoxModule, DxAccordionModule, DxNumberBoxModule, DxHtmlEditorModule, DxToolbarModule, DxDropDownButtonModule, DxLookupModule, DxTabPanelModule } from 'devextreme-angular';
import { CKEditorModule } from '@ckeditor/ckeditor5-angular';
import {
  DxPivotGridModule,
  DxChartModule,
} from 'devextreme-angular';
import 'flatpickr/dist/flatpickr.css'; // you may need to adjust the css import depending on your build tool
import { FlatpickrModule } from 'angularx-flatpickr';
import { RegistrazioneStep1Component } from './public/components/registrazione-step1/registrazione-step1.component';
import { RegistrazioneStep2Component } from './public/components/registrazione-step2/registrazione-step2.component';
import { ConsensiComponent } from './user/components/consensi/consensi.component';
import config from "devextreme/core/config";

import { registerLocaleData } from '@angular/common';
import localeIt from '@angular/common/locales/it';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { AziendaDettaglioComponent } from './admin/components/azienda-dettaglio/azienda-dettaglio.component';
import { AziendeComponent } from './admin/components/aziende-common/aziende.component';
registerLocaleData(localeIt);

export const interceptorProviders =
  [
    { provide: HTTP_INTERCEPTORS, useClass: LoaderInterceptor, multi: true }
  ];

config({
  editorStylingMode: 'outlined',
  defaultCurrency: 'EUR',
  //forceIsoDateParsing: true,
});

@NgModule({
  declarations: [
    AppComponent,
    UserComponent,
    CoripetComponent,
    AdminComponent,
    CommonsComponent,
    RegistrazioneStep1Component,
    RegistrazioneStep2Component,
    ConsensiComponent,
    AziendaDettaglioComponent,
    AziendeComponent
  ],
  exports: [
  ],
  imports: [
    BrowserModule,
    FormsModule,
    ReactiveFormsModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    HttpClientModule,
    NgbModule,
    CommonsModule,
    AdminModule,
    UserModule,
    CoripetModule,
    AppRoutingModule,
    DxDataGridModule, DxBulletModule,
    DxSelectBoxModule, DxLoadIndicatorModule, DxTemplateModule, DxFormModule,
    DxTextAreaModule,
    DxTextBoxModule,
    DxDropDownBoxModule,
    DxDateBoxModule,
    DxFormModule,
    DxTooltipModule,
    DxAccordionModule,
    DxHtmlEditorModule,
    DxToolbarModule,
    DxTextAreaModule,
    DxNumberBoxModule,
    CKEditorModule,
    DxPivotGridModule,
    DxChartModule,
    FlatpickrModule.forRoot(),
    DxDropDownButtonModule,
    DxLookupModule,
    DxTabPanelModule,
    DxButtonModule,
   TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    }),
  ],
  providers: [
    interceptorProviders,
    //{ provide: LOCALE_ID, useValue: 'it-IT'},
  ],
  bootstrap: [AppComponent],
})
export class AppModule { }

export function HttpLoaderFactory(http: HttpClient): TranslateHttpLoader {
  return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}
