<style>
    ::ng-deep div.dx-datagrid-header-panel{
    position: sticky;
    top:0;
    z-index: 1000; 
    /*z-index is applied so that this element appears in front*/
    }

    ::ng-deep .dx-datagrid-headers.dx-datagrid-nowrap{
        position: sticky;
        top:60px;
        background-color: white;
        z-index: 1000;
    }
</style>
<div id="asta-in-corso-box" *ngIf="isEnabledToAuction()" class="content" (scroll)="onScroll()" [ngClass]="{'top100': !isAstaSingola()}">
    
    <app-active-auction-banner *ngIf="auctionDetail == undefined && codiceAsta == undefined"></app-active-auction-banner>
    <app-next-auction-countdown *ngIf="auctionDetail == undefined && codiceAsta == undefined"></app-next-auction-countdown>

    <div *ngIf="auctionDetail">
        <h2 title="Tipologia: {{auctionDetail.tipologiaAsta}}"><span translate>aste-incorso.asta</span> {{auctionDetail.codiceAsta}}
            <span class="auctions-infos2">
                <span translate>aste-incorso.apertura-asta</span> {{auctionDetail.dataOraInizioAsta}}. <span translate>aste-incorso.chiusura-asta</span> {{auctionDetail.dataOraFineAsta}}  <span translate>aste-incorso.ora-italiana</span>
            </span>
            <div class="countdown_refresh2">
                <span translate>aste-incorso.tempo-rimanente-al-prossimo-refresh </span><span id="cdRefresh">{{(secondsToRefresh/1000)}}</span></div>
        </h2>
    </div>

    <!-- filtri header text e pulsantiera-->
    <div class="section-tools" *ngIf="auctionDetail != undefined && codiceAsta != undefined">
        <div class="search-incorso">
            <button><i class="far fa-search"></i></button>
            <div class="row">
                <div class="input">
                    <input type="text" placeholder="Cerca" id="ricerca" [(ngModel)]="searchLotText" name="ricerca" (keyup)="filterByText()">
                </div>
            </div>
        </div>
        <div class="filters">
            <div class="row">
                <div  class="input">
                    <div class="reload desktop"id="btnRicarica1">
                        <button class="primary btn btn-success" [disabled]="getDisabledReloadLots()" name="btnRicarica1" (click)="getDisabledReloadLots()==''?getAuctionDetailWithLots():undefined">
                            <span class="icon"><i class="fal fa-sync" [ngClass]="{'disabled-reload': getDisabledReloadLots() == 'disabled'}"></i></span>
                            <span class="text">Ricarica</span>
                        </button>
                        <button class="primary btn btn-success" id="zoomMinus" title="Zoom -" (click)="decreaseFont()">
                            <span class="icon"><i class="fas fa-search-minus"></i></span>
                            <span class="text">-</span>
                        </button>
                        <button class="primary btn btn-success" id="zoomPlus" title="Zoom +" (click)="increaseFont()">
                            <span class="icon"><i class="fas fa-search-plus"></i></span>
                            <span class="text">+</span>
                        </button>                                    
                    </div>
                </div>
            </div>
        </div>
    </div>

    <!-- filtri header option tipologia materiale e preferiti -->
    <div class="section-tools" *ngIf="auctionDetail != undefined && codiceAsta != undefined">
        <div class="search1">
            <div class="row">
                <div class="input">
                    <label translate>aste-incorso.filtra-per-tipologia-materiale</label>
                </div>
            </div>
        </div>
        <div class="filters">
            <div class="" style="display: -webkit-box !important;">                
                <div *ngFor="let material of material_list; let i = index" class="input full me-4">
                    <input type="checkbox" id="{{material.type_material}}" [(ngModel)]="material.checked" class="filter" checked="" (click)="filterByMaterial(material)">
                    <label for="{{material.type_material}}" translate>aste-incorso.{{material.type_material}}</label>
                </div>
            </div>
        </div>
        <div class="filters_favourite">
            <div id="tipoProdottoTmplFav" class="">
                <div class="input">
                    
                    <label for="matr-favourite" class="label_preferiti">
                        <i *ngIf="toggleFilterFavourite" class="pointer fa fa-star star-filter-full" (click)="filterFavouriteLots($event)" title="Filtra lotti preferiti"></i>
                        <i *ngIf="!toggleFilterFavourite" class="pointer star star-filter-empty" (click)="filterFavouriteLots($event)" title="Rimuovi filtro lotti preferiti"></i>
                        &nbsp;&nbsp;
                        <span translate>aste-incorso.preferiti</span>
                    </label>
                </div>
            </div> 
        </div>        
    </div>

    <!-- filtri header option area geografica -->
    <div class="section-tools" *ngIf="auctionDetail != undefined && codiceAsta != undefined">
        <div class="search2">
            <div class="row">
                <div class="input">
                    <label translate>aste-incorso.filtra-per-area-geografica</label>
                </div>
            </div>
        </div>
        <div class="filters-geo">
            <div class="" style="display: -webkit-box !important;">
                <div *ngFor="let areaGeo of area_list; let i = index" class="input full me-4">
                    <input type="checkbox" id="{{areaGeo.area}}" class="filter" checked="" (click)="filterByArea(areaGeo)">
                    <label for="{{areaGeo.area}}" translate>aste-incorso.{{areaGeo.area}}</label>
                </div>                        
            </div>
        </div>
    </div>

    <div *ngIf="auctionDetail != undefined && codiceAsta != undefined">
        <div class="row">
            <ul class="view-actions">
                <li class="view1 active" id="view1" title="Visualizzazione a blocchi"></li>
                <a [routerLink]="getRouterLinkAuctionList(codiceAsta)"><li class="view2" id="view2" title="Visualizzazione a lista"></li></a>
            </ul>
        </div>
        <div class="row">&nbsp;</div>
        <div class="cards-lots-list bottom100" id="cards-lots-list">
            <div *ngFor="let lot of sortByFavourite(auctionLots); let i = index" class="lot">
                <ul class="card-top-actions">
                    <ul *ngIf="lot.isExtratime && !lot.isClosed"><li class="fas auction_extended" [title]="ConstantsModule.FLAG_EXTRATIME_LOT"></li></ul>
                    <ul *ngIf="!lot.isExtratime && !lot.isClosed"><li name="riempispazio"></li></ul>
                    <ul *ngIf="lot.isClosed"><li class="fas auction_closed" [title]="ConstantsModule.FLAG_AUC_CLOSED"></li></ul>
                    <ul *ngIf="lot.isDisabled && !lot.isClosed && !lot.isWinning "><li class="fas auction_closed" [title]="ConstantsModule.FLAG_AUC_DISABLED"></li></ul>
                    <ul *ngIf="lot.isWinning"><li class="fas company_winning" [title]="ConstantsModule.FLAG_WINNING_LOT"></li></ul>
                    <ul *ngIf="!lot.favourite"><li class="star" (click)="addFavouriteLot(lot.codiceLotto)" title="Aggiungi a preferiti"></li></ul>
                    <ul *ngIf="lot.favourite"><li class="fa fa-star" (click)="removeFavouriteLot(lot.codiceLotto)" title="Rimuovi da preferiti"></li></ul>
                    <ul><li class="card-lightbox-toggler" (click)="lotDetail(lot)"></li></ul>
                </ul>
                <article>
                    <div class="card-body {{getProdottoClass(lot.tipoProdotto)}}" [ngClass]="{'verde': lot.isWinning}">
                        <ul class="lot-main-infos">
                            <li class="lot-number">{{lot.codiceLotto}}</li>
                            <li class="quantita">
                                <span translate>aste-incorso.Quantità</span>
                                 <b>{{lot.quantitaTon}} t</b></li>
                            <!--li>Numero rilanci: {{numeroRilanci}}</li-->
                            <li class="prezzo">
                                <b>
                                    <span translate>aste-incorso.Prezzo</span> 
                                    <span *ngIf="lot.prezzoUltimoRilancio">{{LocalUtil.formatNumber2(lot.prezzoUltimoRilancio)}}</span>
                                    <span *ngIf="!lot.prezzoUltimoRilancio">{{LocalUtil.formatNumber2(lot.prezzoBase)}}</span> 
                                    <span> &euro;/t</span>
                                </b>
                            </li>
                        </ul>
                        <div class="error-text" style="margin:0; display: inline-flex; font-weight: bold;"><!--{{lot.lotAuctionEnd}}-->&nbsp;<div id="{{lot.id}}"></div></div>
                        <div><!--class="{{statusMessageCss}}"-->
                            <!--{{statusMessage}}-->
                        </div>
                        <app-timer (monitorEvent)="timerEventRaised($event)" [name]="lot.codiceLotto" [dateOfficialEnd]="auctionDetail.dataOraFineAsta" [dateEndCountdown]="getDataFineAstaEffettiva(lot.dataOraFineAstaEffettiva)" [hourEndCountdown]="getOraFineAstaEffettiva(lot.dataOraFineAstaEffettiva)" [showSign]="false" [visible]="false"></app-timer>
                    </div>

                    <div class="action">
                        <div class="action-wrapper"><!--class={{actionClass}}-->
                            <div class="select-wrapper" *ngIf="lot.isClosed || lot.isDisabled || lot.isWinning">
                                <div *ngIf="!isTaglioRilancioLibero"><!--{{#notEnabledTaglioLibero}}-->
                                    <select name="rilancioTagli" disabled>
                                        <option class="select-selected" *ngFor="let bet of bet_list" [value]="bet.taglioRilancio">+{{bet.taglioRilancio}}€</option>
                                    </select>
                                </div>
                                <div *ngIf="isTaglioRilancioLibero"><!--{{#enabledTaglioLibero}}-->
                                    <input type="number" name="rilancioTagli" disabled class="rilancioTaglioLibero" pattern="\d*" maxlength="4">
                                </div>
                            </div>
                            <button class="btn success btn-make-offer" disabled *ngIf="lot.isClosed || lot.isDisabled || lot.isWinning" translate>aste-incorso.rilancia</button>

                            <div class="select-wrapper" *ngIf="!lot.isClosed && !lot.isDisabled && !lot.isWinning">
                                <div *ngIf="!isTaglioRilancioLibero"><!--{{#notEnabledTaglioLibero}}-->
                                    <select name="rilancioTagli" (change)="changeBetSlot($event, lot.codiceLotto)">
                                        <option class="select-selected" *ngFor="let bet of bet_list" [value]="bet.taglioRilancio">+{{bet.taglioRilancio}}€</option>
                                    </select>
                                </div>
                                <div *ngIf="isTaglioRilancioLibero"><!--{{#enabledTaglioLibero}}-->
                                    <input type="number" name="rilancioTagli" class="rilancioTaglioLibero" [(ngModel)]="lot.bindModel" pattern="\d*" maxlength="4">
                                </div>
                            </div>
                            <button class="btn success btn-make-offer" (click)="makeOffer(lot.codiceLotto, lot.prezzoUltimoRilancio, lot)" *ngIf="!lot.isClosed && !lot.isDisabled && !lot.isWinning" translate>aste-incorso.rilancia</button>
                        </div>
                    </div>
                </article>
            </div>
        </div>
        
    </div>
    
</div>
<div class="top100" *ngIf="!isEnabledToAuction()">
    <span class="warning">
        <span translate>aste-incorso.azienda-non-abilitata</span>
    </span>
</div>