<div id="auctions-list" class="auctions-list">
    <dx-data-grid id="gridContainerLot" [dataSource]="lots" #dataGridLots keyExpr="id" [allowColumnReordering]="true"
        [allowColumnResizing]="true" [showBorders]="true" [columnHidingEnabled]="false" [columnAutoWidth]="true"
        (onEditingStart)="onEditingStart($event)" (onRowUpdating)="onRowUpdate($event)"
        (onRowExpanding)="onRowExpanding($event)" (onRowRemoving)="onRowRemoving($event)"
        (onInitNewRow)="onInitNewRow($event)" (onRowInserting)="onRowInsert($event)"
        (onRowPrepared)="onRowPrepared($event)"><!--(onRowRemoving)="todo"-->

        <dxo-column-chooser [enabled]="true" [width]="300" [allowSearch]="true" [enabled]="true" mode="dragAndDrop"
            title="Seleziona colonne"></dxo-column-chooser>
        <dxo-export [enabled]="true" fileName="auction_lot_list"></dxo-export>

        <dxo-load-panel [enabled]="true"></dxo-load-panel>
        <dxo-filter-row [visible]="true"></dxo-filter-row>
        <dxo-header-filter [visible]="true"></dxo-header-filter>
        <dxo-scrolling mode="infinite"></dxo-scrolling>

        <dxo-search-panel [visible]="true" [highlightCaseSensitive]="false" placeholder="Cerca...">
        </dxo-search-panel>

        <!-- edit form and columns -->
        <dxi-column dataField="id" [allowEditing]="false" caption="Id" alignment="left" [visible]="false">
        </dxi-column>
        <dxi-column dataField="userId" caption="UserId" [allowEditing]="false" dataType="string" [visible]="false">
        </dxi-column>

        <dxi-column dataField="codiceLotto" caption="Codice lotto" [allowEditing]="true" dataType="string"
            [visible]="true">
            <dxi-validation-rule type="required"></dxi-validation-rule>
        </dxi-column>

        <dxi-column dataField="createDate" caption="Data registrazione" [allowEditing]="false" dataType="date"
            format="dd/MM/yyyy" [visible]="false">
        </dxi-column>
        <dxi-column dataField="modifiedDate" caption="Data modifica" [allowEditing]="false" dataType="date"
            format="dd/MM/yyyy" [visible]="false">
        </dxi-column>

        <dxi-column dataField="nomeOrganization" caption="Nome" [allowEditing]="true" dataType="string"
            [visible]="false">
            <dxi-validation-rule type="required"></dxi-validation-rule>
        </dxi-column>

        <dxi-column dataField="tipoProdotto" caption="Tipo" [allowEditing]="true" dataType="string" [visible]="true">
            <dxi-validation-rule type="required"></dxi-validation-rule>
            <dxo-lookup [dataSource]="material_list" valueExpr="type_material" displayExpr="type_material">
            </dxo-lookup>
        </dxi-column>

        <dxi-column dataField="areaGeografica" caption="Area" [allowEditing]="true" dataType="string" [visible]="true">
            <dxi-validation-rule type="required"></dxi-validation-rule>
            <dxo-lookup [dataSource]="area_list" valueExpr="area" displayExpr="area">
            </dxo-lookup>
        </dxi-column>

        <dxi-column dataField="quantitaTon" caption="Quantità (ton)" [allowEditing]="true" dataType="number"
            [visible]="true">
            <dxi-validation-rule type="required"></dxi-validation-rule>
        </dxi-column>

        <dxi-column dataField="prezzoBase" caption="Base d'asta" [allowEditing]="true" dataType="number"
            [visible]="true">
            <dxi-validation-rule type="required"></dxi-validation-rule>
        </dxi-column>

        <dxi-column dataField="statoLotto" caption="Stato lotto" [allowEditing]="false" showEditorAlways="false"
            dataType="string" [visible]="false" cellTemplate="statusTemplate">
        </dxi-column>

        <dxi-column dataField="schedaTecnica" caption="Scheda tecnica" [allowEditing]="true" dataType="string"
            [visible]="false">
            <dxi-validation-rule type="required"></dxi-validation-rule>
            <dxo-lookup [dataSource]="scheda_list" valueExpr="document" displayExpr="document">
            </dxo-lookup>
        </dxi-column>

        <dxi-column dataField="codiceAsta" caption="Codice asta" [allowEditing]="true" dataType="string"
            [visible]="false">
            <dxi-validation-rule type="required"></dxi-validation-rule>
        </dxi-column>

        <dxi-column dataField="codiceRifiutoCER" caption="Codice CER" [allowEditing]="true" dataType="string"
            [visible]="false">
        </dxi-column>

        <dxi-column dataField="dataDisponibilitaLotto" caption="Data disponibilita lotto" [allowEditing]="true"
            dataType="date" format="dd/MM/yyyy" [visible]="true">
            <dxi-validation-rule type="required"></dxi-validation-rule>
        </dxi-column>

        <dxi-column dataField="pesoMedio" caption="Peso medio" [allowEditing]="true" dataType="string"
            [visible]="false">
        </dxi-column>

        <dxi-column dataField="note" caption="Note" [allowEditing]="true" dataType="string" [visible]="false">
        </dxi-column>

        <dxi-column dataField="codiceProdotto" caption="Codice prodotto" [allowEditing]="true" dataType="string"
            [visible]="false">
        </dxi-column>

        <dxi-column dataField="imballaggio" caption="imballaggio" [allowEditing]="true" dataType="string"
            [visible]="false">
        </dxi-column>

        <dxi-column dataField="dataOraFineAstaEffettiva" caption="Data fine asta lotto" [allowEditing]="false"
            dataType="date" format="dd/MM/yyyy HH:mm" [visible]="false">
        </dxi-column>

        <dxi-column dataField="numeroRilanci" caption="Nr. rilanci" [allowEditing]="false" dataType="number"
            [visible]="false">
        </dxi-column>

        <dxi-column dataField="prezzoUltimoRilancio" caption="Prezzo ultimo rilancio" [allowEditing]="false"
            dataType="number" [visible]="false">
        </dxi-column>

        <dxi-column dataField="pagato" caption="Pagato" [allowEditing]="true" dataType="boolean" [visible]="true">
        </dxi-column>

        <dxi-column dataField="spedito" caption="Ritirato" [allowEditing]="true" dataType="boolean" [visible]="true">
        </dxi-column>

        <dxi-column dataField="centroSelezione" caption="Centri di selezione" [allowEditing]="false" dataType="string"
            [visible]="false">
        </dxi-column>

        <dxi-column type="buttons">
            <dxi-button name="edit" [visible]="isCoripet()"></dxi-button>
            <dxi-button name="reqPublishLot" icon="check" title="Richiedi approvazione lotto" [visible]="isLotDraft"
                [onClick]="requestApproveLot"></dxi-button>
            <dxi-button name="undoReqPublishLot" icon="revert" title="Annulla richiesta approvazione lotto"
                [visible]="isLotReqApprove" [onClick]="undoRequestApproveLot"></dxi-button>
            <dxi-button name="delete" [visible]="isCoripet()"></dxi-button>
        </dxi-column>

        <dxo-toolbar>
            <dxi-item location="after">
                <div *dxTemplate>
                    <dx-button id="loadExcelLotti" icon="xlsxfile" (onClick)="loadExcelLotti()" hint="Carica excel">
                    </dx-button>
                </div>
            </dxi-item>
            <dxi-item location="after">
                <div *dxTemplate>
                    <dx-button id="undoReqPublishLots" icon="revert" (onClick)="undoRequestApproveAllLots()"
                        hint="Annulla richiesta pubblicazione di tutti i lotti Bozza">
                    </dx-button>
                </div>
            </dxi-item>
            <dxi-item location="after">
                <div *dxTemplate>
                    <dx-button id="reqPublishLots" icon="check" (onClick)="requestApproveAllLots()"
                        hint="Richiedi pubblicazione di tutti i lotti Bozza">
                    </dx-button>
                </div>
            </dxi-item>
            <dxi-item location="after">
                <div *dxTemplate>
                    <dx-button id="addLot" icon="add" (onClick)="addNewLot()" hint="Aggiungi nuovo lotto">
                    </dx-button>
                </div>
            </dxi-item>
        </dxo-toolbar>

        <dxo-grouping [autoExpandAll]="true"></dxo-grouping>
        <dxo-column-chooser [enabled]="true"></dxo-column-chooser>
        <dxo-load-panel [enabled]="true"></dxo-load-panel>

        <div *dxTemplate="let statusTemplate of 'statusTemplate'">
            <span class="status" class="{{statusTemplate.data.statoLotto}}">
                {{statusTemplate.data.statoLotto}}
            </span>
        </div>

        <dxo-master-detail [enabled]="true" template="detailLot"></dxo-master-detail>

        <div *dxTemplate="let detailLot of 'detailLot'">

            <dx-form [(formData)]="detailLot.data">

                <dxi-item itemType="tabbed" [tabPanelOptions]="{ onTitleClick: tabChanged}" [colSpan]="20">
                    <dxi-tab title="Dettaglio lotto">
                        <dxi-item itemType="group" [colCount]="3" cssClass="detail-form-section">
                            <dxi-item dataField="codiceLotto"></dxi-item>
                            <dxi-item dataField="tipoProdotto"></dxi-item>
                            <dxi-item dataField="quantitaTon"></dxi-item>

                        </dxi-item>
                        <dxi-item itemType="group" [colCount]="4" cssClass="detail-form-section">
                            <dxi-item dataField="areaGeografica"></dxi-item>
                            <dxi-item dataField="codiceProdotto"></dxi-item>
                            <dxi-item dataField="pesoMedio"></dxi-item>
                            <dxi-item dataField="codiceRifiutoCER"></dxi-item>
                        </dxi-item>
                        <dxi-item itemType="group" [colCount]="3" cssClass="detail-form-section">
                            <dxi-item dataField="prezzoUltimoRilancio"></dxi-item>
                            <dxi-item dataField="numeroRilanci"></dxi-item>
                            <dxi-item dataField="prezzoBase"></dxi-item>
                        </dxi-item>
                        <dxi-item itemType="group" [colCount]="4" cssClass="detail-form-section">
                            <dxi-item dataField="dataDisponibilitaLotto"></dxi-item>
                            <dxi-item dataField="dataOraFineAstaEffettiva"></dxi-item>
                            <dxi-item editorType="dxCheckBox" dataField="pagato"></dxi-item>
                            <dxi-item editorType="dxCheckBox" dataField="spedito"></dxi-item>
                        </dxi-item>
                        <dxi-item itemType="group" [colCount]="1" cssClass="detail-form-section">
                            <dxi-item dataField="note" editorType="dxTextArea"></dxi-item>
                        </dxi-item>
                    </dxi-tab>
                    <dxi-tab title="Centri di selezione">
                        <dxi-item dataField="centroSelezione" itemTemplate="csTemplate" [label]="{text: ''}">
                            <div *dxTemplate="let centroSelezione of 'centroSelezione'">
                                <app-elenco-cs-lotto [cs]="detailLot.data.centroSelezione"
                                    [readonly]="!isEditMode"></app-elenco-cs-lotto>
                            </div>
                        </dxi-item>
                    </dxi-tab>
                    <dxi-tab title="Stato">
                        <dxi-item dataField="statoLotto"></dxi-item>
                    </dxi-tab>
                </dxi-item>

            </dx-form>
        </div>

        <dxo-editing [allowUpdating]="true" [allowAdding]="true" [allowDeleting]="true" mode="form">

            <dxo-form [colCount]="20">
                <dxi-item itemType="tabbed" [tabPanelOptions]="{ onTitleClick: tabChanged}" [colSpan]="20">
                    <dxi-tab title="Dettaglio lotto">
                        <dxi-item itemType="group" [colCount]="5" cssClass="detail-form-section">
                            <dxi-item dataField="codiceLotto" [colSpan]="2"></dxi-item>
                            <dxi-item dataField="tipoProdotto" [colSpan]="2"></dxi-item>
                            <dxi-item dataField="areaGeografica" [colSpan]="1"></dxi-item>
                        </dxi-item>
                        <dxi-item itemType="group" [colCount]="5" cssClass="detail-form-section">
                            <dxi-item dataField="codiceProdotto"></dxi-item>
                            <dxi-item dataField="quantitaTon"></dxi-item>
                            <dxi-item dataField="prezzoBase"></dxi-item>
                            <dxi-item dataField="pesoMedio"></dxi-item>
                            <dxi-item dataField="codiceRifiutoCER"></dxi-item>
                        </dxi-item>
                        <dxi-item itemType="group" [colCount]="5" cssClass="detail-form-section">
                            <dxi-item dataField="schedaTecnica" [colSpan]="3"></dxi-item>
                            <dxi-item dataField="dataDisponibilitaLotto" [colSpan]="2"></dxi-item>
                            <dxi-item editorType="dxCheckBox" dataField="pagato"></dxi-item>
                            <dxi-item editorType="dxCheckBox" dataField="spedito"></dxi-item>
                        </dxi-item>
                        <dxi-item itemType="group" [colCount]="1" cssClass="detail-form-section">
                            <dxi-item editorType="dxTextArea" dataField="note"></dxi-item>
                        </dxi-item>
                    </dxi-tab>
                    <dxi-tab title="Centri di selezione">
                        <dxi-item dataField="centroSelezione" itemTemplate="csTemplate" [label]="{text: ''}">
                            <div *dxTemplate="let centroSelezione of 'centroSelezione'">
                                <app-elenco-cs-lotto [cs]="modelEdit.centroSelezioneLottoItem" [lot]="modelEdit"
                                    (monitorEvent)="updateCs($event)" [readonly]="!isEditMode"></app-elenco-cs-lotto>
                            </div>
                        </dxi-item>
                    </dxi-tab>
                    <dxi-tab title="Stato">
                        <dxi-item dataField="statoLotto"></dxi-item>
                    </dxi-tab>
                </dxi-item>
            </dxo-form>

        </dxo-editing>

    </dx-data-grid>
</div>