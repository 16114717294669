import { Location } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';
import { Route, Router, Routes } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ActivityItem, AuctionItem, NazioneItem, OrganizationItem, OrganizationOperatorItem, OrganizationPlantItem, ProvinciaItem, UserItem, UserOperatorItem } from 'src/app/models/dataModels';
import { APIService } from 'src/app/services/APIService';
import { AuctionService } from 'src/app/services/auction.service';
import { AuthService } from 'src/app/services/auth.service';
import { CommonService } from 'src/app/services/common.service';
import { ConstantsModule } from 'src/app/services/constants.module';
import { LocalUtilService } from 'src/app/services/local-util.service';
import { MessagesService } from 'src/app/services/messages.service';
import { OrganizationService } from 'src/app/services/organization.service';

@Component({
  selector: 'app-elenco-operatori-full',
  templateUrl: './elenco-operatori-full.component.html',
  styleUrls: ['./elenco-operatori-full.component.css']
})
export class ElencoOperatoriFullComponent extends APIService implements OnInit {

  @Input() operators: OrganizationOperatorItem[] = [];
  nation_list: NazioneItem[] = [];
  province_list: ProvinciaItem[] = [];

  constructor(public localService: LocalUtilService,
    public authService: AuthService,
    public auctionService: AuctionService,
    public organizationService: OrganizationService,
    public commonService: CommonService,
    private modalService: NgbModal,
    private messageService: MessagesService,
    location: Location, 
    router: Router) {
    super(localService, location, router);

  }

  ngOnInit(): void {
    this.loadNazioni();
    this.loadProvince();
  }

  loadNazioni() {
    this.commonService.getNationsList().subscribe(
        data => {
          let nazioneVuota = new NazioneItem();
          nazioneVuota.codice="";
          nazioneVuota.descrizione=" - ";          
          this.nation_list.push(nazioneVuota);
          this.nation_list.push.apply(this.nation_list, data.list);
      },
        this.handleError(true)
      );
  }

  loadProvince() {
    this.province_list = [];
    this.commonService.getProvinceItalianeList().subscribe(
        data => {
          let provinciaVuota = new ProvinciaItem();
          provinciaVuota.codice="";
          provinciaVuota.descrizione=" - ";          
          this.province_list.push(provinciaVuota);
          this.province_list.push.apply(this.province_list, data.list);
      },
        this.handleError(true)
      );
    }

    getOperatorStatusClass(itemOp:UserOperatorItem) {
      let output = '';
      if (itemOp.disabled == "Y") {
        output = "disabled";
        itemOp.status = "Disabilitato";
      } else if (itemOp.validated == "") {
        output = "pending";
        itemOp.status = "In attesa di verifica";
      } else if (itemOp.validated == "N") {
        output = "disabled";
        itemOp.status = "Rifiutato";
      } else if (itemOp.validated == "Y") {
        output = "active";
        itemOp.status = "Attivo";
      }
      return output;
    }
  
    getOperatorStatus(itemOp:UserOperatorItem) {
      let output = '';
      if (itemOp.disabled == "Y") {
        output = "Disabilitato";
      } else if (itemOp.validated == "") {
        output = "In attesa di verifica";
      } else if (itemOp.validated == "N") {
        output = "Rifiutato";
      } else if (itemOp.validated == "Y") {
        output = "Attivo";
      }
      return output;
    }

    onRowPrepared(info) {
      if (info.rowType === 'data') {
        info.rowElement.addClass('auction');
      }
    }

}
