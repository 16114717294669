<div class="modal-div" *ngIf="tipo == 'message'">
  <div class="modal-header" name="modal-dialog">
    <h4 class="modal-title" translate>{{titolo}}</h4>
    <button type="button" class="close modale" aria-label="Chiudi" (click)="chiudi()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <p translate>{{messaggio}}</p>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-primary modale" (click)="chiudi()" translate>Chiudi</button>
  </div>
</div>

<div class="modal-div" *ngIf="tipo == 'forgotPassword'">
  <form>
    <div class="modal-header" name="modal-dialog">
      <h4 class="modal-title" translate>{{titolo}}</h4>
      <button type="button" class="close modale" aria-label="Chiudi" (click)="chiudi()">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body">
      <div class="container">
        <div class="row">
          <div class="input">
            <span translate>loginPage.inserisci-email</span><br /><br />
            <div class="input-wrapper full">
              <input type="email" [(ngModel)]="email" id="forgot-email" [ngModelOptions]="{standalone: true}">
              <label for="email">Email</label>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="modal-footer">
      <button type="button" class="btn btn-default modale" (click)="cancel()" data-dismiss="modal"
        id="fail-close-btn">Cancel</button>
      <button type="button" class="btn btn-primary modale" (click)="okForgotPassword()" data-dismiss="modal"
        id="password-recover">Ok</button>
    </div>
  </form>
</div>

<div class="modal-div" *ngIf="tipo == 'openNote'">
  <form>
    <div class="modal-header" name="modal-dialog">
      <h5 class="modal-title" id="noteModalLabel">Note {{codiceLotto}}</h5>
      <button type="button" class="close modale" aria-label="Chiudi" (click)="chiudi()">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body">
      <div id="toolbar-container-note"></div>
      <ckeditor id="noteText" nome="noteText" [editor]="Editor" data="{{note}}"></ckeditor>
    </div>
    <div class="modal-footer">
      <button type="button" class="btn btn-primary modale" (click)="cancel()" data-dismiss="modal"
        id="fail-close-btn">Chiudi</button>
    </div>
  </form>
</div>

<!-- <div class="modal-div" *ngIf="tipo == 'centri-di-selezione'">
  <div class="modal-header" name="modal-dialog">
    <h4 class="modal-title">Aggiungi Centro di selezione</h4>
    <button type="button" class="close modale" aria-label="Chiudi" (click)="chiudi()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <div class="container">
      <div *ngIf="grid">
        <dx-data-grid id="gridContainer" [dataSource]="cs_list" keyExpr="id" [showColumnHeaders]="true" height="500px"
          [columns]="['codice', 'nome', 'indirizzo']" [showBorders]="true" (onEditingStart)="onEditingStart($event)">
          <dxo-paging [pageSize]="40"> </dxo-paging>
          <dxo-editing [allowUpdating]="true">
          </dxo-editing>
        </dx-data-grid>
      </div>
      <div *ngIf="form">
        <div class="input">
          <div class="input-wrapper full">
            <input type="text" id="codiceLotto" [(ngModel)]="quantita">
            <label for="newOperator--codiceLotto" translate>Quantità *</label>
          </div>
          <p *ngIf="mandatory" class="fw-bold text-danger mt-2 mb-5">Inserisci la quantita` di tonnellate per il centro di
            selezione</p>
        </div>
        <p class="fw-bold mb-4">Centro di selezione:</p>
        <p>{{editDetails}}</p>
      </div>
    </div>
  </div>
  <div class="modal-footer">
    <button *ngIf="button" type="button" class="btn" (click)="indietro()" data-dismiss="modal"
      id="fail-close-btn">Indietro</button>
    <button type="button" class="btn" (click)="cancel()" data-dismiss="modal" id="fail-close-btn">Annulla</button>
    <button *ngIf="button" type="button" class="btn btn-primary modale" (click)="save()" data-dismiss="modal"
      id="fail-close-btn">Aggiungi</button>
  </div>
</div> -->

<div class="modal-div" *ngIf="tipo == 'openCentriSelezione'">
  <div class="modal-header" name="modal-dialog">
    <h4 class="modal-title">{{titolo}}</h4>
    <button type="button" class="close modale" aria-label="Chiudi" (click)="chiudi()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <div class="container">
      <table id="csText" nome="csText">
        <tr>
          <th>Nome</th>
          <th>Quantita&grave;</th>
          <th>CER</th>
          <th>Peso medio carico</th>
          <th>Indirizzo</th>
        </tr>
        <tr *ngFor="let cs of centriSelezione; let i = index">
          <td>{{cs.nome}}</td>
          <td><b>{{cs.quantitaTon}}</b></td>
          <td>{{cs.CER}}</td>
          <td>{{cs.pesoMedioCarico}}</td>
          <td>{{cs.indirizzo}} - {{cs.cap}} - {{cs.citta}} ({{cs.provincia}}) - {{cs.nazioneDescr}}</td>
        </tr>
      </table>
    </div>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-primary modale" (click)="cancel()" data-dismiss="modal"
      id="fail-close-btn">Chiudi</button>
  </div>
</div>

<div class="modal-div" *ngIf="tipo == 'openCentriSelezioneFull'">
  <div class="modal-header" name="modal-dialog">
    <h4 class="modal-title">{{titolo}}</h4>
    <button type="button" class="close modale" aria-label="Chiudi" (click)="chiudi()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <div class="container">
      <table id="csText" nome="csText">
        <tr>
          <th>Nome</th>
          <th>Indirizzo</th>
          <th>Peso medio carico</th>
          <th></th>
        </tr>
        <tr *ngFor="let cs of centriSelezione; let i = index">
          <td>{{cs.nome}}</td>
          <td>{{cs.indirizzo}} - {{cs.cap}} - {{cs.citta}} ({{cs.provincia}}) - {{cs.nazioneDescr}}</td>
          <td>{{cs.pesoMedioCarico}}</td>
          <td><i class="fas fa-check pointer primary-color margin30" (click)="selectCs(cs)" title="Seleziona"
              tooltip="Seleziona"></i></td>
        </tr>
      </table>
    </div>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-primary modale" (click)="cancel()" data-dismiss="modal"
      id="fail-close-btn">Chiudi</button>
  </div>
</div>

<div class="modal-div" *ngIf="tipo == 'excelLotti'">
  <div class="modal-header" name="modal-dialog">
    <h4 class="modal-title">{{titolo}}</h4>
    <button type="button" class="close modale" aria-label="Chiudi" (click)="chiudi()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <div class="container-half">
      <div class="lots-list new-lots-list admin-buttons">
        <!--div class="lot"-->
        <article class="popup">
          <div class="buttons ingrid buttons-left">

            <div class="doc-datas">
              <app-file-box docname="excel-lotti.xlsx" [showCoripetButtons]="false"
                (file)="getExcelLottiFile($event)"></app-file-box>
            </div>
            <br />
            <button class="box__file btn btncarica success btn-primary popup" id="btnCaricaXlsxLotti"
              (click)="caricaExcelLotti()">Carica</button>
            <button class="box__file btn btncarica success btn-primary popup" id="btnScaricaXlsxTemplate"
              (click)="downloadTemplateExcelLotti()">Scarica template Excel<i class="fa fa-download white"></i></button>
          </div>
        </article>
      </div>
    </div>
    <div class="modal-footer">
      <button type="button" class="btn btn-primary modale" (click)="cancel()" data-dismiss="modal"
        id="fail-close-btn">Chiudi</button>
    </div>
  </div>
</div>

<div class="modal-div" *ngIf="tipo == 'loadOrgDocument'">
  <div class="modal-header" name="modal-dialog">
    <h4 class="modal-title">{{titolo}}</h4>
    <button type="button" class="close modale" aria-label="Chiudi" (click)="chiudi()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <div class="container-half">
      <div class="lots-list new-lots-list admin-buttons">
        <!--div class="lot"-->
        <article class="popup">
          <div class="buttons ingrid buttons-left">

            <div class="doc-datas">
              <app-file-box status="undefined" [docname]="docname" [showCoripetButtons]="false"
                (file)="getDocumentoAzienda($event)"></app-file-box>
            </div>
            <br />
            <button class="box__file btn btncarica success btn-primary popup"
              (click)="caricaDocumentoAzienda()">Carica</button>
          </div>
        </article>
      </div>
    </div>
    <div class="modal-footer">
      <button type="button" class="btn btn-primary modale" (click)="cancel()" data-dismiss="modal"
        id="fail-close-btn">Chiudi</button>
    </div>
  </div>
</div>

<div class="modal-div" *ngIf="tipo == 'openLotDetail'">
  <div class="modal-header" name="modal-dialog">
    <h4 class="modal-title">{{titolo}}</h4>
    <button type="button" class="close modale" aria-label="Chiudi" (click)="chiudi()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <div class="container">

      <div class="" id="lot-detail-tmpl" *ngIf="lot">
        <div class="new-lots-list won">
          <div class="lot">
            <article>
              <div class="left">
                <div class="lot-row">
                  <div class="lot-cell">
                    <div class="lot-number">
                      <div class="star star"></div>
                      <div class="num">
                        {{lot.codiceLotto}}
                      </div>
                    </div>
                  </div>
                  <div class="lot-cell tipo-e-scheda">
                    <div class="type"><span translate>popup.tipo</span> <b>{{lot.tipoProdotto}}</b></div>
                    <div *ngIf="lot.schedaTecnica" class="download-scheda" data="scheda-tecnica" title="Scheda tecnica">
                      <i class="fal fa-file-pdf"></i>
                      <div class="filename-scheda-tecnica hidden">scheda-tecnica-{{lot.codiceLotto}}.pdf</div>
                    </div>
                    <div>
                      <a name="openNote" class="note" (click)="openNote(note, codiceLotto)"> Note</a>
                    </div>
                  </div>
                  <div class="lot-cell small">
                    <div class="quantity">
                      <span translate>popup.quantita</span>
                      <b>{{lot.quantitaTon}} t</b>
                    </div>
                  </div>
                </div>
                <div class="lot-row">
                  <div class="lot-cell">
                    <div class="area">
                      <span translate>popup.area</span>: <b>{{lot.areaGeografica}}</b>
                    </div>
                  </div>
                  <div class="lot-cell small">
                    <div class="relaunch">
                      <span translate>popup.rilanci</span> <b>{{lot.numeroRilanci}}</b>
                    </div>
                  </div>
                  <div class="lot-cell">
                    <div class="auction-close">
                      <span translate>popup.chiusura-asta</span> <b>{{lot.dataOraFineAstaEffettiva}}</b>
                    </div>
                  </div>
                </div>
                <div class="lot-row">
                  <div class="lot-cell" name="contendentiUnici" id="contendentiUnici">
                    <span translate>popup.numero-contendenti-unici</span>{{lot.numContendentiUnici}}
                  </div>
                  <div class="lot-cell" id="centri-selezione-title" name="centri-selezione-title">
                    <span translate>popup.centri-di-selezione</span>
                  </div>
                </div>
                <div class="lot-row scrolly">
                  <div class="lot-cell">
                  </div>
                  <div class="lot-cell" id="centri-selezione-table" name="centri-selezione-table">
                    <div *ngFor="let csLot of lot.centroSelezione; let i = index" id="centri-selezione-template">
                      <div class="csrow1"><b>{{csLot.nome}}&nbsp;</b> - CER: {{lot.codiceRifiutoCER}}</div>
                      <div class="csrow2"><b translate>popup.quantita</b> {{csLot.quantitaTon}} Ton</div>
                      <div class="csrow3"><b translate>popup.peso-medio-carico</b> {{csLot.pesoMedioCarico}}</div>
                      <div class="csrow4"><b translate>popup.indirizzo</b> {{csLot.indirizzo}} - {{csLot.cap}}
                        {{csLot.citta}} ({{csLot.provincia}})</div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="right">
                <div class="lot-row">
                  <div class="lot-cell total ok">
                    <span translate>popup.ultimo-rilancio</span> <b>{{lot.prezzoUltimoRilancio}} &euro;</b>
                  </div>
                </div>
                <div class="lot-row">
                  <span translate>popup.base-d-asta</span> <b>{{lot.prezzoBase}}</b>
                </div>
                <br />
                <div class="lot-row" id="centri-selezione">
                  <span translate>popup.cronologia.rilanci</span> <br />
                </div>
                <div class="lot-row centri-selezione-table" id="lots_history_popup_list" name="lots_history_popup_list"
                  *ngIf="lot.rilanciLotto">
                  <div *ngFor="let rilancio of lot.rilanciLotto; let i = index">
                    <span>{{i}}</span> - <span>{{rilancio.prezzoRilancio}} &euro;</span> <span translate> popup.alle
                    </span><span>{{rilancio.createDate}}</span><br />
                  </div>
                </div>
              </div>
            </article>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-primary modale" (click)="cancel()" data-dismiss="modal" id="fail-close-btn"
      translate>popup.chiudi</button>
  </div>
</div>