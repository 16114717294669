import { AfterViewInit, Component, OnInit } from '@angular/core';
import { Location } from '@angular/common';
import { Route, Router, Routes } from '@angular/router';
import { LocalUtilService } from 'src/app/services/local-util.service';
import { AuthService } from 'src/app/services/auth.service';
import { APIService } from 'src/app/services/APIService';
import { ConstantsModule } from 'src/app/services/constants.module';
import { MenuListItem, NewMenuListItem } from 'src/app/models/dataModels';

@Component({
  selector: 'app-spalla',
  templateUrl: './spalla.component.html',
  styleUrls: ['./spalla.component.css']
})
export class SpallaComponent extends APIService implements OnInit, AfterViewInit {

  isMenuOpen = false;

  constructor(localUtil: LocalUtilService,
    location: Location,
    router: Router,
    public authService: AuthService) {
    super(localUtil, location, router);
  }

  menuList: NewMenuListItem[];
  subMenuList: NewMenuListItem[];

  ngAfterViewInit() {
  }

  ngOnInit(): void {
    /*  const fullUrl = window.location.href;
     const parts = fullUrl.split("/");
     const pageName = parts[parts.length - 1]; // Get the last part of the URL
     console.log(pageName);
     this.authService.getMenuListByPage(pageName).subscribe(
       (data: any) => {
         if (data.list != undefined) {
           console.log(data.list);
           this.subMenuList = data.list;
           console.log(data.list)
         }
       }
     ) */
    this.authService.getMenuList().subscribe(
      (data: any) => {
        //  console.log(data.list)
        var landingAvailable = false;
        if (data.list != undefined) {
          // console.log(this.menuList)
          this.menuList = data.list;
          this.openChildItems();

          this.checkLanding();
        }
      }, this.handleError(true)
    );

    this.localUtil.subscribeLogout().subscribe(
      data => {
        if (data && data == true) {
          this.doLogout();
        }
      }
    )

  }

  toggleMenus() {
    this.isMenuOpen = !this.isMenuOpen; // Toggle the menu state
  }

  checkLanding() {
    let currentUrl = this.router.url;
    if (this.menuList != undefined && this.menuList.length > 0) {
      if (currentUrl != undefined && currentUrl.length > 0) {
        currentUrl = currentUrl.substring(1);
        if (currentUrl == '') {
          //path inesistente, vado alla pagina islanding = true
          this.menuList.forEach(item => {
            if (item.isLanding) {
              this.router.navigate([item.key, {}]);
            }
          });
        }
      }
    }
  }

  openChildItems() {
    const itemsToOpen = this.menuList.filter(item => item.openChild);

    itemsToOpen.forEach(item => {
      this.openChilds(item);
    });
  }



  openChilds(itemMenu: NewMenuListItem) {
    this.authService.getMenuListByPage(itemMenu.page).subscribe(
      (data: any) => {
        //console.log(itemMenu.page);
        if (data.list != undefined) {
          this.subMenuList = data.list;
          //console.log(data.list)
        }
      }
    )

  }

  isActiveSubMenu(menuItem: NewMenuListItem) {
    let output = false;
    let currentUrl = this.router.url;
    if (!menuItem.key.startsWith('/')) {
      if (currentUrl != undefined && currentUrl.length > 1) {
        //tolgo lo slash iniziale
        currentUrl = currentUrl.substring(1);
      }
    }
    //const urlforActiveSubMenu = menuItem.page.replace('.html', ''); // Remove the '.html' extension
    const urlforActiveSubMenu = menuItem.page;
    if (currentUrl == urlforActiveSubMenu) {
      output = true;
    }
    return output;
  }

  gotoUrl(url: string) {
    //this.localUtil.showCompanySubHeader();
    //this.localUtil.hideCompanyCapacityHeader(); 
    console.log(url);
    // const urlWithoutExtension = url.replace('.html', ''); // Remove the '.html' extension
    this.router.navigate([url, {}]);
  }

  isActiveFather(fatherItem: NewMenuListItem) {
    let output = false;
    //console.log(fatherItem)

    if (this.subMenuList != undefined && this.subMenuList.length > 0) {
      this.subMenuList.forEach(subMenuItem => {
        if (this.isActiveSubMenu(subMenuItem) && subMenuItem.parent == fatherItem.key) {
          output = true;
        }
      });
    } else {
      let currentUrl = this.router.url;
      if (!fatherItem.page.startsWith('/')) {
        if (currentUrl != undefined && currentUrl.length > 1) {
          //tolgo lo slash iniziale
          currentUrl = currentUrl.substring(1);
        }
      }
      //  const urlforActive = fatherItem.page.replace('.html', ''); // Remove the '.html' extension
      const urlforActive = fatherItem.page; // Remove the '.html' extension
      if (currentUrl == urlforActive) {
        output = true;
      }
    }
    return output;
  }

  doLogout() {

    let data: any;
    this.authService.logout().subscribe(
      data => {
      },
      this.handleError(true)
    );

    sessionStorage.removeItem("user");
    sessionStorage.clear();
    localStorage.clear();
    this.localUtil.removeAuthParams();
    this.router.navigate([ConstantsModule.REF_AZ_LOGIN_PATH, {}]);


  }




  showUl: boolean = true;
  toggleMenu() {
    $("#container").toggleClass("sidebar_closed");
    $('#cards-lots-list-header').css('width', $('#cards-lots-list').css('width'));
    setTimeout("$('.auctions-list').masonry({itemSelector: '.auction-item',columnWidth: 330,gutter: 30});", 100);
    this.setHeaderSticky();
  }


  setHeaderSticky() {
    let togVisibility = $('#main-menu-items').css('visibility');
    if (togVisibility == 'visible') {
      console.log("IF");
      this.showUl = true;
      togVisibility = 'hidden';
      //togVisibility = 'visible';
    } else {
      console.log("ELSE");
      this.showUl = false;
      togVisibility = 'visible';
    }
    $('#main-menu-items').css('visibility', togVisibility);
    $('#main-menu-items2').css('visibility', togVisibility);
    $('#logo').css('visibility', togVisibility);

    if ($('#cards-lots-list').length > 0) {
      $('#cards-lots-list-header').css('width', $('#cards-lots-list').css('width'));
    }
    
    if ($("#container").hasClass("sidebar_closed")) {

      $('header.fixed-header').addClass('full');
      $('.overview-tool.fixed-parameters').addClass('full');
      if ($('#cards-lots-list').length > 0) {
        $('#cards-lots-list-header').css('margin-left', $('#cards-lots-list').offset().left - 10);
      }

    } else {

      $('header.fixed-header').removeClass('full');
      $('.overview-tool.fixed-parameters').removeClass('full');
      if ($('#cards-lots-list').length > 0) {
        $('#cards-lots-list-header').css('margin-left', $('#cards-lots-list').offset().left - 10 - 230 + 'px');
        $('#cards-lots-list-header').css('width', $('#cards-lots-list').css('width'));
      }
    }

  }

}
