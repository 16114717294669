<div id="upload-legale" class="content top100">
    <!-- AGGIUNGERE FOCUSED SE SI VUOLE "ILLUMINARE" IL PANNELLO -->
    <section class="step-section focused">
        <div class="card">
            <h2>Documento rappresentante legale</h2>
            <div class="doc-datas doc-form-upload">
                <app-file-box [uuid]="existsDocument != undefined ? existsDocument.uuid : undefined" [status]="existsDocument != undefined ? existsDocument.status : ''" docname="rappresentante-legale.pdf" (file)="getLegaleRappresentanteFile($event)" [showError]="false" [showCoripetButtons]="false"></app-file-box>
            </div>
        </div>
        <div class="infos">
            <div class="number-wrapper">
                <div class="number">01</div>
                <div class="label">
                    Rappresentante legale
                </div>
            </div>
        </div>
    </section>
    <div class="main-actions">
        <a (click)="back()" class="btn">Annulla</a>
        <button class="btn primary btn-primary" (click)="uploadLegale()" id="btn_fileUpload" data-sector="legale">Invia</button>
    </div>
</div>