import { Location } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Route, Router, Routes } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ConfirmComponent } from 'src/app/commons/components/confirm/confirm.component';
import { ActivityItem, AuctionItem, ItemInterface, OrganizationItem, OrganizationPlantItem, UserItem, UserOperatorItem, UserOrgItem } from 'src/app/models/dataModels';
import { APIService } from 'src/app/services/APIService';
import { AuctionService } from 'src/app/services/auction.service';
import { AuthService } from 'src/app/services/auth.service';
import { ConstantsModule } from 'src/app/services/constants.module';
import { LocalUtilService } from 'src/app/services/local-util.service';
import { MessagesService } from 'src/app/services/messages.service';
import { OrganizationService } from 'src/app/services/organization.service';

@Component({
  selector: 'app-coripet-azienda-dettaglio',
  templateUrl: './azienda-dettaglio.component.html',
  styleUrls: ['./azienda-dettaglio.component.css']
})
export class CoripetAziendaDettaglioComponent extends APIService implements OnInit {

  constructor(public localService: LocalUtilService,
    public authService: AuthService,
    public auctionService: AuctionService,
    public organizationService: OrganizationService,
    private modalService: NgbModal,
    private messageService: MessagesService,
    location: Location,
    router: Router,
    private route: ActivatedRoute) {
    super(localService, location, router);

  }

  organization: OrganizationItem;
  userOrganization: UserItem;
  plant: OrganizationPlantItem;
  operator: UserOperatorItem;
  vatnumber: string;

  organizations_list: OrganizationItem[];
  plants_list: OrganizationPlantItem[];
  operators_list: UserOperatorItem[];

  ngOnInit(): void {
    this.organizations_list = [];
    this.plants_list = [];
    this.operators_list = [];
    //this.vatnumber = this.route.snapshot.paramMap.get('vatnumber');
    let idOrg = this.route.snapshot.paramMap.get('vatnumber');
    console.log('CoripetAziendaDettaglioComponent() idOrg: ' + idOrg);
    this.loadOrganizationDetail(idOrg);  }

  loadOrganizationDetail(idOrg: string) {
    this.organizationService.getOrganizationDetail(idOrg).subscribe(
      data => {
        console.log(data);
        this.organization = data.detail;
        this.vatnumber = this.organization.orgname;
        console.log('CoripetAziendaDettaglioComponent() vatnumber: ' + this.vatnumber);
        try {

          if (this.organization.status != undefined) {
            this.organization.statusClass = this.organization.status.toLowerCase();
          }

          if (this.organization.disabled == "Y") {
            this.organization.statusClass = "disabled";
            this.organization.status = "Disabilitata";
          } else if (this.organization.validated == "N") {
            this.organization.statusClass = "disabled";
            this.organization.status = "In attesa di riverifica";
          } else if (this.organization.validated == "") {
            this.organization.statusClass = "pending";
            this.organization.status = "In attesa di verifica";
          } else if (this.organization.validated == "Y") {
            this.organization.statusClass = "active";
            this.organization.status = "Abilitata";
          }

          this.organizationService.getUserDetail(this.organization.userId).subscribe(
            (res: any) => {
              console.log(res);
              let user: UserItem = res.detail;
              this.organization.referente = user.nome + ' ' + user.cognome;
              this.userOrganization = res.detail;
            }, this.handleError(true)
          );

          this.organizations_list.push(this.organization);
        }
        catch (err) {
          let params: any[] = [this.organization.userId];
          this.localUtil.showError("", ConstantsModule.ERROR_TITLE, this.messageService.getLabelFromCode("USER_NOT_FOUND", params));
        }
        this.loadOrganizationPlants(data.detail.orgname);
        this.loadOrganizationOperators(data.detail.orgname);
      }, this.handleError(true)
    );
  }

  loadOrganizationPlants(orgname) {
    this.organizationService.getOrganizationPlants(orgname).subscribe(
      data => {

        if (data.list) {
          data.list.forEach((itemImpianto, index) => {
            if (itemImpianto.certificazioneImpiantoValid == "") {
              itemImpianto.statusClass = "pending";
              itemImpianto.status = "In attesa di verifica";
            } else if (itemImpianto.certificazioneImpiantoValid == "N") {
              itemImpianto.statusClass = "disabled";
              itemImpianto.status = "Rifiutato";
            } else if (itemImpianto.certificazioneImpiantoValid == "Y") {
              itemImpianto.statusClass = "active";
              itemImpianto.status = "Attivo";
            }

            var iso9001 = true;
            if (itemImpianto.certificazioneIso9001 == "") {
              iso9001 = false;
              itemImpianto.statusDescr9001 = this.messageService.getLabelFromCode("DESCR_DOC_MANCANTE");
              itemImpianto.fileClass9001 = "ko";
            } else {
              iso9001 = true;
              if (itemImpianto.certificazioneIso9001Valid == "Y") {
                itemImpianto.statusDescr9001 = this.messageService.getLabelFromCode("DESCR_DOC_APPROVATO");
                itemImpianto.fileClass9001 = "ok";
              } else if (itemImpianto.certificazioneIso9001Valid == "") {
                itemImpianto.statusDescr9001 = this.messageService.getLabelFromCode("DESCR_DOC_DA_APPROVARE");
                itemImpianto.fileClass9001 = "pending";
              } else if (itemImpianto.certificazioneIso9001Valid == "N") {
                itemImpianto.statusDescr9001 = this.messageService.getLabelFromCode("DESCR_DOC_RIFIUTATO");
                itemImpianto.fileClass9001 = "ko";
              }
            }

            var iso14001 = true;
            if (itemImpianto.certificazioneIso14001 == "") {
              iso14001 = false;
              itemImpianto.statusDescr14001 = this.messageService.getLabelFromCode("DESCR_DOC_MANCANTE");
              itemImpianto.fileClass14001 = "ko";
            } else {
              iso14001 = true;
              if (itemImpianto.certificazioneIso14001Valid == "Y") {
                itemImpianto.statusDescr14001 = this.messageService.getLabelFromCode("DESCR_DOC_APPROVATO");
                itemImpianto.fileClass14001 = "ok";
              } else if (itemImpianto.certificazioneIso14001Valid == "") {
                itemImpianto.statusDescr14001 = this.messageService.getLabelFromCode("DESCR_DOC_DA_APPROVARE");
                itemImpianto.fileClass14001 = "pending";
              } else if (itemImpianto.certificazioneIso14001Valid == "N") {
                itemImpianto.statusDescr14001 = this.messageService.getLabelFromCode("DESCR_DOC_RIFIUTATO");
                itemImpianto.fileClass14001 = "ko";
              }
            }

            var docAuth = true;
            if (itemImpianto.docAutorizzazione == "") {
              docAuth = false;
              itemImpianto.statusDescrDocAuth = this.messageService.getLabelFromCode("DESCR_DOC_MANCANTE");
              itemImpianto.fileClassDocAuth = "ko";
            } else {
              docAuth = true;
              if (itemImpianto.docAutorizzazioneValid == "Y") {
                itemImpianto.statusDescrDocAuth = this.messageService.getLabelFromCode("DESCR_DOC_APPROVATO");
                itemImpianto.fileClassDocAuth = "ok";
              } else if (itemImpianto.docAutorizzazioneValid == "") {
                itemImpianto.statusDescrDocAuth = this.messageService.getLabelFromCode("DESCR_DOC_DA_APPROVARE");
                itemImpianto.fileClassDocAuth = "pending";
              } else if (itemImpianto.docAutorizzazioneValid == "N") {
                itemImpianto.statusDescrDocAuth = this.messageService.getLabelFromCode("DESCR_DOC_RIFIUTATO");
                itemImpianto.fileClassDocAuth = "ko";
              }
            }

            if (!iso9001 || !iso14001 || !docAuth) {
              itemImpianto.statusDoc = this.messageService.getLabelFromCode("DESCR_DOC_DA_APPROVARE") + " o " + this.messageService.getLabelFromCode("DESCR_DOC_MANCANTI");
            } else {
              itemImpianto.statusDoc = "";
            }

            this.plants_list.push(itemImpianto);
          });

        }

      }, this.handleError(true));
  }

  loadOrganizationOperators(vatnumber: string) {
    this.organizationService.getOrganizationOperators(vatnumber).subscribe(
      data => {

        if (data.list) {
          let operators: UserOperatorItem[] = data.list;
          operators.forEach((itemOp, index) => {

            if (itemOp.disabled == "Y") {
              itemOp.statusClass = "disabled";
              itemOp.status = "Disabilitato";
            } else if (itemOp.validated == "") {
              itemOp.statusClass = "pending";
              itemOp.status = "In attesa di verifica";
            } else if (itemOp.validated == "N") {
              itemOp.statusClass = "disabled";
              itemOp.status = "Rifiutato";
            } else if (itemOp.validated == "Y") {
              itemOp.statusClass = "active";
              itemOp.status = "Attivo";
            }

            if (itemOp.deleted != "Y") {
              this.operators_list.push(itemOp);
            }
          });

        }

      }, this.handleError(true));
  }

  gotoBack() {
    this.location.back();
  }

  annullaModifica(data) {
    data.editing = !data.editing;
    this.closeDettaglio(data);
    this.toggleMenu(data);
  }

  modificaOrg(data) {
    data.editing = !data.editing;
    if (data.editing) {
      this.disableAllEditForms(this.organizations_list);
      data.editing = true;
    }
    this.openDettaglioOrg(data);
    this.closeMenu(data);
  }

  modificaImpianto(data) {
    data.editing = !data.editing;
    if (data.editing) {
      this.disableAllEditForms(this.plants_list);
      data.editing = true;
    }
    this.openDettaglioPlant(data);
    this.closeMenu(data);
  }

  eliminaImpianto(data) {

    let params: any[] = [data.nomeImpianto];
    const modalRef = this.modalService.open(ConfirmComponent);
    modalRef.componentInstance.titolo = this.messageService.getLabelFromCode("DELETE_FRAMEWORK_TITLE");
    modalRef.componentInstance.messaggio = this.messageService.getLabelFromCode("DELETE_FRAMEWORK_MESSAGE", params);
    modalRef.componentInstance.passEntry.subscribe((receivedEntry: any) => {
      if (receivedEntry != undefined) {

        //let confirm = receivedEntry.trim();// non serve nessun parametro di ritorno da popup confirm

        this.organizationService.deleteFramework(this.localUtil.authParams.organization_detail.orgname, data.id).subscribe(
          (res) => {
            this.localUtil.showMessage("", ConstantsModule.INFO_TITLE, this.messageService.getLabelFromCode("SUCCESS_FRAMEWORK_DELETE"), true);
            this.loadOrganizationPlants(this.vatnumber);
          },
          this.handleError(true)
        );

      }
    });

  }

  openDettaglioPlant(data) {
    data.formOpened = true;
    this.plant = data;
    this.userOrganization = data;
  }

  closeMenu(data) {
    data.menuOpened = false;
  }

  openDettaglioOrg(data) {
    data.formOpened = true;
    this.organization = data;
    this.userOrganization = data;
  }

  closeDettaglio(data) {
    console.log(data);
    data.formOpened = false;
  }

  isEditing(data) {
    return (data.editing == true);
  }

  toggleDettaglioOrg(data) {
    data.formOpened = !data.formOpened;
    if (data.formOpened) {
      this.disableAllEditForms(this.organizations_list);
      this.organization = data;
      data.formOpened = true;
    }
  }

  toggleDettaglioPlant(data) {
    data.formOpened = !data.formOpened;
    if (data.formOpened) {
      this.disableAllEditForms(this.plants_list);
      this.plant = data;
      data.formOpened = true;
    }
  }

  toggleDettaglioOperator(data) {
    data.formOpened = !data.formOpened;
    if (data.formOpened) {
      this.disableAllEditForms(this.operators_list);
      this.operator = data;
      data.formOpened = true;
    }
  }

  isMenuOpened(data) {
    return (data.menuOpened == true);
  }

  toggleMenu(data) {
    console.log(data);
    data.menuOpened = !data.menuOpened;
  }

  isFormOpened(data) {
    return (data.formOpened == true);
  }

  disableAllEditForms(list) {
    if (list != undefined) {
      list.forEach((item: ItemInterface) => {
        item.editing = false;
        item.formOpened = false;
        item.menuOpened = false;
      });
    }
  }


  onContentReady(event) {
    $('.dx-datagrid-headers').hide();
    $('.dx-hidden').hide();
    //$('.companies-list .dx-data-row tr').addClass('company2');
    //$('.dx-datagrid-nodata').html('<h3>Nessun risultato trovato</h3>');
  }

}
