import { Location } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ConfirmComponent } from 'src/app/commons/components/confirm/confirm.component';
import { OrganizationOperatorCreateItem, OrganizationOperatorItem } from 'src/app/models/dataModels';
import { APIService } from 'src/app/services/APIService';
import { AuthService } from 'src/app/services/auth.service';
import { ConstantsModule } from 'src/app/services/constants.module';
import { LocalUtilService } from 'src/app/services/local-util.service';
import { MessagesService } from 'src/app/services/messages.service';
import { OrganizationService } from 'src/app/services/organization.service';

@Component({
  selector: 'app-azienda-operatori', 
  templateUrl: './azienda-operatori.component.html',
  styleUrls: ['./azienda-operatori.component.css'], 
})
export class AziendaOperatoriComponent extends APIService implements OnInit {
 
  attivi: boolean = true;
  disabilitati: boolean = true;

  operators_list: OrganizationOperatorItem[] = [];
  operator: OrganizationOperatorItem = new OrganizationOperatorItem();
  operatorForm: FormGroup;
  submitted: boolean = false;
  password_fields: string[] = ['password', 'confermaPassword'];

  constructor(public localService: LocalUtilService,
    public authService: AuthService,
    public organizationService: OrganizationService,
    private modalService: NgbModal,
    private messageService: MessagesService,
    location: Location,
    router: Router) {
    super(localService, location, router);

    this.operator.lingua = 'IT';

    this.operatorForm = new FormGroup({
      nome: new FormControl(this.operator.nome, [Validators.required]),
      cognome: new FormControl(this.operator.cognome, [Validators.required]),
      email: new FormControl(this.operator.email, [Validators.required, Validators.email]),
      password: new FormControl(this.operator.password, [Validators.required]),
      confermaPassword: new FormControl(this.operator.confermaPassword, [Validators.required]),
      telefono: new FormControl(this.operator.telefono, [Validators.required]),
      mobile: new FormControl(this.operator.mobile, [Validators.required]),
      lingua: new FormControl(this.operator.lingua, [Validators.required]),
    });
  }

  ngOnInit(): void {
    console.log('init()');
    this.listOperators();
  }

  attiviCheckBox() {
    this.listOperators();
  }

  disabilitatiCheckBox() {
    this.listOperators();
  }

  isNewEditing() {
    return (this.operator.newEditing == true);
  }

  listOperators() {
    this.operators_list = [];
    let list_to_add: OrganizationOperatorItem[] = [];
    this.organizationService.getOrganizationOperators(this.localUtil.authParams.organization_detail.orgname).subscribe(
      (data: any) => {
        console.log('setting operators list: ' + data.list.length);
        if (data.list) {
          data.list.forEach((item, index) => {
            if (item.disabled == "Y") {
              console.log('user ' + item.nome + ' disabled');
              item.statusClass = "disabled";
              item.status = "Disabilitato";
              item.stato = "disabilitato";
            } else if (item.validated == "N") {
              console.log('user ' + item.nome + ' not yet validated');
              item.statusClass = "pending";
              item.status = "In attesa di verifica";
            } else if (item.disabled == "N") {
              console.log('user ' + item.nome + ' active');
              item.statusClass = "active";
              item.status = "Attivo";
              item.stato = "abilitato";
            }

            if (item.role == "OPERATORE") {
              item.roleDescr = "Operatore Coripet";
            } else if (item.role == "OPER_PLASTICFINDER") {
              item.roleDescr = "Admin";
            } else if (item.role == "OPER_AZIENDALE") {
              item.roleDescr = "Operatore Aziendale";
            } else if (item.role == "REF_AZIENDALE") {
              item.roleDescr = "Referente Aziendale";
            }

            if (item.deleted != "Y") {
              list_to_add.push(item);
            }
          });

        }
        //this.operators_list = list_to_add;

        if (this.attivi == true) {
          console.log('attivi true');
          for (let val of list_to_add) {
            if (val.status === 'Attivo') {
              this.operators_list.push(val);
            }
          }
        } 
        
        if (this.disabilitati == true) {
          console.log('disabilitati true');
          for (let val of list_to_add) {
            console.log('val.status: ' + val.status);
            if (val.status === 'Disabilitato') {
              this.operators_list.push(val); 
            }
          }
        } 
      });
  }

  toggleNuovoOperatore() {
    this.operator.newEditing = !this.operator.newEditing;
    if (this.operator.newEditing) {
      this.cleanValidation();
    }
  }

  cleanValidation() {
    this.operatorForm.markAllAsTouched();
  }

  annullaNuovoOperatore() {
    this.operator = new OrganizationOperatorItem();
    this.operator.newEditing = false;
  }

  salvaNuovoOperatore() {
    if (this.validate()) {
      console.log('salvaNuovoOperatore() form valido');
      let newOp: OrganizationOperatorCreateItem = new OrganizationOperatorCreateItem();
      newOp = {
        nome: this.operatorForm.get('nome').value,
        cognome: this.operatorForm.get('cognome').value,
        email: this.operatorForm.get('email').value,
        lingua: this.operatorForm.get('lingua').value,
        telefono: this.operatorForm.get('telefono').value,
        mobile: this.operatorForm.get('mobile').value,
        password: this.operatorForm.get('password').value,
        confermaPassword: '',
        role: '',
      };

      this.organizationService.createUser(this.localUtil.authParams.organization_detail.orgname, newOp).subscribe(
        (res) => {
          this.localUtil.showMessage("", ConstantsModule.INFO_TITLE, this.messageService.getLabelFromCode("GENERIC_SUCCESS_MESSAGE_SAVE"), true);
          this.annullaNuovoOperatore();
          this.listOperators();
        },
        this.handleError(true)
      );

    } else {
      this.localUtil.showMessage("", ConstantsModule.ERROR_TITLE, this.messageService.getLabelFromCode("ERROR_FORM_VALIDATION_MESSAGE"), true);
    }
  }

  validate() {
    console.log('validate()');
    this.submitted = true;
    let output = true;
    console.log(this.operatorForm);
    Object.keys(this.operatorForm.controls).forEach(key => {
      //console.log(key + ' ' + this.operatorForm.get(key).value + ' valid?: ' + this.operatorForm.get(key).valid);
      if (!this.operatorForm.get(key).valid) {
        output = false;
      }
    });
    console.log('password check this.operator.password: ' + this.operatorForm.get('password').value + ' valid: ' + this.operatorForm.get('password').valid);
    console.log('password check this.operator.confermaPassword: ' + this.operatorForm.get('confermaPassword').value + ' valid: ' + this.operatorForm.get('confermaPassword').valid);
    if (this.operatorForm.get('password').valid && this.operatorForm.get('confermaPassword').valid) {
      if (this.operatorForm.get('password').value != this.operatorForm.get('confermaPassword').value) {
        console.log('password check');
        output = false;
        //ERROR_AZ_OP_CREATE_PASSWORD_NOT_EQUAL
        this.operatorForm.controls['password'].setErrors({ 'incorrect': true, 'message': this.messageService.getLabelFromCode("ERROR_AZ_OP_CREATE_PASSWORD_NOT_EQUAL") });
        this.operatorForm.controls['confermaPassword'].setErrors({ 'incorrect': true, 'message': this.messageService.getLabelFromCode("ERROR_AZ_OP_CREATE_PASSWORD_NOT_EQUAL") });
      } else if (this.operatorForm.get('password').value.trim().length < 6) {
        //ERROR_AZ_OP_CREATE_PASSWORD_LENGTH
        this.operatorForm.controls['password'].setErrors({ 'incorrect': true, 'message': this.messageService.getLabelFromCode("ERROR_AZ_OP_CREATE_PASSWORD_LENGTH") });
        this.operatorForm.controls['confermaPassword'].setErrors({ 'incorrect': true, 'message': this.messageService.getLabelFromCode("ERROR_AZ_OP_CREATE_PASSWORD_LENGTH") });
        output = false;
      }
    } else {
      output = false;
    }
    return output;
  }

  getControlErrorMessage(name) {
    let output = '';
    console.log(this.operatorForm.controls[name].errors);
    let errors = this.operatorForm.controls[name].errors;
    output = errors['message'];
    return output;
  }

  validateField(nome) {
    return this.operatorForm.get(nome).valid;
  }

  controlHasError(nome) {
    return !this.operatorForm.get(nome).valid && this.submitted;
  }

  controlPassHasError(nome1, nome2) {
    let ctl1 = this.operatorForm.get('passwords.' + nome1);
    let ctl2 = this.operatorForm.get('passwords.' + nome2);
    console.log('ctl1: ');
    console.log(this.operator[nome1]);
    console.log('ctl2: ');
    console.log(this.operator[nome2]);
    return false;
  }


  onRowPrepared(info) {
    if (info.rowType === 'data') {
      //console.log('onRowPrepared() info.rowElement: ' + info.rowElement);
    }
  }

  onContentReady(event) {
    $('.dx-datagrid-headers').hide();
    $('.dx-hidden').hide();
    //$('.dx-datagrid-nodata').html('<h3>Nessun risultato trovato</h3>');
  }

  toggleDettaglio(data) {
    data.formOpened = !data.formOpened;
    if (data.formOpened) {
      this.disableAllEditForms();
      this.operator = data;
      data.formOpened = true;
    }
  }

  openDettaglio(data) {
    data.formOpened = true;
    this.operator = data;
  }

  closeDettaglio(data) {
    console.log(data);
    data.formOpened = false;
  }

  isFormOpened(data) {
    return (data.formOpened == true);
  }

  toggleMenu(data) {
    console.log(data);
    data.menuOpened = !data.menuOpened;
  }

  closeMenu(data) {
    data.menuOpened = false;
  }

  isMenuOpened(data) {
    return (data.menuOpened == true);
  }

  modificaOperatore(data) {
    data.editing = !data.editing;
    if (data.editing) {
      this.disableAllEditForms();
      data.editing = true;
    }
    this.openDettaglio(data);
    this.closeMenu(data);
  }

  disableAllEditForms() {
    if (this.operators_list != undefined) {
      this.operators_list.forEach((operator: OrganizationOperatorItem) => {
        operator.editing = false;
        operator.formOpened = false;
        operator.menuOpened = false;
      });
    }
  }

  annullaModificaOperatore(data) {
    data.editing = !data.editing;
    this.closeDettaglio(data);
    this.toggleMenu(data);
  }

  isEditing(data) {
    return (data.editing == true);
  }

  aggiornaOperatore(data) {

    this.organizationService.updateUser(data).subscribe(
      (res) => {
        this.localUtil.showMessage("", ConstantsModule.INFO_TITLE, this.messageService.getLabelFromCode("GENERIC_SUCCESS_MESSAGE_SAVE"), true);
        this.listOperators();
      },
      this.handleError(true)
    );

  }

  disabilitaOperatore(data) {

    console.log(data);

    this.organizationService.disableUser(data.email).subscribe(
      (res) => {
        this.localUtil.showMessage("", ConstantsModule.INFO_TITLE, this.messageService.getLabelFromCode("GENERIC_SUCCESS_MESSAGE_SAVE"), true);
        this.listOperators();
      },
      this.handleError(true)
    );

  }

  abilitaOperatore(data) {

    console.log(data);

    this.organizationService.enableUser(data.email).subscribe(
      (res) => {
        this.localUtil.showMessage("", ConstantsModule.INFO_TITLE, this.messageService.getLabelFromCode("GENERIC_SUCCESS_MESSAGE_SAVE"), true);
        this.listOperators();
      },
      this.handleError(true)
    );

  }

  eliminaOperatore(data) {

    let params: any[] = [data.nome, data.cognome, data.email];
    const modalRef = this.modalService.open(ConfirmComponent);
    modalRef.componentInstance.titolo = this.messageService.getLabelFromCode("DELETE_OPERATOR_TITLE");
    modalRef.componentInstance.messaggio = this.messageService.getLabelFromCode("DELETE_OPERATOR_MESSAGE", params);
    modalRef.componentInstance.passEntry.subscribe((receivedEntry: any) => {
      if (receivedEntry != undefined) {

        //let confirm = receivedEntry.trim();// non serve nessun parametro di ritorno da popup confirm

        this.organizationService.deleteUser(data.email).subscribe(
          (res) => {
            this.localUtil.showMessage("", ConstantsModule.INFO_TITLE, this.messageService.getLabelFromCode("SUCCESS_OPERATOR_DELETE"), true);
            this.listOperators();
          },
          this.handleError(true)
        );

      }
    });


  }

}
