import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Router, Routes } from '@angular/router';
import { LocalUtilService } from './local-util.service';
import { ConstantsModule } from './constants.module';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';


@Injectable({
    providedIn: 'root'
})
export class ClientService {

    constructor(protected localUtil: LocalUtilService,
                public route: Router,
                public httpClient: HttpClient) {

    }

    get httNotLogged(): object {
        return {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                ////'origin': environment.origin
            }),
            observe: 'body',
            responseType: 'json'
        };
    }

    get httpOptions(): object {
        try {
            return {
                headers: new HttpHeaders({
                    'Content-Type': 'application/json',
                    'Accept': 'application/json',
                    ////'origin': environment.origin,
                    'Authorization': 'Bearer ' + this.localUtil.authParams.token
                }),
                observe: 'body',
                responseType: 'json'
            };
        } catch (err) {
            if (this.localUtil.authParams == undefined) {
                this.route.navigate([ConstantsModule.LOGIN_PATH, {}]);
            }
            return undefined;
        }
    }

    get httpOptionsWithParams(): any {
        try {
            //console.log('httpOptionsWithParams() token: ' + this.localUtil.authParams.token);
            return {
                headers: new HttpHeaders({
                    'Content-Type': 'application/json',
                    'Accept': 'application/json',
                    //'origin': environment.origin,
                    'Authorization': 'Bearer ' + this.localUtil.authParams.token
                }),
                observe: 'body',
                responseType: 'json',
                params: HttpParams
            };
        } catch (err) {
            if (this.localUtil.authParams == undefined) {
                this.route.navigate([ConstantsModule.LOGIN_PATH, {}]);
            }
            return undefined;
        }
    }

    get httpFileOptions(): object {
        try {
            return {
                headers: new HttpHeaders({
                    'Content-Type': 'application/octet-stream',
                    'Accept': 'application/json',
                    //'origin': environment.origin,
                    'Authorization': 'Bearer ' + this.localUtil.authParams.token
                }),
                observe: 'body',
                responseType: 'application/octet-stream'
            };
        } catch (err) {
            if (this.localUtil.authParams == undefined) {
                this.route.navigate([ConstantsModule.LOGIN_PATH, {}]);
            }
            return undefined;
        }
    }

    get httpDownloadBinOptons(): object {
        try {
            return {
                headers: new HttpHeaders({
                    'Content-Type': 'application/octet-stream',
                    'Accept': 'application/json',
                    'cache-control': 'no-cache',
                    'X-Requested-With': 'XMLHttpRequest',
                    //'origin': environment.origin,
                    'Authorization': 'Bearer ' + this.localUtil.authParams.token
                }),
                observe: 'body',
                responseType: 'application/octet-stream'
            };
        } catch (err) {
            if (this.localUtil.authParams == undefined) {
                this.route.navigate([ConstantsModule.LOGIN_PATH, {}]);
            }
            return undefined;
        }
    }

    get httpUploadOptions(): object {
        try {
            return {
                headers: new HttpHeaders({
                    'Accept': 'application/json',
                    //'origin': environment.origin,
                    'Authorization': 'Bearer ' + this.localUtil.authParams.token
                }),
                observe: 'body',
                responseType: 'application/json'
            };
        } catch (err) {
            if (this.localUtil.authParams == undefined) {
                this.localUtil.launchLogout();
            }
            return undefined;
        }
    }

    get httpDownloadB64Options(): object {
        try {
            return {
                headers: new HttpHeaders({
                    'Content-Type': 'application/json',
                    'Accept': '*/*',
                    'cache-control': 'no-cache',
                    //'origin': environment.origin,
                    'Authorization': 'Bearer ' + this.localUtil.authParams.token
                }),
                observe: 'body',
                responseType: 'application/octet-stream'
            };
        } catch (err) {
            if (this.localUtil.authParams == undefined) {
                this.route.navigate([ConstantsModule.LOGIN_PATH, {}]);
            }
            return undefined;
        }
    }

    downloadFile(filename: string, uuid: string): void{

        const baseUrl = ConstantsModule.API_HOST + ConstantsModule.API_UPLOAD_PREFIX + ConstantsModule.API_PREFIX + '/document/' + uuid;
        const token = this.localUtil.authParams.token;
        const headers = new HttpHeaders().set('authorization','Bearer ' + token);
        this.httpClient.get(baseUrl,{responseType: 'arraybuffer',headers:headers}).subscribe(
            (response: any) => {
                let type = 'application/pdf';
                if (filename.toLowerCase().endsWith('.pdf')) {
                    type = 'application/pdf';
                } else if (filename.toLowerCase().endsWith('.xlsx')) {
                    type = 'application/ms-excel';
                }
                this.downLoadFileExec(response, type, filename);
            });
    }

    private downLoadFileExec(data: any, type: string, filename: string) {
        const a:any = document.createElement('a');
        a.style = 'display: none';
        document.body.appendChild(a);
        console.log(data);
        let blob = new Blob([data], { type: type});
        let url = window.URL.createObjectURL(blob);
        a.href = url;
        a.download = filename;
        a.click();
        URL.revokeObjectURL(url);
    }

    downloadFileB64(filename: string, type: string, b64: string): void{
        this.downLoadFilB64eExec(b64, type, filename);
    }

    private downLoadFilB64eExec(b64str: any, type: string, filename: string) {

        const a = document.createElement('a');
        //a.style = 'display: none';
        a.download=filename;
        a.name="excel-lot-template";
        document.body.appendChild(a);
        //const blob = new Blob([res], {type: 'octet/stream'});
        //window.location.href='data:application/vnd.ms-excel;base64,' + b64str;
        const url = 'data:' + type + ';base64,' + b64str;//URL.createObjectURL('data:application/vnd.ms-excel;base64,' + b64str);
        //data:application/excel;base64,JVBERi0xLjMNJeLjz9MNCj........Pg1zdGFydHhyZWYNMTczDSUlRU9GDQ=="
        a.href = url;
        a.download = filename;
        a.click();
        URL.revokeObjectURL(url);
        let tag = document.getElementsByTagName('excel-lot-template');
        $('a[name=excel-lot-template]').remove();
        setTimeout("$('a[name=excel-lot-template]').remove();", 500);

    }

}
