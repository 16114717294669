<div id="auctions-list" class="auctions-list">
    <dx-data-grid id="gridContainerCsLot" [dataSource]="cs" #dataGridCs keyExpr="codiceCentroSelezione"
        [allowColumnReordering]="true" [allowColumnResizing]="true" [showBorders]="true" [columnHidingEnabled]="false"
        [columnAutoWidth]="true" (onInitNewRow)="onInitNewRow($event)" (onRowExpanding)="onRowExpanding($event)"
        (onEditingStart)="onEditingStart($event)" (onRowInserting)="onRowInserting($event)"
        (onRowInserted)="onRowInserted($event)" (onRowRemoved)="onRowRemoved($event)"
        (onRowPrepared)="onRowPrepared($event)">

        <!-- edit form and columns -->
        <dxi-column dataField="centroSelezione.codice" cellTemplate="celTmpl" [allowEditing]="false" caption="Codice"
            alignment="left" [visible]="true">
        </dxi-column>
        <dxi-column dataField="codiceCentroSelezione" cellTemplate="celTmpl" [allowEditing]="true" caption="Codice"
            alignment="left" [visible]="false">
        </dxi-column>

        <dxi-column dataField="centroSelezione.nome" [allowEditing]="false" width="200" caption="Nome" alignment="left"
            [visible]="true" cellTemplate="celTmpl">
        </dxi-column>

        <dxi-column dataField="centroSelezione.CER" [allowEditing]="false" width="70" caption="CER" alignment="left"
            [visible]="true" cellTemplate="celTmpl">
        </dxi-column>

        <dxi-column dataField="centroSelezione.pesoMedioCarico" [allowEditing]="false" caption="Peso medio carico"
            alignment="left" [width]="130" [visible]="true" cellTemplate="celTmpl">
        </dxi-column>

        <dxi-column dataField="centroSelezione.indirizzo" [allowEditing]="false" caption="Indirizzo" alignment="left"
            cellTemplate="indirizzoTemplate" [width]="300" [visible]="true" cellTemplate="celTmpl">
        </dxi-column>

        <dxi-column dataField="quantitaTon" caption="Quantità (ton)" [allowEditing]="true" dataType="string"
            [width]="150" [visible]="true">
        </dxi-column>
        <dxi-column dataField="codiceLotto" caption="" [allowEditing]="true" dataType="string" [width]="120"
            [visible]="true" cellTemplate="csCellTemplate" editCellTemplate="csEditTemplate">
        </dxi-column>

        <dxi-column dataField="codiceAsta" caption="Codice asta" [allowEditing]="false" dataType="string"
            [visible]="false">
        </dxi-column>

        <dxi-column type="buttons">
            <dxi-button name="delete" [visible]="isCoripet() && !readonly"></dxi-button>
        </dxi-column>

        <div *dxTemplate="let csCellTemplate of 'csCellTemplate'">

        </div>
        <div *dxTemplate="let celTmpl of 'celTmpl'">
            {{getColumnValue(celTmpl.column.dataField, celTmpl)}}
        </div>
        <div *dxTemplate="let indirizzoTemplate of 'indirizzoTemplate'">
            {{getIndirizzo(indirizzoTemplate.data.centroSelezione)}}
        </div>

        <dxo-master-detail [enabled]="true" template="detailCs"></dxo-master-detail>

        <div *dxTemplate="let csEditTemplate of 'csEditTemplate'">
            <dx-button text="{{csEditTemplate.data.codiceLotto}}" cssClass="btn-select-cs dx-tab-text"
                (onClick)="selectCsFromList($event, csEditTemplate.data.codiceLotto)">Seleziona centro</dx-button>
        </div>

        <div *dxTemplate="let detailCs of 'detailCs'">
            <dx-form [(formData)]="detailCs.data">

                <dxi-item itemType="tabbed">
                    <dxi-tab title="Centro di selezione">
                        <dxi-item itemType="group" [colCount]="3" cssClass="detail-form-section">
                            <dxi-item dataField="codiceCentroSelezione"></dxi-item>
                            <dxi-item dataField="centroSelezione.nome"></dxi-item>
                            <dxi-item dataField="centroSelezione.CER"></dxi-item>
                        </dxi-item>
                        <dxi-item itemType="group" [colCount]="2" cssClass="detail-form-section">
                            <dxi-item dataField="centroSelezione.pesoMedioCarico"></dxi-item>
                            <dxi-item dataField="centroSelezione.indirizzo"></dxi-item>
                        </dxi-item>
                        <dxi-item itemType="group" [colCount]="2" cssClass="detail-form-section">
                            <dxi-item dataField="centroSelezione.pesoMedioCarico"></dxi-item>
                            <dxi-item dataField="centroSelezione.indirizzo"></dxi-item>
                        </dxi-item>
                        <dxi-item itemType="group" [colCount]="3" cssClass="detail-form-section">
                            <dxi-item dataField="quantitaTon"></dxi-item>
                            <dxi-item dataField="codiceLotto"></dxi-item>
                            <dxi-item dataField="codiceAsta"></dxi-item>
                        </dxi-item>
                    </dxi-tab>
                </dxi-item>
            </dx-form>
        </div>

        <dxo-editing [allowUpdating]="false" [allowAdding]="isCoripet() && !readonly" [allowDeleting]="true" mode="row">

            <dxo-form>

                <dxi-item itemType="tabbed">
                    <dxi-tab title="Centro di selezione">
                        <dxi-item itemType="group" [colCount]="3" cssClass="detail-form-section">
                            <dxi-item dataField="codiceCentroSelezione"></dxi-item>
                            <dxi-item dataField="centroSelezione.nome" [editorOptions]="{ readOnly: true }"></dxi-item>
                            <dxi-item dataField="centroSelezione.CER" [editorOptions]="{ readOnly: true }"></dxi-item>
                        </dxi-item>
                        <dxi-item itemType="group" [colCount]="2" cssClass="detail-form-section">
                            <dxi-item dataField="centroSelezione.pesoMedioCarico"
                                [editorOptions]="{ readOnly: true }"></dxi-item>
                            <dxi-item dataField="centroSelezione.indirizzo"
                                [editorOptions]="{ readOnly: true }"></dxi-item>
                        </dxi-item>
                        <dxi-item itemType="group" [colCount]="2" cssClass="detail-form-section">
                            <dxi-item dataField="centroSelezione.pesoMedioCarico"
                                [editorOptions]="{ readOnly: true }"></dxi-item>
                            <dxi-item dataField="centroSelezione.indirizzo"
                                [editorOptions]="{ readOnly: true }"></dxi-item>
                        </dxi-item>
                        <dxi-item itemType="group" [colCount]="3" cssClass="detail-form-section">
                            <dxi-item dataField="quantitaTon"></dxi-item>
                            <dxi-item dataField="codiceLotto" [editorOptions]="{ readOnly: true }"></dxi-item>
                            <dxi-item dataField="codiceAsta" [editorOptions]="{ readOnly: true }"></dxi-item>
                        </dxi-item>
                    </dxi-tab>
                </dxi-item>
            </dxo-form>

        </dxo-editing>

    </dx-data-grid>
</div>