<span class="spacer"></span>
<footer id="footer">
    <div>
        <p class="fs-5"><a (click)="setLocale('it_IT')" class="cursor-pointer px-1 {{cssForLocale('it_IT')}}">IT</a>
            <span class="text-black"> / </span>
            <a (click)="setLocale('en_US')" class="cursor-pointer px-1 {{cssForLocale('en_US')}}">EN</a>
        </p>
    </div>
    <svg id="shape" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1366 13.91" preserveAspectRatio="none">
        <path id="Subtraction_5" data-name="Subtraction 5" class="cls-1"
            d="M1366,13.91H0V0C203.33,9.1,439.68,13.91,683.5,13.91,926.89,13.91,1162.89,9.12,1366,0V118.91h0Z" />
    </svg>
    <div class="logo">
        <img src="" style="max-height: 28px;">
    </div>
    <div class="support">
        <p translate>footer.hai-bisogno-di-supporto <a href="mailto:support.coripet@digital-auction.eu" translate>footer.contattci</a></p>
    </div>
    <!-- DATA RILASCIO: 20/01/2022-->
</footer>