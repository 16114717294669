
import { Location } from '@angular/common';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { APIService } from 'src/app/services/APIService';
import { AuthService } from 'src/app/services/auth.service';
import { LocalUtilService } from 'src/app/services/local-util.service';
import { MessagesService } from 'src/app/services/messages.service';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { AreaGeograficaItem, AuctionBetSetupItem, AuctionBoxTimer, AuctionItem, AuctionLotFavElem, AuctionLotItem, AuctionLotTimerItem, AuctionSetupItem, CentroSelezioneItem, GetAuctionLotsResponse, GetAuctionLotUserFavouriteResponse, GetAuctionSetupResponse, GetSelectionCenterListResponse, HeaderDetail, MaterialTypeItem, MatRule, SchedaTecnicaItem, SliderValue } from 'src/app/models/dataModels';
import { AuctionService } from 'src/app/services/auction.service';
import { CommonService } from 'src/app/services/common.service';
import { ConstantsModule } from 'src/app/services/constants.module';
import { DxDataGridComponent } from 'devextreme-angular';
import { ConfirmComponent } from 'src/app/commons/components/confirm/confirm.component';
import { OrganizationService } from 'src/app/services/organization.service';
import { PopupComponent } from 'src/app/commons/components/popup/popup.component';
import { Component, HostListener, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import moment from 'moment';

@Component({
  selector: 'app-aste-in-corso-box',
  templateUrl: './aste-in-corso-box.component.html',
  styleUrls: ['./aste-in-corso-box.component.css']
})
export class AsteInCorsoBoxComponent extends APIService implements OnInit, OnDestroy {

  constructor(public localService: LocalUtilService,
    public authService: AuthService,
    public auctionService: AuctionService,
    public commonService: CommonService,
    private modalService: NgbModal,
    private messageService: MessagesService,
    private organizationService: OrganizationService,
    private sanitizer: DomSanitizer,
    public httpClient: HttpClient,
    location: Location, 
    router: Router,
    private route: ActivatedRoute) {
    super(localService, location, router);

  }

  auctionReloadLotsMonitor: Subscription;
  timerCallbackMonitor: Subscription;
  
  area_list: AreaGeograficaItem[] = [];
  material_list: MaterialTypeItem[] =[];
  bet_list: AuctionBetSetupItem[] = [];

  codiceAsta: string;
  auctionDetail: AuctionItem;
  auctionLots: AuctionLotItem[] = [];
  auctionLotsFull: AuctionLotItem[] = [];
  favouriteLots: AuctionLotFavElem[] = [];
  scheda_list: SchedaTecnicaItem[] = [];
  auction_configuration: AuctionSetupItem;
  
  minimumBetSlot: number;
  toggleFilterFavourite: boolean = false;
  betSlotLotto = new Map();

  //reload button vars
  reloadEnabled: boolean = true;
  
  //refresh 15 sec var
  secondsToRefresh: number;

  isTaglioRilancioLibero: boolean = false;

  //search header
  searchLotText: string;

  //navbar sticky
  navbarfixed:boolean = false;

  //headerDetail
  headerDetail: HeaderDetail = new HeaderDetail();

  enabled_materials: string[] = ['PET-Azzurrato', 'PET-Colorato', 'PET-Trasparente', 'PET-Misto'];

  ngOnInit(): void {

    this.codiceAsta = this.route.snapshot.paramMap.get('codiceAsta');
    this.localUtil.activeAuctionCode = this.codiceAsta;

    this.simulateScroll();
    this.setHeaderSticky();
    //this.localUtil.hideCompanySubHeader();
    //this.localUtil.showCompanyCapacityHeader();
    this.loadAreaGeo();
    this.loadMaterialType();
    this.loadSchedaTecnicaList();
    this.loadRules();
    this.loadAuctionConfiguration();
    
    if (this.codiceAsta != undefined) {
      //this.localUtil.showAuctionHeaders();
      this.getAuctionDetailWithLots();
      this.initHeaderMonitor();
      this.initAuctionHeader();
    }
  }

  ngOnDestroy() {
    if (this.auctionReloadLotsMonitor) {
      this.auctionReloadLotsMonitor.unsubscribe();
    }
    if (this.timerCallbackMonitor) {
      this.timerCallbackMonitor.unsubscribe();
    }
  }

  initAuctionHeader() {
    let headerDetail: HeaderDetail = new HeaderDetail();
    headerDetail.dashboardHeader = false;
    headerDetail.stickyHeader = false;
    headerDetail.capacityHeader = true;
    this.headerDetail.view = ConstantsModule.AUC_VIEW_BOX;
    this.localUtil.setHeaderMonitor(headerDetail);
  }

  simulateScroll() {
    window.scrollTo(0,window.scrollY+1);
    window.scrollTo(0,window.scrollY-1);
  }

  initHeaderMonitor() {
    
    if (this.codiceAsta != undefined && !this.auctionReloadLotsMonitor) {
      
      this.auctionReloadLotsMonitor = this.localUtil.getAuctionReloadLotsMonitor().subscribe(
        res => {
          if (res) {
            this.getAuctionDetailWithLots();
          }
        }
      );

      this.timerCallbackMonitor = this.localUtil.getTimersCallbackMonitor().subscribe(
        res => {
          if (res) {
            console.log('timer ends, callback called, refreshing lots and timers');
            this.getAuctionDetailWithLots();
          }
        }
      );

    }

  }

  setTimers(timers: AuctionBoxTimer[]) {
    this.localUtil.setTimers(timers);
  }

  getAuctionDetailWithLots() {
    if (this.reloadEnabled) {
      this.reloadEnabled = false;
      
      this.localUtil.lotsLoaderCalled();

      setTimeout(()=>{
        this.enableReloadButton()
      }, 1000);

      this.auctionService.getAuctionLots(this.codiceAsta, "").subscribe(
        (res:GetAuctionLotsResponse) => {
          this.auctionDetail = res.auctionDetail;

          if (this.auctionDetail.statoAsta == ConstantsModule.AUCTION_STATUS_CLOSED) {

            const modalRef = this.modalService.open(PopupComponent);
            modalRef.componentInstance.titolo = ConstantsModule.INFO_TITLE;
            modalRef.componentInstance.messaggio = this.messageService.getLabelFromCode('AUCTION_CLOSED');
            modalRef.componentInstance.tipo = 'message';
            ConstantsModule.REFRESH_LOTS_ENABLED = false;
            modalRef.componentInstance.passEntry.subscribe((receivedEntry:any) => {
              this.router.navigate([ConstantsModule.REF_AZ_ASTE_VINTE_PATH]);
            });
          }

          (res.lotList as AuctionLotItem[]).map((lotItem) => {
            let newLotItem = new AuctionLotItem();
            newLotItem = lotItem;
            newLotItem.isClosed = true;
          });

          this.auctionLotsFull = res.lotList; // assegno la lista lotti una volta terminata l'elaborazione dei campi per evitare sfarfallamenti a video
          this.localUtil.setServerTime(res.serverTime);
          this.prepareTimers(res);
          this.setAttributeToLots(res.lotList);
          this.disableItemRule2(res.lotList);
          this.loadFavouriteLots(res.lotList, true);
          this.getBetSlotList(this.auctionDetail.tipologiaAsta);
        },
        this.handleError(true)
        );
    }

  }

  sortByFavourite(list: AuctionLotItem[]) {
    return list.sort((a, b) =>  (a.favourite && b.favourite) ? 1 : (a.favourite?-1:1));
  }

  loadAuctionConfiguration() {
    if (this.codiceAsta != undefined) {
      this.organizationService.getAuctionUserSetup(this.codiceAsta).subscribe(
          (res:any) => {
            if (res.list != undefined) {
              this.auction_configuration = res.list;
              res.list.forEach((auctionConfig:AuctionSetupItem) => {
                if (auctionConfig.active) {
                  this.auction_configuration = auctionConfig;
                }                
              });
              this.isTaglioRilancioLibero = this.getAuctionPropValue(ConstantsModule.AUC_TAGLIO_RILANCIO_KEY) == ConstantsModule.AUC_TAGLIO_RILANCIO_LIBERO ? true: false;
              //get nuovo parametro range secondi refresh lotti // per ora metto 15 secondi con costante
              //this.localUtil.getUserAuctionConfigByName(this.auction_configuration, '')
              this.setRefreshTimer(ConstantsModule.AUC_REFRESH_LOT_MILLIS);
              this.loadRefreshTimer();
              
            }
        }, this.handleError(true)
      );
    }
  }

  getAuctionPropValue(key: string) {
    let output = '';
    if (this.auction_configuration && key != undefined && key != '' && this.auction_configuration.properties != undefined) {
      this.auction_configuration.properties.forEach(config => {
        if (config.code == key) {
          output = config.value;
        }
      });
    }
    return output;
  }

  setRefreshTimer(seconds: number) {
    this.secondsToRefresh = ConstantsModule.AUC_REFRESH_LOT_MILLIS;
  }

  setSliderValues(sliderValue: SliderValue) {
    this.localUtil.setValueSliderCompanySubHeader(sliderValue);
  }

  makeOffer(codiceLotto: string, prezzoUltimoRilancio: number, lot: AuctionLotItem) {
    let taglioRilancio: number = this.betSlotLotto.get(codiceLotto);
    if (taglioRilancio == undefined) {
      taglioRilancio = this.minimumBetSlot;
    }
    
    let prezzoMioRilancio: number = 0;
    let prezzoPartenza: number = parseInt(prezzoUltimoRilancio.toString());
    if (prezzoPartenza == 0) {
      prezzoPartenza = lot.prezzoBase;
    }
    if (!this.isTaglioRilancioLibero) {
      prezzoMioRilancio = prezzoPartenza + parseInt(taglioRilancio.toString());
    } else {
      prezzoMioRilancio = prezzoPartenza + parseInt(lot.bindModel.toString());
    }

    let params:any[] = [prezzoMioRilancio, codiceLotto];
    const modalRef = this.modalService.open(ConfirmComponent);
    modalRef.componentInstance.titolo = this.messageService.getLabelFromCode("INFO_TITLE");
    modalRef.componentInstance.messaggio = this.messageService.getLabelFromCode("MAKE_OFFER_CONFIRM", params);
    modalRef.componentInstance.passEntry.subscribe((receivedEntry:any) => {
        if (receivedEntry != undefined) {
        
            this.auctionService.makeOffer(this.codiceAsta, codiceLotto, prezzoUltimoRilancio, prezzoMioRilancio).subscribe(
              res => {
                this.getAuctionDetailWithLots();
                this.loadRules();
              }, this.handleError(true)
            );
        }            
    }, this.handleError(true));

  }

  loadRules() {
    if (this.codiceAsta != undefined) {
      this.auctionService.getAuctionRules(this.codiceAsta).subscribe(
        capData => {
          
          this.localUtil.CapData = capData;

          //Barra totale impianto
          let barValues:SliderValue = new SliderValue();
          barValues.type = ConstantsModule.SLIDER_TYPE_CAPACITY;
          
          barValues.curValue = this.localUtil.CapData.rule1.oldWon;
          barValues.resValue = (this.localUtil.CapData.rule1.totalEligibility-this.localUtil.CapData.rule1.oldWon);
          barValues.maxValue = this.localUtil.CapData.rule1.totalEligibility;
          barValues.curPerc = (100*barValues.curValue/barValues.maxValue);
          this.setSliderValues(barValues);

          //rule3 per tipoProdotto
          this.localUtil.CapData.rule3.forEach((matRule:MatRule) => {          
          
            barValues = new SliderValue();
            barValues.type = matRule.typeMaterial;
            barValues.curValue = matRule.winning;
            barValues.resValue = (matRule.ruleEligibility-matRule.winning);
            barValues.maxValue = matRule.ruleEligibility;
            barValues.curPerc = (100*barValues.curValue/barValues.maxValue);
            this.setSliderValues(barValues);

          });

          this.localUtil.setCurrentCost(this.localUtil.numberWithCommas(capData.rule1.cost));

        }, this.handleError(true)
      );
    }
  }

  filterByFavourite(favourite: boolean) {
    let lots: AuctionLotItem[] = [];
    if (favourite != undefined) {
      lots = this.auctionLotsFull;
    } else {
      lots = this.auctionLots;
    }
    let auctionLotFiltered: AuctionLotItem[] = [];
    lots.forEach((lot:AuctionLotItem) => {
      if (this.toggleFilterFavourite) {
        if (lot.favourite) {
          auctionLotFiltered.push(lot);
        }
      } else {
        auctionLotFiltered.push(lot);
      }
    });
    this.auctionLots = auctionLotFiltered;
    if (favourite) {
      this.filterByText();
    }
    setTimeout("$('.cards-lots-list').masonry({itemSelector: '.lot',columnWidth: 200,gutter: 30});", 500);
  }

  filterByMaterial(materialItem: MaterialTypeItem) {
    let lots: AuctionLotItem[] = [];
    if (materialItem) {
      this.setMaterialFilterValue(materialItem);
      lots = this.auctionLotsFull;
    } else {
      lots = this.auctionLots;
    }
    let auctionLotFiltered: AuctionLotItem[] = [];
    lots.forEach((lot:AuctionLotItem) => {
      let addItem: boolean = true;
      this.material_list.forEach((material:MaterialTypeItem) => {        
        if (!material.checked && addItem) { //check non selezionato
          if (lot.tipoProdotto == material.type_material) {
            addItem = false;
          }
        }
      });
      if (addItem) {
        auctionLotFiltered.push(lot);
      }
    });
    this.auctionLots = auctionLotFiltered;
    if (materialItem) {
      this.filterByFavourite(undefined);
      this.filterByArea(undefined);
    }
    setTimeout("$('.cards-lots-list').masonry({itemSelector: '.lot',columnWidth: 200,gutter: 30});", 500);
  }

  filterForEnabledMaterials(materialList: MaterialTypeItem[]) {
    let output: MaterialTypeItem[] = [];
    if (materialList != undefined && materialList.length > 0) {
      for (let material of materialList) {
        if (this.enabled_materials.includes(material.type_material)) {
          output.push(material);
        }
      }
    }
    return output;
  }

  setMaterialFilterValue(materialItem: MaterialTypeItem) {
    this.material_list.forEach((material:MaterialTypeItem) => { 
      if (materialItem.id == material.id) {
        material.checked = !material.checked;
      }
    });
  }

  filterByArea(areaGeoItem: AreaGeograficaItem) {
    let lots: AuctionLotItem[] = [];
    if (areaGeoItem) {
      this.setAreaFilterValue(areaGeoItem);
      lots = this.auctionLotsFull;
    } else {
      lots = this.auctionLots;
    }
    let auctionLotFiltered: AuctionLotItem[] = [];
    lots.forEach((lot:AuctionLotItem) => {
      let addItem: boolean = true;
      this.area_list.forEach((area:AreaGeograficaItem) => {        
        if (!area.checked && addItem) { //check non selezionato
          if (lot.areaGeografica == area.area) {
            addItem = false;
          }
        }
      });
      if (addItem) {
        auctionLotFiltered.push(lot);
      }
    });
    this.auctionLots = auctionLotFiltered;
    if (areaGeoItem) {
      this.filterByFavourite(undefined);
      this.filterByMaterial(undefined);
    }
    setTimeout("$('.cards-lots-list').masonry({itemSelector: '.lot',columnWidth: 200,gutter: 30});", 500);
  }

  setAreaFilterValue(areaGeoItem: AreaGeograficaItem) {
    this.area_list.forEach((area:AreaGeograficaItem) => { 
      if (areaGeoItem.id == area.id) {
        area.checked = !area.checked;
      }
    });
  }

  loadFavouriteLots(auctionLots: AuctionLotItem[], beautify: boolean) {//(repaintGrid?: boolean) {
    this.auctionService.listFavouriteLots(this.codiceAsta).subscribe(
      (res:GetAuctionLotUserFavouriteResponse) => {
        if (res && res.list) {
          this.favouriteLots = res.list;
          this.updateFavouriteLots(auctionLots, beautify);
        }
      },
      this.handleError(true)
    );
  }

  addFavouriteLot(codiceLotto: string) {    
    if (codiceLotto) {
      this.auctionService.addFavouriteLot(this.codiceAsta, codiceLotto,).subscribe(
        (res) => {
          this.loadFavouriteLots(this.auctionLots, false);
        },
        this.handleError(true)
      );
    }
  }

  removeFavouriteLot(codiceLotto: string) {    
    if (codiceLotto) {
      this.auctionService.removeFavouriteLot(this.codiceAsta, codiceLotto).subscribe(
        (res) => {
          this.loadFavouriteLots(this.auctionLots, false);
        },
        this.handleError(true)
      );
    }
  }

  updateFavouriteLots(auctionLots: AuctionLotItem[], beautifySelect: boolean) {
    if (auctionLots && this.favouriteLots) {
      auctionLots.forEach(lot => {
        lot.favourite = false;
        this.favouriteLots.forEach(favLot => {
          if (favLot.lotCode == lot.codiceLotto) {
            lot.favourite = true;
          }
        });
      });

      this.auctionLots = auctionLots;
      
      this.filterByText();

      /*if (beautifySelect && beautifySelect == true) {
        setTimeout(()=>this.beautifySelect(), 100); //funziona impostando la classe custom-select all'oggetto padre che contiene la select
      }*/

    } else {
      this.auctionLots = this.auctionLotsFull;
      this.filterByText();
    }
    setTimeout("$('.cards-lots-list').masonry({itemSelector: '.lot',columnWidth: 200,gutter: 30});", 500);
  }

  getDefaultTaglioRilancio() {
    let output = '';
    if (this.bet_list != undefined && this.bet_list.length > 0){
      output = this.bet_list[0].taglioRilancio.toString();
    }
    return output;
  }

  changeBetSlot(e, codiceLotto: string) {
    console.log(e);
    let betVal: number = 0;
    for (let option of e.target) {
      if (option.selected) {
        betVal = option.value;
      }
    }    
    this.betSlotLotto.set(codiceLotto, betVal);
  }

  loadRefreshTimer() {
    if (this.codiceAsta != undefined && (this.localUtil.isListView() || this.localUtil.isBoxView())) {
      setTimeout(()=>{
        this.manageRefreshTimer();
        this.loadRefreshTimer();      
      }, 1000);
    }
  }
  
  manageRefreshTimer() {
    this.secondsToRefresh = this.secondsToRefresh-1000;
    if (this.secondsToRefresh == 0) {
      if (ConstantsModule.REFRESH_LOTS_ENABLED) {
        this.getAuctionDetailWithLots();
      }
      this.setRefreshTimer(ConstantsModule.AUC_REFRESH_LOT_MILLIS);
    }
  }
  
  increaseFont() {
    let style:any = document.body.style;
    var actualZoom =style.zoom;
    if (actualZoom == undefined || actualZoom == "") actualZoom = "100%";
    actualZoom = actualZoom.substr(0, actualZoom.length-1);
    actualZoom = parseInt(actualZoom)+5;
    if (actualZoom >0) {
        style.zoom = actualZoom + "%";
    }
    setTimeout("$('.cards-lots-list').masonry({itemSelector: '.lot',columnWidth: 200,gutter: 30});", 500);
  }

  decreaseFont() {
    let style:any = document.body.style;
    var actualZoom =style.zoom;
    if (actualZoom == undefined || actualZoom == "") actualZoom = "100%";
    actualZoom = actualZoom.substr(0, actualZoom.length-1);
    actualZoom = parseInt(actualZoom)-5;
    if (actualZoom >0) {
      style.zoom = actualZoom + "%";
    }
    setTimeout("$('.cards-lots-list').masonry({itemSelector: '.lot',columnWidth: 200,gutter: 30});", 500);
  }

  enableReloadButton() {
    if (!this.reloadEnabled) {
      this.reloadEnabled = true;
    }
  }

  getDisabledReloadLots() {
    return this.reloadEnabled? '': 'disabled';
  }

  disableItemRule2(auctionLots: AuctionLotItem[]) {
    if (auctionLots) {
      auctionLots.forEach((item:any) => {      
        try {   
          if (this.auctionDetail.tipologiaAsta == ConstantsModule.TIPOLOGIA_ASTA_DEFAULT) {
              if( (this.localUtil.CapData.rule1.residualEligibility + this.localUtil.CapData.rule1.ruleEligibility - this.localUtil.CapData.rule1.winning) < item.quantitaTon){
                  item.isDisabled = true;                  
              } else if( (this.localUtil.CapData.rule2.ruleEligibility - this.localUtil.CapData.rule2.winning) < item.quantitaTon){
                  item.isDisabled = true;
              }

              if( (item.userId != this.localUtil.authParams.user_detail.id)  && (item.idOrganization != this.localUtil.authParams.organization_detail.id)){
                  this.localUtil.CapData.rule3.forEach(function (matRule){
                      if(matRule.typeMaterial==item.tipoProdotto){
                          if((matRule.ruleEligibility-matRule.winning)<item.quantitaTon){
                            item.isDisabled = true;
                          }
                      }
                  });
              }
          }
          
        } catch (err) {
          console.log(err);
        }
      });
    }
  }

  getBetSlotList(tipologiaAsta: string) {
    if (this.bet_list == undefined || this.bet_list.length == 0 ) {
      this.auctionService.getAuctionBetSetupByTipology(tipologiaAsta).subscribe(
        (data) => {
          this.bet_list = data.list;
          this.minimumBetSlot = this.bet_list[0].taglioRilancio;
        }, this.handleError(true));
    }

  }

  onToolbarPreparing(e) {
    e.toolbarOptions.items.unshift({
      location: "after",
      template: "favouritesFilterButton"
    });
  }

  filterFavouriteLots(e) {
    this.toggleFilterFavourite = !this.toggleFilterFavourite;
    this.filterByFavourite(undefined);
  }

  loadAreaGeo() {
    this.commonService.getAreaGeograficaList().subscribe(
      data => {
        this.area_list = data.list;
        this.area_list.forEach((area: AreaGeograficaItem) => {
          area.checked = true;
        });
    },
      this.handleError(true)
    );
  }

  loadMaterialType() {
    this.commonService.getMaterialTypesList().subscribe(
      data => {
        this.material_list = this.filterForEnabledMaterials(data.list);
        this.material_list.forEach((material: MaterialTypeItem) => {
          material.checked = true;
        });
    },
      this.handleError(true)
    );
  }

  loadSchedaTecnicaList() {
    let scheda:SchedaTecnicaItem = new SchedaTecnicaItem();
    scheda.document = 'scheda-tecnica-PET-Azzurrato.pdf';
    this.scheda_list.push(scheda);
    scheda = new SchedaTecnicaItem();
    scheda.document = 'scheda-tecnica-PET-Colorato.pdf';
    this.scheda_list.push(scheda);
    scheda = new SchedaTecnicaItem();
    scheda.document = 'scheda-tecnica-PET-Trasparente.pdf';
    this.scheda_list.push(scheda);
    scheda = new SchedaTecnicaItem();
    scheda.document = 'scheda-tecnica-PET-Misto.pdf';
    this.scheda_list.push(scheda);   
  }

  getColorePET(tipoProdotto:any) {
    let output = '';
    if (tipoProdotto != undefined) {
      if (tipoProdotto == ConstantsModule.PET_COLORATO) {
        output = ConstantsModule.PET_CSS_COLORATO;
      } else if (tipoProdotto == ConstantsModule.PET_AZZURRATO) {
        output = ConstantsModule.PET_CSS_AZZURRATO;
      } else if (tipoProdotto == ConstantsModule.PET_TRASPARENTE) {
        output = ConstantsModule.PET_CSS_TRASPARENTE;
      } else if (tipoProdotto == ConstantsModule.PET_MISTO) {
        output = ConstantsModule.PET_CSS_MISTO;
      }
    }
    return output;
  }

  timerEventRaised(outputTimerEmit: AuctionLotTimerItem) {
    this.updateLotStatus(outputTimerEmit);
  }

  updateLotStatus(outputTimerEmit: AuctionLotTimerItem) {
    if (this.auctionLots != undefined && outputTimerEmit != undefined) {
      this.auctionLots.forEach(lot => {
        if (lot.codiceLotto == outputTimerEmit.codiceLotto) {
          lot.isClosed = outputTimerEmit.isClosed;
          lot.isExtratime = outputTimerEmit.isExtratime;
        }
      });
    }
  }

  setAttributeToLots(auctionLots: AuctionLotItem[]) {

    if (auctionLots != undefined) {
      let index: number = 0;
      auctionLots.forEach(lot => {
        if (lot.idOrganization != undefined && lot.idOrganization == this.localUtil.authParams.organization_detail.id.toString()) {
          lot.isWinning = true;
        }
        if (!lot.isWinning && lot.userId == this.localUtil.authParams.user_detail.id) {
          lot.isWinning = true;        
        }
        if (this.auctionDetail.dataOraFineAsta != lot.dataOraFineAstaEffettiva) {
          lot.isExtratime = true;
        }
        lot.isClosed = true;
        index++;
      });
    }
  }

  prepareTimers(res: GetAuctionLotsResponse) {
    
    console.log('prepareTimers()');
    let timers: AuctionBoxTimer[] = [];

    //console.log(res);
    console.log('dataPrimoLottoInScadenza: ' + res.dataPrimoLottoInScadenza);
    console.log('dataUltimoLottoInScadenza: ' + res.dataUltimoLottoInScadenza);

    let dataScadenzaAsta = this.getMomentDate(this.auctionDetail.dataOraFineAsta);
    let dataNow = this.getNowMomentDate();
    
    let auctionBaseTimeEnded: boolean = true;
    if (res && res.dataPrimoLottoInScadenza != undefined && res.dataPrimoLottoInScadenza != '' && res) {
      if (dataScadenzaAsta > dataNow) {
        let timer = new AuctionBoxTimer();
        timer.label = "Tempo rimanente alla fine dell'asta";
        timer.dataOraFineAsta = this.auctionDetail.dataOraFineAsta;
        timer.dataOraFineAstaEffettiva = this.auctionDetail.dataOraFineAsta;
        timer.name = 'scadenza asta base';
        timers.push(timer);
        auctionBaseTimeEnded = false;
      } else {
        console.log('if asta scaduta, nessun timer');
      }

      let dataScadenzaPrimoLotto = this.getMomentDate(res.dataPrimoLottoInScadenza);
      if (dataScadenzaPrimoLotto > dataNow) {
        let timer = new AuctionBoxTimer();
        if (res.dataPrimoLottoInScadenza == res.auctionDetail.dataOraFineAsta ) {
          timer.label = "Tempo rimanente alla fine dell'asta";
        } else {
          if (auctionBaseTimeEnded) {
            timer.label = "Primo lotto in scadenza";
          } else {
            timer.label = "Ultimo lotto in scadenza";
          }
        }
        timer.dataOraFineAsta = this.auctionDetail.dataOraFineAsta;
        timer.dataOraFineAstaEffettiva = res.dataPrimoLottoInScadenza;
        timer.name = 'primo lotto';
        timers.push(timer);
      }
      
    } else {
      //timer scadenza asta base
      if (dataScadenzaAsta > dataNow) {
        let timer = new AuctionBoxTimer();
        timer.label = "Tempo rimanente alla fine dell'asta";
        timer.dataOraFineAsta = this.auctionDetail.dataOraFineAsta;
        timer.dataOraFineAstaEffettiva = this.auctionDetail.dataOraFineAsta;
        timer.name = 'scadenza asta base';
        timers.push(timer);
        auctionBaseTimeEnded = false;
      } else {
        console.log('else asta scaduta, nessun timer');
      }
    }

    if (res && res.dataUltimoLottoInScadenza != undefined && res.dataUltimoLottoInScadenza != res.dataPrimoLottoInScadenza) {
      let dataUltimoLottoInScadenza = this.getMomentDate(res.dataUltimoLottoInScadenza);
      if (dataUltimoLottoInScadenza > dataNow) {
        let timer = new AuctionBoxTimer();
        timer.dataOraFineAsta = this.auctionDetail.dataOraFineAsta;
        timer.dataOraFineAstaEffettiva = res.dataUltimoLottoInScadenza;
        timer.name = 'ultimo lotto';
        timer.label = "Ultimo lotto in scadenza";
        timers.push(timer);
      }
    }

    this.setTimers(timers);
    

    /*if (auctionLots != undefined) {
      auctionLots.forEach(lot => {
        if (lot.dataOraFineAstaEffettiva != this.auctionDetail.dataOraFineAsta) {
          extraTimes.set(lot.dataOraFineAstaEffettiva, lot.dataOraFineAstaEffettiva);
        }
      });

      console.log(extraTimes);
      
      let index = 1;
      for(let timerKey of extraTimes.keys()) {
      
        let toAdd: boolean = false;
        let timer1 = new AuctionBoxTimer();
        timer1.name = 'timer' + index;
        if (index == 1) {
          console.log('timer1.dataOraFineAstaEffettiva: ' + timer1.dataOraFineAstaEffettiva + ' this.auctionDetail.dataOraFineAsta: ' + this.auctionDetail.dataOraFineAsta);
          if (timer1.dataOraFineAstaEffettiva == undefined || timer1.dataOraFineAstaEffettiva == this.auctionDetail.dataOraFineAsta ) {
            timer1.label = "Tempo rimanente alla fine dell'asta"; //'Primo lotto in scadenza';
          } else {
            timer1.label = "Primo lotto in scadenza";
          }
          toAdd = true;
        } else {
          if (timer1.dataOraFineAstaEffettiva == undefined) {
            timer1.label = "Ultimo lotto in scadenza";
            toAdd = true;
          }
        }
        if (toAdd) {
          timer1.dataOraFineAsta = this.auctionDetail.dataOraFineAsta;
          timer1.dataOraFineAstaEffettiva = extraTimes.get(timerKey);
          timers.push(timer1);
        }
        index++;
      }
    }*/


  }

  filterByText() {
    if (this.searchLotText) {
      let auctionLotFiltered: AuctionLotItem[] = [];
      this.auctionLotsFull.forEach((lot:AuctionLotItem) => {
        if (JSON.stringify(lot).toLowerCase().indexOf(this.searchLotText.toLowerCase()) > -1) {
          auctionLotFiltered.push(lot);
        }
      });
      this.auctionLots = auctionLotFiltered;
    } else {
      this.auctionLots = this.auctionLotsFull;
    }
    this.filterByFavourite(undefined);
    this.filterByMaterial(undefined);
    this.filterByArea(undefined);
    setTimeout("$('.cards-lots-list').masonry({itemSelector: '.lot',columnWidth: 200,gutter: 30});", 500);
  }

  @HostListener('window:resize',['$event']) onResize(){
    this.manageStickyHeader();
  }

  @HostListener('window:scroll',['$event']) onScroll(){
    this.manageStickyHeader();
  }

  manageStickyHeader() {
    
    var header; 
    var sticky;

    if (header == undefined) {
      header = document.getElementById("cards-lots-list");
      if (header != undefined) {
          sticky = header.offsetTop;
      }
    }

    let offset_var = 160;
    if ($('#cards-lots-list-header').hasClass('header-no-default-auction')) {
      offset_var = 75;
    }

    if (window.pageYOffset+offset_var > sticky) {
        this.navbarfixed = true;
        this.headerDetail.stickyHeader = false;
        this.localUtil.setHeaderMonitor(this.headerDetail);
        if (this.codiceAsta) {
          this.localUtil.showHeaderSticky(this.isAstaSingola()?ConstantsModule.AUC_MOTORE_ASTA_SINGOLA:ConstantsModule.AUC_MOTORE_ASTA_MULTIPLA);
        }
    } else {
        this.navbarfixed = false;
        this.headerDetail.stickyHeader = false;
        this.localUtil.setHeaderMonitor(this.headerDetail);
        if (this.codiceAsta) {
          this.localUtil.hideHeaderSticky(this.isAstaSingola()?ConstantsModule.AUC_MOTORE_ASTA_SINGOLA:ConstantsModule.AUC_MOTORE_ASTA_MULTIPLA);
        }
    }
    this.setHeaderSticky();

  }

  isAstaSingola() {
    if (this.auction_configuration) {
      if (this.auction_configuration.motore == ConstantsModule.AUC_MOTORE_ASTA_SINGOLA) {
        return true;
      } else {
        return false;
      }
    } else {
      return false;
    }
  }

  setHeaderSticky() {
    try {
      /*$('#lotto').width($('#lottoHeader').width());
      $('#tipologia').width($('#tipologiaHeader').width());
      $('#area').width($('#areaHeader').width());
      $('#quantita').width($('#quantitaHeader').width());
      $('#rilanci').width($('#rilanciHeader').width());
      $('#prezzo').width($('#prezzoHeader').width());
      $('#tempo').width($('#tempoHeader').width());
      $('#stato').width($('#statoHeader').width());
      $('#azione1').width($('#azione1Header').width());
      $('#azione2').width($('#azione2Header').width());*/

      if ($("#container").hasClass("sidebar_closed")) {
        $('header.fixed-header').addClass('full');
        $('.overview-tool.fixed-parameters').addClass('full');
        $('#cards-lots-list-header').css('margin-left', $('#cards-lots-list').offset().left - 10 + 'px');
      } else {
        $('header.fixed-header').removeClass('full');
        $('.overview-tool.fixed-parameters').removeClass('full');
        $('#cards-lots-list-header').css('margin-left', $('#cards-lots-list').offset().left - 10 - 230 + 'px');
      }
    } catch (err) {
      //
    }
  }

  lotDetail(lot: AuctionLotItem) {
    let lotDetailElem: AuctionLotItem = lot;
    let csLots: CentroSelezioneItem[] = [];
    //console.log('lotDetail()');
    //console.log(lot);
    const modalRef = this.modalService.open(PopupComponent);
    modalRef.componentInstance.tipo = 'openLotDetail';
    modalRef.componentInstance.codiceLotto = lot.codiceLotto;
    
    this.commonService.getSelectionCenterList().subscribe(
      (data:GetSelectionCenterListResponse) => {
        let csList = data.list;
        lot.centroSelezione.forEach(csLot => {
          //console.log('csLot: ' + JSON.stringify(csLot));
          csList.forEach((cs:CentroSelezioneItem) => {
            //console.log('cs: ' + JSON.stringify(cs));
            if (cs.codice == csLot.codiceCentroSelezione) {
              csLot = $.extend(cs, csLot);
              csLots.push(csLot);
              console.log(csLot);
            }
          });
        });
      }
    );
      
    console.log(csLots);
    this.auctionService.getLotDetail(lot.codiceAsta, lot.codiceLotto).subscribe(
      data => {
          lotDetailElem = data.lot;
          lotDetailElem.centroSelezione = csLots;
          this.auctionService.getAuctionLotsHistory(lot.codiceAsta, lot.id).subscribe(
            res => {
              lotDetailElem.rilanciLotto = res.list;
              lotDetailElem.numContendentiUnici = res.numContendentiUnici;
              console.log('lotDetail()');
              console.log(lotDetailElem);
              modalRef.componentInstance.lot = lotDetailElem;
              }, this.handleError(true)
            );

      }, this.handleError(true)
    );
  
  }

  getProdottoClass(tipoProdotto: string) {
    if (tipoProdotto != undefined) {
      return tipoProdotto.toLowerCase();
    } else {
      return tipoProdotto;
    }
  }

  isEnabledToAuction() {
    return this.localUtil.isEnabledToAuction();
  }

  /*beautifySelect() {
    try {
      var x, i, j, l, ll, selElmnt, a, b, c;
      x = document.getElementsByClassName("custom-select");
      l = x.length;
      for (i = 0; i < l; i++) {
          selElmnt = x[i].getElementsByTagName("select")[0];
          ll = selElmnt.length;
          a = document.createElement("DIV");
          a.setAttribute("class", "select-selected");
          a.innerHTML = selElmnt.options[selElmnt.selectedIndex].innerHTML;
          x[i].appendChild(a);
          b = document.createElement("DIV");
          b.setAttribute("class", "select-items select-hide");
          for (j = 1; j < ll; j++) {
              c = document.createElement("DIV");
              c.innerHTML = selElmnt.options[j].innerHTML;
              c.addEventListener("click", function(e) {
                  var y, i, k, s, h, sl, yl;
                  s = this.parentNode.parentNode.getElementsByTagName("select")[0];
                  sl = s.length;
                  h = this.parentNode.previousSibling;
                  for (i = 0; i < sl; i++) {
                    if (s.options[i].innerHTML == this.innerHTML) {
                        s.selectedIndex = i;
                        h.innerHTML = this.innerHTML;
                        y = this.parentNode.getElementsByClassName("same-as-selected");
                        yl = y.length;
                        for (k = 0; k < yl; k++) {
                        y[k].removeAttribute("class");
                        }
                        this.setAttribute("class", "same-as-selected");
                        break;
                    }
                  }
                  h.click();
              });
              b.appendChild(c);
          }
          x[i].appendChild(b);
            a.addEventListener("click", function(e) {
                e.stopPropagation();
                closeAllSelect(this);
                this.nextSibling.classList.toggle("select-hide");
                this.classList.toggle("select-arrow-active");
            });
      }

      function closeAllSelect(elmnt) {
          var x, y, i, xl, yl, arrNo = [];
          x = document.getElementsByClassName("select-items");
          y = document.getElementsByClassName("select-selected");
          xl = x.length;
          yl = y.length;
          for (i = 0; i < yl; i++) {
              if (elmnt == y[i]) {
              arrNo.push(i)
              } else {
              y[i].classList.remove("select-hide");
              }
          }
          for (i = 0; i < xl; i++) {
              if (arrNo.indexOf(i)) {
              x[i].classList.add("select-arrow-active");
              }
          }
      }

      document.addEventListener("click", closeAllSelect);

    } catch(err) {
      console.log(err);
    }

  }*/

}

