import { Location } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { Route, Router, Routes } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { AuctionItem } from 'src/app/models/dataModels';
import { APIService } from 'src/app/services/APIService';
import { AuctionService } from 'src/app/services/auction.service';
import { AuthService } from 'src/app/services/auth.service';
import { ConstantsModule } from 'src/app/services/constants.module';
import { LocalUtilService } from 'src/app/services/local-util.service';
import { MessagesService } from 'src/app/services/messages.service';

@Component({
  selector: 'app-aste-pubbliche',
  templateUrl: './aste-pubbliche.component.html',
  styleUrls: ['./aste-pubbliche.component.css']
})
export class AstePubblicheComponent extends APIService implements OnInit {

  constructor(public localService: LocalUtilService,
    public authService: AuthService,
    public auctionService: AuctionService,
    private modalService: NgbModal,
    private messageService: MessagesService,
    location: Location, 
    router: Router) {
    super(localService, location, router);

  }
  
  auction: AuctionItem;
  auctions_list: any[]=[];  

  ngOnInit(): void {
    this.listAuctionPubbliche();
  }

  listAuctionPubbliche() {
      
    this.auctionService.listaAste("Pubblica").subscribe(
      (data:any) => { 
        this.auctions_list = data.list;
      });
  }

  onRowPrepared(info) {
    if(info.rowType === 'data') {  
      info.rowElement.addClass('auction');  
    }
  }

  onContentReady(event) {
    //$('table.dx-datagrid-table').find('div.dx-data-row').addClass('lot');
    //$('.dx-hidden').hide();
    //$('.dx-datagrid-nodata').html('<h3>Nessun risultato trovato</h3>');
  }

  openDettaglio(e) {
    let className = e.event.target.className;
    if(e.rowType === "data" && className.toLowerCase().indexOf('pdf') < 0) {
      this.router.navigate([ConstantsModule.REF_AZ_ASTE_PUBBLICHE_DETTAGLIO_PATH + e.data.codiceAsta, {}]);
      //event.component.editRow(e.rowIndex);
    }
  }
  
}
