import { DatePipe, Location } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import ExcellentExport, { ConvertOptions, SheetOptions } from 'excellentexport';
import { ConfirmComponent } from 'src/app/commons/components/confirm/confirm.component';
import { PopupComponent } from 'src/app/commons/components/popup/popup.component';
import { AuctionDettaglioItem, AuctionItem, AuctionLotCreateItem, AuctionLotItem, CentroSelezioneItem, FileWithContentUpload, NazioneItem, OrganizationOperatorItem } from 'src/app/models/dataModels';
import { APIService } from 'src/app/services/APIService';
import { AuctionService } from 'src/app/services/auction.service';
import { CommonService } from 'src/app/services/common.service';
import { ConstantsModule } from 'src/app/services/constants.module';
import { LocalUtilService } from 'src/app/services/local-util.service';
import { MessagesService } from 'src/app/services/messages.service';
import * as ClassicEditor from '@ckeditor/ckeditor5-build-classic';

@Component({
  selector: 'app-coripet-asta-dettaglio',
  templateUrl: './dettaglio.component.html',
  styleUrls: ['./dettaglio.component.css']
})
export class CoripetAstaDettaglioComponent extends APIService implements OnInit {

  showPopup: boolean = false;
  saveButtonOptions: any;
  closeButtonOptions: any;
  backButtonOptions: any;
  documentoAzienda: FileWithContentUpload;


  constructor(localUtil: LocalUtilService,
    public auctionService: AuctionService,
    private modalService: NgbModal,
    private messageService: MessagesService,
    location: Location,
    router: Router,
    private route: ActivatedRoute,
    public commonService: CommonService) {
    super(localUtil, location, router);
    console.log('CoripetAstaDettaglioComponent()');

    this.openPopup = this.openPopup.bind(this);
    const that = this;
    this.saveButtonOptions = {
      text: 'Aggiungi',
      stylingMode: 'contained',
      width: '200',
      type: 'default',
      onClick() {
        that.save();
      }
    },
      this.closeButtonOptions = {
        text: 'Annulla',
        width: '200',
        onClick() {
          that.showPopup = false;
        }
      },
      this.backButtonOptions = {
        text: 'Indietro',
        width: '200',
        onClick() {
          that.indietro();
        }
      }

    this.auctionDettaglioForm = new FormGroup({
      codiceLotto: new FormControl(this.auctionDettaglio.codiceLotto, [Validators.required]),
      codiceProdotto: new FormControl(this.auctionDettaglio.codiceProdotto, [Validators.required]),
      tipoProdotto: new FormControl(this.auctionDettaglio.tipoProdotto, [Validators.required]),
      areaGeografica: new FormControl(this.auctionDettaglio.areaGeografica, [Validators.required]),
      quantitaTon: new FormControl(this.auctionDettaglio.quantitaTon, [Validators.required]),
      dataDisponibilitaLotto: new FormControl(this.auctionDettaglio.dataDisponibilitaLotto, [Validators.required]),
      note: new FormControl(this.auctionDettaglio.note),
      pesoMedia: new FormControl(this.auctionDettaglio.pesoMedio),
      prezzoBase: new FormControl(this.auctionDettaglio.prezzoBase, [Validators.required]),
      schedaTecnica: new FormControl(this.auctionDettaglio.schedaTecnica)

    })
  }

  public Editor = ClassicEditor;
  auctionDettaglio: AuctionDettaglioItem = new AuctionDettaglioItem();
  auctionDettaglioForm: FormGroup;
  submitted: boolean = false;
  auction: AuctionItem;
  codiceAsta: string;
  lot_list: AuctionLotItem[] = [];
  cs_list: CentroSelezioneItem[] = [];
  nation_list: NazioneItem[] = [];

  excelLotti: FileWithContentUpload;
  flagExcelLotti: boolean;

  customizeItemTemplate(item: any) {
    item.template = "formItem";
  }

  asteDetails: any[] = [];
  ngOnInit(): void {
    this.asteDetails = this.centroSelezione;
    console.log(this.asteDetails);
    this.codiceAsta = this.route.snapshot.paramMap.get('codiceAsta');
    console.log('codiceAsta: ' + this.codiceAsta);
    this.loadCentriSelezione();
    this.loadNazioni();
    this.loadAuctionDetails(undefined, false);
    this.loadMaterialType();
    this.loadAreaGeo();
  }

  editDetails: string;
  grid: boolean = true;
  form: boolean = false;
  button: boolean = false;
  quantita: any = '';
  mandatory: boolean = false;
  centroSelezione: any[] = [];
  codice: any;

  click(e) {
    this.grid = false;
    this.form = true;
    this.button = true;
    console.log(e);
    this.editDetails = e.data.codice + e.data.nome + e.data.indirizzo;
    this.codice = e.data.codice;
  }

  indietro() {
    this.grid = true;
    this.form = false;
    this.button = false;
    this.mandatory = false;
  }

  save() {
    if (this.quantita === '') {
      this.mandatory = true;
    } else {
      console.log("API Calling");
      this.mandatory = false;
      this.centroSelezione.push({
        "codiceCentroSelezione": this.codice,
        "quantitaTon": this.quantita
      });
      console.log(this.centroSelezione);
      this.form = false;
      this.grid = true;
      this.button = false;
      this.showPopup = false;
      this.quantita = '';
    }
  }

  rimuovi(index: number) {
    if (index >= 0 && index < this.asteDetails.length) {
      this.asteDetails.splice(index, 1);
      //sessionStorage.setItem('aste-details', JSON.stringify(this.asteDetails));
    }
  }

  type_material: any[] = [];
  loadMaterialType() {
    this.commonService.getMaterialTypesList().subscribe(
      data => {
        this.type_material = data.list;
      },
      this.handleError(true)
    );
  }

  geo_area: any[] = [];
  loadAreaGeo() {
    this.commonService.getAreaGeograficaList().subscribe(
      data => {
        this.geo_area = data.list;
      },
      this.handleError(true)
    );
  }

  loadNazioni() {
    this.commonService.getNationsList().subscribe(
      data => {
        this.nation_list = data.list;
      });
  }

  loadCentriSelezione() {
    this.auctionService.getSelectionCenterList().subscribe(
      data => {
        this.cs_list = data.list;
      });
  }

  getLotByCode(codiceLotto: string) {
    let output = undefined;
    //console.log('getLotByCode() this.lot_list: ' + JSON.stringify(this.lot_list));
    if (this.lot_list != undefined) {
      this.lot_list.forEach((lotElem: any) => {
        if (lotElem.codiceLotto == codiceLotto) {
          output = lotElem;
        }
      });
    }
    return output;
  }

  gotoBack() {
    this.location.back();
  }

  loadAuctionDetails(textFilter, onlywon) {

    var qparam = '';

    this.auctionService.getAuctionLots(this.codiceAsta, qparam).subscribe(
      (data: any) => {
        console.log('data.auctionDetail: ' + JSON.stringify(data.auctionDetail));
        this.auction = data.auctionDetail;
        /*this.statoAsta = data.auctionDetail.statoAsta;
        this.tipologiaAsta = data.auctionDetail.tipologiaAsta;
        this.dataOraFineAsta = data.auctionDetail.dataOraFineAsta;*/
        if (data.lotList != undefined) {
          this.auction.numLotti = data.lotList.length;
          //console.log('lot_list: ' + data.lotList);

          if (this.auction.statoAsta == ConstantsModule.AUCTION_STATUS_AVAILABLE) {
            this.manageAllOtherAuction(data.lotList);
          } else {
            this.manageAllOtherAuction(data.lotList);
          }


          console.log('lot_list: ' + this.lot_list.length);
        }
      });

  }

  manageAvailableAuction(lotList) {
    console.log('manageAvailableAuction()');
    this.manageAllOtherAuction(lotList);
  }

  manageAllOtherAuction(lotList) {
    this.lot_list = [];
    console.log('manageAllOtherAuction()');
    if (lotList) {
      lotList.forEach(item => {
        console.log(item);
        item.prezzoUltimoRilancio = this.localUtil.formatNumber2(item.prezzoUltimoRilancio);

        if (item.pagato == 0) {
          item.paid = "Non pagato";
          item.paidClass = "ko";
        } else if (item.pagato == 1) {
          item.paid = "Pagato";
          item.paidClass = "ok";
        }
        if (item.spedito == 0) {
          item.retired = "Non ritirato";
          item.retiredClass = "ko";
        } else if (item.spedito == 1) {
          item.retired = "Ritirato";
          item.retiredClass = "ok";
        }

        item.paidChecked = item.pagato == 1;
        item.retiredChecked = item.spedito == 1;
        item.notPaidChecked = item.pagato == 0;
        item.notRetiredChecked = item.spedito == 0;

        item.codiceLottoNoPoints = this.localUtil.getCodiceLottoNoPoints(item.codiceLotto);

        if (item.nomeOrganization == undefined) {
          item.nomeOrganization = '-';
        }

        if (
          (this.daapprovare && item.statoLotto == "In Pubblicazione") ||
          (this.approvati && item.statoLotto == "Pubblicato") ||
          (this.rifiutati && item.statoLotto == "Rifiutato") ||
          (this.bozza && item.statoLotto == "Bozza")
        ) {
          console.log("IF")
          this.lot_list.push(item);
        }

      });
      console.log('data.lotList: ');
      console.log(lotList);

      //this.lot_list = lotList;
    }

  }

  daapprovare: boolean = true;
  approvati: boolean = true;
  rifiutati: boolean = true;
  bozza: boolean = true;
  filterCheckBox() {
    this.loadAuctionDetails(undefined, false);
  }

  onRowPrepared(info) {
    if (info.rowType === 'data') {
      //console.log('onRowPrepared() info.rowElement: ' + info.rowElement);
      //info.rowElement.addClass('auction');  
    }
  }

  onStartEditing(e) {
    console.log('onStartEditing()');
  }

  onContentReady(event) {
    console.log('contenReady');
    $('.dx-datagrid-headers').remove();
    $('.dx-datagrid-rowsview').css('border-top', 'none');
    //$('.dx-datagrid-header-panel').css('border-bottom', 'none');
  }

  gotoListaAsteChiuse() {
    this.router.navigate([ConstantsModule.REF_AZ_ASTE_CHIUSE_PATH, {}]);
  }

  getSchedaTecnicaUrl(tipoProdotto: string) {
    return ConstantsModule.S3_URL + '/scheda_tecnica/scheda-tecnica-' + tipoProdotto + '.pdf';
  }

  openNote(note: string, codiceLotto: string) {
    const modalRef = this.modalService.open(PopupComponent);
    modalRef.componentInstance.tipo = 'openNote';
    modalRef.componentInstance.note = note;
    modalRef.componentInstance.codiceLotto = codiceLotto;
  }

  chiudi() {
    //this.passBack(undefined);
    //this.activeModal.close();
    //this.modalService.dismissAll();
  }

  openPopup() {
    this.showPopup = true;
    //const modalRef = this.modalService.open(PopupComponent);
    //modalRef.componentInstance.tipo = 'centri-di-selezione';
  }

  openCentriSelezione(codiceLotto: string) {
    const modalRef = this.modalService.open(PopupComponent);
    modalRef.componentInstance.tipo = 'openCentriSelezione';
    modalRef.componentInstance.titolo = 'Centri di selezione';
    modalRef.componentInstance.codiceLotto = codiceLotto;
    console.log('openCentriSelezione() codiceLotto: ' + codiceLotto);
    let lotElem = this.getLotByCode(codiceLotto);
    let csListPopup: CentroSelezioneItem[] = [];
    console.log(lotElem);
    if (lotElem != undefined && lotElem.centroSelezione != undefined) {
      lotElem.centroSelezione.forEach(csLottoElem => {
        let item = new CentroSelezioneItem();
        item.quantitaTon = lotElem.quantitaTon;
        item.indirizzo = '';
        item.pesoMedioCarico = '';

        let cs = this.localUtil.getSelectionCenterByCodice(csLottoElem.codiceCentroSelezione, this.cs_list);
        if (cs != undefined) {

          let nazione = this.localUtil.getNazioneByCodice(cs.nazione, this.nation_list);
          console.log('nazione: ' + JSON.stringify(nazione));
          let nazioneDescr = '';
          if (nazione != null && nazione != undefined && nazione != '') {
            nazioneDescr = nazione.descrizione;
            if (nazione.codice == 'IT') {
              nazioneDescr = 'Italia';
            }
            item.nazioneDescr = nazioneDescr;
          }
        }
        item.CER = cs.CER;
        item.cap = cs.cap;
        item.citta = cs.citta;
        item.provincia = cs.provincia;
        item.pesoMedioCarico = cs.pesoMedioCarico;
        item.nome = cs.nome;
        item.indirizzo = cs.indirizzo;
        csListPopup.push(item);
      });
    }
    modalRef.componentInstance.centriSelezione = csListPopup;

  }

  exportExcel(codiceAsta: string, titolo: string) {
    console.log('exportExcel() codiceAsta: ' + codiceAsta + ' titolo: ' + titolo);
    var code = codiceAsta;
    var exportTitle = titolo;
    var exportList = this.lot_list;

    //header xls title creations
    var html = "<thead>";
    var entriesDone = false;
    var toExlude = ['createDate', 'modifiedDate', 'statoLotto', 'codiceRifiutoCER', 'pesoMedio', 'imballaggio', 'dataOraFineAstaEffettiva', 'numeroRilanci', 'prezzoUltimoRilancio', 'spedito', 'pagato'];
    var idToExclude = [];
    this.lot_list.forEach(elem => {
      console.log('exportExcel() loop over lot elem: ' + JSON.stringify(elem));
      //utilities.printLog("loop over lot elem " + JSON.stringify($(this)));
      var p = elem;
      for (var key in p) {
        //utilities.printLog("key: " + key);
        if (p.hasOwnProperty(key) && !entriesDone) {
          //utilities.printLog(key + " -> " + p[key]);
          var jsonElem = p[key];
          let cont = 0;
          for (var keyElem in jsonElem) {
            if (cont == 0) {
              html += "<tr>";
            }
            if (!toExlude.includes(keyElem)) {
              //utilities.printLog("header: " + keyElem);
              html += "<td>" + keyElem + "</td>";
              entriesDone = true;
            } else {
              idToExclude.push(cont);
              //utilities.printLog('idToExclude cont: ' + cont + ' name: ' + keyElem);
            }
            cont++;
          }
          if (cont > 0) {
            html += "</tr>";
          }
        }
        break;
      }

    });

    html += "</thead>";

    //utilities.printLog('idToExclude: ' + idToExclude);
    //table creations
    html += "<tbody>";
    this.lot_list.forEach(elem => {
      //utilities.printLog("loop over lot elem " + JSON.stringify($(this)));
      console.log('exportExcel() loop over lot elem2: ' + JSON.stringify(elem));
      var p = elem;
      for (var key in p) {
        //utilities.printLog("key: " + key);
        if (p.hasOwnProperty(key)) {
          //utilities.printLog(key + " -> " + p[key]);
          var jsonElem: any = p[key];
          let cont = 0;
          for (var keyElem in jsonElem) {
            if (cont == 0) {
              html += "<tr>";
            }
            //utilities.printLog(keyElem + " -> " + jsonElem[keyElem]);
            if (!idToExclude.includes(cont)) {
              let rowcslist = '';
              if (keyElem != 'centroSelezione') {
                html += "<td>" + jsonElem[keyElem] + "</td>";
              } else {
                //jsonElem[keyElem].forEach(function (item) {
                for (let g = 0; g < jsonElem[keyElem].length; g++) {
                  let item = jsonElem[keyElem][g];
                  //utilities.printLog('for centroSelezione: ' + JSON.stringify(item));
                  let cs = this.localUtil.getSelectionCenterByCodice(item.codiceCentroSelezione, this.cs_list);
                  item.nome = '';
                  item.indirizzo = '';
                  if (cs !== undefined) {
                    item.nome = cs.nome;
                    item.indirizzo = cs.indirizzo + ' - ' + cs.cap + ' ' + cs.citta + ' (' + cs.provincia + ')'
                  }
                  let rowcs = '<b>' + item.codiceCentroSelezione + '</b> - ' + item.nome + ' - ' + item.quantitaTon + ' ton - ' + item.indirizzo + '<br/>';
                  rowcslist += rowcs;
                  //});
                }
                html += "<td>" + rowcslist + "</td>";
              }
            }
            cont++;
          }
          if (cont > 0) {
            html += "</tr>";
          }
        }
      }
    });

    html += "</tbody>";

    $("#export-table").html(html);
    console.log('exportExcel() pre export');
    let tableObj: any = $("#export-table");

    /*(<any>$("#export-table")).table2excel({
        name: exportTitle,
        filename: 'export_' + code + '.xls'
    });*/

    const a: any = document.createElement('a');
    a.download = "export.xlsx";
    a.style = 'display: none';
    document.body.appendChild(a);
    let blob = new Blob([html], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;' });
    //let url = window.URL.createObjectURL(blob);
    //a.href = url;
    //a.download = titolo;
    //a.click();
    //URL.revokeObjectURL(url);

    const options = {
      anchor: document.getElementById('anchor'),
      filename: 'export',
      format: 'xlsx'
    } as ConvertOptions;

    const sheets = [
      {
        name: 'Export',
        from: {
          table: document.getElementById('export-table')
        }
      }
    ] as SheetOptions[];
    const workbook = ExcellentExport.convert(options, sheets);
    const anchor = document.getElementById('anchor') as HTMLAnchorElement;

    console.log('anchor: ' + anchor);
    console.log('exportExcel() post export');
  }

  requestApproveAllLots() {

    let params: any[] = [this.auction.codiceAsta];
    const modalRef = this.modalService.open(ConfirmComponent);
    modalRef.componentInstance.titolo = this.messageService.getLabelFromCode("INFO_TITLE");
    modalRef.componentInstance.messaggio = this.messageService.getLabelFromCode("AUCTION_REQUEST_APPROVE_ALL_LOTS_MESSAGE", params);
    modalRef.componentInstance.passEntry.subscribe((receivedEntry: any) => {
      if (receivedEntry != undefined) {

        this.auctionService.requestApproveLotList(this.auction.codiceAsta, this.lot_list).subscribe(
          res => {
            this.localUtil.showMessage("", ConstantsModule.INFO_TITLE, this.messageService.getMessageFromCode("OK_OPERATION"), true);
            this.reloadComponent();
          },
          this.handleError(true)
        );
      }
    }, this.handleError(true));

  }

  undoRequestApproveAllLots() {

    let params: any[] = [this.auction.codiceAsta];
    const modalRef = this.modalService.open(ConfirmComponent);
    modalRef.componentInstance.titolo = this.messageService.getLabelFromCode("INFO_TITLE");
    modalRef.componentInstance.messaggio = this.messageService.getLabelFromCode("AUCTION_UNDO_REQUEST_APPROVE_ALL_LOTS_MESSAGE", params);
    modalRef.componentInstance.passEntry.subscribe((receivedEntry: any) => {
      if (receivedEntry != undefined) {

        this.auctionService.undoRequestApproveLotList(this.auction.codiceAsta, this.lot_list).subscribe(
          res => {
            this.localUtil.showMessage("", ConstantsModule.INFO_TITLE, this.messageService.getMessageFromCode("OK_OPERATION"), true);
            this.reloadComponent();
          },
          this.handleError(true)
        );
      }
    }, this.handleError(true));

  }

  downloadTemplateExcelLotti() {

    this.auctionService.downloadTemplateExcelLots().subscribe(
      b64 => {
        this.auctionService.downloadFileB64('template-excel-lots.xlsx', 'application/vnd.ms-excel', b64);
      },
      this.handleError(true)
    );

  }

  //need to check this because i have changed the documentoAzienda to excelLotti
  UploadExcelLottiFile(event) {
    const inputElement = event.target as HTMLInputElement;
    const file = inputElement.files[0];
    if (file != undefined) {
      console.log(file);
      this.excelLotti = new FileWithContentUpload();
      this.excelLotti.file = file;
      console.log('getExcelLottiFile() loaded file: ' + file.name);
    } else {
      this.excelLotti = undefined;
    }
  }


  getExcelLottiFile(file) {
    if (file != undefined) {
      console.log(file);
      this.documentoAzienda = new FileWithContentUpload();
      this.documentoAzienda.file = file;
      console.log('getExcelLottiFile() loaded file: ' + file.name);
    } else {
      this.excelLotti = undefined;
    }
  }


  caricaExcelLotti() {
    console.log('caricaExcelLotti()');
    console.log(this.excelLotti);

    if (this.excelLotti != undefined && this.excelLotti != undefined) {

      this.excelLotti.contentType = 'FWK';
      this.excelLotti.codiceAsta = this.auction.codiceAsta;

      this.auctionService.uploadExcelLots(this.excelLotti).subscribe(
        data => {
          console.log(data);
          data = JSON.parse(data);
          console.log('excelLotti uploaded! file_uuid: ' + data.file_uuid);
          console.log(data);
          this.ngOnInit();
        }, this.handleError(true));
    } else {
      this.localUtil.showMessage("", ConstantsModule.ERROR_TITLE, this.messageService.getLabelFromCode("UPLOAD_FORM_FILE_NOT_LOADED"), true);
    }

  }




  uploadDocument() {
    this.documentoAzienda.contentType = 'LOT';
    this.documentoAzienda.codiceLotto = this.auctionDettaglioForm.get('codiceLotto').value;

    this.commonService.documentUpload(this.documentoAzienda).subscribe(dt => {
      this.createResponseDetails.forEach(data => {
        let updateRequest = {
          "areaGeografica": data.areaGeografica,
          "centroSelezione": data.centroSelezione,
          "codiceAsta": data.codiceAsta,
          "codiceLotto": data.codiceLotto,
          "codiceProdotto": data.codiceProdotto,
          "codiceRifiutoCER": data.codiceRifiutoCER,
          "createDate": data.createDate,
          "dataDisponibilitaLotto": data.dataDisponibilitaLotto,
          "dataOraFineAstaEffettiva": data.dataOraFineAstaEffettiva,
          "id": data.id,
          "idOrganization": data.idOrganization,
          "modifiedDate": data.modifiedDate,
          "note": data.note,
          "numeroRilanci": data.numeroRilanci,
          "pagato": data.pagato,
          "prezzoBase": data.prezzoBase,
          "prezzoUltimoRilancio": data.prezzoUltimoRilancio,
          "quantitaTon": data.quantitaTon,
          "schedaTecnica": JSON.parse(dt).file_uuid,
          "spedito": data.spedito,
          "statoLotto": data.statoLotto,
          "tipoProdotto": data.tipoProdotto
        }
        this.auctionService.updateLotAste(this.auction.codiceAsta, data.codiceLotto, updateRequest).subscribe(dt => {
        }, this.handleError(true));
      });
      this.localUtil.showMessage("", ConstantsModule.INFO_TITLE, this.messageService.getMessageFromCode("LOT_CREATE"), true);
      this.loadAuctionDetails(undefined, false);
      this.auctionDettaglio.newEditing = true;
    }, this.handleError(true));
  }

  loadExcelLotti() {

    console.log('caricaExcelLotti()');
    let params: any[] = [this.auction.codiceAsta];
    const modalRef = this.modalService.open(PopupComponent);
    modalRef.componentInstance.titolo = this.messageService.getLabelFromCode("EXCEL_LOTS_TITLE");
    modalRef.componentInstance.messaggio = '';
    modalRef.componentInstance.tipo = 'excelLotti';
    modalRef.componentInstance.codiceAsta = this.auction.codiceAsta;
    modalRef.componentInstance.passEntry.subscribe((receivedEntry: any) => {
      if (receivedEntry != undefined) {

        console.log('carica da popup return');

      }
    }, this.handleError(true));

  }


  /*   getExcelLottiFile(file) {
      console.log("inside")
      if (file != undefined) {
        console.log('getExcelLottiFile() loaded file: ' + file.name);
        this.excelLotti = new FileWithContentUpload();
        this.excelLotti.file = file;
      } else {
        this.excelLotti = undefined;
      }
    }
  
    caricaExcelLotti() {
  
      if (this.excelLotti != undefined && this.excelLotti != undefined) {
  
        this.excelLotti.codiceAsta = this.codiceAsta;
  
        this.auctionService.uploadExcelLots(this.excelLotti).subscribe(
          data => {
            data = JSON.parse(data);
            console.log('excelLotti uploaded! file_uuid: ' + data.file_uuid);
            console.log(data);
            this.passEntry.emit(true);
          }, this.handleError(true));
      } else {
        this.localUtil.showMessage("", ConstantsModule.ERROR_TITLE, this.messageService.getLabelFromCode("UPLOAD_FORM_FILE_NOT_LOADED"), true);
  
      }
  
    } */

  isNewEditing() {
    return (this.auctionDettaglio.newEditing == true);
  }

  toggleNuovoOperatore() {
    this.auctionDettaglio.newEditing = !this.auctionDettaglio.newEditing;
    if (this.auctionDettaglio.newEditing) {
      this.cleanValidation();
    }
  }

  cleanValidation() {
    this.auctionDettaglioForm.markAllAsTouched();
  }

  date = new Date();
  pipe = new DatePipe('en-US');
  firstSection: boolean = true;
  secondSection: boolean = false;
  createResponseDetails: any[] = [];
  salvaAuctionDettaglio() {
    if (this.validate()) {
      let newOp: AuctionDettaglioItem = new AuctionDettaglioItem();
      let auctionDettaglioForm = this.pipe.transform(this.auctionDettaglioForm.get('dataDisponibilitaLotto').value, 'dd/MM/yyyy');
      console.log(auctionDettaglioForm);
      newOp = {
        codiceRifiutoCER: "",
        prezzoBase: this.auctionDettaglioForm.get('prezzoBase').value,
        pesoMedio: "",
        schedaTecnica: "",
        codiceLotto: this.auctionDettaglioForm.get('codiceLotto').value,
        codiceProdotto: this.auctionDettaglioForm.get('codiceProdotto').value,
        tipoProdotto: this.auctionDettaglioForm.get('tipoProdotto').value,
        areaGeografica: this.auctionDettaglioForm.get('areaGeografica').value,
        quantitaTon: this.auctionDettaglioForm.get('quantitaTon').value,
        dataDisponibilitaLotto: auctionDettaglioForm,
        note: this.auctionDettaglioForm.get('note').value,
        centroSelezione: this.centroSelezione,
        // codiceAsta:this.auction.codiceAsta,
      }
      this.auctionService.insertLotAste(this.auction.codiceAsta, newOp).subscribe(dt => {
        console.log(dt.detail);
        this.createResponseDetails.push(dt.detail);
        console.log(this.createResponseDetails);
        this.localUtil.showMessage("", ConstantsModule.INFO_TITLE, this.messageService.getMessageFromCode("LOT_CREATE"), true);
        this.firstSection = false;
        this.secondSection = true;
        this.handleError(true);
      }, this.handleError(true));
    }
  }

  annullaNuovoOperatore() {
    this.auctionDettaglio = new AuctionDettaglioItem();
    this.auctionDettaglio.newEditing = false;
  }

  controlHasError(nome) {
    return !this.auctionDettaglioForm.get(nome).valid && this.submitted;
  }

  validate() {
    this.submitted = true;
    let output = true;
    console.log(this.auctionDettaglioForm);
    Object.keys(this.auctionDettaglioForm.controls).forEach(key => {
      if (!this.auctionDettaglioForm.get(key).valid) {
        output = false;
      }
    });
    return output;
  }
}
