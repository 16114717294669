import { ConstantsModule } from './constants.module';
import { LocalUtilService } from './local-util.service';
import { Location } from '@angular/common';
import { Router, Routes } from '@angular/router';
import { Injectable } from '@angular/core';
import moment from 'moment';
import { AuctionBoxTimer, AuctionItem, AuctionLotItem, OrganizationItem } from '../models/dataModels';

@Injectable()
export class APIService {
      
    routes: Routes;

    constructor(public localUtil: LocalUtilService,
        public location: Location,
        public router: Router
        ) {
            this.router.routeReuseStrategy.shouldReuseRoute = () => false;
            if (this.router.url.indexOf(ConstantsModule.USER_ASTE_IN_CORSO) < 0) {
              this.localUtil.activeAuctionCode = undefined;
            }
    }

    resizeTabbedForm() {
      $('.dx-tab-text').parent().parent().parent().parent().parent().parent().parent().parent().parent().parent().addClass('tabbed-form-with-full');
    }

    printLog(data){
        console.log(data);
    }

    handleError<T>(showPopup:any, callback?) {
        this.localUtil.hideLoader();

        if (this.localUtil.authParams == undefined) {
            //console.log('launchLogout()');
            //this.localUtil.launchLogout();
        }

        return (res: any) => {
            console.error(res);
            this.localUtil.printLog('res.error.code: ' + res.error.code);
            this.localUtil.printLog('res.error.message: ' + res.error.message);
            try {
                res.error = JSON.parse(res.error);
                this.localUtil.printLog('res.error.code1: ' + res.error.code);
                this.localUtil.printLog('res.error.message1: ' + res.error.message);    
            } catch(errore) {
                //
            }
            if (res.error.code === 'ERR_AUTHENTICATION' || res.error.code === 'E:AUTHENTICATION' || res.error.code === 'ERR:AUTHENTICATION' || res.error.code == 'E:AUTHORIZATION_FAIL') {
                //this.localUtil.printLog('error authentication res.error.code: ' + res.error.code);
                this.localUtil.removeAuthParams();

                this.router.navigate([ConstantsModule.LOGIN_PATH, {}]);
                /*if (this.location.path() != ConstantsModule.LOGIN_PATH) {
                    location.href = ConstantsModule.LOGIN_PATH;
                }*/
            }

            //this.localUtil.printLog('lastErrorCode: ' +res.error.code );
            if (showPopup != undefined) {
                if (!showPopup) {
                    if (callback) {
                        callback();
                    }
                    return;
                }
            }

            let message = res.error.message;

            if (res.error.fields != undefined) {
                if (res.error.fields.length > 0) {
                    message = 'Validation failed for:<br/>';
                    res.error.fields.forEach((element:any) => {
                        message += '<br/>' + element.field + " - " + element.message;
                    });
                }
            }
            this.localUtil.showMessage(res.error.code, ConstantsModule.ERROR_TITLE, message, true);
            if (callback) {
                callback();
            }
        };
    }

    isPopupVisible() {
        return $('#modal-dialog').hasClass('show');
    }

    isAdmin() {
        ////this.localUtil.printLog('isAdmin() ' + this.localUtil.isAdmin());
        return this.localUtil.isAdmin();
    }

    isCoripet() {
        return this.localUtil.isCoripet();
    }

    isUser() {
        ////this.localUtil.printLog('isAdmin() ' + this.localUtil.isAdmin());
        return this.localUtil.isUser();
    }

    isAnagAdminOrCoripet(anagAzienda: OrganizationItem) {
      //console.log('isAnagAdminOrCoripet() anagAzienda: ');
      //console.log(anagAzienda);
      if (anagAzienda != undefined) {
        return anagAzienda.orgname == this.localUtil._adminOrgname || anagAzienda.orgname == this.localUtil._coripetOrgname;
      } else {
        return false;
      }
    }

    downloadFile(uuid:string, filename: string) {
        this.localUtil.downloadFile(uuid, filename);
    }

    downloadFromS3Url(url:string) {
        this.localUtil.downloadFileFromS3(url);
    }

    reloadComponent() {
        let currentUrl = this.router.url;
        this.router.routeReuseStrategy.shouldReuseRoute = () => false;
        this.router.onSameUrlNavigation = 'reload';
        this.router.navigate([currentUrl]);
    }

    ruleNumberZeroPositive(e) {
        if (e != undefined && e.value != undefined) {
          if (e.value > -1) {
            return true;
          }
        }
        return false;
      }
    
      ruleNumberPositive(e) {
        try {
          if (e != undefined && e.value != undefined) {
            if (e.value < 0) {        
              return false;
            }
          } else {
            return false;
          }
          return true;
        } catch (err) {
          console.log(err);
          return false;
        }
      }

      getDescrizioneRuolo(role: string) {
        let output = '';
        if (role == "OPERATORE") {
          output = "Operatore Coripet";
        } else if (role == "OPER_PLASTICFINDER") {
          output = "Admin";
        } else if (role == "OPER_AZIENDALE") {
          output = "Operatore Aziendale";
        } else if (role == "REF_AZIENDALE") {
          output = "Referente Aziendale";
        }
        return output;
      }

      get ConstantsModule() {
        return ConstantsModule;
      }

      get LocalUtil(){
        return this.localUtil;
      }

      getRouterLinkAuctionBox(codiceAsta: string) {
        return '/' + ConstantsModule.USER_ASTE_IN_CORSO_BOX + codiceAsta;
      }

      getRouterLinkAuctionList(codiceAsta: string) {
        return '/' + ConstantsModule.USER_ASTE_IN_CORSO + codiceAsta;
      }

      getDataFineAstaEffettiva(data: string) {
        let output = '';
        if (data != undefined) {
          let dataSplitted = data.split(" ");
          output = dataSplitted[0];
        }
        return output;
      }
    
      getOraFineAstaEffettiva(data: string) {
        let output = '';
        if (data != undefined) {
          let dataSplitted = data.split(" ");
          output = dataSplitted[1];
        }
        return output;
      }

      getMomentDate(data:string) {
        let dateSplitted = data.split(" ")[0].split("/");
        let timeNotSplitted = data.split(" ")[1];
        let nowDate: moment.Moment = moment();
        let dateTimeEnd = dateSplitted[2] + '-' + dateSplitted[1] + '-' + dateSplitted[0] + 'T' + timeNotSplitted + ":00";
        let momentDate: moment.Moment = moment(dateTimeEnd);
        return momentDate;
      }

      getNowMomentDate() {
        let momentDate: moment.Moment = moment();
        return momentDate;
      }

      getUTCFromDevextremeDate(data: string, ora?: string) {
        console.log('getUTCFromDevextremeDate() input params : ' + data + ' ' + ora);
        let output = undefined;
        if (data) {
          moment.locale('IT');
          //////this.localUtil.printLog('onRowInsert() if : ' + this.modelEdit.dataOraInizioAnalisi);
          let datas = new Date(data);
          console.log('getUTCFromDevextremeDate() dt : ' + datas);
          var month = datas.getMonth() + 1 + ""; //months from 1-12
          console.log('getUTCFromDevextremeDate() data : ' + data);
          if (month.toString().length < 2) month = "0" + month;
          console.log('getUTCFromDevextremeDate() month : ' + month);
          var day = datas.getDate() + "";
          if (day.toString().length < 2) day = "0" + day;
          console.log('getUTCFromDevextremeDate() day : ' + day);
          var year = datas.getFullYear();
          let newdate = day + "/" + month + "/" + year;
          console.log('getUTCFromDevextremeDate() newdate : ' + newdate);
          if (!ora) {
            ora = '00:00'
          }
          console.log('getUTCFromDevextremeDate() newdate with time : ' + newdate + ' ' + ora);
                    
          output = moment(newdate + ' ' + ora, 'DD/MM/YYYY HH:mm', true).format();
          
          console.log('getUTCFromDevextremeDate() output : ' + output);
          //console.log('getUTCFromDevextremeDate() output.toLocaleString : ' + output.toLocaleString());
        }
        console.log(output);
        return output.toLocaleString();
      }


      convertItalianDateTimeToGTM(dataOra: string) {
        console.log('convertItalianDateTimeToGTM() dataOra: ' + dataOra);
        moment.locale("IT");
        const date = moment.utc(new Date(dataOra)).format();
        console.log(date, "- now in UTC");
        const local = moment.utc(date).local().format();
        console.log(local, "- UTC now to local");        
        return local;
      }

      isInExtraTime(timer: AuctionBoxTimer) {
        let output = false;
        //console.log(timer);
        if (timer) {
          //console.log('timer.dataOraFineAsta: ' + timer.dataOraFineAsta + ' timer.dataOraFineAstaEffettiva: ' + timer.dataOraFineAstaEffettiva);
          if (timer.dataOraFineAsta != timer.dataOraFineAstaEffettiva) {
            output = true;
          }
        }
        return output;
      }
}
