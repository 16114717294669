import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import * as ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { AuctionLotItem, CentroDiSelezioneItem, CentroSelezioneItem, CentroSelezioneLottoItem, FileWithContentUpload, OrganizationDocumentsUpdateItem } from 'src/app/models/dataModels';
import { AuctionService } from 'src/app/services/auction.service';
import { APIService } from 'src/app/services/APIService';
import { LocalUtilService } from 'src/app/services/local-util.service';
import { MessagesService } from 'src/app/services/messages.service';
import { ActivatedRoute, Router } from '@angular/router';
import { CommonService } from 'src/app/services/common.service';
import { Location } from '@angular/common';
import { ConstantsModule } from 'src/app/services/constants.module';
import { OrganizationService } from 'src/app/services/organization.service';


@Component({
  selector: 'app-popup',
  templateUrl: './popup.component.html',
  styleUrls: ['./popup.component.css']
})
export class PopupComponent implements OnInit {
  @Input() titolo: string;
  @Input() messaggio: string;
  @Input() tipo: string;
  @Output() passEntry: EventEmitter<any> = new EventEmitter();
  public Editor = ClassicEditor;

  //forgotPassword
  email: string;

  //openNote
  @Input() note: string;
  @Input() codiceLotto: string;

  //openCentriSelezione
  @Input() centriSelezioneLotto: CentroSelezioneLottoItem[] = [];
  @Input() centriSelezione: CentroSelezioneItem[] = [];

  //excelLos
  excelLotti: FileWithContentUpload;
  @Input() codiceAsta: string;

  //loadOrgDocument
  @Input() orgname: string;
  @Input() docname: string;
  documentoAzienda: FileWithContentUpload;

  //openLotDetail
  @Input() lot: AuctionLotItem;
  cs_list: CentroSelezioneItem[] = [];

  constructor(public localUtil: LocalUtilService,
    public auctionService: AuctionService,
    public organizationService: OrganizationService,
    private modalService: NgbModal,
    private messageService: MessagesService,
    location: Location,
    public router: Router,
    private route: ActivatedRoute,
    public commonService: CommonService,
    public activeModal: NgbActiveModal) {
    //super(localUtil, location, router);
    console.log('PopupComponent()');
  }

  ngOnInit(): void {
    //this.loadCentriSelezione();
    console.log('tipo: ' + this.tipo);
  }

  // editDetails: string;
  // grid: boolean = true;
  // form: boolean = false;
  // button: boolean = false;
  // quantita: any = '';
  // mandatory: boolean = false;
  // centroSelezione: any[] = [];
  // codice: any;
  // onEditingStart(e) {
  //   this.grid = false;
  //   this.form = true;
  //   this.button = true;
  //   console.log(e);
  //   this.editDetails = e.data.codice + e.data.nome + e.data.indirizzo;
  //   this.codice = e.data.codice;
  // }

  // indietro() {
  //   this.grid = true;
  //   this.form = false;
  //   this.button = false;
  //   this.mandatory = false;
  // }

  // save() {
  //   if (this.quantita === '') {
  //     this.mandatory = true;
  //   } else {
  //     console.log("API Calling");
  //     this.mandatory = false;
  //     this.centroSelezione.push({
  //       "codiceCentroSelezione": this.codice,
  //       "quantitaTon": this.quantita
  //     });
  //     console.log(this.centroSelezione);
  //     sessionStorage.setItem('aste-details', JSON.stringify(this.centroSelezione));
  //     this.activeModal.close();
  //   }
  // }

  // loadCentriSelezione() {
  //   this.auctionService.getSelectionCenterList().subscribe(
  //     data => {
  //       console.log(data.list);
  //       this.cs_list = data.list;
  //     });
  // }

  getExcelLottiFile(file) {
    if (file != undefined) {
      console.log('getExcelLottiFile() loaded file: ' + file.name);
      this.excelLotti = new FileWithContentUpload();
      this.excelLotti.file = file;
    } else {
      this.excelLotti = undefined;
    }
  }

  caricaExcelLotti() {

    if (this.excelLotti != undefined && this.excelLotti != undefined) {

      this.excelLotti.codiceAsta = this.codiceAsta;

      this.auctionService.uploadExcelLots(this.excelLotti).subscribe(
        data => {
          data = JSON.parse(data);
          console.log('excelLotti uploaded! file_uuid: ' + data.file_uuid);
          console.log(data);
          this.passEntry.emit(true);
        }, this.handleError(true));
    } else {
      this.localUtil.showMessage("", ConstantsModule.ERROR_TITLE, this.messageService.getLabelFromCode("UPLOAD_FORM_FILE_NOT_LOADED"), true);

    }

  }

  downloadTemplateExcelLotti() {

    this.auctionService.downloadTemplateExcelLots().subscribe(
      b64 => {
        this.auctionService.downloadFileB64('template-excel-lots.xlsx', 'application/vnd.ms-excel', b64);
      },
      this.handleError(true)
    );

  }

  passBack(ref: any) {
    this.passEntry.emit(ref);
  }

  openModal(titolo: string, messaggio: string, tipo: string) {
    console.log('openModal()');
    this.titolo = titolo;
    this.messaggio = messaggio;
    this.tipo = tipo;
  }

  /*successDialogClose() {
    console.log('successDialogClose()');
    this.passBack(undefined);
  }*/

  toggleTechnicalMessage() {

  }

  chiudi() {
    console.log('chiudi()');
    this.passBack(undefined);
    this.activeModal.close();
    //this.modalService.dismissAll();
  }

  okForgotPassword() {
    console.log('ok() this.email: ' + this.email);
    this.modalService.dismissAll();
    this.passBack(this.email);
  }

  cancel() {
    console.log('cancel()');
    this.passBack(undefined);
    this.modalService.dismissAll();
  }

  caricaDocumentoAzienda() {
    console.log('caricaDocumentoAzienda() this.documentoAzienda: ' + this.documentoAzienda);
    console.log('caricaDocumentoAzienda() this.docname: ' + this.docname);
    if (this.documentoAzienda != undefined && this.docname != undefined) {

      this.documentoAzienda.contentType = 'ORG';
      this.documentoAzienda.vatnumber = this.orgname;

      this.commonService.documentUpload(this.documentoAzienda).subscribe(
        data => {
          data = JSON.parse(data);
          console.log('caricaDocumentoAzienda uploaded! file_uuid: ' + data.file_uuid);
          console.log(data);
          this.updateDocument(this.documentoAzienda.vatnumber, data.file_uuid, this.docname, data);
          this.passEntry.emit(true);
        }, this.handleError(true));
    } else {
      this.localUtil.showMessage("", ConstantsModule.ERROR_TITLE, this.messageService.getLabelFromCode("UPLOAD_FORM_FILE_NOT_LOADED"), true);

    }

  }

  updateDocument(vatnumber: string, uuid: string, document: string, dataUpload: any) {

    let updateBean: OrganizationDocumentsUpdateItem = new OrganizationDocumentsUpdateItem();

    if (document) {
      if (document == ConstantsModule.DOC_VISURA) {
        updateBean.visuraCameraleUuid = uuid;
      } else if (document == ConstantsModule.DOC_LEGALE) {
        updateBean.rappresentanteLegaleUuid = uuid;
      } else if (document == ConstantsModule.DOC_FIDEIUSSIONE) {
        updateBean.fidejussioneBancariaUuid = uuid;
      } else if (document == ConstantsModule.DOC_231) {
        updateBean.dichiarazione231_2001Uuid = uuid;
      }

      this.organizationService.updateOrganizationDocuments(vatnumber, updateBean).subscribe(
        (data: any) => {
          this.passBack(dataUpload);
        },
        this.handleError(true));
    }
  }

  getDocumentoAzienda(file) {
    if (file != undefined) {
      console.log('getDocumentoAzienda() loaded file: ' + file.name);
      this.documentoAzienda = new FileWithContentUpload();
      this.documentoAzienda.file = file;
    } else {
      this.documentoAzienda = undefined;
    }
  }

  selectCs(cs: CentroSelezioneItem) {
    this.passBack(cs);
  }

  printLog(data: any) {
    this.localUtil.printLog(data);
  }

  openNote(note: string, codiceLotto: string) {
    const modalRef = this.modalService.open(PopupComponent);
    modalRef.componentInstance.tipo = 'openNote';
    modalRef.componentInstance.note = note;
    modalRef.componentInstance.codiceLotto = codiceLotto;
  }

  handleError<T>(showPopup: any) {
    this.localUtil.hideLoader();

    if (this.localUtil.authParams == undefined) {
      //console.log('launchLogout()');
      //this.localUtil.launchLogout();
    }

    return (res: any) => {
      console.error(res);
      this.localUtil.printLog('res.error.code: ' + res.error.code);
      this.localUtil.printLog('res.error.message: ' + res.error.message);
      try {
        res.error = JSON.parse(res.error);
        this.localUtil.printLog('res.error.code1: ' + res.error.code);
        this.localUtil.printLog('res.error.message1: ' + res.error.message);
      } catch (errore) {
        //
      }
      if (res.error.code === 'ERR_AUTHENTICATION' || res.error.code === 'E:AUTHENTICATION' || res.error.code === 'ERR:AUTHENTICATION' || res.error.code == 'E:AUTHORIZATION_FAIL') {
        //this.localUtil.printLog('error authentication res.error.code: ' + res.error.code);
        this.localUtil.removeAuthParams();

        this.router.navigate([ConstantsModule.LOGIN_PATH, {}]);
        /*if (this.location.path() != ConstantsModule.LOGIN_PATH) {
            location.href = ConstantsModule.LOGIN_PATH;
        }*/
      }

      //this.localUtil.printLog('lastErrorCode: ' +res.error.code );
      if (showPopup != undefined) {
        if (!showPopup) {
          return;
        }
      }

      let message = res.error.message;

      if (res.error.fields != undefined) {
        if (res.error.fields.length > 0) {
          message = 'Validation failed for:<br/>';
          res.error.fields.forEach((element: any) => {
            message += '<br/>' + element.field + " - " + element.message;
          });
        }
      }
      this.localUtil.showMessage(res.error.code, ConstantsModule.ERROR_TITLE, message, true);
    };
  }

}
