import { HttpClient, HttpClientModule } from '@angular/common/http';
import { Component, NgModule, OnInit } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { DxAccordionModule, DxBulletModule, DxButtonModule, DxDataGridModule, DxDateBoxModule, DxDropDownBoxModule, DxFormModule, DxHtmlEditorModule, DxLoadIndicatorModule, DxNumberBoxModule, DxSelectBoxModule, DxTemplateModule, DxTextAreaModule, DxTextBoxModule, DxToolbarModule, DxTooltipModule, DxChartModule, DxPivotGridModule, DxLookupModule, DxTabPanelModule, DxPopupModule, DxScrollViewModule } from 'devextreme-angular';
import { DxiTabModule, DxoTabPanelOptionsModule } from 'devextreme-angular/ui/nested';
import { AppRoutingModule } from '../app-routing.module';
import { CommonsModule } from '../commons/commons.component';
import { ElencoOperatoriComponent } from '../commons/components/elenco-operatori/elenco-operatori.component';
import { AdminAsteComponent } from './components/aste/aste.component';
import { AdminAstaDettaglioComponent } from './components/aste/dettaglio/dettaglio.component';
import { AdminAziendeComponent } from './components/aziende/aziende.component';
import { AdminAziendaDettaglioComponent } from './components/aziende/dettaglio/azienda-dettaglio.component';
import { AdminCentriDiSelezioneComponent } from './components/centri-di-selezione/centri-di-selezione.component';
import { AdminDashboardComponent } from './components/dashboard/dashboard.component';
import { AdminOperatoriComponent } from './components/operatori/operatori.component';
import { AdminSetupComponent } from './components/setup/setup.component';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';

@Component({
  selector: 'app-admin',
  templateUrl: './admin.component.html',
  styleUrls: ['./admin.component.css']
})
export class AdminComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}

@NgModule({
  declarations: [
    AdminDashboardComponent,
    AdminOperatoriComponent,
    AdminAziendeComponent,
    AdminAsteComponent,
    AdminSetupComponent,
    AdminCentriDiSelezioneComponent,
    AdminAstaDettaglioComponent,
    AdminAziendaDettaglioComponent,
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,
    NgbModule,
    CommonsModule,
    AppRoutingModule,
    DxDataGridModule, DxButtonModule, DxBulletModule,
    DxSelectBoxModule, DxLoadIndicatorModule, DxTemplateModule, DxFormModule,
    DxTextAreaModule,
    DxTextBoxModule,
    DxDropDownBoxModule,
    DxDateBoxModule,
    DxFormModule,
    DxTooltipModule, DxTemplateModule,
    DxAccordionModule,
    DxHtmlEditorModule,
    DxToolbarModule,
    DxTextAreaModule,
    DxNumberBoxModule,
    DxChartModule,
    DxPivotGridModule,
    DxLookupModule,
    DxTabPanelModule,
    DxoTabPanelOptionsModule,
    DxPopupModule,
    DxScrollViewModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    }),
  ],
  exports: [
    AdminDashboardComponent,
    AdminOperatoriComponent,
    AdminAziendeComponent,
    AdminAsteComponent,
    AdminSetupComponent,
    AdminCentriDiSelezioneComponent,
    AdminAstaDettaglioComponent,
    AdminAziendaDettaglioComponent,
  ],
  providers: [],
  bootstrap: [AdminComponent]
})
export class AdminModule { }

export function HttpLoaderFactory(http: HttpClient): TranslateHttpLoader {
  return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}
