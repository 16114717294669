<div class="modal-div">
    <div class="modal-header" name="modal-dialog">
      <h4 class="modal-title">{{titolo}}</h4>
      <button type="button" class="close modale" aria-label="Chiudi" (click)="chiudi()">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body">
      <p translate>{{messaggio}}</p>
    </div>
    <div class="modal-footer">
      <button type="button" class="btn btn-primary modale" (click)="confirm()">Conferma</button>
      <button type="button" class="btn btn-default modale" (click)="chiudi()">Chiudi</button>
    </div>
</div>