import { Location } from '@angular/common';
import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { DxDataGridComponent } from 'devextreme-angular';
import { ConfirmComponent } from 'src/app/commons/components/confirm/confirm.component';
import { AuctionBetSetupCreateItem, AuctionBetSetupItem, AuctionCreateItem, AuctionItem, AuctionLotItem, AuctionSetupCreateItem, AuctionSetupItem, AuctionSetupPropItem, AuctionSetupUpdateItem, AuctionTipologyCreateItem, AuctionTipologyItem, CodiceDescrizioneItem } from 'src/app/models/dataModels';
import { APIService } from 'src/app/services/APIService';
import { AuctionService } from 'src/app/services/auction.service';
import { AuthService } from 'src/app/services/auth.service';
import { ConstantsModule } from 'src/app/services/constants.module';
import { LocalUtilService } from 'src/app/services/local-util.service';
import { MessagesService } from 'src/app/services/messages.service';
import { Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-setup-properties',
  templateUrl: './setup-properties.component.html',
  styleUrls: ['./setup-properties.component.css']
})
export class SetupPropertiesComponent extends APIService implements OnInit {

  @ViewChild("dataGridProperties", { static: false }) dataGridProperties: DxDataGridComponent;
  @Input() properties: AuctionSetupPropItem[] = [];
  @Output() updated = new EventEmitter<boolean>();

  constructor(public localService: LocalUtilService,
    private modalService: NgbModal,
    private messageService: MessagesService,
    public auctionService: AuctionService,
    location: Location,
    router: Router) {
    super(localService, location, router);
    //this.onSelectionChangedTab = this.onSelectionChangedTab.bind(this);
    //this.resizeTabbedForm = this.resizeTabbedForm.bind(this);
  }

  ngOnInit(): void {

  }

  onRowClickProperties(e) {
    console.log(e);
    e.component.editRow(e.rowIndex);
  }

  onRowUpdateProperties(e) {
    console.log(e);
    this.updated.emit(true);

    /*let rows = this.dataGridSetup.instance.getVisibleRows();
    if (rows) {
      rows.forEach(row => {
        if (row.isEditing) {
          this.dataGridSetup.instance.cellValue(row.rowIndex, 'dataModifica', new Date()); 
        }
      });
    }

    this.dataGridSetup.instance.cellValue(e.rowIndex, "dataModifica", new Date()); */
  }

  onRowPrepared(info) {
    if (info.rowType === 'data') {
      console.log('onRowPrepared() info.rowElement: ' + info.rowElement);
      info.rowElement.addClass('auction');
    }
  }

}