<div id="azienda-impianti" class="content bottom80"> <!-- ngIf="isActiveComponent()"-->           
    <div class="add-item-section">
        <div class="add-plant-panel" [ngClass]="{'hidden': !isNewEditing(), 'show': isNewEditing()}">
            <form id="form_createFramework" [formGroup]="plantForm" class="form-group">
                <section class="step-section focused">
                    <div class="card">
                        <h2 translate>azienda-impianti.dati-impianto</h2>
                        <input type="hidden" id="plant-id" formControlName="id" class="form-control">
                        <div class="row">
                            <div class="input" [ngClass]="{'error': controlHasError('nomeImpianto')}" (mouseleave)="validateField('nomeImpianto')">
                                <div class="input-wrapper full">
                                    <input type="text" id="new-plant-name" maxlength="255" required formControlName="nomeImpianto" class="form-control">
                                    <label for="new-plant-name" translate>azienda-impianti.nome-impianto</label>
                                </div>                                
                            </div>
                        </div>
                        <div class="row">
                            <div class="input" [ngClass]="{'error': controlHasError('nazionalita')}" (mouseleave)="validateField('nazionalita')">
                                <div class="input-wrapper full">
                                    <select name="" id="new-plant-nationality" required formControlName="nazionalita" class="form-control">
                                        <option *ngFor="let nation of nation_list; let i = index" [value]="nation.codice">{{nation.descrizione}}</option>
                                    </select>
                                    <label for="new-plant-nationality" translate>azienda-impianti.nazionalit <span>*</span></label>
                                </div>
                            </div>
                        </div>
                        <div class="d-flex">
                            <div class="input" id="field-provincia" [ngClass]="{'error': controlHasError('provincia')}" (mouseleave)="validateField('provincia')">
                                <div class="input-wrapper full">
                                    <select name="" id="new-plant-province" formControlName="provincia" class="form-control">
                                        <option *ngFor="let provincia of province_list; let i = index" [value]="provincia.descrizione">{{provincia.descrizione}}</option>
                                    </select>
                                    <label for="new-plant-province" translate>azienda-impianti.provincia</label>
                                </div>
                            </div>
                            <div class="input ms-2" [ngClass]="{'error': controlHasError('citta')}" (mouseleave)="validateField('citta')">
                                <div class="input-wrapper full">
                                    <input type="text" name="" id="new-plant-city" maxlength="255" required formControlName="citta" class="form-control">
                                    <label for="new-plant-city" translate>azienda-impianti.citt &nbsp;<span>*</span></label>
                                </div>
                            </div>
                        </div>
                        <div class="d-flex">
                            <div class="input me-2" [ngClass]="{'error': controlHasError('indirizzo')}" (mouseleave)="validateField('indirizzo')">
                                <div class="input-wrapper full">
                                    <input type="text" id="new-plant-address" required formControlName="indirizzo" class="form-control" maxlength="255">
                                    <label for="new-plant-address" translate>azienda-impianti.indirizzo</label>
                                </div>
                            </div>
                            <div class="input cap" [ngClass]="{'error': controlHasError('cap')}" (mouseleave)="validateField('cap')">
                                <div class="input-wrapper full">
                                    <input type="text" id="new-plant-cap" required formControlName="cap" class="form-control" maxlength="5" name="zipcode">
                                    <label for="new-plant-cap" translate>azienda-impianti.cap</label>
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="input" [ngClass]="{'error': controlHasError('pec')}" (mouseleave)="validateField('pec')">
                                <div class="input-wrapper full">
                                    <input type="text" id="new-plant-pec" required formControlName="pec" class="form-control" maxlength="255">
                                    <label for="new-plant-address" translate>azienda-impianti.pec</label>
                                </div>
                            </div>
                            <div class="input" [ngClass]="{'error': controlHasError('capacitaProduttivaAnnua')}" (mouseleave)="validateField('capacitaProduttivaAnnua')">
                                <div class="input-wrapper full">
                                    <input type="number" id="new-plant-production" maxlength="15" required formControlName="capacitaProduttivaAnnua" class="form-control">
                                    <label for="new-plant-production" translate>azienda-impianti.capacit &nbsp;<span translate>azienda-impianti.produttiva-annua-in-tonnellate</span></label>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="infos">
                        <div class="number-wrapper">
                            <div class="number">01</div>
                            <div class="label">
                                <span translate>azienda-impianti.dati-impianto</span>
                            </div>
                        </div>
                    </div>
                </section>
            
                <section class="step-section">
                    <div class="card">
                        <h2 translate>azienda-impianti.documenti-di-certificazione-impianto</h2>
                        <div class="doc-datas">
                            <app-file-box [status]="plant.certificazioneIso9001Valid" docname="certificazione-iso-9001.pdf" (file)="getIso9001File($event)" [showError]="checkError('certificazioneIso9001')" [uuid]="plant.certificazioneIso9001"></app-file-box>
                            <app-file-box [status]="plant.certificazioneIso14001Valid" docname="certificazione-iso-14001.pdf" (file)="getIso14001File($event)" [showError]="checkError('certificazioneIso14001')" [uuid]="plant.certificazioneIso14001"></app-file-box>
                            <app-file-box [status]="plant.docAutorizzazioneValid"  docname="documento-autorizzazione.pdf" (file)="getDocAuthFile($event)" [showError]="checkError('docAutorizzazione')" [uuid]="plant.docAutorizzazione"></app-file-box>
                        </div>
                    </div>
                    <div class="infos">
                        <div class="number-wrapper">
                            <div class="number">02</div>
                            <div class="label">
                                <span translate>azienda-impianti.documenti-di-certificazione-impianto</span>
                            </div>
                        </div>
                    </div>
                </section>

                <section class="step-section">
                    <div class="card">
                        <h2 translate>azienda-impianti.informazioni-utili-per-la-spedizione</h2>
                        <div class="row">
                            <div class="input" [ngClass]="{'error': controlHasError('nomeRifSpedizione')}" (mouseleave)="validateField('nomeRifSpedizione')">
                                <div class="input-wrapper full">
                                    <input type="text" id="new-plant-delivery-name" maxlength="255" formControlName="nomeRifSpedizione">
                                    <label for="new-plant-delivery-name" translate>azienda-impianti.nome-e-riferimento-per-la-spedizione</label>
                                </div>
                            </div>
                        </div>
                        <div class="d-flex">
                            <div class="input me-2" [ngClass]="{'error': controlHasError('telefonoRifSpedizione')}" (mouseleave)="validateField('telefonoRifSpedizione')">
                                <div class="input-wrapper full">
                                    <input type="text" id="new-plant-delivery-phone" maxlength="75" formControlName="telefonoRifSpedizione">
                                    <label for="new-plant-delivery-phone" translate>azienda-impianti.telefono-di-riferimento</label>
                                </div>
                            </div>
                            <div class="input" [ngClass]="{'error': controlHasError('mobileRifSpedizione')}" (mouseleave)="validateField('mobileRifSpedizione')">
                                <div class="input-wrapper full">
                                    <input type="text" id="new-plant-delivery-mobile-phone" maxlength="75" formControlName="mobileRifSpedizione">
                                    <label for="new-plant-delivery-mobile-phone" translate>azienda-impianti.telefono-mobile-di-riferimento</label>
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="input" [ngClass]="{'error': controlHasError('emailRifSpedizione')}" (mouseleave)="validateField('emailRifSpedizione')">
                                <div class="input-wrapper full">
                                    <input type="email" id="new-plant-delivery-email" maxlength="255" formControlName="emailRifSpedizione">
                                    <label for="new-plant-delivery-email" translate>azienda-impianti.email-di-riferimento</label>
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="input" [ngClass]="{'error': controlHasError('noteTrasportatore')}" (mouseleave)="validateField('noteTrasportatore')">
                                <div class="input-wrapper full">
                                    <textarea name="" id="new-plant-delivery-notes" cols="30" rows="10" maxlength="2000" formControlName="noteTrasportatore"></textarea>
                                    <label for="new-plant-delivery-notes" translate>azienda-impianti.note-per-il-trasportatore</label>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="infos">
                        <div class="number-wrapper">
                            <div class="number">03</div>
                            <div class="label">
                                <span translate>azienda-impianti.informazioni-utili-per-la-spedizione</span>
                            </div>
                        </div>
                    </div>
                </section>
            </form>
        </div>
        <div class="add-operator-actions" [ngClass]="{'hidden': !isNewEditing(), 'show': isNewEditing()}">
                <div class="pb-5 text-end">
                    <button class="btn " id="btn_cancelCreate" (click)="annullaImpianto()"><span translate>azienda-impianti.annulla</span></button>
                    <button class="btn btn-primary primary" type="submit" (click)="salvaImpianto()" id="btn_createPlant"><i class="fal fa-save"></i> <span translate>salva</span></button>
                </div>
        </div>
        <button class="add add-operator new-operator-toggler" (click)="toggleNuovoImpianto()">
            <article>
                <div class="icon"><i class="fal fa-plus-circle"></i></div>
                <div class="text"><span translate>azienda-impianti.aggiungi-impianto-di-riciclo</span></div>
            </article>
        </button>
        
    </div>
    <h2 translate>azienda-impianti.impianti-di-riciclo</h2>
    <div id="plants-list" class="plants-list">
        <dx-data-grid 
            id="gridContainer"
            [dataSource]="plants_list"
            keyExpr="id"
            [allowColumnReordering]="false"
            [allowColumnResizing]="false"
            [showBorders]="true"
            [columnHidingEnabled]="false"
            (onRowPrepared)="onRowPrepared($event)"
            (onContentReady)="onContentReady($event)"
            >

            <dxo-load-panel [enabled]="true"></dxo-load-panel>
            <dxo-filter-row [visible]="false"></dxo-filter-row>
            
            <dxo-search-panel
                [visible]="false"
                [highlightCaseSensitive]="false">
            </dxo-search-panel>

            <!-- edit form and columns -->
            <dxi-column dataField="id" [allowEditing]="false" [width]="100" caption="Id" alignment="left" [visible]="false">
            </dxi-column>
            <dxi-column dataField="userId" caption="UserId" dataType="string" showEditorAlways="true" [visible]="false">
            </dxi-column>
            <dxi-column dataField="dataCreazione" caption="Data creazione" [width]="150" dataType="date" format="dd/MM/yyyy" showEditorAlways="true" [visible]="false">
            </dxi-column>
            <dxi-column dataField="dataModifica" caption="Data modifica" dataType="date" format="dd/MM/yyyy" showEditorAlways="true" [visible]="false">
            </dxi-column>
            <dxi-column dataField="nomeImpianto" caption="Nome impianto" dataType="string" [visible]="true" cellTemplate="lotRowTemplate">
            </dxi-column>
            <dxi-column dataField="cognome" caption="Cognome" dataType="string" [visible]="false">                    
            </dxi-column>
            <dxi-column dataField="email" caption="Email" dataType="string" [visible]="false">                    
            </dxi-column>
            <dxi-column dataField="lingua" caption="Lingua" dataType="string" [visible]="false">                    
            </dxi-column>
            <dxi-column dataField="telefono" caption="Telefono" dataType="string" [visible]="false">                    
            </dxi-column>
            <dxi-column dataField="mobile" caption="Mobile" dataType="string" [visible]="false">
            </dxi-column>
            <dxi-column dataField="role" caption="Ruolo" dataType="string" [visible]="false">
            </dxi-column>
            <dxi-column dataField="stato" caption="Stato" dataType="string" [visible]="false">
            </dxi-column>
            <dxi-column dataField="dataRegistrazione" caption="Data registrazione" dataType="string" [visible]="false">
            </dxi-column>

            <div *dxTemplate="let plantDetail of 'lotRowTemplate'">
                <div class="plant" id="{{plantDetail.data.id}}">
                    <article>
                        <ul class="plant-header card-header">
                            <li class="name">{{plantDetail.data.nomeImpianto}}</li>
                            <li class="creation-date">Registrato il {{plantDetail.data.createDate}}</li>
                            <li class="status {{plantDetail.data.statusClass}}">{{plantDetail.data.status}}</li>
                            <li class="actions" [ngClass]="{'menu-open': isMenuOpened(plantDetail.data)}">
                                <div class="actions-icons">
                                    <div class="card-menu-toggler" [ngClass]="{'open': isMenuOpened(plantDetail.data)}" (click)="toggleMenu(plantDetail.data)">
                                    </div>
                                    <div class="card-body-toggler" [ngClass]="{'open': isFormOpened(plantDetail.data)}" (click)="toggleDettaglio(plantDetail.data)">
                                    </div>
                                </div>
                                <div class="plant-menu">
                                    <ul>
                                        <li class="modify-plant" name="modify-plant" [ngClass]="{'hidden': isEditing(plantDetail.data)}" (click)="modificaImpianto(plantDetail.data)">
                                            <div class="icon"><i class="fal fa-edit"></i></div>
                                            <div class="text"><span translate>azienda-impianti.modifica</span></div>
                                        </li>
                                        <li class="delete-plant" [ngClass]="{'hidden': isEditing(plantDetail.data)}" (click)="eliminaImpianto(plantDetail.data)">
                                            <div class="icon"><i class="fal fa-times"></i></div>
                                            <div class="text" ><span translate>azienda-impianti.elimina</span></div>
                                        </li>
                                    </ul>
                                </div>
                            </li>
                        </ul>
                        <app-dettaglio-azienda-impianto [plantDetail]="plantDetail.data" [showDetailForm]="isFormOpened(plantDetail.data)" [vatnumber]="vatnumber"></app-dettaglio-azienda-impianto>
                    </article>
                </div>
            </div>
            
        </dx-data-grid>
    </div>
</div>