import { Injectable, Injector, NgModule } from "@angular/core";
import {
  HttpEvent,
  HttpRequest,
  HttpHandler,
  HttpInterceptor,
  HttpResponse
} from "@angular/common/http";
import { Observable } from "rxjs";
import { finalize, delay, tap } from "rxjs/operators";
import { map, catchError } from 'rxjs/operators';
import { LocalUtilService } from "../services/local-util.service";
import { ActivatedRoute, Router } from "@angular/router";
import { ConstantsModule } from "../services/constants.module";


@Injectable()
export class LoaderInterceptor implements HttpInterceptor {
  constructor(private injector: Injector,
    private localUtil: LocalUtilService,
    private router: Router) { }

  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {

    if (!this.localUtil.isLoading() && !this.localUtil.isDisableLoading()) {
      if (this.letShowLoader(this.router.url)) {
        this.localUtil.showLoader();
      }
    }
    let output = next.handle(req).pipe(tap(event => {
      if (event instanceof HttpResponse) {
        this.localUtil.hideLoader();
      }
    }));

    return output;
    
  }

  letShowLoader(url: string) {
    let output = true;
    if (url != undefined) {
      if (url.indexOf(ConstantsModule.USER_ASTE_IN_CORSO) > -1 || url.indexOf(ConstantsModule.USER_ASTE_IN_CORSO_BOX) > -1) {
        output = false;
      }
    }

    return output;
  }

}