import { Location } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { FileWithContentUpload, NazioneItem, OrganizationPlantItem, ProvinciaItem } from 'src/app/models/dataModels';
import { APIService } from 'src/app/services/APIService';
import { AuctionService } from 'src/app/services/auction.service';
import { CommonService } from 'src/app/services/common.service';
import { ConstantsModule } from 'src/app/services/constants.module';
import { LocalUtilService } from 'src/app/services/local-util.service';
import { MessagesService } from 'src/app/services/messages.service';
import { OrganizationService } from 'src/app/services/organization.service';
import { PopupComponent } from '../popup/popup.component';

@Component({
  selector: 'app-dettaglio-azienda-impianto',
  templateUrl: './dettaglio-azienda-impianto.component.html',
  styleUrls: ['./dettaglio-azienda-impianto.component.css']
})
export class DettaglioAziendaImpiantoComponent extends APIService implements OnInit {

  constructor(localUtil: LocalUtilService,
    public auctionService: AuctionService,
    private modalService: NgbModal,
    private messageService: MessagesService,
    public organizationService: OrganizationService,
    location: Location, 
    router: Router,
    private route: ActivatedRoute,
    public commonService: CommonService) {
    super(localUtil, location, router);
    console.log('DettaglioAziendaImpiantoComponent()');
    
  }

  nation_list: NazioneItem[] = [];
  province_list: ProvinciaItem[] = [];

  @Input() plantDetail: OrganizationPlantItem;
  @Input() showDetailForm: boolean = false;
  @Input() vatnumber: string;
  @Input() showCoripetButtons: boolean = true;
  
  
  plantForm: FormGroup;
  submitted: boolean = false;
  iso9001: FileWithContentUpload;
  iso14001: FileWithContentUpload;
  docAuth: FileWithContentUpload;
  flag9001: boolean;
  flag14001: boolean;
  flagDocAuth: boolean;

  ngOnInit(): void {
    
    //let vatnumber = this.route.snapshot.paramMap.get('vatnumber');
    let idOrg = this.route.snapshot.paramMap.get('vatnumber');
    console.log('DettaglioAziendaImpiantoComponent() idOrg: ' + idOrg);
    this.getOrgnameByIdOrg(idOrg);
    if (this.plantDetail != undefined) {
      this.initializeForm();
    }

    this.loadNazioni();
    this.loadProvince();
  }

  getOrgnameByIdOrg(idOrg: string) {
    this.organizationService.getOrganizationDetail(idOrg).subscribe(
      data => {
        console.log(data);
        this.vatnumber = data.detail.orgname;
        console.log('DettaglioAziendaImpiantoComponent() vatnumber: ' + this.vatnumber);
      }, this.handleError(true)
    );
  }

  initializeForm() {
    //console.log('initializeForm()');
    this.plantForm = new FormGroup({
      id: new FormControl(this.plantDetail.id, []),
      createDate: new FormControl(this.plantDetail.createDate, []),
      nomeImpianto: new FormControl(this.plantDetail.nomeImpianto, [Validators.required]),
      nazionalita: new FormControl(this.plantDetail.nazionalita, [Validators.required]),
      provincia: new FormControl(this.plantDetail.provincia, []),
      citta: new FormControl(this.plantDetail.citta, [Validators.required]),
      cap: new FormControl(this.plantDetail.cap, [Validators.required]),
      indirizzo: new FormControl(this.plantDetail.indirizzo, [Validators.required]),
      capacitaProduttivaAnnua: new FormControl(this.plantDetail.capacitaProduttivaAnnua, [Validators.required]),
      pec: new FormControl(this.plantDetail.pec, [Validators.required, Validators.email]),
      certificazioneImpiantoValid: new FormControl(this.plantDetail.certificazioneImpiantoValid, []),
      certificazioneIso9001: new FormControl(this.plantDetail.certificazioneIso9001, [Validators.required]),
      certificazioneIso9001Valid: new FormControl(this.plantDetail.certificazioneIso9001Valid, []),
      certificazioneIso14001: new FormControl(this.plantDetail.certificazioneIso14001, [Validators.required]),
      certificazioneIso14001Valid: new FormControl(this.plantDetail.certificazioneIso14001Valid, []),
      docAutorizzazione: new FormControl(this.plantDetail.docAutorizzazione, [Validators.required]),
      docAutorizzazioneValid: new FormControl(this.plantDetail.docAutorizzazioneValid, []),
      nomeRifSpedizione: new FormControl(this.plantDetail.nomeRifSpedizione, []),
      telefonoRifSpedizione: new FormControl(this.plantDetail.telefonoRifSpedizione, []),
      mobileRifSpedizione: new FormControl(this.plantDetail.mobileRifSpedizione, []),
      noteTrasportatore: new FormControl(this.plantDetail.noteTrasportatore, []),
      emailRifSpedizione: new FormControl(this.plantDetail.emailRifSpedizione, []),
    });
  }

  checkError(prop: string) {
    let output: boolean = !this.plantForm.get(prop).valid && this.submitted;
    //console.log('prop: ' + prop  + ' valid: ' + this.plantForm.get(prop).valid + ' output: ' + output);
    return output;
  }

  getIso9001File(file) {
    if (file != undefined) {
      console.log('getIso9001File() loaded file: ' + file.name);
    }else {
      console.log('getIso9001File() unloaded file!');
    }
    this.iso9001 = new FileWithContentUpload();
    this.iso9001.file = file;
    this.plantForm.get('certificazioneIso9001').reset({ value: '' });
  }

  getIso14001File(file) {
    if (file != undefined) {
      console.log('getIso14001File() loaded file: ' + file.name);
    }else {
      console.log('getIso14001File() unloaded file!');
    }
    this.iso14001 = new FileWithContentUpload();
    this.iso14001.file = file;
    this.plantForm.get('certificazioneIso14001').reset({ value: '' });
  }

  getDocAuthFile(file) {
    if (file != undefined) {
      console.log('getDocAuthFile() loaded file: ' + file.name);
    }else {
      console.log('getDocAuthFile() unloaded file!');
    }
    this.docAuth = new FileWithContentUpload();
    this.docAuth.file = file;
    this.plantForm.get('docAutorizzazione').reset({ value: '' });
  }

  salvaImpianto() {
    
    if (this.validate()) {
      console.log('salvaImpianto() form valido');
      this.updateImpianto();
    } else {
      this.localUtil.showMessage("", ConstantsModule.ERROR_TITLE, this.messageService.getLabelFromCode("ERROR_FORM_VALIDATION_MESSAGE"), true);
    }
  }

  controlHasError(nome) {
    if (nome != 'provincia') {
      return !this.plantForm.get(nome).valid && this.submitted;
    } else {
      if (this.plantForm.get('nazionalita').value == 'IT' && this.plantForm.get(nome).value != '' && this.submitted) {
        //console.log('controlHasError() provincia if1 provincia: '+ this.plantForm.get(nome).value);
        return false;
      } else if (this.plantForm.get('nazionalita').value == 'IT' && this.plantForm.get(nome).value == '' && this.submitted) {
        //console.log('controlHasError() provincia if2 provincia: '+ this.plantForm.get(nome).value);
        return true;
      } else if (this.plantForm.get('nazionalita').value != 'IT' && this.submitted) {
        //console.log('controlHasError() provincia if3');
        return false;
      } else {
        //console.log('controlHasError() provincia else');
        return false;
      }
    }
  }

  validateField(nome) {
    return this.plantForm.get(nome).valid;
  }

  validate() {
    this.submitted = true;
    let output = true;
    console.log(this.plantForm);
    Object.keys(this.plantForm.controls).forEach(key => {
      console.log(key + ' ' + this.plantForm.get(key).value);
      if (!this.plantForm.get(key).valid) {
        output = false;
        console.log(key + ' ' + this.plantForm.get(key).value + ' not valid');
      } else {
        this.plantDetail[key] = this.plantForm.get(key).value;
      }
    });
    /*if (output) {
      this.prepareBean(this.plant, this.plantForm);
    }*/
    return output;
  }

  updateImpianto() {
    console.log('updateImpianto()');
    this.flag9001 = true;
    this.flag14001 = true;
    this.flagDocAuth = true;    
    //update
    if (this.iso9001 != undefined && this.iso9001.file != undefined) {
      this.flag9001 = false;
      this.iso9001.contentType = 'FWK';
      this.iso9001.vatnumber = this.vatnumber;
      this.iso9001.frameworkId = this.plantDetail.id.toString();
      
      this.commonService.documentUpload(this.iso9001).subscribe(
        data => {
          data = JSON.parse(data);
          console.log('iso9001 uploaded! file_uuid: ' + data.file_uuid);
          console.log(data);
          this.plantDetail.certificazioneIso9001 = data.file_uuid;
          this.flag9001 = true;
          this.salvaBeanImpianto();
        }, this.handleError(true));
    }

    if (this.iso14001 != undefined && this.iso14001.file != undefined) {
      this.flag14001 = false;
      this.iso14001.contentType = 'FWK';
      this.iso14001.vatnumber = this.vatnumber;
      this.iso14001.frameworkId = this.plantDetail.id.toString();
      
      this.commonService.documentUpload(this.iso14001).subscribe(
        data => {
          data = JSON.parse(data);
          console.log('iso14001 uploaded! file_uuid: ' + data.file_uuid);
          console.log(data);
          this.plantDetail.certificazioneIso14001 = data.file_uuid;
          this.flag14001 = true;
          this.salvaBeanImpianto();
        }, this.handleError(true));
    }

    if (this.docAuth != undefined && this.docAuth.file != undefined) {
      this.flagDocAuth = false;
      this.docAuth.contentType = 'FWK';
      this.docAuth.vatnumber = this.vatnumber;
      this.docAuth.frameworkId = this.plantDetail.id.toString();
      
      this.commonService.documentUpload(this.docAuth).subscribe(
        data => {
          data = JSON.parse(data);
          console.log('docAuth uploaded! file_uuid: ' + data.file_uuid);
          console.log(data);
          this.plantDetail.docAutorizzazione = data.file_uuid;
          this.flagDocAuth = true;
          this.salvaBeanImpianto();
        }, this.handleError(true));
    }

    this.salvaBeanImpianto();
  }

  salvaBeanImpianto() {
    
   if (this.flag9001 && this.flag14001 && this.flagDocAuth) {
        console.log('salvaBeanImpianto() insert this.plant: ');
        console.log(this.plantDetail);
        this.organizationService.updateOrganizationPlant(this.vatnumber, this.plantDetail).subscribe(
          (data) => {

            const modalRef = this.modalService.open(PopupComponent);
            modalRef.componentInstance.titolo = this.messageService.getLabelFromCode("INFO_TITLE");
            modalRef.componentInstance.messaggio = this.messageService.getLabelFromCode("SUCCESS_FRAMEWORK_UPDATE");
            modalRef.componentInstance.tipo = 'message';
            modalRef.componentInstance.passEntry.subscribe((receivedEntry:any) => {
              this.reloadComponent();
            });

        },
        this.handleError(true));
    }

  }

  showDetail() {
    return this.showDetailForm;
  }

  loadNazioni() {
    this.commonService.getNationsList().subscribe(
        data => {
          let nazioneVuota = new NazioneItem();
          nazioneVuota.codice="";
          nazioneVuota.descrizione=" - ";          
          this.nation_list.push(nazioneVuota);
          this.nation_list.push.apply(this.nation_list, data.list);
      });
  }

  loadProvince() {
    this.province_list = [];
    this.commonService.getProvinceItalianeList().subscribe(
        data => {
          let provinciaVuota = new ProvinciaItem();
          provinciaVuota.codice="";
          provinciaVuota.descrizione=" - ";          
          this.province_list.push(provinciaVuota);
          this.province_list.push.apply(this.province_list, data.list);
      });
  }

  approveFramework() {
    this.organizationService.validateOrganizationPlant(this.vatnumber, this.plantDetail.id).subscribe(
      (data) => {

        const modalRef = this.modalService.open(PopupComponent);
        modalRef.componentInstance.titolo = this.messageService.getLabelFromCode("INFO_TITLE");
        modalRef.componentInstance.messaggio = this.messageService.getMessageFromCode("OK_OPERATION");
        modalRef.componentInstance.tipo = 'message';
        modalRef.componentInstance.passEntry.subscribe((receivedEntry:any) => {
          this.reloadComponent();
        });

    },
    this.handleError(true));
  }

  rejectFramework() {
    this.organizationService.rejectOrganizationPlant(this.vatnumber, this.plantDetail.id).subscribe(
      (data) => {

        const modalRef = this.modalService.open(PopupComponent);
        modalRef.componentInstance.titolo = this.messageService.getLabelFromCode("INFO_TITLE");
        modalRef.componentInstance.messaggio = this.messageService.getMessageFromCode("OK_OPERATION");
        modalRef.componentInstance.tipo = 'message';
        modalRef.componentInstance.passEntry.subscribe((receivedEntry:any) => {
          this.reloadComponent();
        });

    },
    this.handleError(true));
  }

}
