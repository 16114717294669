import { Location } from '@angular/common';
import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { DxDataGridComponent } from 'devextreme-angular';
import { ConfirmComponent } from 'src/app/commons/components/confirm/confirm.component';
import { PopupComponent } from 'src/app/commons/components/popup/popup.component';
import { AuctionCreateItem, AuctionItem, AuctionLotItem, AuctionTipologyItem, OrganizationOperatorItem } from 'src/app/models/dataModels';
import { APIService } from 'src/app/services/APIService';
import { AuctionService } from 'src/app/services/auction.service';
import { AuthService } from 'src/app/services/auth.service';
import { ConstantsModule } from 'src/app/services/constants.module';
import { LocalUtilService } from 'src/app/services/local-util.service';
import { MessagesService } from 'src/app/services/messages.service';

@Component({
  selector: 'app-coripet-aste',
  templateUrl: './aste.component.html',
  styleUrls: ['./aste.component.css']
})
export class CoripetAsteComponent extends APIService implements OnInit {

  constructor(public localService: LocalUtilService,
    public authService: AuthService,
    public auctionService: AuctionService,
    private modalService: NgbModal,
    private messageService: MessagesService,
    location: Location,
    router: Router,
    private route: ActivatedRoute) {
    super(localService, location, router);

  }

  auctions_list: AuctionItem[] = [];
  codiceAsta: string;
  dettaglioLoaded: boolean = false;

  ngOnInit(): void {
    console.log('init()');
    this.listAuctions();
    this.listAuctionTipology();
    this.codiceAsta = this.route.snapshot.paramMap.get('codiceAsta');
  }

  reloadAuction(auctionCode: string) {
    this.auctions_list.forEach(auction => {
      if (auction.codiceAsta == auctionCode) {
        auction.cssClass = auction.statoAsta.replace(' ', '');
      }
    });
    this.reloadToPublishLots(auctionCode);
  }

  disponibile: boolean = true;
  pubblica: boolean = true;
  incorso: boolean = true;
  conclusa: boolean = true;
  annullate: boolean = true;

  clickCheckBox() {
    this.listAuctions();
  }

  listAuctions() {
    this.auctions_list = [];
    this.auctionService.listaAste(undefined).subscribe(
      (data) => {
        let list = data.list;
        list.forEach(data => {
          if (
            (this.pubblica && data.statoAsta === 'Pubblica') ||
            (this.incorso && data.statoAsta === 'In Corso') ||
            (this.conclusa && data.statoAsta === 'Chiusa')||
            (this.disponibile && data.statoAsta === 'Disponibile')
          ) {
            this.auctions_list.push(data);
          }
        });
        /*if (!this.pubblica && !this.incorso && !this.conclusa) {
          this.auctions_list = [];
        }*/
        this.auctions_list.forEach(auction => {
          auction.cssClass = auction.statoAsta.replace(' ', '');
        });
        this.loadToPublishLots();
      });

  }

  onRowPreparedAuction(info) {
    /*if(info.rowType === 'data') {  
      info.rowElement.addClass('auction');  
    }*/
  }

  onRowClick(e) {
    console.log(e);
    this.router.navigate(['operatore-aste-dettaglio.html/', e.data.codiceAsta]);
    if (this.dataGridAste.instance.isRowExpanded(e.key)) {
      this.dataGridAste.instance.collapseRow(e.key);
    } else {
      this.dataGridAste.instance.cancelEditData();
      this.dataGridAste.instance.collapseAll(-1);
      this.dataGridAste.instance.expandRow(e.key);
    }
  }

  //new
  @ViewChild("dataGridAste", { static: false }) dataGridAste: DxDataGridComponent;
  tipology_list: AuctionTipologyItem[] = [];

  listAuctionTipology() {

    this.auctionService.listAuctionTipology().subscribe(
      (data) => {
        //console.log('setting auction tipology list: ' + data.list.length);
        this.tipology_list = data.list;
      });

  }

  loadToPublishLots() {
    if (this.auctions_list) {
      console.log('loadToPublishLots()');
      this.auctions_list.forEach(auction => {
        auction.cssClass = auction.statoAsta.replace(' ', '');
        this.auctionService.getAuctionLots(auction.codiceAsta, '').subscribe(
          data => {
            var somma = 0;
            if (data.lotList != undefined) {
              data.lotList.forEach(itemLot => {
                if (itemLot.statoLotto == ConstantsModule.AUCTION_LOT_STATUS_INPUBLISHING) {
                  somma++;
                }
              });
            }
            auction.lots = data.lotList;
            auction.sum_lots_to_publish = somma;
            auction.daValidare = somma + ' lotti';
            // set fields
            this.setLotsFields(auction);
          }
        );
      });
    }
  }

  apriDetailAuction() {
    if (!this.dettaglioLoaded) {
      this.dataGridAste.instance.expandRow(this.codiceAsta);
      this.dettaglioLoaded = true;
    }
  }

  onContentReady(e) {
    if (this.codiceAsta != undefined) {
      this.apriDetailAuction();
    }
  }

  onCancelEdit(e) {
    this.codiceAsta = undefined;
  }

  reloadToPublishLots(auctionCode: string) {
    if (this.auctions_list) {
      this.auctions_list.forEach(auction => {
        if (auction.codiceAsta == auctionCode) {
          console.log('reloadToPublishLots() codiceAsta: ' + auctionCode);
          auction.cssClass = auction.statoAsta.replace(' ', '');
          this.auctionService.getAuctionLots(auction.codiceAsta, '').subscribe(
            data => {
              var somma = 0;
              if (data.lotList != undefined) {
                data.lotList.forEach(itemLot => {
                  if (itemLot.statoLotto == ConstantsModule.AUCTION_LOT_STATUS_INPUBLISHING) {
                    somma++;
                  }
                });
              }
              auction.lots = data.lotList;
              auction.sum_lots_to_publish = somma;
              auction.daValidare = somma + ' lotti';
              // set fields
              this.setLotsFields(auction);
            }
          );
        }
      });
    }
  }

  setLotsFields(auction) {
    let lotList: AuctionLotItem[] = auction.lots;
    //console.log('setLotsFields()');
    if (lotList) {
      lotList.forEach(item => {
        item.prezzoUltimoRilancio = this.localUtil.formatNumber2(item.prezzoUltimoRilancio);

        if (item.pagato == 0) {
          item.paid = "Non pagato";
          item.paidClass = "ko";
        } else if (item.pagato == 1) {
          item.paid = "Pagato";
          item.paidClass = "ok";
        }
        if (item.spedito == 0) {
          item.retired = "Non ritirato";
          item.retiredClass = "ko";
        } else if (item.spedito == 1) {
          item.retired = "Ritirato";
          item.retiredClass = "ok";
        }

        item.paidChecked = item.pagato == 1;
        item.retiredChecked = item.spedito == 1;
        item.notPaidChecked = item.pagato == 0;
        item.notRetiredChecked = item.spedito == 0;

        item.codiceLottoNoPoints = this.localUtil.getCodiceLottoNoPoints(item.codiceLotto);

        if (item.nomeOrganization == undefined) {
          item.nomeOrganization = '-';
        }

      });
      //console.log('data.lotList: ');
      //console.log(lotList);
    }

  }

  raisedEvents(e, auction: AuctionItem) {
    console.log('raiseEvent()');
    console.log(e);
    console.log(auction);
    this.reloadAuction(auction.codiceAsta);
  }

  onRowExpanding(e) {
    this.dataGridAste.instance.cancelEditData();
    //this.dataGridAste.instance.collapseAll(-1);
  }

  onRowInsert(e) {
    let auction: AuctionCreateItem = e.data;
    this.auctionService.createAuction(auction).subscribe(
      data => {
        this.localUtil.showMessage("", ConstantsModule.INFO_TITLE, this.messageService.getLabelFromCode("SUCCESS_CREATE_AUCTION_MESSAGE"), true);
        this.listAuctions();
      }, this.handleError(true)
    );
  }

  requestApproveAllLots(auction: AuctionItem) {

    let params: any[] = [auction.codiceAsta];
    const modalRef = this.modalService.open(ConfirmComponent);
    modalRef.componentInstance.titolo = this.messageService.getLabelFromCode("INFO_TITLE");
    modalRef.componentInstance.messaggio = this.messageService.getLabelFromCode("AUCTION_REQUEST_APPROVE_ALL_LOTS_MESSAGE", params);
    modalRef.componentInstance.passEntry.subscribe((receivedEntry: any) => {
      if (receivedEntry != undefined) {

        this.auctionService.requestApproveLotList(auction.codiceAsta, auction.lots).subscribe(
          res => {
            this.localUtil.showMessage("", ConstantsModule.INFO_TITLE, this.messageService.getMessageFromCode("OK_OPERATION"), true);
            this.reloadAuction(auction.codiceAsta);
            //this.reloadComponent();
          },
          this.handleError(true)
        );
      }
    }, this.handleError(true));

  }

  undoRequestApproveAllLots(auction: AuctionItem) {

    let params: any[] = [auction.codiceAsta];
    const modalRef = this.modalService.open(ConfirmComponent);
    modalRef.componentInstance.titolo = this.messageService.getLabelFromCode("INFO_TITLE");
    modalRef.componentInstance.messaggio = this.messageService.getLabelFromCode("AUCTION_UNDO_REQUEST_APPROVE_ALL_LOTS_MESSAGE", params);
    modalRef.componentInstance.passEntry.subscribe((receivedEntry: any) => {
      if (receivedEntry != undefined) {

        this.auctionService.undoRequestApproveLotList(auction.codiceAsta, auction.lots).subscribe(
          res => {
            this.localUtil.showMessage("", ConstantsModule.INFO_TITLE, this.messageService.getMessageFromCode("OK_OPERATION"), true);
            this.reloadAuction(auction.codiceAsta);
            //this.reloadComponent();
          },
          this.handleError(true)
        );
      }
    }, this.handleError(true));

  }

  loadExcelLotti(auction: AuctionItem) {

    console.log('caricaExcelLotti()');
    let params: any[] = [auction.codiceAsta];
    const modalRef = this.modalService.open(PopupComponent);
    modalRef.componentInstance.titolo = this.messageService.getLabelFromCode("EXCEL_LOTS_TITLE");
    modalRef.componentInstance.messaggio = '';
    modalRef.componentInstance.tipo = 'excelLotti';
    modalRef.componentInstance.codiceAsta = auction.codiceAsta;
    modalRef.componentInstance.passEntry.subscribe((receivedEntry: any) => {
      if (receivedEntry != undefined) {
        this.reloadAuction(auction.codiceAsta);
        modalRef.close();
        this.localUtil.showMessage("", ConstantsModule.INFO_TITLE, this.messageService.getMessageFromCode("OK_OPERATION"), true);
        console.log('carica da popup return');
      }
    }, this.handleError(true));

  }

  onRowPrepared(info) {
    if (info.rowType === 'data') {
      console.log('onRowPrepared() info.rowElement: ' + info.rowElement);
      info.rowElement.addClass('auction');
    }
  }


}
