import { AfterViewInit, Component, ElementRef, OnInit, ViewChild, Renderer2 } from '@angular/core';
import { Location } from '@angular/common';
import { Router, Routes } from '@angular/router';
import { APIService } from 'src/app/services/APIService';
import { LocalUtilService } from 'src/app/services/local-util.service';
import { DashboardService } from 'src/app/services/dashboard.service';
import { ActivityItem, AsteItem, AuctionItem, BoxArchivio, BoxItem, OrganizationOperatorCreateItem, PivotLotItem } from 'src/app/models/dataModels';
import { DxChartComponent, DxPivotGridComponent } from 'devextreme-angular';
import { OrganizationService } from 'src/app/services/organization.service';
import { AuctionService } from 'src/app/services/auction.service';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MessagesService } from 'src/app/services/messages.service';

@Component({
  selector: 'app-admin-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.css']
})
export class AdminDashboardComponent extends APIService implements OnInit, AfterViewInit {

  addUserForm: FormGroup;
  addUser: OrganizationOperatorCreateItem = new OrganizationOperatorCreateItem();
  submitted: boolean = false;

  activities_list: ActivityItem[] = [];
  auctions_list: AuctionItem[] = [];

  constructor(localUtil: LocalUtilService, public organizationService: OrganizationService,
    location: Location, public auctionService: AuctionService, public messageService: MessagesService,
    router: Router,
    public dashboardService: DashboardService,
    private renderer: Renderer2) {
    super(localUtil, location, router);
    this.customizeTooltip = this.customizeTooltip.bind(this);

    this.addUserForm = new FormGroup({
      nome: new FormControl(this.addUser.nome, [Validators.required]),
      cognome: new FormControl(this.addUser.cognome, [Validators.required]),
      email: new FormControl(this.addUser.email, [Validators.required]),
      lingua: new FormControl(this.addUser.lingua, [Validators.required]),
      telefono: new FormControl(this.addUser.telefono, [Validators.required]),
      mobile: new FormControl(this.addUser.mobile, [Validators.required]),
      password: new FormControl(this.addUser.password, [Validators.required]),
      confermaPassword: new FormControl(this.addUser.confermaPassword, [Validators.required])
    })
  }

  //@ViewChild(DxPivotGridComponent, { static: false }) pivotGrid: DxPivotGridComponent;
  @ViewChild("pivotGrid", { static: false }) pivotGrid: DxPivotGridComponent;

  @ViewChild(DxChartComponent, { static: false }) chart: DxChartComponent;

  pivotGridDataSource: any;

  dashBoardList: any[] = [];

  lots_pivot: PivotLotItem[] = [];

  pivot_accordion: any[];

  customizeTooltip(args) {
    return {
      html: `${args.seriesName} | Total<div class='currency'>${args.valueText}</div>`,
    };
  }

  loadLotPivotData() {
    console.log('loadLotPivotData()');
    this.dashboardService.getPivotLotsList().subscribe(
      data => {
        console.log(data);
        this.dashBoardList = data.list;
        data.list.forEach(element => {
          element.data = new Date(element.data);
        });

        this.lots_pivot = data.list;
        this.pivotGridDataSource = {
          fields: [{
            caption: 'Num Rilanci',
            width: 120,
            dataField: 'numeroRilanci',
            area: 'row'
          }, {
            caption: 'Area geografica',
            width: 120,
            dataField: 'areaGeografica',
            area: 'row',
            sortBySummaryField: 'tipoProdotto',
          }, {
            caption: 'Asta',
            dataField: 'codiceAsta',
            width: 150,
            area: 'row',
          }, {
            dataField: 'data',
            dataType: 'date',
            area: 'column',
          }, {
            groupName: 'date',
            groupInterval: 'month',
            visible: false,
          }, {
            caption: 'Tipo prodotto',
            dataField: 'tipoProdotto',
            dataType: 'string',
            area: 'column',
          }, {
            caption: 'Prezzo ultimo rilancio',
            dataField: 'prezzoUltimoRilancio',
            dataType: 'number',
            summaryType: 'sum',
            format: {
              currency: 'EUR',
              precision: 0,
              type: 'currency',
            },
            area: 'data',
          }],
          store: this.lots_pivot,
        };

        this.pivot_accordion = [];
        this.pivot_accordion.push('Pivot');
        this.initializePivotDataSource();

      });
  }
  
  initializePivotDataSource() {
    console.log('initializePivotDataSource()');
    this.pivotGridDataSource = {
      fields: [{
        caption: 'Area geografica',
        width: 120,
        dataField: 'areaGeografica',
        area: 'row',
        sortBySummaryField: 'tipoProdotto',
      }, {
        caption: 'Asta',
        dataField: 'codiceAsta',
        width: 150,
        area: 'row',
      }, {
        dataField: 'data',
        dataType: 'date',
        area: 'column',
      }, {
        groupName: 'date',
        groupInterval: 'month',
        visible: false,
      }, {
        caption: 'Tipo prodotto',
        dataField: 'tipoProdotto',
        dataType: 'string',
        area: 'column',
      }, {
        caption: 'Prezzo ultimo rilancio',
        dataField: 'prezzoUltimoRilancio',
        dataType: 'number',
        summaryType: 'sum',
        format: {
          currency:'EUR',
          precision: 0,
          type: 'currency',
        },
        area: 'data',
      }],
      store: this.lots_pivot,
    };
  }

  ngOnInit(): void {
    this.adminInfo();
    this.operatorInfo();
    this.loadLotPivotData();
    this.listActivities();
    this.listAuctionNotClosed();
  }

  vatNumber: string;
  adminInfo() {
    this.organizationService.getAdminInfo().subscribe(dt => {
      console.log(dt['vatnumber']);
      this.vatNumber = dt['vatnumber'];
    })
  }

  operatorVatNumber: string;
  operatorInfo() {
    this.organizationService.getCoripetInfo().subscribe(dt => {
      console.log(dt['vatnumber']);
      this.operatorVatNumber = dt['vatnumber'];
    })
  }

  bindPivotChart() {
    if (this.pivotGrid && this.pivotGrid.instance) {
        this.pivotGrid.instance.bindChart(this.chart.instance, {
          dataFieldsDisplayMode: 'splitPanes',
          alternateDataFields: true,
        });
    } else {
      console.log('pivotGrid instance is not accessible');
    }
  }

  ngAfterViewInit() {
    setTimeout(() => {
      if (this.pivotGrid && this.pivotGrid.instance) {
        this.pivotGrid.instance.bindChart(this.chart.instance, {
          dataFieldsDisplayMode: 'splitPanes',
          alternateDataFields: true,
        });

        const dataSource = this.pivotGrid.instance.getDataSource();
        dataSource.expandHeaderItem('row', ['NORD']);
        dataSource.expandHeaderItem('column', [ (new Date()).getFullYear()]);
      } else {
        console.log('pivotGrid instance is not accessible');
      }
    }, 3000);
  }

  isNewEditing() {
    return (this.addUser.newEditing == true);
  }

  firstButton: string;
  secondButton: string;
  firstToogleButton() {
    this.firstButton = 'admin';
    this.secondButton = '';
    this.addUser.newEditing = !this.addUser.newEditing;
    if (this.addUser.newEditing) {
      this.cleanValidation();
    }
  }

  secondToogleButton() {
    this.secondButton = 'operator';
    this.firstButton = '';
    this.addUser.newEditing = !this.addUser.newEditing;
    if (this.addUser.newEditing) {
      this.cleanValidation();
    }
  }

  cleanValidation() {
    this.addUserForm.markAllAsTouched();
  }

  annullaNuovoOperatore() {
    this.addUser = new OrganizationOperatorCreateItem();
    this.addUser.newEditing = false;
  }

  controlHasError(nome) {
    return !this.addUserForm.get(nome).valid && this.submitted;
  }

  salva() {
    if (this.firstButton === 'admin') {
      if (this.validate()) {
        let request: OrganizationOperatorCreateItem = new OrganizationOperatorCreateItem();
        request = {
          nome: this.addUserForm.get('nome').value,
          cognome: this.addUserForm.get('cognome').value,
          email: this.addUserForm.get('email').value,
          lingua: this.addUserForm.get('lingua').value,
          telefono: this.addUserForm.get('telefono').value,
          mobile: this.addUserForm.get('mobile').value,
          password: this.addUserForm.get('password').value,
          confermaPassword: this.addUserForm.get('confermaPassword').value,
          role: ''
        };

        this.organizationService.createAdminUser(this.vatNumber, request).subscribe(dt => {
          this.localUtil.showMessage("", this.ConstantsModule.INFO_TITLE, 'Admin creato correttamente', true);
          console.log(dt);
          this.handleError(true);
        }, err => {
          this.handleError(true);
        });
      }
    } else if (this.secondButton === 'operator') {
      this.salvaOperator();
    }
  }

  salvaOperator() {
    if (this.validate()) {
      let request: OrganizationOperatorCreateItem = new OrganizationOperatorCreateItem();
      request = {
        nome: this.addUserForm.get('nome').value,
        cognome: this.addUserForm.get('cognome').value,
        email: this.addUserForm.get('email').value,
        lingua: this.addUserForm.get('lingua').value,
        telefono: this.addUserForm.get('telefono').value,
        mobile: this.addUserForm.get('mobile').value,
        password: this.addUserForm.get('password').value,
        confermaPassword: this.addUserForm.get('confermaPassword').value,
        role: ''
      };

      this.organizationService.createCoripetUser(this.vatNumber, request).subscribe(dt => {
        this.localUtil.showMessage("", this.ConstantsModule.INFO_TITLE, 'Operatore Coripet creato correttamente', true);
        console.log(dt);
        this.handleError(true);
      }, err => {
        this.handleError(true);
      });
    }
  }

  getControlErrorMessage(name) {
    let output = '';
    console.log(this.addUserForm.controls[name].errors);
    let errors = this.addUserForm.controls[name].errors;
    output = errors['message'];
    return output;
  }

  validate() {
    console.log('validate()');
    this.submitted = true;
    let output = true;
    console.log(this.addUserForm);
    Object.keys(this.addUserForm.controls).forEach(key => {
      //console.log(key + ' ' + this.operatorForm.get(key).value + ' valid?: ' + this.operatorForm.get(key).valid);
      if (!this.addUserForm.get(key).valid) {
        output = false;
      }
    });
    console.log('password check this.operator.password: ' + this.addUserForm.get('password').value + ' valid: ' + this.addUserForm.get('password').valid);
    console.log('password check this.operator.confermaPassword: ' + this.addUserForm.get('confermaPassword').value + ' valid: ' + this.addUserForm.get('confermaPassword').valid);
    if (this.addUserForm.get('password').valid && this.addUserForm.get('confermaPassword').valid) {
      if (this.addUserForm.get('password').value != this.addUserForm.get('confermaPassword').value) {
        console.log('password check');
        output = false;
        //ERROR_AZ_OP_CREATE_PASSWORD_NOT_EQUAL
        this.addUserForm.controls['password'].setErrors({ 'incorrect': true, 'message': this.messageService.getLabelFromCode("ERROR_AZ_OP_CREATE_PASSWORD_NOT_EQUAL") });
        this.addUserForm.controls['confermaPassword'].setErrors({ 'incorrect': true, 'message': this.messageService.getLabelFromCode("ERROR_AZ_OP_CREATE_PASSWORD_NOT_EQUAL") });
      } else if (this.addUserForm.get('password').value.trim().length < 6) {
        //ERROR_AZ_OP_CREATE_PASSWORD_LENGTH
        this.addUserForm.controls['password'].setErrors({ 'incorrect': true, 'message': this.messageService.getLabelFromCode("ERROR_AZ_OP_CREATE_PASSWORD_LENGTH") });
        this.addUserForm.controls['confermaPassword'].setErrors({ 'incorrect': true, 'message': this.messageService.getLabelFromCode("ERROR_AZ_OP_CREATE_PASSWORD_LENGTH") });
        output = false;
      }
    } else {
      output = false;
    }
    return output;
  }

  onContentReady(event) {
    console.log('contenReady');
    //$('table.dx-datagrid-table').find('div.dx-data-row').addClass('lot');
    $('.dx-hidden').hide();
    //$('.dx-datagrid-nodata').html('<h3>Nessun risultato trovato</h3>');
  }

  onRowPrepared(info) {
    if (info.rowType === 'data') {
      info.rowElement.addClass('auction');
    }
  }

  onRowClick(e) {
    console.log(e.data.codiceAsta);
    this.router.navigate(['admin-aste-dettaglio.html/', e.data.codiceAsta]);
  }

  listActivities() {

    this.organizationService.getActivities().subscribe(
      (data: any) => {
        var conta = 0;
        data.list.forEach(item => {
          conta++;
          if (item.operation == 'INSERT') {
            item.operazione = "aggiunto";
            item.desinenza = "alla";
          } else if (item.operation == 'DELETE') {
            item.operazione = "rimosso";
            item.desinenza = "dalla";
          } else if (item.operation == 'UPDATE') {
            item.operazione = "aggiornato";
            item.desinenza = "nella";
          }
          
          if (conta < 10) {
            this.activities_list.push(item);
          }
        });

      }, this.handleError(true));
  }

  bozza_List: any[] = [];
  listAuctionNotClosed() {

    this.auctionService.listaAste(undefined).subscribe(
      (data) => {
        //console.log('setting auction list: ' + data.list.length);       
        data.list.forEach((auction: AuctionItem) => {
          //if (auction.statoAsta != 'Chiusa') {
          auction.cssClass = auction.statoAsta.replace(' ', '');
          //this.auctions_list.push(auction);
          if (auction.statoAsta != "Bozza") {
            this.auctions_list.push(auction);
          }
          if (auction.statoAsta === "Bozza") {
            this.bozza_List.push(auction);
          }
          //}
        });
      },
      this.handleError(true));

  }

}
