<div class="p-3">
    <div class="add-item-section add-item-section-user">
        <div class="add-operator-panel" [ngClass]="{'hidden': !isNewEditing(), 'show': isNewEditing()}">
            <h3 translate>aste.aggiungi-asta</h3>
            <form id="form_createOperator" [formGroup]="asteForm" class="form-group">
                <section class="step-section focused">
                    <div class="card">
                        <div class="input" [ngClass]="{'error': controlHasError('dataOraInizioAsta')}">
                            <div class="input-wrapper full">
                                <input required formControlName="dataOraInizioAsta" type="datetime-local"
                                    id="dataOraInizioAsta" maxlength="75">
                                <label for="newOperator--dataOraInizioAsta"
                                    translate>aste.data-e-ora-inizio-asta</label>
                            </div>
                        </div>
                        <div class="input" [ngClass]="{'error': controlHasError('dataOraFineAsta')}">
                            <div class="input-wrapper full">
                                <input required formControlName="dataOraFineAsta" type="datetime-local"
                                    id="dataOraFineAsta" maxlength="75">
                                <label for="newOperator--dataOraFineAsta" translate>aste.data-e-ora-fine-asta</label>
                            </div>
                        </div>
                        <div class="input" [ngClass]="{'error': controlHasError('dataDisponibilita')}">
                            <div class="input-wrapper full">
                                <input required formControlName="dataDisponibilita" type="datetime-local"
                                    id="dataDisponibilita" maxlength="75">
                                <label for="newOperator--dataDisponibilita"
                                    translate>aste.data-e-ora-disponibilità</label>
                            </div>
                        </div>
                        <div class="input" [ngClass]="{'error': controlHasError('dataPubblicazione')}">
                            <div class="input-wrapper full">
                                <input required formControlName="dataPubblicazione" type="datetime-local"
                                    id="dataPubblicazione" maxlength="75">
                                <label for="newOperator--dataPubblicazione"
                                    translate>aste.data-e-ora-pubblicazione</label>
                            </div>
                        </div>
                        <div class="input" [ngClass]="{'error': controlHasError('tipologiaAsta')}">
                            <div class="input-wrapper full">
                                <select name="tipologiaAsta" id="tipologiaAsta" required formControlName="tipologiaAsta"
                                    class="form-control">
                                    <!-- <option *ngFor="let lot of geo_area; let i = index" [value]="lot.area">
                                        {{lot.area}}</option> -->
                                    <option value="TIPO1">Tipologia 1 (default)</option>
                                    <option value="TIPO2">Tipologia 2</option>
                                </select>
                                <label for="newOperator--tipologiaAsta" translate>aste.tipologia <span>*</span></label>
                            </div>
                        </div>
                    </div>
                    <div class="infos">
                        <div class="number-wrapper">
                            <div class="number">01</div>
                            <div class="label">
                                <span translate>aste.dati-asta</span>
                            </div>
                        </div>
                    </div>
                </section>
            </form>
        </div>
        <div class="container add-operator-actions" [ngClass]="{'hidden': !isNewEditing(), 'show': isNewEditing()}">
            <div class="pb-5 text-end">
                <button class="btn" id="btn_cancelCreate" (click)="annullaNuovoOperatore()"><span
                        translate>azienda-operatori.annulla</span></button>
                <button class=" btn" style="border-color: orange;color: orange;" type="submit" id="btn_createOperator"
                    (click)="salvaAuctionDettaglio()"><i class="fal fa-save"></i> <span
                        translate>azienda-operatori.salva</span></button>
            </div>
        </div>
        <button class="add add-operator new-operator-toggler" (click)="toggleNuovaAsta()">
            <article>
                <div class="icon"><i class="fal fa-plus-circle"></i></div>
                <div class="text"><span translate>aste.aggiungi-asta</span></div>
            </article>
        </button>
    </div>
</div>
<div class="px-3">
    <hr>
</div>
<div class="p-4 bottom80">
    <h2 translate>aste.aste</h2>
    <div id="auctions-list" class="auctions-list">
        <dx-data-grid #dataGridAste id="gridContainer" [dataSource]="auctions_list" keyExpr="id"
            [allowColumnReordering]="true" [allowColumnResizing]="true" [showBorders]="true"
            [columnHidingEnabled]="false" (onRowPrepared)="onRowPreparedAuction($event)"
            (onContentReady)="onContentReady($event)" (onRowInserting)="onRowInsert($event)"
            (onRowExpanding)="onRowExpanding($event)" (onRowClick)="onRowClick($event)">

            <dxo-column-chooser [enabled]="false" [width]="300" [allowSearch]="true" [enabled]="true" mode="dragAndDrop"
                title="Seleziona colonne"></dxo-column-chooser>
            <dxo-export [enabled]="true" fileName="auctions_list"></dxo-export>

            <dxo-load-panel [enabled]="true"></dxo-load-panel>
            <dxo-filter-row [visible]="false"></dxo-filter-row>
            <dxo-header-filter [visible]="false"></dxo-header-filter>
            <dxo-scrolling mode="infinite"></dxo-scrolling>

            <dxo-search-panel [visible]="true" [highlightCaseSensitive]="false" placeholder="Cerca...">
            </dxo-search-panel>
            <!-- edit form and columns -->
            <dxi-column dataField="id" [allowEditing]="false" [formItem]="{ visible: false }"
                caption="{{ 'aste.id' | translate}}" alignment="left" [visible]="false">
            </dxi-column>
            <dxi-column dataField="userId" caption="{{ 'aste.userId' | translate}}" [allowEditing]="false"
                [formItem]="{ visible: false }" dataType="string" [visible]="false">
            </dxi-column>
            <dxi-column dataField="dataCreazione" caption="{{ 'aste.data-creazione' | translate}}"
                [allowEditing]="false" [formItem]="{ visible: false }" dataType="date" format="dd/MM/yyyy"
                [visible]="false">
            </dxi-column>
            <dxi-column dataField="dataModifica" caption="{{ 'aste.data-modifica' | translate}}" [allowEditing]="false"
                [formItem]="{ visible: false }" dataType="date" format="dd/MM/yyyy" [visible]="false">
            </dxi-column>
            <dxi-column dataField="codiceAsta" caption="{{ 'aste.codice-asta' | translate}}" [allowEditing]="false"
                [formItem]="{ visible: false }" dataType="string" [visible]="true" cssClass="number">
            </dxi-column>
            <dxi-column dataField="dataDisponibilita" caption="{{ 'aste.data-visibilità-a-coripet' | translate}}"
                dataType="datetime" format="dd/MM/yyyy HH:mm" dateSerializationFormat="dd/MM/yyyy HH:mm"
                [visible]="true">
                <dxi-validation-rule type="required"></dxi-validation-rule>
            </dxi-column>
            <dxi-column dataField="dataPubblicazione" caption="{{ 'aste.data-pubblicazione-asta' | translate}}"
                dataType="datetime" format="dd/MM/yyyy HH:mm" dateSerializationFormat="dd/MM/yyyy HH:mm"
                [visible]="true">
                <dxi-validation-rule type="required"></dxi-validation-rule>
            </dxi-column>
            <dxi-column dataField="dataOraInizioAsta" caption="{{ 'aste.data-inizio-asta' | translate}}"
                dataType="datetime" format="dd/MM/yyyy HH:mm" dateSerializationFormat="dd/MM/yyyy HH:mm"
                [visible]="true">
                <dxi-validation-rule type="required"></dxi-validation-rule>
            </dxi-column>
            <dxi-column dataField="dataOraFineAsta" caption="{{ 'aste.data-fine-asta' | translate}}" dataType="datetime"
                format="dd/MM/yyyy HH:mm" dateSerializationFormat="dd/MM/yyyy HH:mm" [visible]="true">
                <dxi-validation-rule type="required"></dxi-validation-rule>
            </dxi-column>
            <dxi-column dataField="statoAsta" caption="{{ 'aste.stato-asta' | translate}}" [allowEditing]="false"
                [formItem]="{ visible: false }" dataType="string" [visible]="true" cellTemplate="statusTemplate">
            </dxi-column>
            <dxi-column dataField="tipologiaAsta" caption="{{ 'aste.tipologia' | translate}}" dataType="string"
                [visible]="true">
                <dxi-validation-rule type="required"></dxi-validation-rule>
                <dxo-lookup [dataSource]="tipology_list" valueExpr="codice" displayExpr="descrizione">
                </dxo-lookup>
            </dxi-column>
            <dxi-column dataField="daValidare" caption="{{ 'aste.da-validare' | translate}}" [allowEditing]="false"
                [formItem]="{ visible: false }" dataType="string" [visible]="true" cellTemplate="daValidareTemplate">
            </dxi-column>

            <dxo-toolbar>
                <dxi-item location="before">
                    <div *dxTemplate>
                        <div class="mt-2">
                            <div class="input full form-check form-check-inline">
                                <input class="form-check-input" type="checkbox" id="attive" class="filter"
                                    value="attive" [(ngModel)]="attive" (click)="filterCheckBox()">
                                <label class="form-check-label" for="attive" translate>Attive</label>
                            </div>
                            <div class="input full form-check form-check-inline">
                                <input class="form-check-input" type="checkbox" id="concluse" class="filter"
                                    value="concluse" [(ngModel)]="concluse" (click)="filterCheckBox()">
                                <label class="form-check-label" for="concluse" translate>Concluse</label>
                            </div>
                            <div class="input full form-check form-check-inline">
                                <input class="form-check-input" type="checkbox" id="pubbliche" class="filter"
                                    value="pubbliche" [(ngModel)]="pubbliche" (click)="filterCheckBox()">
                                <label class="form-check-label" for="pubbliche" translate>Pubbliche</label>
                            </div>
                            <div class="input full form-check form-check-inline">
                                <input class="form-check-input" type="checkbox" id="bozza" class="filter" value="bozza"
                                    [(ngModel)]="bozza" (click)="filterCheckBox()">
                                <label class="form-check-label" for="bozza" translate>Bozza</label>
                            </div>
                            <div class="input full form-check form-check-inline">
                                <input class="form-check-input" type="checkbox" id="annullate" class="filter"
                                    value="annullate" [(ngModel)]="annullate" (click)="filterCheckBox()">
                                <label class="form-check-label" for="annullate" translate>Annullate</label>
                            </div>
                        </div>
                    </div>
                </dxi-item>
                <dxi-item location="before" name="searchPanel"></dxi-item>
                <dxi-item name="exportButton"></dxi-item>
            </dxo-toolbar>

            <div *dxTemplate="let statusTemplate of 'statusTemplate'">
                <div class="status {{statusTemplate.data.cssClass}}">{{statusTemplate.data.statoAsta}}</div>
            </div>

            <div *dxTemplate="let daValidareTemplate of 'daValidareTemplate'">
                <div class="status-alert-new" data-label="Lotti"
                    *ngIf="daValidareTemplate.data.sum_lots_to_publish > 0">
                    <b>{{daValidareTemplate.data.sum_lots_to_publish}}</b>&nbsp;lotti
                </div>
                <div class="" data-label="Lotti" *ngIf="daValidareTemplate.data.sum_lots_to_publish == 0">
                    -
                </div>
            </div>

            <dxo-master-detail [enabled]="false" template="detailAuction"></dxo-master-detail>

            <div *dxTemplate="let detailAuction of 'detailAuction'">
                <dx-form [(formData)]="detailAuction.data">
                    <dxi-item itemType="group" caption="Lista lotti" [colSpan]="20" cssClass="top20 detail-form">
                        <div *ngFor="let lot of detailAuction.data.lots; let i = index"
                            class="lots-list new-lots-list won bottom50" id='lots-list'
                            [ngClass]="{'coripet-lots-list': isCoripet()}">
                            <app-dettaglio-lotto [lotDetail]="lot"
                                [statoAsta]="detailAuction.data.statoAsta"></app-dettaglio-lotto>
                        </div>
                    </dxi-item>
                </dx-form>

            </div>

            <dxo-editing [allowUpdating]="false" [allowAdding]="false" [allowDeleting]="false" mode="form">

            </dxo-editing>

        </dx-data-grid>
    </div>
</div>