import { Location } from '@angular/common';
import { Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { APIService } from 'src/app/services/APIService';
import { AuthService } from 'src/app/services/auth.service';
import { LocalUtilService } from 'src/app/services/local-util.service';
import { MessagesService } from 'src/app/services/messages.service';


import { AuctionItem } from 'src/app/models/dataModels';
import { ClientService } from 'src/app/services/client.service';
import { DxDataGridComponent } from 'devextreme-angular';
import { dxElement } from 'devextreme/core/element';
import ODataStore from "devextreme/data/odata/store";
import DataSource from "devextreme/data/data_source";
import CustomStore from 'devextreme/data/custom_store';
import { AuctionService } from 'src/app/services/auction.service';
import 'devextreme/integration/jquery';
import { Content } from '@angular/compiler/src/render3/r3_ast';
import { ConstantsModule } from 'src/app/services/constants.module';

@Component({
  selector: 'app-aste-vinte',
  templateUrl: './aste-vinte.component.html',
  styleUrls: ['./aste-vinte.component.css']
})
export class AsteVinteComponent extends APIService implements OnInit {

  @ViewChild(DxDataGridComponent) dataGrid: DxDataGridComponent;

  constructor(public localService: LocalUtilService,
    public auctionService: AuctionService,
    private modalService: NgbModal,
    private messageService: MessagesService,
    location: Location, 
    router: Router) {
    super(localService, location, router);

  }

  auctions_list: any[]=[];  
  allMode: string;
  checkBoxesMode: string;

  ngOnInit(): void {
    console.log('init()');
    this.loadAuctions();
  }

  loadAuctions() {

    this.auctionService.listaAsteVinte("Chiusa").subscribe(
      (data:any) => { 
        console.log('setting auction list: ' + data.list.length);       
        this.auctions_list = data.list;
      });
  }

  onRowPrepared(info) {
    if(info.rowType === 'data') {  
      console.log('onRowPrepared() info.rowElement: ' + info.rowElement);
      info.rowElement.addClass('auction');  
    }
  }

  onContentReady(event) {
    console.log('contenReady');
    //$('table.dx-datagrid-table').find('div.dx-data-row').addClass('lot');
    //$('.dx-hidden').hide();
    //$('.dx-datagrid-nodata').html('<h3>Nessun risultato trovato</h3>');
    this.listReport();
  }

  listReport() {
    console.log('listReport()');
    
    if (this.auctions_list != undefined) {
        this.auctions_list.forEach(item => {
          console.log('listReport() item: ' + item);
          this.auctionService.getAuctionReportWon(item.codiceAsta).subscribe(
            data => {
              item.uuid = data.uuid
            });

        });
    }
  }

  openDettaglio(e) {
    console.log('event.target: ' + e.event.target.className);
    let className = e.event.target.className;
    if(e.rowType === "data" && className.toLowerCase().indexOf('pdf') < 0) {
      this.router.navigate([ConstantsModule.REF_AZ_ASTE_VINTE_DETTAGLIO_PATH + e.data.codiceAsta, {}]);
      //event.component.editRow(e.rowIndex);
    }
  }

}
