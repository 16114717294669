import { Location } from '@angular/common';
import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Route, Router, Routes } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { DxDataGridComponent } from 'devextreme-angular';
import { filter } from 'rxjs';
import { AppComponent } from 'src/app/app.component';
import { ActivityItem, AuctionItem, DocumentData, NazioneItem, OrganizationItem, OrganizationOperatorItem, OrganizationOperatorUpdateItem, OrganizationPlantItem, OrganizationUpdateItem, ProvinciaItem, UserItem, UserOperatorItem } from 'src/app/models/dataModels';
import { APIService } from 'src/app/services/APIService';
import { AuctionService } from 'src/app/services/auction.service';
import { AuthService } from 'src/app/services/auth.service';
import { CommonService } from 'src/app/services/common.service';
import { ConstantsModule } from 'src/app/services/constants.module';
import { LocalUtilService } from 'src/app/services/local-util.service';
import { MessagesService } from 'src/app/services/messages.service';
import { OrganizationService } from 'src/app/services/organization.service';
import { PopupComponent } from '../popup/popup.component';

const getDataCreazione = function (rowData: any): number {
  return (new Date(rowData.dataCreazione)).getDay();
};
@Component({
  selector: 'app-aziende',
  templateUrl: './aziende.component.html',
  styleUrls: ['./aziende.component.css']
})
export class AziendeComponent extends APIService implements OnInit {

  @ViewChild("datagridAziende", { static: false }) datagridAziende: DxDataGridComponent;
  tabPanelOptions: any;

  fondiFilter: any;
  capacitaFilter: any;
  dataFilter: any;
  showFilterRow: boolean;
  showHeaderFilter: boolean;
  applyFilterTypes: any;
  currentFilter: any;
  customOperations: any;

  organizations_list: OrganizationItem[] = [];

  organization: OrganizationItem;
  userOrganization: UserItem;
  nation_list: NazioneItem[] = [];
  province_list: ProvinciaItem[] = [];

  visuraCamerale: DocumentData;
  rappresentanteLegale: DocumentData;
  fidejussioneBancaria: DocumentData;
  dichiarazione231_2001: DocumentData;

  certificazioneIso9001: DocumentData;
  certificazioneIso14001: DocumentData;
  docAutorizzazione: DocumentData;
  activeTab: string;
  detail: OrganizationItem;

  filterActive: boolean = true;
  filterToVerify: boolean = true;
  filterDisabled: boolean = true;

  idAzienda: string;

  full_organizations: OrganizationItem[] = [];

  constructor(public localService: LocalUtilService,
    public authService: AuthService,
    public auctionService: AuctionService,
    public organizationService: OrganizationService,
    public commonService: CommonService,
    private modalService: NgbModal,
    private messageService: MessagesService,
    location: Location,
    router: Router,
    private route: ActivatedRoute) {
    super(localService, location, router);

    this.showFilterRow = true;
    this.showHeaderFilter = true;
    this.applyFilterTypes = [{
      key: 'auto',
      name: 'Immediately',
    }, {
      key: 'onClick',
      name: 'On Button Click',
    }];

    this.fondiFilter = [{
      text: 'Meno di € 50.000',
      value: ['totaleFidejussione', '<', 50000],
    }, {
      text: '€ 50.000 -  € 100.000',
      value: [
        ['totaleFidejussione', '>=', 50000],
        ['totaleFidejussione', '<', 100000],
      ],
    }, {
      text: '€ 100.000 - € 150.000',
      value: [
        ['totaleFidejussione', '>=', 100000],
        ['totaleFidejussione', '<', 150000],
      ],
    }, {
      text: '€ 150.000 - € 200.000',
      value: [
        ['totaleFidejussione', '>=', 150000],
        ['totaleFidejussione', '<', 200000],
      ],
    }, {
      text: 'Più di € 200.000',
      value: ['totaleFidejussione', '>=', 200000],
    }];

    this.capacitaFilter = [{
      text: 'Meno di 50.000 ton',
      value: ['capacitaProduttiva', '<', 50000],
    }, {
      text: '50.000 - 100.000 ton',
      value: [
        ['capacitaProduttiva', '>=', 50000],
        ['capacitaProduttiva', '<', 100000],
      ],
    }, {
      text: '100.000 - 150.000 ton',
      value: [
        ['capacitaProduttiva', '>=', 100000],
        ['capacitaProduttiva', '<', 150000],
      ],
    }, {
      text: '150.000 - 200.000 ton',
      value: [
        ['capacitaProduttiva', '>=', 150000],
        ['capacitaProduttiva', '<', 200000],
      ],
    }, {
      text: 'Più di 200.000 ton',
      value: ['capacitaProduttiva', '>=', 200000],
    }];

    this.dataFilter = [{}];
    this.currentFilter = this.applyFilterTypes[0].key;

    /*this.tabOptionChanged = this.tabOptionChanged.bind(this);
    onOptionChanged: this.tabOptionChanged,
      
    
    this.tabPanelOptions = {
      onTitleClick: this.tabChanged,
      selectedIndex: 0
    };*/

    this.reloadCompaniesList = this.reloadCompaniesList.bind(this);
    this.tabChanged = this.tabChanged.bind(this);
  }

  /*tabOptionChanged(e) {
    if (e.name === "selectedIndex") {
      this.tabOption.selectedIndex = e.value;
    }
  }*/



  loadNazioni() {
    this.commonService.getNationsList().subscribe(
      data => {
        let nazioneVuota = new NazioneItem();
        nazioneVuota.codice = "";
        nazioneVuota.descrizione = " - ";
        this.nation_list.push(nazioneVuota);
        this.nation_list.push.apply(this.nation_list, data.list);
      },
      this.handleError(true)
    );
  }

  loadProvince() {
    this.province_list = [];
    this.commonService.getProvinceItalianeList().subscribe(
      data => {
        let provinciaVuota = new ProvinciaItem();
        provinciaVuota.codice = "";
        provinciaVuota.descrizione = " - ";
        this.province_list.push(provinciaVuota);
        this.province_list.push.apply(this.province_list, data.list);
      },
      this.handleError(true)
    );
  }


  loadOrganizationUser(data: any, vatnumber: string) {
    console.log('loadOrganizationUser() ' + data.userId);
    this.organizationService.getUserDetail(data.userId).subscribe(
      (res: any) => {
        console.log(res);
        let user: UserItem = res.detail;
        data.referente = user.nome + ' ' + user.cognome;
        data.userOrganization = res.detail;
      }, this.handleError(false)
    );
  }

  loadOrganizationPlants(dataRow: any, vatnumber: string) {
    this.organizationService.getOrganizationPlants(vatnumber).subscribe(
      data => {

        //this.plants_list = [];
        dataRow.plants = [];

        if (data.list) {
          data.list.forEach((itemImpianto: OrganizationPlantItem, index) => {
            if (itemImpianto.certificazioneImpiantoValid == "") {
              itemImpianto.statusClass = "pending";
              itemImpianto.status = "In attesa di verifica";
            } else if (itemImpianto.certificazioneImpiantoValid == "N") {
              itemImpianto.statusClass = "disabled";
              itemImpianto.status = "Rifiutato";
            } else if (itemImpianto.certificazioneImpiantoValid == "Y") {
              itemImpianto.statusClass = "active";
              itemImpianto.status = "Attivo";
            }

            var iso9001 = true;
            if (itemImpianto.certificazioneIso9001 == "") {
              iso9001 = false;
              itemImpianto.certificazioneIso9001Status = this.messageService.getLabelFromCode("DESCR_DOC_MANCANTE");
              itemImpianto.certificazioneIso9001CssClass = "ko";
            } else {
              iso9001 = true;
              if (itemImpianto.certificazioneIso9001Valid == "Y") {
                itemImpianto.certificazioneIso9001Status = this.messageService.getLabelFromCode("DESCR_DOC_APPROVATO");
                itemImpianto.certificazioneIso9001CssClass = "ok";
              } else if (itemImpianto.certificazioneIso9001Valid == "") {
                itemImpianto.certificazioneIso9001Status = this.messageService.getLabelFromCode("DESCR_DOC_DA_APPROVARE");
                itemImpianto.certificazioneIso9001CssClass = "pending";
              } else if (itemImpianto.certificazioneIso9001Valid == "N") {
                itemImpianto.certificazioneIso9001Status = this.messageService.getLabelFromCode("DESCR_DOC_RIFIUTATO");
                itemImpianto.certificazioneIso9001CssClass = "ko";
              }
            }

            var iso14001 = true;
            if (itemImpianto.certificazioneIso14001 == "") {
              iso14001 = false;
              itemImpianto.statusDescr14001 = this.messageService.getLabelFromCode("DESCR_DOC_MANCANTE");
              itemImpianto.certificazioneIso14001CssClass = "ko";
            } else {
              iso14001 = true;
              if (itemImpianto.certificazioneIso14001Valid == "Y") {
                itemImpianto.certificazioneIso14001Status = this.messageService.getLabelFromCode("DESCR_DOC_APPROVATO");
                itemImpianto.certificazioneIso14001CssClass = "ok";
              } else if (itemImpianto.certificazioneIso14001Valid == "") {
                itemImpianto.certificazioneIso14001Status = this.messageService.getLabelFromCode("DESCR_DOC_DA_APPROVARE");
                itemImpianto.certificazioneIso14001CssClass = "pending";
              } else if (itemImpianto.certificazioneIso14001Valid == "N") {
                itemImpianto.certificazioneIso14001Status = this.messageService.getLabelFromCode("DESCR_DOC_RIFIUTATO");
                itemImpianto.certificazioneIso14001CssClass = "ko";
              }
            }

            var docAuth = true;
            if (itemImpianto.docAutorizzazione == "") {
              docAuth = false;
              itemImpianto.docAutorizzazioneStatus = this.messageService.getLabelFromCode("DESCR_DOC_MANCANTE");
              itemImpianto.docAutorizzazioneCssClass = "ko";
            } else {
              docAuth = true;
              if (itemImpianto.docAutorizzazioneValid == "Y") {
                itemImpianto.docAutorizzazioneStatus = this.messageService.getLabelFromCode("DESCR_DOC_APPROVATO");
                itemImpianto.docAutorizzazioneCssClass = "ok";
              } else if (itemImpianto.docAutorizzazioneValid == "") {
                itemImpianto.docAutorizzazioneStatus = this.messageService.getLabelFromCode("DESCR_DOC_DA_APPROVARE");
                itemImpianto.docAutorizzazioneCssClass = "pending";
              } else if (itemImpianto.docAutorizzazioneValid == "N") {
                itemImpianto.docAutorizzazioneStatus = this.messageService.getLabelFromCode("DESCR_DOC_RIFIUTATO");
                itemImpianto.docAutorizzazioneCssClass = "ko";
              }
            }

            if (!iso9001 || !iso14001 || !docAuth) {
              itemImpianto.statusDoc = this.messageService.getLabelFromCode("DESCR_DOC_DA_APPROVARE") + " o " + this.messageService.getLabelFromCode("DESCR_DOC_MANCANTI");
            } else {
              itemImpianto.statusDoc = "";
            }

            dataRow.plants.push(itemImpianto);
            //this.plants_list.push(itemImpianto);
          });
          if (vatnumber == this.lastOrgname) {
            this.lastOrgnamePlantLoaded = true;
            console.log('last organization plants loaded');
          }

        }

      }, this.handleError(true));
  }

  loadOrganizationOperators(dataRow: any, vatnumber: string) {
    this.organizationService.getOrganizationOperators(vatnumber).subscribe(
      data => {
        dataRow.operators = [];

        if (data.list) {
          let operators: UserOperatorItem[] = data.list;
          operators.forEach((itemOp, index) => {

            //if (itemOp.deleted != "Y") {
            dataRow.operators.push(itemOp);
            //}
          });
          if (vatnumber == this.lastOrgname) {
            this.lastOrgnameOperLoaded = true;
            console.log('last organization operators loaded');
            this.datagridAziende.instance.repaint();
          }


        }

      }, this.handleError(true));
  }

  onEditingStart(e) {
    this.datagridAziende.instance.cancelEditData();
    this.datagridAziende.instance.collapseAll(-1);
    this.activeTab = ConstantsModule.ORG_TAB;
    console.log('onEditingStart() activeTab: ' + this.activeTab);
    console.log('onEditing start');
    this.detail = e.data;
    this.idAzienda = undefined;
  }

  onRowUpdate(e) {
    console.log('onRowUpdate()');
    if (e && e.newData && e.newData.userOrganization != undefined) {
      //if (this.activeTab && this.activeTab == ConstantsModule.ORG_USER_TAB) {
      console.log('saveUser()');
      this.idAzienda = e.key;
      let userOrg: OrganizationOperatorUpdateItem = new OrganizationOperatorUpdateItem();
      userOrg = $.extend({}, e.oldData.userOrganization, e.newData.userOrganization);
      this.organizationService.updateUser(userOrg).subscribe(
        (res: any) => {
          /*this.localUtil.showMessage("", ConstantsModule.INFO_TITLE, this.messageService.getLabelFromCode("GENERIC_SUCCESS_MESSAGE_SAVE"), true);
          this.dettaglioLoaded = false;
          this.apriEditAzienda();*/
        },
        this.handleError(true)
      );
    }
    //} else if (this.activeTab && this.activeTab == ConstantsModule.ORG_TAB) {
    console.log('saveCompany()');
    this.idAzienda = e.key;
    let orgname = e.oldData.orgname;
    let org: OrganizationUpdateItem = new OrganizationUpdateItem();
    org = $.extend({}, e.oldData, e.newData);

    this.organizationService.updateOrganization(orgname, org).subscribe(
      (res: any) => {
        this.localUtil.showMessage("", ConstantsModule.INFO_TITLE, this.messageService.getLabelFromCode("GENERIC_SUCCESS_MESSAGE_SAVE"), true);
        this.dettaglioLoaded = false;
        this.apriEditAzienda();
      },
      this.handleError(true)
    );

    /*} else {
      this.localUtil.showMessage("", ConstantsModule.INFO_TITLE, this.messageService.getMessageFromCode("E:GENERIC"), true);
    }*/

  }

  plantsCallback(e) {
    console.log(e);
    this.reloadOrganizationPlants();
  }

  reloadOrganizationPlants() {
    if (this.organizations_list != undefined && this.detail != undefined) {
      this.organizations_list.forEach(org => {
        if (org.orgname == this.detail.orgname) {
          this.loadOrganizationPlants(org, org.orgname);
        }
      });
    }
  }

  tabChanged(e) {
    console.log(e.itemData.title);
    this.activeTab = e.itemData.title;
    //console.log('tabChanged() activeTab: ' + this.activeTab);
  }

  getDocumentStatusClass(uuid: string, validated: string) {
    let output = '';
    if (uuid == "") {
      output = "ko";
    } else {
      if (validated == "Y") {
        output = "ok";
      } else if (validated == "N") {
        output = "ko";
      } else if (validated == "") {
        output = "pending";
      }
    }
    return output;
  }

  getDocumentStatus(uuid: string, validated: string) {
    let output = '';
    if (uuid == "") {
      output = this.messageService.getLabelFromCode("DESCR_DOC_MANCANTE");
    } else {
      if (validated == "Y") {
        output = this.messageService.getLabelFromCode("DESCR_DOC_APPROVATO");
      } else if (validated == "N") {
        output = this.messageService.getLabelFromCode("DESCR_DOC_RIFIUTATO");
      } else if (validated == "") {
        output = this.messageService.getLabelFromCode("DESCR_DOC_DA_APPROVARE");
      }
    }
    return output;
  }

  ngOnInit(): void {
    console.log('init()');
    this.loadNazioni();
    this.loadProvince();
    this.listOrganizations();
    this.idAzienda = this.route.snapshot.paramMap.get('id');
  }

  lastOrgname: string;
  lastOrgnameUserLoaded: boolean = false;
  lastOrgnamePlantLoaded: boolean = false;
  lastOrgnameOperLoaded: boolean = false;

  listOrganizations(orgname?: string, isInEditMode?: boolean) {
    console.log('listOrganizations() orgname: ' + orgname + ' isInEditMode: ' + isInEditMode);
    let list_org: OrganizationItem[] = [];
    this.organizationService.getOrganizationList().subscribe(
      data => {

        if (data.list != undefined && data.list.length > 0) {
          this.full_organizations = data.list;
          this.lastOrgname = data.list[data.list.length - 1].orgname;
          console.log('this.lastOrgname: ' + this.lastOrgname);
          console.log('this.lastOrgname ragionesociale: ' + data.list[data.list.length - 1].ragioneSociale);
        }
        data.list.forEach((org: OrganizationItem) => {
          if (orgname == undefined || orgname == org.orgname) {
            try {
              org.tab = 'ORG_TAB';
              let dataSplitted: any[] = org.dataCreazione.split('/');//AAAA/MM/GG
              if (dataSplitted) {
                org.dataCreazione = dataSplitted[2] + '/' + dataSplitted[1] + '/' + dataSplitted[0];
              }

              if (org.disabled == "Y") {
                org.statusClass = "disabled";
                org.status = "Disabilitata";
              } else if (org.validated == "N") {
                org.statusClass = "disabled";
                org.status = "Non abilitata";
              } else if (org.validated == "") {
                org.statusClass = "pending";
                org.status = "In attesa di verifica";
              } else if (org.validated == "Y") {
                org.statusClass = "active";
                org.status = "Abilitata";
              }

              if (org.visuraCamerale == "") {
                org.visuraCameraleStatus = this.messageService.getLabelFromCode("DESCR_DOC_MANCANTE");
                org.visuraCameraleCssClass = "ko";
              } else {
                if (org.visuraCameraleValidated == "Y") {
                  org.visuraCameraleStatus = this.messageService.getLabelFromCode("DESCR_DOC_APPROVATO");
                  org.visuraCameraleCssClass = "ok";
                } else if (org.visuraCameraleValidated == "N") {
                  org.visuraCameraleStatus = this.messageService.getLabelFromCode("DESCR_DOC_RIFIUTATO");
                  org.visuraCameraleCssClass = "ko";
                } else if (org.visuraCameraleValidated == "") {
                  org.visuraCameraleStatus = this.messageService.getLabelFromCode("DESCR_DOC_DA_APPROVARE");
                  org.visuraCameraleCssClass = "pending";
                }
              }


              if (org.rappresentanteLegale == "") {
                org.rappresentanteLegaleStatus = this.messageService.getLabelFromCode("DESCR_DOC_MANCANTE");
                org.rappresentanteLegaleCssClass = "ko";
              } else {
                if (org.rappresentanteLegaleValidated == "Y") {
                  org.rappresentanteLegaleStatus = this.messageService.getLabelFromCode("DESCR_DOC_APPROVATO");
                  org.rappresentanteLegaleCssClass = "ok";
                } else if (org.rappresentanteLegaleValidated == "N") {
                  org.rappresentanteLegaleStatus = this.messageService.getLabelFromCode("DESCR_DOC_RIFIUTATO");
                  org.rappresentanteLegaleCssClass = "ko";
                } else if (org.rappresentanteLegaleValidated == "") {
                  org.rappresentanteLegaleStatus = this.messageService.getLabelFromCode("DESCR_DOC_DA_APPROVARE");
                  org.rappresentanteLegaleCssClass = "pending";
                }
              }

              if (org.fidejussioneBancaria == "") {
                org.fidejussioneBancariaStatus = this.messageService.getLabelFromCode("DESCR_DOC_MANCANTE");
                org.fidejussioneBancariaCssClass = "ko";
              } else {
                if (org.fidejussioneBancariaValidated == "Y") {
                  org.fidejussioneBancariaStatus = this.messageService.getLabelFromCode("DESCR_DOC_APPROVATO");
                  org.fidejussioneBancariaCssClass = "ok";
                } else if (org.fidejussioneBancariaValidated == "N") {
                  org.fidejussioneBancariaStatus = this.messageService.getLabelFromCode("DESCR_DOC_RIFIUTATO");
                  org.fidejussioneBancariaCssClass = "ko";
                } else if (org.fidejussioneBancariaValidated == "") {
                  org.fidejussioneBancariaStatus = this.messageService.getLabelFromCode("DESCR_DOC_DA_APPROVARE");
                  org.fidejussioneBancariaCssClass = "pending";
                }
              }

              if (org.dichiarazione231_2001 == "") {
                org.dichiarazione231_2001Status = this.messageService.getLabelFromCode("DESCR_DOC_MANCANTE");
                org.dichiarazione231_2001CssClass = "ko";
              } else {
                if (org.dichiarazione231_2001Validated == "Y") {
                  org.dichiarazione231_2001Status = this.messageService.getLabelFromCode("DESCR_DOC_APPROVATO");
                  org.dichiarazione231_2001CssClass = "ok";
                } else if (org.dichiarazione231_2001Validated == "N") {
                  org.dichiarazione231_2001Status = this.messageService.getLabelFromCode("DESCR_DOC_RIFIUTATO");
                  org.dichiarazione231_2001CssClass = "ko";
                } else if (org.dichiarazione231_2001Validated == "") {
                  org.dichiarazione231_2001Status = this.messageService.getLabelFromCode("DESCR_DOC_DA_APPROVARE");
                  org.dichiarazione231_2001CssClass = "pending";
                }
              }

              //console.log(org.ragioneSociale + ' -> userId: ' + org.userId);
              this.organizationService.getUserDetail(org.userId).subscribe(
                (res: any) => {
                  let user: UserItem = res.detail;
                  user.tab = 'ORG_USER_TAB';
                  org.userOrganization = user;
                  org.referente = user.nome + ' ' + user.cognome;
                  if (org.orgname == this.lastOrgname) {
                    this.lastOrgnameUserLoaded = true;
                  }
                }, this.handleError(true)
              );

              org.plants = [];
              this.loadOrganizationPlants(org, org.orgname);
              org.operators = [];
              this.loadOrganizationOperators(org, org.orgname);
              this.full_organizations.push(org);
              list_org.push(org);
            }
            catch (err) {
              let params: any[] = [org.userId];
              this.localUtil.showError("", ConstantsModule.ERROR_TITLE, this.messageService.getLabelFromCode("USER_NOT_FOUND", params));
            }

            if (orgname != undefined && isInEditMode != undefined && isInEditMode == true) {
              console.log('listOrganiziations() setting update org in edit mode');
              console.log(org);
              this.detail = org;
            }

            this.organizations_list = list_org;
            //console.log(list_org);
            //console.log('organization loaded');
          }
        });
      },
      this.handleError(true)
    );
  }


  onEditCanceled(e) {
    console.log('onEditCanceled()');
    if (this.idAzienda != undefined) {
      this.dettaglioLoaded = false;
    }
  }

  apriEditAzienda() {
    console.log('apriEditAzienda() this.dettaglioLoaded: ' + this.dettaglioLoaded);
    if (!this.dettaglioLoaded) {
      console.log('apro dettaglio idAzienda: ' + this.idAzienda);
      let rowIndex = this.datagridAziende.instance.getRowIndexByKey(parseInt(this.idAzienda));
      console.log('apro dettaglio rowIndex: ' + rowIndex);
      if (rowIndex > 1) {
        this.datagridAziende.instance.editRow(rowIndex);
        this.dettaglioLoaded = true;
      }
    }
  }

  onRowExpanding(e) {
    this.idAzienda = undefined;
    this.datagridAziende.instance.collapseAll(-1);
    this.datagridAziende.instance.cancelEditData();
  }

  formatNumber(num: number) {
    return this.localUtil.formatNumber2(num);
  }

  onCellPrepared(e) {
  }

  customizeItemTemplate(item: any) {
    item.template = "formItem";
  }

  dettaglioLoaded: boolean = false;

  onContentReady(event) {
    //console.log('onContentReady this.idAzienda: ' + this.idAzienda);
    if (this.idAzienda != undefined && this.lastOrgnameUserLoaded && this.lastOrgnamePlantLoaded && this.lastOrgnameOperLoaded) {
      this.apriEditAzienda();
    }
  }

  onContentReadyPlants(event) {
  }

  getNazionalitaDescr(val) {
    let output = '';
    this.nation_list.forEach(item => {
      if (item.codice == val || item.descrizione == val) {
        output = item.descrizione;
      }
    });
    return output;
  }

  onRowPreparedOrganization(info) {
    if (info.rowType === 'data') {
      //console.log('onRowPrepared() info.rowElement: ' + info.rowElement);
      info.rowElement.addClass('auction');
    }
  }

  caricaDocumento(document: string) {

    console.log('caricaDocumento() document: ' + document);
    let params: any[] = [document];
    const modalRef = this.modalService.open(PopupComponent);
    modalRef.componentInstance.titolo = this.messageService.getLabelFromCode("LOAD_ORG_DOCUMENT") + document;
    modalRef.componentInstance.messaggio = '';
    modalRef.componentInstance.tipo = 'loadOrgDocument';
    modalRef.componentInstance.orgname = this.detail.orgname;
    modalRef.componentInstance.docname = document;
    modalRef.componentInstance.passEntry.subscribe((receivedEntry: any) => {
      if (receivedEntry != undefined) {
        modalRef.close();
        console.log(receivedEntry);
        this.updateDocumentUUID(document, this.detail.orgname, receivedEntry.file_uuid);
        //this.localUtil.showMessage("", ConstantsModule.INFO_TITLE, this.messageService.getMessageFromCode("OK_OPERATION"), true);
        console.log('carica da popup return');
      }
    }, this.handleError(true));

  }

  updateDocumentUUID(document: string, orgname: string, uuid: any) {
    console.log('updateDocumentUUID() document: ' + document + ' uuid: ' + uuid);
    console.log();

    if (document == ConstantsModule.DOC_VISURA) {
      this.detail.visuraCameraleValidated = "";
      this.detail.visuraCameraleStatus = this.messageService.getLabelFromCode("DESCR_DOC_DA_APPROVARE");
      this.detail.visuraCameraleCssClass = "pending";
      this.detail.visuraCamerale = uuid;
    } else if (document == ConstantsModule.DOC_LEGALE) {
      console.log('updateDocumentUUID() updating doc legale');
      this.detail.rappresentanteLegaleValidated = "";
      this.detail.rappresentanteLegaleStatus = this.messageService.getLabelFromCode("DESCR_DOC_DA_APPROVARE");
      this.detail.rappresentanteLegaleCssClass = "pending";
      this.detail.rappresentanteLegale = uuid;
    } else if (document == ConstantsModule.DOC_FIDEIUSSIONE) {
      this.detail.fidejussioneBancariaValidated = "";
      this.detail.fidejussioneBancariaStatus = this.messageService.getLabelFromCode("DESCR_DOC_DA_APPROVARE");
      this.detail.fidejussioneBancariaCssClass = "pending";
      this.detail.fidejussioneBancaria = uuid;
    } else if (document == ConstantsModule.DOC_231) {
      this.detail.dichiarazione231_2001Validated = "";
      this.detail.dichiarazione231_2001Status = this.messageService.getLabelFromCode("DESCR_DOC_DA_APPROVARE");
      this.detail.dichiarazione231_2001CssClass = "pending";
      this.detail.dichiarazione231_2001 = uuid;
    }
  }

  approveOrganization() {

    this.organizationService.approveOrganization(this.detail.orgname).subscribe(
      data => {

        const modalRef = this.modalService.open(PopupComponent);
        modalRef.componentInstance.titolo = this.messageService.getLabelFromCode("INFO_TITLE");
        modalRef.componentInstance.messaggio = this.messageService.getMessageFromCode("OK_OPERATION");
        modalRef.componentInstance.tipo = 'message';
        modalRef.componentInstance.passEntry.subscribe((receivedEntry: any) => {
          this.updateOrgStatus("Y");
        });

      }, this.handleError(true));
  }

  rejectOrganization() {

    this.organizationService.rejectOrganization(this.detail.orgname).subscribe(
      data => {

        const modalRef = this.modalService.open(PopupComponent);
        modalRef.componentInstance.titolo = this.messageService.getLabelFromCode("INFO_TITLE");
        modalRef.componentInstance.messaggio = this.messageService.getMessageFromCode("OK_OPERATION");
        modalRef.componentInstance.tipo = 'message';
        modalRef.componentInstance.passEntry.subscribe((receivedEntry: any) => {
          this.updateOrgStatus("N");
        });

      }, this.handleError(true));

  }

  updateOrgStatus(status: string) {
    if (status == "Y") {
      this.detail.statusClass = "active";
      this.detail.status = "Abilitata";
    } else if (status == "N") {
      this.detail.statusClass = "disabled";
      this.detail.status = "Non abilitata";
    }
  }

  approveDocument(docname: string) {
    console.log('approveDocument() ' + docname);
    this.organizationService.approveDocument(this.detail.orgname, docname).subscribe(
      data => {

        const modalRef = this.modalService.open(PopupComponent);
        modalRef.componentInstance.titolo = this.messageService.getLabelFromCode("INFO_TITLE");
        modalRef.componentInstance.messaggio = this.messageService.getMessageFromCode("OK_OPERATION");
        modalRef.componentInstance.tipo = 'message';
        modalRef.componentInstance.passEntry.subscribe((receivedEntry: any) => {
          this.updateDocStatus(docname, "Y");
        });

      }, this.handleError(true)
    );
  }

  rejectDocument(docname: string) {
    console.log('rejectDocument() ' + docname);
    this.organizationService.rejectDocument(this.detail.orgname, docname).subscribe(
      data => {

        const modalRef = this.modalService.open(PopupComponent);
        modalRef.componentInstance.titolo = this.messageService.getLabelFromCode("INFO_TITLE");
        modalRef.componentInstance.messaggio = this.messageService.getMessageFromCode("OK_OPERATION");
        modalRef.componentInstance.tipo = 'message';
        modalRef.componentInstance.passEntry.subscribe((receivedEntry: any) => {
          this.updateDocStatus(docname, "N");
        });

      }, this.handleError(true)
    );
  }

  updateDocStatus(document: string, status: string) {
    console.log('updateDocStatus() document: ' + document);
    if (document == ConstantsModule.DOC_VISURA) {
      this.detail.visuraCameraleValidated = status;
    } else if (document == ConstantsModule.DOC_LEGALE) {
      this.detail.rappresentanteLegaleValidated = status;
    } else if (document == ConstantsModule.DOC_FIDEIUSSIONE) {
      this.detail.fidejussioneBancariaValidated = status;
    } else if (document == ConstantsModule.DOC_231) {
      this.detail.dichiarazione231_2001Validated = status;
    }
  }

  //vecchia pagina sostituita da <questa pagina>/idAzienda
  /*onRowClick(e) {
    console.log(e.data);
    this.router.navigate(['operatore-aziende-dettaglio.html/', e.data.id]);
  }*/

  onRowClick(e) {
    console.log(e.data);
    let id = this.route.snapshot.paramMap.get('id');
    if (id != undefined && id == e.data.id) {
      this.dettaglioLoaded = false;
      this.idAzienda = e.data.id;
      this.apriEditAzienda();
    } else {
      if (this.isCoripet()) {
        this.router.navigate(['operatore-aziende.html/', e.data.id]);
      } else {
        this.router.navigate(['admin-aziende.html/', e.data.id]);
      }
    }
  }

  reloadCompaniesList(filterName: string) {
    console.log('reloadCompaniesList()');
    console.log(filterName);

    // Toggle the filter states
    if (filterName == 'filterActive') this.filterActive = !this.filterActive;
    if (filterName == 'filterToVerify') this.filterToVerify = !this.filterToVerify;
    if (filterName == 'filterDisabled') this.filterDisabled = !this.filterDisabled;

    // Filter organizations
    this.filterOrganizations();
  }

  filterOrganizations() {
    // Clear the organizations list
    this.organizations_list = [];

    if (this.full_organizations != undefined) {
      console.log('this.filterActive: ' + this.filterActive);
      console.log('this.filterToVerify: ' + this.filterToVerify);
      console.log('this.filterDisabled: ' + this.filterDisabled);

      // Use a Set to track unique organizations with explicit type
      let uniqueOrgs: Set<OrganizationItem> = new Set();

      this.full_organizations.forEach((org: OrganizationItem) => {  // Ensure org is typed
        console.log(org);

        // Check each condition and add to the Set if unique
        if (this.filterDisabled && org.disabled == "Y") {
          uniqueOrgs.add(org);
        }

        if (this.filterActive && org.validated == "Y") {
          uniqueOrgs.add(org);
        }

        if (this.filterToVerify && org.validated == "") {
          uniqueOrgs.add(org);
        }

        // If this.filterDisabled is true, we may also want to include those that are not validated.
        if (this.filterDisabled && org.validated == "N") {
          uniqueOrgs.add(org);
        }
      });

      // Convert Set to array and assign to organizations_list
      this.organizations_list = Array.from(uniqueOrgs);
    }
  }




}
