import { Location } from '@angular/common';
import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { DxDataGridComponent } from 'devextreme-angular';
import { ConfirmComponent } from 'src/app/commons/components/confirm/confirm.component';
import { AuctionBetSetupCreateItem, AuctionBetSetupItem, AuctionCreateItem, AuctionItem, AuctionLotItem, AuctionSetupCreateItem, AuctionSetupItem, AuctionSetupPropItem, AuctionSetupUpdateItem, AuctionTipologyCreateItem, AuctionTipologyItem, CodiceDescrizioneItem } from 'src/app/models/dataModels';
import { APIService } from 'src/app/services/APIService';
import { AuctionService } from 'src/app/services/auction.service';
import { AuthService } from 'src/app/services/auth.service';
import { ConstantsModule } from 'src/app/services/constants.module';
import { LocalUtilService } from 'src/app/services/local-util.service';
import { MessagesService } from 'src/app/services/messages.service';
import { Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-setup-bet',
  templateUrl: './setup-bet.component.html',
  styleUrls: ['./setup-bet.component.css']
})
export class SetupBetComponent extends APIService implements OnInit {

  @ViewChild("dataGridBet", { static: false }) dataGridBet: DxDataGridComponent;
  @Input() bet: AuctionBetSetupItem[] = [];
  @Output() updated = new EventEmitter<boolean>();
  @Input() tipologiaAsta: string;

  constructor(public localService: LocalUtilService,
    private modalService: NgbModal,
    private messageService: MessagesService,
    public auctionService: AuctionService,
    location: Location,
    router: Router) {
    super(localService, location, router);
  }

  ngOnInit(): void {
  }

  onCellPreparedBet(e) {
    e.cellElement.css("text-align", "left");
  }

  onRowInsertBet(e) {
    let auctionBet: AuctionBetSetupItem = e.data;
    auctionBet.taglioRilancio = parseInt(auctionBet.taglioRilancio.toString());
    let betToInsert: AuctionBetSetupCreateItem = new AuctionBetSetupCreateItem();
    betToInsert.slot = auctionBet.taglioRilancio.toString();
    this.auctionService.insertBetSlot(this.tipologiaAsta, betToInsert).subscribe(
      data => {
        this.updated.emit(true);
        this.localUtil.showMessage("", ConstantsModule.INFO_TITLE, this.messageService.getLabelFromCode("SUCCESS_CREATE_AUCTION_BET_SLOT_MESSAGE"), true);
      }, this.handleError(true, //this.reloadBetData()
      )
    );
  }

  onRowDeleteBet(e) {
    console.log(e.data);
    this.auctionService.deleteBetSlot(e.data.auctionBetSetupId).subscribe(
      data => {
        this.updated.emit(true);
        this.localUtil.showMessage("", ConstantsModule.INFO_TITLE, this.messageService.getLabelFromCode("SUCCESS_DELETE_AUCTION_BET_SLOT_MESSAGE"), true);
      }, this.handleError(true, //this.reloadBetData()
      )
    );
  }

  onRowPrepared(info) {
    if (info.rowType === 'data') {
      console.log('onRowPrepared() info.rowElement: ' + info.rowElement);
      info.rowElement.addClass('auction');
    }
  }

}