<div id="upload-visura" class="content top100">
    <!-- AGGIUNGERE FOCUSED SE SI VUOLE "ILLUMINARE" IL PANNELLO -->
    <section class="step-section focused">
        <div class="card">
            <h2>Visura camerale</h2>
            <div class="doc-datas doc-form-upload">
                <app-file-box [uuid]="existsDocument != undefined ? existsDocument.uuid : undefined" [status]="existsDocument != undefined ? existsDocument.status : ''" docname="visura-camerale.pdf" (file)="getVisuraCameraleFile($event)" [showError]="false" [showCoripetButtons]="false"></app-file-box>
            </div>
        </div>
        <div class="infos">
            <div class="number-wrapper">
                <div class="number">01</div>
                <div class="label">
                    Visura camerale
                </div>
            </div>
        </div>
    </section>
    <div class="main-actions">
        <a (click)="back()" class="btn">Annulla</a>
        <!-- BOTTONE REALE CON DISABLED -->
        <!-- <button class="btn success" disabled>Invia</button> -->
        <button class="btn primary btn-primary" (click)="uploadVisura()" id="btn_fileUpload" data-sector="visura">Invia</button>
    </div>
</div>