<div *ngFor="let item of auctionsPublished">
    <div class="countdown_dashboard" *ngIf="item.visible">
        <div class="auctions-focus auctions-focus-dashboard">
            <h2>Asta - {{item.codiceAsta}}</h2>
            <h1>Tempo rimanente alla prossima asta</h1>
            <div class="countdown" *ngIf="item.dataInizioAsta && item.oraInizioAsta">
                <app-timer [dateOfficialEnd]="item.dataInizioAsta + ' ' + item.oraInizioAsta" [dateEndCountdown]="item.dataInizioAsta" [hourEndCountdown]="item.oraInizioAsta" [showSign]="false"></app-timer>
            </div>
            <div class="infos">
                L'asta inizier&agrave; il <b>{{item.dataInizioAsta}}</b> alle ore <b>{{item.oraInizioAsta}}</b> e si concluder&agrave; il <b>{{item.dataFineAsta}}</b> alle ore <b>{{item.oraFineAsta}} ora italiana.</b>
            </div>
        </div>
        <div id="getting-started"></div>                    
    </div>
</div>