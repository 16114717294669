import { DatePipe, Location } from '@angular/common';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { AuctionDettaglioItem, AuctionItem, AuctionLotItem, CentroSelezioneItem, FileWithContentUpload, NazioneItem } from 'src/app/models/dataModels';
import { APIService } from 'src/app/services/APIService';
import { AuctionService } from 'src/app/services/auction.service';
import { CommonService } from 'src/app/services/common.service';
import { ConstantsModule } from 'src/app/services/constants.module';
import { LocalUtilService } from 'src/app/services/local-util.service';
import { MessagesService } from 'src/app/services/messages.service';
import { ConfirmComponent } from '../confirm/confirm.component';
import { PopupComponent } from '../popup/popup.component';
import * as ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { FormGroup } from '@angular/forms';

@Component({
  selector: 'app-dettaglio-lotto',
  templateUrl: './dettaglio-lotto.component.html',
  styleUrls: ['./dettaglio-lotto.component.css']
})
export class DettaglioLottoComponent extends APIService implements OnInit {

  date = new Date();
  pipe = new DatePipe('en-US');

  updatePopup: boolean = false;
  showPopup: boolean = false;

  firstSection: boolean = true;
  secondSection: boolean = false;
  public Editor = ClassicEditor;

  saveButtonOptions: any;
  closeButtonOptions: any;
  backButtonOptions: any;

  auction: AuctionItem;

  constructor(localUtil: LocalUtilService,
    public auctionService: AuctionService,
    private modalService: NgbModal,
    private messageService: MessagesService,
    location: Location,
    router: Router,
    private route: ActivatedRoute,
    public commonService: CommonService) {
    super(localUtil, location, router);

    const that = this;
    this.saveButtonOptions = {
      text: 'Aggiungi',
      stylingMode: 'contained',
      width: '200',
      type: 'default',
      onClick() {
        that.save();
      }
    },
      this.closeButtonOptions = {
        text: 'Annulla',
        width: '200',
        onClick() {
          that.showPopup = false;
        }
      },
      this.backButtonOptions = {
        text: 'Indietro',
        width: '200',
        onClick() {
          that.indietro();
        }
      }

  }

  @Input() lotDetail: AuctionLotItem;
  @Input() statoAsta: string;
  @Output() monitorEvent: EventEmitter<boolean> = new EventEmitter();
  cs_list: CentroSelezioneItem[] = [];
  nation_list: NazioneItem[] = [];

  menuAzioniOptions: any = {
    height: 'auto',
    width: '100%',
    color: 'white'
  }

  //coripet_menu_actions: string[] = [ ConstantsModule.CORIPET_MENU_ACTIONS_REQ_PUBLISH, ConstantsModule.CORIPET_MENU_ACTIONS_MODIFY, ConstantsModule.CORIPET_MENU_ACTIONS_DELETE];
  coripet_menu_actions_draft: string[] = [ConstantsModule.CORIPET_MENU_ACTIONS_REQ_PUBLISH, ConstantsModule.CORIPET_MENU_ACTIONS_MODIFY, ConstantsModule.CORIPET_MENU_ACTIONS_DELETE];
  coripet_menu_actions_inpublishreq: string[] = [ConstantsModule.CORIPET_MENU_ACTIONS_UNDO_REQ_PUBLISH];

  ngOnInit(): void {
    console.log(this.statoAsta);
    console.log("dettaglio-lotto");
    this.codiceAsta = this.route.snapshot.paramMap.get('codiceAsta');
    //  console.log("this.lotDetail")
    this.loadMaterialType();
    this.loadAreaGeo();
    this.loadCentriSelezione();
    this.loadNazioni();
    this.loadAuctionDetails(undefined, false);
    //  console.log(this.lotDetail.schedaTecnica)
    if (this.lotDetail) {
      this.lotDetail.cssClass = this.lotDetail.statoLotto.replace(' ', '');
    }
  }

  openPopup() {
    this.showPopup = true;
  }

  type_material: any[] = [];
  loadMaterialType() {
    this.commonService.getMaterialTypesList().subscribe(
      data => {
        this.type_material = data.list;
      },
      this.handleError(true)
    );
  }

  geo_area: any[] = [];
  loadAreaGeo() {
    this.commonService.getAreaGeograficaList().subscribe(
      data => {
        this.geo_area = data.list;
      },
      this.handleError(true)
    );
  }

  editDetails: string;
  grid: boolean = true;
  form: boolean = false;
  button: boolean = false;
  quantita: any = '';
  mandatory: boolean = false;
  centroSelezione: any[] = [];
  codice: any;

  click(e) {
    this.grid = false;
    this.form = true;
    this.button = true;
    console.log(e);
    this.editDetails = e.data.codice + e.data.nome + e.data.indirizzo;
    this.codice = e.data.codice;
  }

  save() {
    if (this.quantita === '') {
      this.mandatory = true;
    } else {
      console.log("API Calling");
      this.mandatory = false;
      /*  this.centroSelezione.push({
         "codiceCentroSelezione": this.codice,
         "quantitaTon": this.quantita
       }); */

      if (this.asteDetails) {
        this.centroSelezione = this.asteDetails.concat({
          "codiceCentroSelezione": this.codice,
          "quantitaTon": this.quantita
        });
      } else {
        this.centroSelezione = [{
          "codiceCentroSelezione": this.codice,
          "quantitaTon": this.quantita
        }];
      }
      console.log(this.centroSelezione);
      this.asteDetails = this.centroSelezione;
      this.form = false;
      this.grid = true;
      this.button = false;
      this.showPopup = false;
      this.quantita = '';
    }
  }

  indietro() {
    this.grid = true;
    this.form = false;
    this.button = false;
    this.mandatory = false;
  }

  rimuovi(index: number) {
    if (index >= 0 && index < this.asteDetails.length) {
      this.asteDetails.splice(index, 1);
      //sessionStorage.setItem('aste-details', JSON.stringify(this.asteDetails));
    }
  }

  cancel() {
    this.updatePopup = false;
  }

  setNewLotState(state: string) {
    this.lotDetail.statoLotto = state;
    this.lotDetail.cssClass = this.lotDetail.statoLotto.replace(' ', '');
  }

  menuAzioniClick(e) {
    console.log('menuAzioniClick() ' + this.lotDetail.codiceLotto);
    let action = e.itemData;
    console.log('menuAzioniClick() ' + this.lotDetail.codiceLotto + ' action: ' + action);
    console.log(e.itemData);

    if (action == ConstantsModule.CORIPET_MENU_ACTIONS_REQ_PUBLISH) {
      this.requestApproveLot(this.lotDetail.codiceAsta, this.lotDetail.codiceLotto);
    }
    else if (action == ConstantsModule.CORIPET_MENU_ACTIONS_MODIFY) {
      this.getLotDetail(this.lotDetail.codiceAsta, this.lotDetail.codiceLotto)
    }
    else if (action == ConstantsModule.CORIPET_MENU_ACTIONS_DELETE) {
      this.deleteLot(this.lotDetail.codiceAsta, this.lotDetail.codiceLotto);
    } else if (action == ConstantsModule.CORIPET_MENU_ACTIONS_UNDO_REQ_PUBLISH) {
      this.undoRequestApproveLot(this.lotDetail.codiceAsta, this.lotDetail.codiceLotto);
    }

  }

  showMessage() {
    console.log("click");
    this.showPopup = true;
  }

  codiceLotto: any;
  codiceProdotto: any;
  tipoProdotto: any;
  areaGeografica: any;
  quantitaTon: any;
  prezzoBase: any;
  dataDisponibilitaLotto: any;
  note: any;
  id: any;
  idOrganization: any;
  codiceAsta: any;

  centroSeleziones: any;

  asteDetails: any[] = [];

  getLotDetail(codiceAsta: string, codiceLotto: string) {
    this.secondSection = false;
    this.firstSection = true;
    console.log("insude")

    this.auctionService.getLotDetail(codiceAsta, codiceLotto).subscribe(
      data => {
        console.log(data);
        const apiDate = data.lot.dataDisponibilitaLotto;
        const formattedDate = this.formatDate(apiDate);

        this.codiceLotto = data.lot.codiceLotto;
        this.codiceProdotto = data.lot.codiceProdotto;
        this.tipoProdotto = data.lot.tipoProdotto;
        this.areaGeografica = data.lot.areaGeografica;
        this.quantitaTon = data.lot.quantitaTon;
        this.prezzoBase = data.lot.prezzoBase;
        this.dataDisponibilitaLotto = formattedDate;
        this.note = data.lot.note;
        this.asteDetails = data.lot.centroSelezione;
        this.id = data.lot.id;
        this.idOrganization = data.lot.idOrganization;
        this.centroSeleziones = data.lot.centroSelezione;
        this.updatePopup = true;
      }
    )
  }

  formatDate(apiDate: string): string {
    const parts = apiDate.split('/');
    if (parts.length === 3) {
      const [day, month, year] = parts;
      return `${year}-${month}-${day}`;
    }
    return apiDate;
  }

  updateResponseList: any[] = [];
  errorPopup: boolean = false;
  errorMessage: string;
  update() {
    console.log(this.asteDetails);
    let newOp: AuctionDettaglioItem = new AuctionDettaglioItem();
    let dataDisponibilitaLotto = this.pipe.transform(this.dataDisponibilitaLotto, 'dd/MM/yyyy');
    let updateRequest = {
      "id": this.id,
      "idOrganization": this.idOrganization,
      "codiceLotto": this.codiceLotto,
      "codiceProdotto": this.codiceProdotto,
      "tipoProdotto": this.tipoProdotto,
      "areaGeografica": this.areaGeografica,
      "quantitaTon": this.quantitaTon,
      "prezzoBase": this.prezzoBase,
      "dataDisponibilitaLotto": dataDisponibilitaLotto,
      "note": this.note,
      "centroSelezione": this.asteDetails
    }
    this.auctionService.updateLotAste(this.codiceAsta, this.codiceLotto, updateRequest).subscribe(dt => {
      console.log(dt);
      this.updateResponseList.push(dt.detail);
      this.firstSection = false;
      this.secondSection = true;
      this.handleError(true);
    }, err => {
      this.errorMessage = err.error.message;
      this.errorPopup = true;
      this.handleError(true);
    });
  }

  documentoAzienda: FileWithContentUpload;
  excelLotti: FileWithContentUpload;

  getExcelLottiFile(file) {
    if (file != undefined) {
      console.log(file);
      this.documentoAzienda = new FileWithContentUpload();
      this.documentoAzienda.file = file;
      console.log('getExcelLottiFile() loaded file: ' + file.name);
    } else {
      this.excelLotti = undefined;
    }
  }

  auctionDettaglioForm: FormGroup;
  uploadDocument() {
    this.documentoAzienda.contentType = 'LOT';
    this.documentoAzienda.codiceLotto = this.codiceLotto;

    this.commonService.documentUpload(this.documentoAzienda).subscribe(dt => {
      this.updateResponseList.forEach(data => {
        let updateRequest = {
          "areaGeografica": data.areaGeografica,
          "centroSelezione": data.centroSelezione,
          "codiceAsta": data.codiceAsta,
          "codiceLotto": data.codiceLotto,
          "codiceProdotto": data.codiceProdotto,
          "codiceRifiutoCER": data.codiceRifiutoCER,
          "createDate": data.createDate,
          "dataDisponibilitaLotto": data.dataDisponibilitaLotto,
          "dataOraFineAstaEffettiva": data.dataOraFineAstaEffettiva,
          "id": data.id,
          "idOrganization": data.idOrganization,
          "modifiedDate": data.modifiedDate,
          "note": data.note,
          "numeroRilanci": data.numeroRilanci,
          "pagato": data.pagato,
          "prezzoBase": data.prezzoBase,
          "prezzoUltimoRilancio": data.prezzoUltimoRilancio,
          "quantitaTon": data.quantitaTon,
          "schedaTecnica": JSON.parse(dt).file_uuid,
          "spedito": data.spedito,
          "statoLotto": data.statoLotto,
          "tipoProdotto": data.tipoProdotto
        }
        this.auctionService.updateLotAste(this.codiceAsta, data.codiceLotto, updateRequest).subscribe(dt => {
          this.loadAuctionDetails(undefined, false);
          this.updatePopup = false;
        }, this.handleError(true));
      });
      this.localUtil.showMessage("", ConstantsModule.INFO_TITLE, this.messageService.getMessageFromCode("LOT_CREATE"), true);
    }, this.handleError(true));

  }

  loadAuctionDetails(textFilter, onlywon) {

    var qparam = '';

    this.auctionService.getAuctionLots(this.codiceAsta, qparam).subscribe(
      (data: any) => {
        console.log('data.auctionDetail: ' + JSON.stringify(data.auctionDetail));
        this.auction = data.auctionDetail;
        /*this.statoAsta = data.auctionDetail.statoAsta;
        this.tipologiaAsta = data.auctionDetail.tipologiaAsta;
        this.dataOraFineAsta = data.auctionDetail.dataOraFineAsta;*/
        if (data.lotList != undefined) {
          this.auction.numLotti = data.lotList.length;
          //console.log('lot_list: ' + data.lotList);

          if (this.auction.statoAsta == ConstantsModule.AUCTION_STATUS_AVAILABLE) {
            //this.manageAllOtherAuction(data.lotList);
          } else {
            //this.manageAllOtherAuction(data.lotList);
          }


          //console.log('lot_list: ' + this.lot_list.length);
        }
      });

  }

  requestApproveLot(codiceAsta: string, codiceLotto: string) {
    console.log('requestApproveLot() ' + codiceLotto);
    if (codiceAsta && codiceLotto) {
      this.auctionService.requestApproveLot(this.lotDetail.codiceAsta, this.lotDetail.codiceLotto).subscribe(
        res => {
          this.monitorEvent.emit(true);
          this.localUtil.showMessage("", ConstantsModule.INFO_TITLE, this.messageService.getMessageFromCode("OK_OPERATION"), true);
          this.setNewLotState(ConstantsModule.AUCTION_LOT_STATUS_INPUBLISHING);
        },
        this.handleError(true)
      );
    } else {
      this.localUtil.showMessage("", ConstantsModule.INFO_TITLE, this.messageService.getMessageFromCode("E:GENERIC"), true);
    }

  }

  undoRequestApproveLot(codiceAsta: string, codiceLotto: string) {
    console.log('undoRequestApproveLot() ' + codiceLotto);
    if (codiceAsta && codiceLotto) {
      this.auctionService.undoRequestApproveLot(this.lotDetail.codiceAsta, this.lotDetail.codiceLotto).subscribe(
        res => {
          this.monitorEvent.emit(true);
          this.localUtil.showMessage("", ConstantsModule.INFO_TITLE, this.messageService.getMessageFromCode("OK_OPERATION"), true);
          this.setNewLotState(ConstantsModule.AUCTION_LOT_STATUS_DRAFT);
        },
        this.handleError(true)
      );
    } else {
      this.localUtil.showMessage("", ConstantsModule.INFO_TITLE, this.messageService.getMessageFromCode("E:GENERIC"), true);
    }

  }

  deleteLot(codiceAsta: string, codiceLotto: string) {

    if (codiceAsta && codiceLotto) {

      let params: any[] = [codiceAsta, codiceLotto];
      const modalRef = this.modalService.open(ConfirmComponent);
      modalRef.componentInstance.titolo = this.messageService.getLabelFromCode("INFO_TITLE");
      modalRef.componentInstance.messaggio = this.messageService.getLabelFromCode("AUCTION_DELETE_LOT_MESSAGE", params);
      modalRef.componentInstance.passEntry.subscribe((receivedEntry: any) => {
        if (receivedEntry != undefined) {

          this.auctionService.deleteLot(codiceAsta, codiceLotto).subscribe(
            res => {
              this.monitorEvent.emit(true);
              this.localUtil.showMessage("", ConstantsModule.INFO_TITLE, this.messageService.getMessageFromCode("OK_OPERATION"), true);
              this.reloadComponent();
            },
            this.handleError(true)
          );
        }
      }, this.handleError(true));

    } else {
      this.localUtil.showMessage("", ConstantsModule.INFO_TITLE, this.messageService.getMessageFromCode("E:GENERIC"), true);
    }

  }

  approveLot() {
    console.log('approveLot() ' + this.lotDetail.codiceAsta + ' ' + this.lotDetail.codiceLotto);

    this.auctionService.approveLot(this.lotDetail.codiceAsta, this.lotDetail.codiceLotto).subscribe(
      res => {
        this.monitorEvent.emit(true);
        this.localUtil.showMessage("", ConstantsModule.INFO_TITLE, this.messageService.getMessageFromCode("OK_OPERATION"), true);
        this.setNewLotState(ConstantsModule.AUCTION_LOT_STATUS_PUBLISHED);
      },
      this.handleError(true)
    );

  }

  undoApproveLot() {
    console.log('undoApproveLot() ' + this.lotDetail.codiceAsta + ' ' + this.lotDetail.codiceLotto);

    this.auctionService.undoApproveLot(this.lotDetail.codiceAsta, this.lotDetail.codiceLotto).subscribe(
      res => {
        this.monitorEvent.emit(true);
        this.localUtil.showMessage("", ConstantsModule.INFO_TITLE, this.messageService.getMessageFromCode("OK_OPERATION"), true);
        this.setNewLotState(ConstantsModule.AUCTION_LOT_STATUS_INPUBLISHING);
      },
      this.handleError(true)
    );

  }

  rejectLot() {
    console.log('rejectLot() ' + this.lotDetail.codiceAsta + ' ' + this.lotDetail.codiceLotto);
    this.auctionService.rejectLot(this.lotDetail.codiceAsta, this.lotDetail.codiceLotto).subscribe(
      res => {
        this.monitorEvent.emit(true);
        this.localUtil.showMessage("", ConstantsModule.INFO_TITLE, this.messageService.getMessageFromCode("OK_OPERATION"), true);
        this.setNewLotState(ConstantsModule.AUCTION_LOT_STATUS_REJECTED);
      },
      this.handleError(true)
    );

  }

  undoRejectLot() {
    console.log('undoRejectLot() ' + this.lotDetail.codiceAsta + ' ' + this.lotDetail.codiceLotto);

    this.auctionService.undoRejectLot(this.lotDetail.codiceAsta, this.lotDetail.codiceLotto).subscribe(
      res => {
        this.monitorEvent.emit(true);
        this.localUtil.showMessage("", ConstantsModule.INFO_TITLE, this.messageService.getMessageFromCode("OK_OPERATION"), true);
        this.setNewLotState(ConstantsModule.AUCTION_LOT_STATUS_INPUBLISHING);
      },
      this.handleError(true)
    );

  }

  loadNazioni() {
    this.commonService.getNationsList().subscribe(
      data => {
        this.nation_list = data.list;
      });
  }

  loadCentriSelezione() {
    this.auctionService.getSelectionCenterList().subscribe(
      data => {
        this.cs_list = data.list;
      });
  }

  getSchedaTecnicaUrl(tipoProdotto: string) {
    return ConstantsModule.S3_URL + '/scheda_tecnica/scheda-tecnica-' + tipoProdotto + '.pdf';
  }

  openNote() {
    const modalRef = this.modalService.open(PopupComponent);
    modalRef.componentInstance.tipo = 'openNote';
    modalRef.componentInstance.note = this.lotDetail.note;
    modalRef.componentInstance.codiceLotto = this.lotDetail.codiceLotto;
  }

  openCentriSelezione() {
    const modalRef = this.modalService.open(PopupComponent);
    modalRef.componentInstance.tipo = 'openCentriSelezione';
    modalRef.componentInstance.titolo = 'Centri di selezione';
    modalRef.componentInstance.codiceLotto = this.lotDetail.codiceLotto;
    console.log('openCentriSelezione() codiceLotto: ' + this.lotDetail.codiceLotto);
    let lotElem = this.lotDetail;
    let csListPopup: CentroSelezioneItem[] = [];
    console.log(lotElem);
    if (lotElem != undefined && lotElem.centroSelezione != undefined) {
      lotElem.centroSelezione.forEach(csLottoElem => {
        let item = new CentroSelezioneItem();
        item.quantitaTon = csLottoElem.quantitaTon;
        item.indirizzo = '';
        item.pesoMedioCarico = '';

        let cs = this.localUtil.getSelectionCenterByCodice(csLottoElem.codiceCentroSelezione, this.cs_list);
        if (cs != undefined) {

          let nazione = this.localUtil.getNazioneByCodice(cs.nazione, this.nation_list);
          console.log('nazione: ' + JSON.stringify(nazione));
          let nazioneDescr = '';
          if (nazione != null && nazione != undefined && nazione != '') {
            nazioneDescr = nazione.descrizione;
            if (nazione.codice == 'IT') {
              nazioneDescr = 'Italia';
            }
            item.nazioneDescr = nazioneDescr;
          }
        }
        item.CER = cs.CER;
        item.cap = cs.cap;
        item.citta = cs.citta;
        item.provincia = cs.provincia;
        item.pesoMedioCarico = cs.pesoMedioCarico;
        item.nome = cs.nome;
        item.indirizzo = cs.indirizzo;
        csListPopup.push(item);
      });
    }
    modalRef.componentInstance.centriSelezione = csListPopup;

  }

  menuClick(e) {
  }

  updateLottoStatePagato() {
    this.lotDetail.pagato = this.lotDetail.pagato == 0 ? 1 : 0;

    if (this.lotDetail.pagato == 1) {

      this.auctionService.setLottoPagato(this.lotDetail.codiceAsta, this.lotDetail.codiceLotto).subscribe(
        data => {
          this.monitorEvent.emit(true);
          this.localUtil.showMessage("", ConstantsModule.INFO_TITLE, this.messageService.getMessageFromCode("OK_OPERATION"), true);
        }, this.handleError(true)
      );

    } else {

      this.auctionService.setLottoNonPagato(this.lotDetail.codiceAsta, this.lotDetail.codiceLotto).subscribe(
        data => {
          this.monitorEvent.emit(true);
          this.localUtil.showMessage("", ConstantsModule.INFO_TITLE, this.messageService.getMessageFromCode("OK_OPERATION"), true);
        }, this.handleError(true)
      );

    }
  }

  updateLottoStateRitirato() {
    this.lotDetail.spedito = this.lotDetail.spedito == 0 ? 1 : 0;

    if (this.lotDetail.spedito == 1) {

      this.auctionService.setLottoRitirato(this.lotDetail.codiceAsta, this.lotDetail.codiceLotto).subscribe(
        data => {
          this.monitorEvent.emit(true);
          this.localUtil.showMessage("", ConstantsModule.INFO_TITLE, this.messageService.getMessageFromCode("OK_OPERATION"), true);
        }, this.handleError(true)
      );

    } else {

      this.auctionService.setLottoNonRitirato(this.lotDetail.codiceAsta, this.lotDetail.codiceLotto).subscribe(
        data => {
          this.monitorEvent.emit(true);
          this.localUtil.showMessage("", ConstantsModule.INFO_TITLE, this.messageService.getMessageFromCode("OK_OPERATION"), true);
        }, this.handleError(true)
      );

    }
  }

}
