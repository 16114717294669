<div class="mobile-menu menu-toggler">
  <i class="fal fa-bars" data-bs-toggle="offcanvas" data-bs-target="#offcanvasTop" aria-controls="offcanvasTop"></i>
</div>
<div class="offcanvas offcanvas-top" tabindex="-1" id="offcanvasTop" aria-labelledby="offcanvasTopLabel">
  <div class="offcanvas-header" style="font-size: 30px;">
    <h5 id="offcanvasTopLabel">Coripet</h5>
    <i class="fal fa-bars" data-bs-dismiss="offcanvas" aria-label="Close"></i>
  </div>
  <div class="offcanvas-body">
    <nav id="main-menu">
      <i class="fas fa-grip-lines-vertical" id="menu_handle" (click)="toggleMenu()"></i>


      <ul class="text-center" id="main-menu-items" [ngClass]="{'open': isMenuOpen}" data-bs-dismiss="offcanvas"
        aria-label="Close">
        <li style="font-size: 20px; margin: 30px;" *ngFor="let item of menuList; let i = index"
          [ngClass]="{'active': isActiveFather(item)}" id="mnu-{{item.key}}" (click)="openChilds(item)">
          <a (click)="gotoUrl(item.page)" translate>sideBarmenu.{{item.key}}</a>
          <ul *ngIf="isActiveFather(item)" class="submenu">
            <li *ngFor="let subItem of subMenuList; let j = index" [ngClass]="{'active': isActiveSubMenu(subItem)}"
              id="mnu-{{subItem.key}}">
              <a (click)="gotoUrl(subItem.page)" translate>sideBarmenu.{{subItem.key}}</a>
            </li>
          </ul>
        </li>
      </ul>


      <ul class="text-center" id="main-menu-items2">
        <li style="font-size: 20px; margin: 30px;" id="logout-link">
          <a (click)="doLogout()">Logout</a>
        </li>
      </ul>
    </nav>
  </div>
</div>
<aside id="sidebar">
  <div class="logo" id="logo" [ngStyle]="{ 'visibility': showUl ? 'visible' : 'hidden'}">
    Coripet
  </div>
  <nav id="main-menu">
    <i class="fas fa-grip-lines-vertical" id="menu_handle" (click)="toggleMenu()"></i>


    <ul [ngStyle]="{ 'visibility': showUl ? 'visible' : 'hidden'}" id="main-menu-items" [ngClass]="{'open': isMenuOpen}">
      <li *ngFor="let item of menuList; let i = index" [ngClass]="{'active': isActiveFather(item)}"
        id="mnu-{{item.key}}" (click)="openChilds(item)">
        <a (click)="gotoUrl(item.page)" translate>sideBarmenu.{{item.key}}</a>
        <ul *ngIf="isActiveFather(item)" class="submenu">
          <li *ngFor="let subItem of subMenuList; let j = index" [ngClass]="{'active': isActiveSubMenu(subItem)}"
            id="mnu-{{subItem.key}}">
            <a (click)="gotoUrl(subItem.page)" translate>sideBarmenu.{{subItem.key}}</a>
          </li>
        </ul>
      </li>
    </ul>


    <ul id="main-menu-items2" [ngStyle]="{ 'visibility': showUl ? 'visible' : 'hidden'}">
      <li id="logout-link">
        <a (click)="doLogout()">Logout</a>
      </li>
    </ul>
  </nav>
</aside>