<div *ngIf="headerVisible">
    <div *ngIf="headerDetail">
        <!-- topHeader -->
        <header id="header" *ngIf="headerDetail.topHeader"
            [ngClass]="{'fixed-header': stickyHeaderAuction || stickyHeaderAuctionBox}">
            <div class="testataheader secondo-spazio" *ngIf="getShowAuctionHeaders()">
                <div *ngIf="headerDetail.view == ConstantsModule.AUC_VIEW_BOX" class="primo-timer server_time2 mb-3">
                    <!--app-timer (monitorEvent)="timerEventRaised($event)" [name]="lot.codiceLotto" [dateOfficialEnd]="auctionDetail.dataOraFineAsta" [dateEndCountdown]="getDataFineAstaEffettiva(lot.dataOraFineAstaEffettiva)" [hourEndCountdown]="getOraFineAstaEffettiva(lot.dataOraFineAstaEffettiva)" [showSign]="false"></app-timer-->
                    <div *ngIf="timers && timers.length>0" [ngClass]="{'rosso-inline': isInExtraTime(timers[0])}">
                        <span translate>{{timers[0].label}}:&nbsp;</span>
                        <span id="server_time"><app-timer #timer1 [name]="timers[0].name"
                                (monitorEvent)="timerLotEndRaised($event, timers[0])"
                                [dateOfficialEnd]="timers[0].dataOraFineAsta"
                                [dateEndCountdown]="getDataFineAstaEffettiva(timers[0].dataOraFineAstaEffettiva)"
                                [hourEndCountdown]="getOraFineAstaEffettiva(timers[0].dataOraFineAstaEffettiva)"
                                [showSign]="false"></app-timer></span>
                    </div>
                </div>
                <div class="testataheader primo-spazio" *ngIf="getShowAuctionHeaders()">
                    <div class="server-time-fixed">
                        <span translate>header.orario-di-sistema</span> <span id="server_time">{{serverTime}}</span>
                    </div>
                </div>
            </div>
            <div class="vr ms-5" *ngIf="getShowAuctionHeaders()"></div>
            <div class="expiration2 {{headerDetail.view}}" *ngIf="getShowAuctionHeaders()">
                <div class="label">
                    <span class="spesa_attuale" translate>header.spesa-attuale</span>&nbsp;
                </div>
                <div class="expiration-date2" id="spesaAttuale">
                    {{currentCost}} &euro;
                </div>
            </div>

            <div class="testataheader terzo-spazio" *ngIf="getShowAuctionHeaders()">
                <div *ngIf="headerDetail.view == ConstantsModule.AUC_VIEW_BOX" class="secondo-timer server_time2">
                    <div *ngIf="timers && timers.length == 2" [ngClass]="{'rosso-inline': isInExtraTime(timers[1])}">
                        <span translate>{{timers[1].label}}:&nbsp;</span>
                        <span id="server_time"><app-timer #timer2 [name]="timers[1].name"
                                (monitorEvent)="timerLotEndRaised($event, timers[1])"
                                [dateOfficialEnd]="timers[1].dataOraFineAsta"
                                [dateEndCountdown]="getDataFineAstaEffettiva(timers[1].dataOraFineAstaEffettiva)"
                                [hourEndCountdown]="getOraFineAstaEffettiva(timers[1].dataOraFineAstaEffettiva)"
                                [showSign]="false"></app-timer></span>
                    </div>
                </div>
            </div>
            <div class="mobile-header">
                <div class="logo"><img src="" alt=""></div>
            </div>
            <div class="user">
                <div class="icon"><i class="fal fa-user"></i></div>
                <div class="name">{{nome}}</div>
                <div class="company"><span>{{qualifica}}</span> <img id="image_logo" src="" alt=""></div>
            </div>
        </header>
        <!-- dashboardHeader -->
        <ul class="overview-tool dashboardHeader" *ngIf="routerPath">
            <!-- <ul class="overview-tool dashboardHeader" *ngIf="isUser() || isAdmin() && headerDetail.dashboardHeader === false"> -->
            <li class="production-capacity">
                <div class="label">
                    <span translate>header.capacita-produttiva-annua</span>
                    <div class="symbol" id="frameworksCapacity">{{frameworksCapacity}} t</div>
                </div>
            </li>
            <li class="last-section">
                <div class="expiration">
                    <div class="label">
                        <span translate>header.la-tua-fideiussione-scade-il</span>
                    </div>
                    <div class="expiration-date" id="dataScadenzaFidejussione">
                        {{dataScadenzaFidejussione}}
                    </div>
                </div>
                <div class="expiration">
                    <div class="label">
                        <span translate>header.valore-fideiussione</span>
                    </div>
                    <div class="expiration-date" id="totaleFidejussione">
                        {{totaleFidejussione}} &euro;
                    </div>
                </div>
            </li>
        </ul>
        <!-- capacityHeader -->
        <ul class="overview-tool capacityHeader" *ngIf="isUser() && headerDetail.capacityHeader"
            [ngClass]="{'fixed-parameters': headerDetail.capacityHeader && (stickyHeaderAuction || stickyHeaderAuctionBox)}">
            <li class="capacity-1" *ngIf="sliderValueCapacity">
                <div class="label">
                    <span translate>header.utilizzo-capacita-disponibile</span>
                    <div class="symbol">t</div>
                </div>
                <div class="slider-bar">
                    <div class="bar-wrapper">
                        <div class="bar-track">
                            <div class="bar" style="width: {{sliderValueCapacity.curPerc}}%"></div>
                        </div>
                    </div>
                    <div class="bar-labels">
                        <div class="value">{{sliderValueCapacity.curValue}} ton</div>
                        <div class="mid">(<span translate>header.q.ta-residua</span> {{sliderValueCapacity.resValue}} t)
                        </div>
                        <div class="max">{{sliderValueCapacity.maxValue}} ton </div>
                    </div>
                </div>
            </li>
            <li class="capacity-PET-Azzurrato" *ngIf="sliderValueAzzurrato">
                <div class="label">
                    <span translate>header.utilizzo-capacita-disponibile-CTA-M</span>
                    <div class="symbol">t</div>
                </div>
                <div class="slider-bar">
                    <div class="bar-wrapper">
                        <div class="bar-track">
                            <div class="bar" style="width: {{sliderValueAzzurrato.curPerc}}%"></div>
                        </div>
                    </div>
                    <div class="bar-labels">
                        <div class="value">{{sliderValueAzzurrato.curValue}} ton</div>
                        <div class="mid"> (<span translate>header.q.ta-residua</span> {{sliderValueAzzurrato.resValue}}
                            t)
                        </div>
                        <div class="max">{{sliderValueAzzurrato.maxValue}} ton </div>
                    </div>
                </div>
            </li>
            <li class="capacity-PET-Colorato" *ngIf="sliderValueColorato">
                <div class="label">
                    <span translate>header.utilizzo-capacita-disponibile-CTC-M</span>
                    <div class="symbol">t</div>
                </div>
                <div class="slider-bar">
                    <div class="bar-wrapper">
                        <div class="bar-track">
                            <div class="bar" style="width: {{sliderValueColorato.curPerc}}%"></div>
                        </div>
                    </div>
                    <div class="bar-labels">
                        <div class="value">{{sliderValueColorato.curValue}} ton</div>
                        <div class="mid">(<span translate>header.q.ta-residua</span> {{sliderValueColorato.resValue}} t)
                        </div>
                        <div class="max">{{sliderValueColorato.maxValue}} ton </div>
                    </div>
                </div>
            </li>
            <li class="capacity-PET-Trasparente" *ngIf="sliderValueTrasparente">
                <div class="label">
                    <span translate>header.utilizzo-capacita-disponibile-CTL-M</span>
                    <div class="symbol">t</div>
                </div>
                <div class="slider-bar">
                    <div class="bar-wrapper">
                        <div class="bar-track">
                            <div class="bar" style="width: {{sliderValueTrasparente.curPerc}}%"></div>
                        </div>
                    </div>
                    <div class="bar-labels">
                        <div class="value">{{sliderValueTrasparente.curValue}} ton</div>
                        <div class="mid">(<span translate>header.q.ta-residua</span> {{sliderValueTrasparente.resValue}}
                            t)
                        </div>
                        <div class="max">{{sliderValueTrasparente.maxValue}} ton </div>
                    </div>
                </div>
            </li>
        </ul>
        <!-- stickyHeader -->
        <table class="cards-lots-list-header {{motoreAsta}} stickyHeader" id="cards-lots-list-header"
            data-toggle="table" *ngIf="headerDetail.stickyHeader">
            <thead class="header" id="myHeader">
                <tr>
                    <th id="lotto">Lotto</th>
                    <th id="tipologia">Tipologia</th>
                    <th id="area">Area</th>
                    <th id="quantita">Q.t&agrave; (t)</th>
                    <th id="rilanci">Rilanci</th>
                    <th id="prezzo">Prezzo (&euro;/t)</th>
                    <th id="tempo">Tempo rimanente</th>
                    <th id="stato">Stato</th>
                    <th id="azione1">Azione</th>
                    <th id="azione2"><i class="fal fa-sync blu fright mr20 pointer" (click)="reloadLots()"
                            [ngClass]="{'disabled-reload-header': getDisabledReloadLots() || loaderLotsEnabled}"
                            title="Ricarica"></i></th>
                </tr>
            </thead>
            <tbody id="rowSizing">
            </tbody>
        </table>
    </div>
</div>