<div id="auctions-list" class="auctions-list">
    <dx-data-grid #dataGridProperties id="gridContainer" [dataSource]="properties" keyExpr="code"
        [allowColumnReordering]="true" [allowColumnResizing]="true" [showBorders]="true" [columnHidingEnabled]="false"
        (onRowClick)="onRowClickProperties($event)" (onRowUpdating)="onRowUpdateProperties($event)"
        (onRowPrepared)="onRowPrepared($event)">

        <dxo-load-panel [enabled]="true"></dxo-load-panel>
        <dxo-filter-row [visible]="true"></dxo-filter-row>
        <dxo-header-filter [visible]="true"></dxo-header-filter>
        <dxo-scrolling mode="infinite"></dxo-scrolling>

        <dxi-column dataField="code" caption="Nome property" [allowEditing]="false" dataType="string" [visible]="true">
            <dxi-validation-rule type="required"></dxi-validation-rule>
        </dxi-column>

        <dxi-column dataField="value" caption="Valore" [allowEditing]="true" dataType="string" [visible]="true">
            <dxi-validation-rule type="required"></dxi-validation-rule>
        </dxi-column>

        <dxo-editing [allowUpdating]="true" [allowAdding]="false" [allowDeleting]="false" mode="row">

        </dxo-editing>
    </dx-data-grid>
</div>