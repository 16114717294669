import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';
import { LocalUtilService } from './local-util.service';
import { ConstantsModule } from './constants.module';
import { APIService } from './APIService';
import { Location } from '@angular/common';
import { ClientService } from './client.service';
import { Observable, of } from 'rxjs';
import { OrganizationDocumentsUpdateItem, OrganizationOperatorCreateItem, OrganizationOperatorUpdateItem, OrganizationPlantItem, OrganizationUpdateItem } from '../models/dataModels';

@Injectable({
    providedIn: 'root'
})
export class OrganizationService extends ClientService {

    constructor(protected localUtilService: LocalUtilService, 
                protected location: Location, 
                private router: Router,
                httpClient: HttpClient) {
        super(localUtilService, router, httpClient);
    }

    genericObservable: Observable<{}>;

    getOrganizationOperators(vatnumber: string) : Observable<any> {
        return this.httpClient.get<any>(ConstantsModule.API_HOST + ConstantsModule.API_ORGANIZATION_PREFIX + ConstantsModule.API_PREFIX + '/' + vatnumber + '/operators', this.httpOptionsWithParams);
    }

    getAllOrganizationOperators() : Observable<any> {
        return this.httpClient.get<any>(ConstantsModule.API_HOST + ConstantsModule.API_USER_PREFIX + ConstantsModule.API_PREFIX + '/operator/operators/list', this.httpOptionsWithParams);
    }

    getUserList() : Observable<any> {
        return this.httpClient.get<any>(ConstantsModule.API_HOST + ConstantsModule.API_USER_PREFIX + ConstantsModule.API_PREFIX + '/list', this.httpOptionsWithParams);
    }

    getOrganizationDetail(vatnumber: string) : Observable<any> {
        return this.httpClient.get<any>(ConstantsModule.API_HOST + ConstantsModule.API_ORGANIZATION_PREFIX + ConstantsModule.API_PREFIX + '/id/' + vatnumber, this.httpOptionsWithParams);
    }

    getOrganizationPlants(orgname: string) : Observable<any> {
        return this.httpClient.get<any>(ConstantsModule.API_HOST + ConstantsModule.API_ORGANIZATION_PREFIX + ConstantsModule.API_PREFIX + '/framework/' + orgname + '/list', this.httpOptionsWithParams);
    }

    getOrganizationList() : Observable<any> {
        return this.httpClient.get<any>(ConstantsModule.API_HOST + ConstantsModule.API_ORGANIZATION_PREFIX + ConstantsModule.API_PREFIX + '/list', this.httpOptionsWithParams);
    }

    updateOrganizationPlant(vatnumber: string, plant: OrganizationPlantItem) : Observable<any> {
        return this.httpClient.put<any>(ConstantsModule.API_HOST + ConstantsModule.API_ORGANIZATION_PREFIX + ConstantsModule.API_PREFIX + '/' + vatnumber + '/framework/update', plant, this.httpOptionsWithParams);
    }

    insertOrganizationPlant(vatnumber: string, plant: OrganizationPlantItem) : Observable<any> {
        return this.httpClient.post<any>(ConstantsModule.API_HOST + ConstantsModule.API_ORGANIZATION_PREFIX + ConstantsModule.API_PREFIX + '/' + vatnumber + '/framework/create', plant, this.httpOptionsWithParams);
    }

    updateUser(user:OrganizationOperatorUpdateItem) {
        return this.httpClient.put<any>(ConstantsModule.API_HOST + ConstantsModule.API_USER_PREFIX + ConstantsModule.API_PREFIX + '/update', user, this.httpOptionsWithParams);
    }

    createUser(vatnumber: string, user:OrganizationOperatorCreateItem) {
        return this.httpClient.post<any>(ConstantsModule.API_HOST + ConstantsModule.API_USER_PREFIX + ConstantsModule.API_PREFIX + '/' + vatnumber + '/organization/operator/create', user, this.httpOptionsWithParams);
    }

    creatAdmin(vatnumber: string, user:OrganizationOperatorCreateItem) {
        return this.httpClient.post<any>(ConstantsModule.API_HOST + ConstantsModule.API_ORGANIZATION_PREFIX + ConstantsModule.API_PREFIX + '/' + vatnumber + '/plasticfinder/create', user, this.httpOptionsWithParams);
    }

    createAdminUser(vatnumber: string, user:OrganizationOperatorCreateItem) {
        return this.httpClient.post<any>(ConstantsModule.API_HOST + ConstantsModule.API_USER_PREFIX + ConstantsModule.API_PREFIX + '/' + vatnumber + '/plasticfinder/create', user, this.httpOptionsWithParams);
    }

    createCoripetUser(vatnumber: string, user:OrganizationOperatorCreateItem) {
        return this.httpClient.post<any>(ConstantsModule.API_HOST + ConstantsModule.API_USER_PREFIX + ConstantsModule.API_PREFIX + '/' + vatnumber + '/operator/create', user, this.httpOptionsWithParams);
    }

    disableUser(email) {
        return this.httpClient.get<any>(ConstantsModule.API_HOST + ConstantsModule.API_USER_PREFIX + ConstantsModule.API_PREFIX + '/disable/' + email, this.httpOptionsWithParams);
    }

    enableUser(email) {
        return this.httpClient.get<any>(ConstantsModule.API_HOST + ConstantsModule.API_USER_PREFIX + ConstantsModule.API_PREFIX + '/enable/' + email, this.httpOptionsWithParams);
    }

    deleteUser(email) {
        return this.httpClient.delete<any>(ConstantsModule.API_HOST + ConstantsModule.API_USER_PREFIX + ConstantsModule.API_PREFIX + '/delete/' + email, this.httpOptionsWithParams);
    }

    deleteFramework(vatnumber: string, frameworkId: string) {
        return this.httpClient.delete<any>(ConstantsModule.API_HOST + ConstantsModule.API_ORGANIZATION_PREFIX + ConstantsModule.API_PREFIX + '/framework/' + vatnumber + '/' + frameworkId + '/delete', this.httpOptionsWithParams);
    }

    updateOrganization(orgname: string, org:OrganizationUpdateItem) {
        return this.httpClient.put<any>(ConstantsModule.API_HOST + ConstantsModule.API_ORGANIZATION_PREFIX + ConstantsModule.API_PREFIX + '/' + orgname +'/update', org, this.httpOptionsWithParams);
    }

    updateOrganizationDocuments(orgname: string, org:OrganizationDocumentsUpdateItem) {
        return this.httpClient.put<any>(ConstantsModule.API_HOST + ConstantsModule.API_ORGANIZATION_PREFIX + ConstantsModule.API_PREFIX + '/' + orgname +'/docs/update', org, this.httpOptionsWithParams);
    }

    getUserDetail(userId: number) {
        return this.httpClient.get<any>(ConstantsModule.API_HOST + ConstantsModule.API_USER_PREFIX + ConstantsModule.API_PREFIX + '/id/' + userId, this.httpOptionsWithParams);
    }

    getActivities() {
        return this.httpClient.get<any>(ConstantsModule.API_HOST + ConstantsModule.API_USER_PREFIX + ConstantsModule.API_PREFIX + '/operators/tasks', this.httpOptionsWithParams);
    }

    approveDocument(vatnumber: string, docname: string) {
        let urlParam = this.getApproveUrlParam(docname);
        return this.httpClient.put<any>(ConstantsModule.API_HOST + ConstantsModule.API_ORGANIZATION_PREFIX + ConstantsModule.API_PREFIX + '/' + vatnumber + urlParam, undefined, this.httpOptionsWithParams);
    }

    rejectDocument(vatnumber: string, docname: string) {
        let urlParam = this.getRejectUrlParam(docname);
        return this.httpClient.put<any>(ConstantsModule.API_HOST + ConstantsModule.API_ORGANIZATION_PREFIX + ConstantsModule.API_PREFIX + '/' + vatnumber + urlParam, undefined, this.httpOptionsWithParams);
    }

    approveDocumentFramework(vatnumber: string, docname: string, frameworkId: number) {
        let urlParam = this.getApproveUrlParam(docname);
        console.log('urlParam: ' + urlParam);
        return this.httpClient.put<any>(ConstantsModule.API_HOST + ConstantsModule.API_ORGANIZATION_PREFIX + ConstantsModule.API_PREFIX + '/' + vatnumber + '/framework/' + frameworkId + urlParam, undefined, this.httpOptionsWithParams);
    }

    rejectDocumentFramework(vatnumber: string, docname: string, frameworkId: number) {
        let urlParam = this.getRejectUrlParam(docname);
        return this.httpClient.put<any>(ConstantsModule.API_HOST + ConstantsModule.API_ORGANIZATION_PREFIX + ConstantsModule.API_PREFIX + '/' + vatnumber + '/framework/' + frameworkId + urlParam, undefined, this.httpOptionsWithParams);
    }

    getApproveUrlParam(docname: string) {
        let urlParam = '/';
        if (docname == ConstantsModule.DOC_VISURA) {
            urlParam += ConstantsModule.DOC_VISURA_APPROVE;
        } else if (docname == ConstantsModule.DOC_FIDEIUSSIONE) {
            urlParam += ConstantsModule.DOC_FIDEIUSSIONE_APPROVE;
        } else if (docname == ConstantsModule.DOC_LEGALE) {
            urlParam += ConstantsModule.DOC_LEGALE_APPROVE;
        } else if (docname == ConstantsModule.DOC_231) {
            urlParam += ConstantsModule.DOC_231_APPROVE;
        } else if (docname == ConstantsModule.DOC_FRAMEWORK_9001) {
            urlParam += ConstantsModule.DOC_FRAMEWORK_9001_APPROVE;
        } else if (docname == ConstantsModule.DOC_FRAMEWORK_14001) {
            urlParam += ConstantsModule.DOC_FRAMEWORK_14001_APPROVE;
        } else if (docname == ConstantsModule.DOC_FRAMEWORK_DOCAUTH) {
            urlParam += ConstantsModule.DOC_FRAMEWORK_DOCAUTH_APPROVE;
        }
        return urlParam;
    }

    getRejectUrlParam(docname: string) {
        let urlParam = '/';
        if (docname == ConstantsModule.DOC_VISURA) {
            urlParam += ConstantsModule.DOC_VISURA_REJECT;
        } else if (docname == ConstantsModule.DOC_FIDEIUSSIONE) {
            urlParam += ConstantsModule.DOC_FIDEIUSSIONE_REJECT;
        } else if (docname == ConstantsModule.DOC_LEGALE) {
            urlParam += ConstantsModule.DOC_LEGALE_REJECT;
        } else if (docname == ConstantsModule.DOC_231) {
            urlParam += ConstantsModule.DOC_231_REJECT;
        } else if (docname == ConstantsModule.DOC_FRAMEWORK_9001) {
            urlParam += ConstantsModule.DOC_FRAMEWORK_9001_REJECT;
        } else if (docname == ConstantsModule.DOC_FRAMEWORK_14001) {
            urlParam += ConstantsModule.DOC_FRAMEWORK_14001_REJECT;
        } else if (docname == ConstantsModule.DOC_FRAMEWORK_DOCAUTH) {
            urlParam += ConstantsModule.DOC_FRAMEWORK_DOCAUTH_REJECT;
        }
        return urlParam;
    }

    approveOrganization(orgname: string) {
        return this.httpClient.put<any>(ConstantsModule.API_HOST + ConstantsModule.API_ORGANIZATION_PREFIX + ConstantsModule.API_PREFIX + '/' + orgname +'/validate', undefined, this.httpOptionsWithParams);
    }

    rejectOrganization(orgname: string) {
        return this.httpClient.put<any>(ConstantsModule.API_HOST + ConstantsModule.API_ORGANIZATION_PREFIX + ConstantsModule.API_PREFIX + '/' + orgname +'/reject', undefined, this.httpOptionsWithParams);
    }

    validateOrganizationPlant(vatnumber: string, frameworkId: number) : Observable<any> {
        return this.httpClient.put<any>(ConstantsModule.API_HOST + ConstantsModule.API_ORGANIZATION_PREFIX + ConstantsModule.API_PREFIX + '/' + vatnumber + '/framework/' + frameworkId + '/validate', undefined, this.httpOptionsWithParams);
    }

    rejectOrganizationPlant(vatnumber: string, frameworkId: number) : Observable<any> {
        return this.httpClient.put<any>(ConstantsModule.API_HOST + ConstantsModule.API_ORGANIZATION_PREFIX + ConstantsModule.API_PREFIX + '/' + vatnumber + '/framework/' + frameworkId + '/reject', undefined, this.httpOptionsWithParams);
    }

    updateOperator(user:OrganizationOperatorUpdateItem) {
        return this.httpClient.put<any>(ConstantsModule.API_HOST + ConstantsModule.API_USER_PREFIX + ConstantsModule.API_PREFIX + '/update', user, this.httpOptionsWithParams);
    }

    getAuctionUserSetup(codiceAsta: string) {
        return this.httpClient.get<any>(ConstantsModule.API_HOST + ConstantsModule.API_UTIL_PREFIX + ConstantsModule.API_PREFIX + '/auction/user/' + codiceAsta + '/setup', this.httpOptionsWithParams);
    }

    getAdminInfo() {
        return this.httpClient.get<any>(ConstantsModule.API_HOST + ConstantsModule.API_UTIL_PREFIX + ConstantsModule.API_PREFIX + '/plasticfinder/info', this.httpOptionsWithParams);
    }

    getCoripetInfo() {
        return this.httpClient.get<any>(ConstantsModule.API_HOST + ConstantsModule.API_UTIL_PREFIX + ConstantsModule.API_PREFIX + '/sysoperator/info', this.httpOptionsWithParams);
    }

}