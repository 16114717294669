import { Location } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ConfirmComponent } from 'src/app/commons/components/confirm/confirm.component';
import { FileWithContentUpload, NazioneItem, OrganizationPlantItem, ProvinciaItem } from 'src/app/models/dataModels';
import { APIService } from 'src/app/services/APIService';
import { AuthService } from 'src/app/services/auth.service';
import { CommonService } from 'src/app/services/common.service';
import { ConstantsModule } from 'src/app/services/constants.module';
import { LocalUtilService } from 'src/app/services/local-util.service';
import { MessagesService } from 'src/app/services/messages.service';
import { OrganizationService } from 'src/app/services/organization.service';

@Component({
  selector: 'app-azienda-impianti',
  templateUrl: './azienda-impianti.component.html',
  styleUrls: ['./azienda-impianti.component.css']
})
export class AziendaImpiantiComponent extends APIService implements OnInit {

  plants_list: OrganizationPlantItem[]=[];
  plant: OrganizationPlantItem = new OrganizationPlantItem();
  plantForm: FormGroup;
  submitted: boolean = false;
  nation_list: NazioneItem[] = [];
  province_list: ProvinciaItem[] = [];
  iso9001: FileWithContentUpload;
  iso14001: FileWithContentUpload;
  docAuth: FileWithContentUpload;
  flag9001: boolean;
  flag14001: boolean;
  flagDocAuth: boolean;
  vatnumber: string;

  constructor(public localService: LocalUtilService,
    public authService: AuthService,
    public organizationService: OrganizationService,
    private modalService: NgbModal,
    private messageService: MessagesService,
    location: Location, 
    router: Router,
    public commonService: CommonService) {
    super(localService, location, router);
    
    this.insertDocImpianto = this.insertDocImpianto.bind(this);
    this.initializeForm();
    console.log(this.plant);
    console.log('end constructor');
  }

  checkError(prop: string) {
    let output: boolean = !this.plantForm.get(prop).valid && this.submitted;
    //console.log('prop: ' + prop  + ' valid: ' + this.plantForm.get(prop).valid + ' output: ' + output);
    return output;
  }

  getIso9001File(file) {
    if (file != undefined) {
      console.log('getIso9001File() loaded file: ' + file.name);
    }else {
      console.log('getIso9001File() unloaded file!');
    }
    this.iso9001 = new FileWithContentUpload();
    this.iso9001.file = file;
    this.plantForm.get('certificazioneIso9001').reset({ value: '' });
  }

  getIso14001File(file) {
    if (file != undefined) {
      console.log('getIso14001File() loaded file: ' + file.name);
    }else {
      console.log('getIso14001File() unloaded file!');
    }
    this.iso14001 = new FileWithContentUpload();
    this.iso14001.file = file;
    this.plantForm.get('certificazioneIso14001').reset({ value: '' });
  }

  getDocAuthFile(file) {
    if (file != undefined) {
      console.log('getDocAuthFile() loaded file: ' + file.name);
    }else {
      console.log('getDocAuthFile() unloaded file!');
    }
    this.docAuth = new FileWithContentUpload();
    this.docAuth.file = file;
    this.plantForm.get('docAutorizzazione').reset({ value: '' });
  }
  

  initializeForm() {
    this.plantForm = new FormGroup({
      id: new FormControl(this.plant.id, []),
      createDate: new FormControl(this.plant.createDate, []),
      nomeImpianto: new FormControl(this.plant.nomeImpianto, [Validators.required]),
      nazionalita: new FormControl(this.plant.nazionalita, [Validators.required]),
      provincia: new FormControl(this.plant.provincia, []),
      citta: new FormControl(this.plant.citta, [Validators.required]),
      cap: new FormControl(this.plant.cap, [Validators.required]),
      indirizzo: new FormControl(this.plant.indirizzo, [Validators.required]),
      capacitaProduttivaAnnua: new FormControl(this.plant.capacitaProduttivaAnnua, [Validators.required]),
      pec: new FormControl(this.plant.pec, [Validators.required, Validators.email]),
      certificazioneImpiantoValid: new FormControl(this.plant.certificazioneImpiantoValid, []),
      certificazioneIso9001: new FormControl(this.plant.certificazioneIso9001, [Validators.required]),
      certificazioneIso9001Valid: new FormControl(this.plant.certificazioneIso9001Valid, []),
      certificazioneIso14001: new FormControl(this.plant.certificazioneIso14001, [Validators.required]),
      certificazioneIso14001Valid: new FormControl(this.plant.certificazioneIso14001Valid, []),
      docAutorizzazione: new FormControl(this.plant.docAutorizzazione, [Validators.required]),
      docAutorizzazioneValid: new FormControl(this.plant.docAutorizzazioneValid, []),
      nomeRifSpedizione: new FormControl(this.plant.nomeRifSpedizione, []),
      telefonoRifSpedizione: new FormControl(this.plant.telefonoRifSpedizione, []),
      mobileRifSpedizione: new FormControl(this.plant.mobileRifSpedizione, []),
      noteTrasportatore: new FormControl(this.plant.noteTrasportatore, []),
      emailRifSpedizione: new FormControl(this.plant.emailRifSpedizione, []),
    });
  }

  ngOnInit(): void {
    console.log('init()');
    if (this.isUser()) {
      this.vatnumber = this.localUtil.authParams.organization_detail.orgname;
      console.log('vatnumber set to ' + this.vatnumber + ' for isUser()');
    }
    this.loadNazioni();
    this.loadProvince();
    this.listFrameworks();
  }

  loadNazioni() {
    this.commonService.getNationsList().subscribe(
        data => {
          let nazioneVuota = new NazioneItem();
          nazioneVuota.codice="";
          nazioneVuota.descrizione=" - ";          
          this.nation_list.push(nazioneVuota);
          this.nation_list.push.apply(this.nation_list, data.list);
      },
      this.handleError(true));
  }

  loadProvince() {
    this.province_list = [];
    this.commonService.getProvinceItalianeList().subscribe(
        data => {
          let provinciaVuota = new ProvinciaItem();
          provinciaVuota.codice="";
          provinciaVuota.descrizione=" - ";          
          this.province_list.push(provinciaVuota);
          this.province_list.push.apply(this.province_list, data.list);
      },
      this.handleError(true));
  }

  isNewEditing() {
    return (this.plant.newEditing == true);
  }

  listFrameworks() {
      
    let list_to_add: OrganizationPlantItem[] = [];
    this.organizationService.getOrganizationPlants(this.localUtil.authParams.organization_detail.orgname).subscribe(
      (data) => {
        console.log('setting plant list: ' + data.list.length);
        if (data.list) {
          data.list.forEach((itemImpianto, index) => {
            
                if (itemImpianto.certificazioneImpiantoValid == "") {
                  itemImpianto.statusClass = "pending";
                  itemImpianto.status = "In attesa di verifica";
                } else if (itemImpianto.certificazioneImpiantoValid == "N") {
                  itemImpianto.statusClass = "disabled";
                  itemImpianto.status = "Rifiutato";
                } else if (itemImpianto.certificazioneImpiantoValid == "Y") {
                  itemImpianto.statusClass = "active";
                  itemImpianto.status = "Attivo";
                }

                var iso9001 = true;
                if (itemImpianto.certificazioneIso9001 == "") {
                    iso9001 = false;
                    itemImpianto.statusDescr9001 = this.messageService.getLabelFromCode("DESCR_DOC_MANCANTE");
                    itemImpianto.fileClass9001 = "ko";
                } else {
                    iso9001 = true;
                    if (itemImpianto.certificazioneIso9001Valid == "Y") {
                        itemImpianto.statusDescr9001 = this.messageService.getLabelFromCode("DESCR_DOC_APPROVATO");
                        itemImpianto.fileClass9001 = "ok";
                    } else if (itemImpianto.certificazioneIso9001Valid == "") {
                        itemImpianto.statusDescr9001 = this.messageService.getLabelFromCode("DESCR_DOC_DA_APPROVARE");
                        itemImpianto.fileClass9001 = "pending";
                    } else if (itemImpianto.certificazioneIso9001Valid == "N") {
                        itemImpianto.statusDescr9001 = this.messageService.getLabelFromCode("DESCR_DOC_RIFIUTATO");
                        itemImpianto.fileClass9001 = "ko";
                    }
                }

                var iso14001 = true;
                if (itemImpianto.certificazioneIso14001 == "") {
                    iso14001 = false;
                    itemImpianto.statusDescr14001 = this.messageService.getLabelFromCode("DESCR_DOC_MANCANTE");
                    itemImpianto.fileClass14001 = "ko";
                } else {
                    iso14001 = true;
                    if (itemImpianto.certificazioneIso14001Valid == "Y") {
                        itemImpianto.statusDescr14001 = this.messageService.getLabelFromCode("DESCR_DOC_APPROVATO");
                        itemImpianto.fileClass14001 = "ok";
                    } else if (itemImpianto.certificazioneIso14001Valid == "") {
                        itemImpianto.statusDescr14001 = this.messageService.getLabelFromCode("DESCR_DOC_DA_APPROVARE");
                        itemImpianto.fileClass14001 = "pending";
                    } else if (itemImpianto.certificazioneIso14001Valid == "N") {
                        itemImpianto.statusDescr14001 = this.messageService.getLabelFromCode("DESCR_DOC_RIFIUTATO");
                        itemImpianto.fileClass14001 = "ko";
                    }
                }

                var docAuth = true;
                if (itemImpianto.docAutorizzazione == "") {
                    docAuth = false;
                    itemImpianto.statusDescrDocAuth = this.messageService.getLabelFromCode("DESCR_DOC_MANCANTE");
                    itemImpianto.fileClassDocAuth = "ko";
                } else {
                    docAuth = true;
                    if (itemImpianto.docAutorizzazioneValid == "Y") {
                        itemImpianto.statusDescrDocAuth = this.messageService.getLabelFromCode("DESCR_DOC_APPROVATO");
                        itemImpianto.fileClassDocAuth = "ok";
                    } else if (itemImpianto.docAutorizzazioneValid == "") {
                        itemImpianto.statusDescrDocAuth = this.messageService.getLabelFromCode("DESCR_DOC_DA_APPROVARE");
                        itemImpianto.fileClassDocAuth = "pending";
                    } else if (itemImpianto.docAutorizzazioneValid == "N") {
                        itemImpianto.statusDescrDocAuth = this.messageService.getLabelFromCode("DESCR_DOC_RIFIUTATO");
                        itemImpianto.fileClassDocAuth = "ko";
                    }
                }

                if (!iso9001 || !iso14001 || !docAuth) {
                    itemImpianto.statusDoc = this.messageService.getLabelFromCode("DESCR_DOC_DA_APPROVARE") + " o " + this.messageService.getLabelFromCode("DESCR_DOC_MANCANTI");
                } else {
                    itemImpianto.statusDoc = "";
                }

            list_to_add.push(itemImpianto);
          });

        }
        this.plants_list = list_to_add;
      },
      this.handleError(true));
  }

  toggleNuovoImpianto() {
    console.log('toggleNuovoImpianto()');
    this.annullaImpianto();
    this.plant.newEditing = !this.plant.newEditing;
    console.log('toggleNuovoImpianto() newEditing: ' + this.plant.newEditing);
    if (this.plant.newEditing) {
      this.cleanValidation();
    }
  }

  apriFormImpiantoDettaglio() {
    this.plant.newEditing = true;
    this.plant.editing = true;
  }

  cleanValidation() {
    this.plantForm.markAllAsTouched();
  }

  annullaImpianto() {
    console.log('annulla impianto this.plant');
    this.plant = new OrganizationPlantItem();
    this.plant.newEditing = false;
    this.submitted = false;
    this.newPlantId = undefined;
    this.initializeForm();
  }
  
  salvaImpianto() {
    
    if (this.validate()) {
      console.log('salvaImpianto() form valido');
      console.log(this.plant);
      
      if (this.plant != undefined && (this.plant.id != undefined && this.plant.id > 0)) {
        this.updateImpianto();
      } else {
        this.insertImpianto();
      }

    } else {
      this.localUtil.showMessage("", ConstantsModule.ERROR_TITLE, this.messageService.getLabelFromCode("ERROR_FORM_VALIDATION_MESSAGE"), true);
    }
  }

  newPlantId: number;

  insertImpianto() {
    
    //if (this.flag9001 && this.flag14001 && this.flagDocAuth) {
      console.log('insertImpianto() update this.plant: ');
      console.log(this.plant);
      this.organizationService.insertOrganizationPlant(this.localUtil.authParams.organization_detail.orgname, this.plant).subscribe(
        (data) => {
          this.localUtil.showLoader();
          this.newPlantId = data.detail.id;
          setTimeout(this.insertDocImpianto, 500);
      },
      this.handleError(true));
    //}

  }

  insertDocImpianto() {
    let id = this.newPlantId;
    console.log('insertDocImpianto()');
    this.flag9001 = true;
    this.flag14001 = true;
    this.flagDocAuth = true;    
    this.plant.id = id;
    //update
    if (this.iso9001 != undefined && this.iso9001.file != undefined) {
      this.flag9001 = false;
      this.iso9001.contentType = 'FWK';
      this.iso9001.vatnumber = this.localUtil.authParams.organization_detail.orgname;
      this.iso9001.frameworkId = id.toString();
      
      this.commonService.documentUpload(this.iso9001).subscribe(
        data => {
          data = JSON.parse(data);
          console.log('iso9001 uploaded! file_uuid: ' + data.file_uuid);
          console.log(data);
          this.plant.certificazioneIso9001 = data.file_uuid;
          this.flag9001 = true;
          this.salvaBeanImpianto();
        }, this.handleError(true));
    }

    if (this.iso14001 != undefined && this.iso14001.file != undefined) {
      this.flag14001 = false;
      this.iso14001.contentType = 'FWK';
      this.iso14001.vatnumber = this.localUtil.authParams.organization_detail.orgname;
      this.iso14001.frameworkId = id.toString();
      
      this.commonService.documentUpload(this.iso14001).subscribe(
        data => {
          data = JSON.parse(data);
          console.log('iso14001 uploaded! file_uuid: ' + data.file_uuid);
          console.log(data);
          this.plant.certificazioneIso14001 = data.file_uuid;
          this.flag14001 = true;
          this.salvaBeanImpianto();
        }, this.handleError(true));
    }

    if (this.docAuth != undefined && this.docAuth.file != undefined) {
      this.flagDocAuth = false;
      this.docAuth.contentType = 'FWK';
      this.docAuth.vatnumber = this.localUtil.authParams.organization_detail.orgname;
      this.docAuth.frameworkId = id.toString();
      
      this.commonService.documentUpload(this.docAuth).subscribe(
        data => {
          data = JSON.parse(data);
          console.log('docAuth uploaded! file_uuid: ' + data.file_uuid);
          console.log(data);
          this.plant.docAutorizzazione = data.file_uuid;
          this.flagDocAuth = true;
          this.salvaBeanImpianto();
        }, this.handleError(true));
    }

    this.salvaBeanImpianto();

  }

  updateImpianto() {
    console.log('updateImpianto()');
    this.flag9001 = true;
    this.flag14001 = true;
    this.flagDocAuth = true;    
    //update
    if (this.iso9001 != undefined && this.iso9001.file != undefined) {
      this.flag9001 = false;
      this.iso9001.contentType = 'FWK';
      this.iso9001.vatnumber = this.localUtil.authParams.organization_detail.orgname;
      this.iso9001.frameworkId = this.plant.id.toString();
      
      this.commonService.documentUpload(this.iso9001).subscribe(
        data => {
          data = JSON.parse(data);
          console.log('iso9001 uploaded! file_uuid: ' + data.file_uuid);
          console.log(data);
          this.plant.certificazioneIso9001 = data.file_uuid;
          this.flag9001 = true;
          this.salvaBeanImpianto();
        }, this.handleError(true));
    }

    if (this.iso14001 != undefined && this.iso14001.file != undefined) {
      this.flag14001 = false;
      this.iso14001.contentType = 'FWK';
      this.iso14001.vatnumber = this.localUtil.authParams.organization_detail.orgname;
      this.iso14001.frameworkId = this.plant.id.toString();
      
      this.commonService.documentUpload(this.iso14001).subscribe(
        data => {
          data = JSON.parse(data);
          console.log('iso14001 uploaded! file_uuid: ' + data.file_uuid);
          console.log(data);
          this.plant.certificazioneIso14001 = data.file_uuid;
          this.flag14001 = true;
          this.salvaBeanImpianto();
        }, this.handleError(true));
    }

    if (this.docAuth != undefined && this.docAuth.file != undefined) {
      this.flagDocAuth = false;
      this.docAuth.contentType = 'FWK';
      this.docAuth.vatnumber = this.localUtil.authParams.organization_detail.orgname;
      this.docAuth.frameworkId = this.plant.id.toString();
      
      this.commonService.documentUpload(this.docAuth).subscribe(
        data => {
          data = JSON.parse(data);
          console.log('docAuth uploaded! file_uuid: ' + data.file_uuid);
          console.log(data);
          this.plant.docAutorizzazione = data.file_uuid;
          this.flagDocAuth = true;
          this.salvaBeanImpianto();
        }, this.handleError(true));
    }

    this.salvaBeanImpianto();
  }

  salvaBeanImpianto() {
    
   if (this.flag9001 && this.flag14001 && this.flagDocAuth) {
        console.log('salvaBeanImpianto() insert this.plant: ');
        console.log(this.plant);
        this.organizationService.updateOrganizationPlant(this.localUtil.authParams.organization_detail.orgname, this.plant).subscribe(
          (data) => {
            this.localUtil.showMessage("", ConstantsModule.INFO_TITLE, this.messageService.getLabelFromCode("SUCCESS_FRAMEWORK_UPDATE"), true);
            this.annullaImpianto();
            this.listFrameworks();
        },
        this.handleError(true));
    }

  }

  validate() {
    this.submitted = true;
    let output = true;
    console.log(this.plantForm);
    Object.keys(this.plantForm.controls).forEach(key => {
      console.log(key + ' ' + this.plantForm.get(key).value);
      if (!this.plantForm.get(key).valid) {
        output = false;
        console.log(key + ' ' + this.plantForm.get(key).value + ' not valid');
      } else {
        this.plant[key] = this.plantForm.get(key).value;
      }
    });
    /*if (output) {
      this.prepareBean(this.plant, this.plantForm);
    }*/
    return output;
  }

  /*prepareBean(plant: OrganizationPlantItem, plantForm: FormGroup ) {
  }*/

  getControlErrorMessage(name) {
    let output = '';
    console.log(this.plantForm.controls[name].errors);
    let errors = this.plantForm.controls[name].errors;
    output = errors['message'];
    return output;
  }

  validateField(nome) {
    return this.plantForm.get(nome).valid;
  }

  controlHasError(nome) {
    if (nome != 'provincia') {
      return !this.plantForm.get(nome).valid && this.submitted;
    } else {
      if (this.plantForm.get('nazionalita').value == 'IT' && this.plantForm.get(nome).value != '' && this.submitted) {
        //console.log('controlHasError() provincia if1 provincia: '+ this.plantForm.get(nome).value);
        return false;
      } else if (this.plantForm.get('nazionalita').value == 'IT' && this.plantForm.get(nome).value == '' && this.submitted) {
        //console.log('controlHasError() provincia if2 provincia: '+ this.plantForm.get(nome).value);
        return true;
      } else if (this.plantForm.get('nazionalita').value != 'IT' && this.submitted) {
        //console.log('controlHasError() provincia if3');
        return false;
      } else {
        //console.log('controlHasError() provincia else');
        return false;
      }
    }
  }

  onRowPrepared(info) {
    if(info.rowType === 'data') {  
      console.log('onRowPrepared() info.rowElement: ' + info.rowElement);
    }
  }

  onContentReady(event) {
    $('.dx-datagrid-headers').hide();
    $('.dx-hidden').hide();
    //$('.dx-datagrid-nodata').html('<h3>Nessun risultato trovato</h3>');
  }

  toggleDettaglio(data) {
    data.formOpened = !data.formOpened;
    if (data.formOpened) {
      this.disableAllEditForms();
      console.log(data);
      this.plant = data;
      console.log('plant: ');
      console.log(this.plant);
      data.formOpened = true;
    }
  }

  openDettaglio(data) {
    data.formOpened = true;
    console.log(data);
    this.plant = data;
  }

  closeDettaglio(data) {
    console.log(data);
    data.formOpened = false;
  }

  isFormOpened(data) {
    return (data.formOpened == true);
  }

  toggleMenu(data) {
    console.log(data);
    data.menuOpened = !data.menuOpened;
  }

  closeMenu(data) {
    data.menuOpened = false;
  }

  isMenuOpened(data) {
    return (data.menuOpened == true);
  }

  modificaImpianto(data) {
    data.editing = !data.editing;
    if (data.editing) {
      this.disableAllEditForms();
      data.editing = true;
    }
    console.log(data);
    this.plant = data;    
    this.initializeForm();
    this.apriFormImpiantoDettaglio();
    this.closeMenu(data);
    this.localService.scrollTop();
  }

  disableAllEditForms() {
    if (this.plants_list != undefined) {
      this.plants_list.forEach( (operator:OrganizationPlantItem) => {
        operator.editing = false;
        operator.formOpened = false;
        operator.menuOpened = false;
      });
    }
  }

  annullaModificaImpianto(data) {
    data.editing = !data.editing;
    console.log('annulla modifica this.plant');
    this.plant = new OrganizationPlantItem();
    this.closeDettaglio(data);
    this.toggleMenu(data);
  }

  isEditing(data) {
    //console.log('isEditing() ' + (data.editing == true));
    return (data.editing == true);
  }

  aggiornaImpianto(data) {
    
    this.organizationService.updateUser(data).subscribe(
      (res) => {
        this.localUtil.showMessage("", ConstantsModule.INFO_TITLE, this.messageService.getLabelFromCode("GENERIC_SUCCESS_MESSAGE_SAVE"), true);
        this.listFrameworks();
      },
      this.handleError(true)
    );

  }

  disabilitaImpianto(data) {

    console.log(data);

    this.organizationService.disableUser(data.email).subscribe(
      (res) => {
        this.localUtil.showMessage("", ConstantsModule.INFO_TITLE, this.messageService.getLabelFromCode("GENERIC_SUCCESS_MESSAGE_SAVE"), true);
        this.listFrameworks();
      },
      this.handleError(true)
    );

  }

  abilitaImpianto(data) {

    console.log(data);

    this.organizationService.enableUser(data.email).subscribe(
      (res) => {
        this.localUtil.showMessage("", ConstantsModule.INFO_TITLE, this.messageService.getLabelFromCode("GENERIC_SUCCESS_MESSAGE_SAVE"), true);
        this.listFrameworks();
      },
      this.handleError(true)
    );

  }

  eliminaImpianto(data) {

    let params:any[] = [data.nomeImpianto];
    const modalRef = this.modalService.open(ConfirmComponent);
    modalRef.componentInstance.titolo = this.messageService.getLabelFromCode("DELETE_FRAMEWORK_TITLE");
    modalRef.componentInstance.messaggio = this.messageService.getLabelFromCode("DELETE_FRAMEWORK_MESSAGE", params);
    modalRef.componentInstance.passEntry.subscribe((receivedEntry:any) => {
      if (receivedEntry != undefined) {

        //let confirm = receivedEntry.trim();// non serve nessun parametro di ritorno da popup confirm

        this.organizationService.deleteFramework(this.localUtil.authParams.organization_detail.orgname, data.id).subscribe(
          (res) => {
            this.localUtil.showMessage("", ConstantsModule.INFO_TITLE, this.messageService.getLabelFromCode("SUCCESS_FRAMEWORK_DELETE"), true);
            this.listFrameworks();
          },
          this.handleError(true)
        );

      }
    });


  }

}

