import { Component, OnInit } from '@angular/core';
import { LocalUtilService } from 'src/app/services/local-util.service';

@Component({
  selector: 'app-loader',
  templateUrl: './loader.component.html',
  styleUrls: ['./loader.component.css']
})
export class LoaderComponent implements OnInit {

  constructor(public localUtil: LocalUtilService) { }

  loaderVisible: boolean = false;
  ngOnInit(): void {

    this.localUtil.loaderMonitor.subscribe(
      data => {
        //console.log('loadercomponent loaderMonitor data: ' + data);
        if (data && data == true) {
          this.showLoader();
        } else {
          this.hideLoader();
        }
      });

  }
  
  showLoader() {
    this.loaderVisible = true;
  }

  hideLoader() {
    this.loaderVisible = false;
  }

}
