import { Location } from '@angular/common';
import { Component, OnInit, ViewChild } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { DxDataGridComponent } from 'devextreme-angular';
import dxTextBox from 'devextreme/ui/text_box';
import { ConfirmComponent } from 'src/app/commons/components/confirm/confirm.component';
import { CodiceDescrizioneItem, OrganizationItem, OrganizationOperatorCreateItem, OrganizationOperatorItem, OrganizationOperatorUpdateItem, UserItem, UserOperatorItem } from 'src/app/models/dataModels';
import { APIService } from 'src/app/services/APIService';
import { AuthService } from 'src/app/services/auth.service';
import { ConstantsModule } from 'src/app/services/constants.module';
import { LocalUtilService } from 'src/app/services/local-util.service';
import { MessagesService } from 'src/app/services/messages.service';
import { OrganizationService } from 'src/app/services/organization.service';

@Component({
  selector: 'app-admin-operatori',
  templateUrl: './operatori.component.html',
  styleUrls: ['./operatori.component.css']
})
export class AdminOperatoriComponent extends APIService implements OnInit {
  
  constructor(public localService: LocalUtilService,
    public authService: AuthService,
    public organizationService: OrganizationService,
    private modalService: NgbModal,
    private messageService: MessagesService,
    location: Location, 
    router: Router) {
    super(localService, location, router);
  }

  ngOnInit(): void {

  }

}