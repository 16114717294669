import { Location } from '@angular/common';
import { Component, OnInit, ViewChild } from '@angular/core';
import { Route, Router, Routes } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { DxDataGridComponent } from 'devextreme-angular';
import { ActivityItem, AuctionItem, OrganizationItem, UserItem } from 'src/app/models/dataModels';
import { APIService } from 'src/app/services/APIService';
import { AuctionService } from 'src/app/services/auction.service';
import { AuthService } from 'src/app/services/auth.service';
import { ConstantsModule } from 'src/app/services/constants.module';
import { LocalUtilService } from 'src/app/services/local-util.service';
import { MessagesService } from 'src/app/services/messages.service';
import { OrganizationService } from 'src/app/services/organization.service';

@Component({
  selector: 'app-coripet-aziende',
  templateUrl: './aziende.component.html',
  styleUrls: ['./aziende.component.css']
})
export class CoripetAziendeComponent extends APIService implements OnInit {

  constructor(public localService: LocalUtilService,
      public authService: AuthService,
      public auctionService: AuctionService,
      public organizationService: OrganizationService,
      private modalService: NgbModal,
      private messageService: MessagesService,
      location: Location, 
      router: Router) {
      super(localService, location, router);
  
    }
    
    ngOnInit() {
      console.log("inside cori")

    }
  
}