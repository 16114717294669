<div *ngIf="externalLink == undefined">
    <div class="upload-area uploaded {{dragAreaClass}}" [ngClass]="{'error': isError()}" draggable="true">
        <div class="box__input circle">
            <div class="file-icon">
                <i *ngIf="!draggedFiles" class="fas fa-cloud-upload"></i>
                <i *ngIf="draggedFiles" class="far fa-trash-alt pointer delete-file" title="Cancella file" (click)="cancelFiles()"></i>
            </div>
            <div class="col-md-12 text-center file-ontop">
                <div *ngIf="showCoripetButtons">
                    <span *ngIf="!draggedFiles && !isCoripet()" class="dragndrop">Trascina il file o</span>
                    <a *ngIf="!draggedFiles && !isCoripet()" (click)="file.click()" class="box__dragndrop">
                        selezionalo dal tuo computer.
                    </a>
                    <div class="file-caricato">
                        <br/>
                        <label *ngIf="draggedFiles || (uuid != undefined && uuid != '')">
                            File caricato
                        </label>
                        <div *ngIf="draggedFiles">({{draggedFiles[0].name}})</div>
                        <div *ngIf="uuid != undefined && !draggedFiles && uuid != undefined && uuid != ''">
                            <div class="download-file a1" (click)="downloadFile(uuid, docname)" data="report" title="{{docname}}">
                                <div class="filename-report">{{docname}}</div>
                            </div>
                        </div>
                    </div>
                    <input type="file"
                        #file
                        [multiple]="false"
                        (change)="onFileChange($event)"
                        style="display:none" />
                </div>
                <div *ngIf="!showCoripetButtons">
                    <div class="file-caricato">
                        <span *ngIf="!draggedFiles" class="dragndrop">Trascina il file o</span>
                        <a *ngIf="!draggedFiles" (click)="file.click()" class="box__dragndrop">
                            selezionalo dal tuo computer.
                        </a>
                        <br/>
                        <label *ngIf="draggedFiles || (uuid != undefined && uuid != '')">
                            File caricato
                        </label>
                        <div *ngIf="draggedFiles && draggedFiles[0]">({{draggedFiles[0].name}})</div>
                        <div *ngIf="uuid != undefined && !draggedFiles && uuid != undefined && uuid != ''">
                            <div class="download-file a2" (click)="downloadFile(uuid, docname)" data="report" title="{{docname}}">
                                <div class="filename-report">{{docname}}</div>
                            </div>
                        </div>
                    </div>
                    <input type="file"
                        #file
                        [multiple]="false"
                        (change)="onFileChange($event)"
                        style="display:none" />
                </div>
            </div>            
        </div>
        <div class="download-file a3 {{statusClass}} {{statusLabel}}">
            <span class="width100" translate>aziende-dettaglio.{{statusLabel}}</span>
        </div>
        <div class="actions operator-actions " [ngClass]="{'top20': isCoripet()}">
            <div *ngIf="isCoripet() && showCoripetButtons">
                <button  class="btn success btn-approve-document" (click)="approveFile()"><i class="fal fa-check"></i>
                    <span translate>aziende-dettaglio.approva-documento</span>
                </button>
                <button class="btn error btn-reject-document" (click)="rejectFile()"><i class="fal fa-times"></i>
                    <span translate>aziende-dettaglio.rifiuta-documento
                    </span>
                    
                </button>
            </div>
        </div>
    </div>
</div>
<div *ngIf="externalLink != undefined">
    <div class="upload-area uploaded">
        <div class="box__input circle">
            <div class="file-icon">
                <i class="fas fa-file-pdf"></i>
            </div>  
            <div class="col-md-12 text-center file-ontop">
                <div class="file-caricato">
                    <label *ngIf="draggedFiles || (uuid != undefined && uuid != '')">
                        File caricato
                    </label>
                    <div *ngIf="uuid != undefined && !draggedFiles && uuid != undefined && uuid != ''">
                        <div class="download-file a4" (click)="downloadFile(uuid, docname)" data="report" title="{{docname}}"><div class="filename-report">{{docname}}</div></div>
                    </div>
                </div>
                <input type="file"
                    #file
                    [multiple]="false"
                    (change)="onFileChange($event)"
                    style="display:none" />
            </div>
        </div>
        <div class="download-file a5 {{statusClass}} {{statusLabel}}">
            <span class="width100" translate>aziende-dettaglio.{{statusLabel}}</span>
        </div>
        <div class="actions operator-actions " [ngClass]="{'top20': isCoripet()}">
            <a *ngIf="isUser()" routerLink="{{externalLink}}"> 
                <span translate>aziende-dettaglio.carica/sostituisci</span>
            </a>
            <div *ngIf="isCoripet() && showCoripetButtons">
                <button  class="btn warning btn-approve-document" (click)="goToExternalLink()" data="visura" data-uuid="ed7e8a99-7969-af50-6862-556cc80cec18" data-action-operation="validate_visura"><i class="fal fa-load"></i>
                    <span translate>aziende-dettaglio.carica/sostituisci</span>
                </button>
                <button  class="btn success btn-approve-document" (click)="approveDocument()"><i class="fal fa-check"></i>
                    <span translate>aziende-dettaglio.approva-documento</span> 
                </button>
                <button class="btn error btn-reject-document" (click)="rejectDocument()"><i class="fal fa-times"></i>
                    <span translate>aziende-dettaglio.rifiuta-documento</span> 
                </button>
            </div>
        </div>
    </div>
</div>