<div class="content auction-details" *ngIf="auction"> <!-- ngIf="isActiveComponent()"-->           
    <h2><span class="status completed" style="font-size: 32px;">Asta {{auction.statoAsta}}</span> {{auction.codiceAsta}} - {{auction.tipologiaAsta}}</h2>

    <div class="auctions-infos">
        L'asta si &egrave; conclusa il <b>{{auction.dataOraFineAsta}}</b><br/>
        <b>{{auction.numLotti}}</b> lotti disponibili.
    </div>

    <!-- <i class="fas fa-angle-left back-button" (click)="gotoListaAsteChiuse()"><span>Torna all'elenco aste</span></i> -->
    <div class="lots-list new-lots-list won" id='lots-list'>
        
        <dx-data-grid #dataGridLots
            id="gridContainer"
            [dataSource]="lot_list"
            keyExpr="id"
            [columnAutoWidth]="true"
            [showBorders]="false"
            (onExporting)="onExporting($event)"
            (onExported)="onExported($event)"            
            (onContentReady)="onContentReady($event)"            
            ><!--dataRowTemplate="dataRowTemplateName"-->

            <dxo-export [enabled]="true" fileName="auction_lots_list"></dxo-export>

            <dxo-load-panel [enabled]="true"></dxo-load-panel>
            <dxo-filter-row [visible]="false"></dxo-filter-row>
            
            <dxo-search-panel
                [visible]="false"
                [highlightCaseSensitive]="false">
            </dxo-search-panel>

            <!-- edit form and columns -->
            <dxi-column dataField="id" [allowEditing]="false" [width]="100" caption="Id" alignment="left" [visible]="false">
            </dxi-column>
            <dxi-column dataField="codiceLotto" caption="" dataType="string"  [allowExporting]="true" [visible]="true" cellTemplate="lotRowTemplate">
            </dxi-column>
            <dxi-column dataField="codiceAsta" caption="Codice asta" dataType="string"  [allowExporting]="true" showEditorAlways="true" [visible]="false">
            </dxi-column>
            <dxi-column dataField="dataCreazione" caption="Data creazione" [width]="150" [allowExporting]="true"  dataType="date" format="dd/MM/yyyy" showEditorAlways="true" [visible]="false">
            </dxi-column>
            <dxi-column dataField="dataModifica" caption="Data modifica" dataType="date"  [allowExporting]="true" format="dd/MM/yyyy" showEditorAlways="true" [visible]="false">
            </dxi-column>
            <dxi-column dataField="tipoProdotto" caption="Tipo prodotto" dataType="string" [allowExporting]="true"  [visible]="false" cellTemplate="lotRowTemplate"><!--cellTemplate="lotRowTemplate"-->
            </dxi-column>
            <dxi-column dataField="quantitaTon" caption="Quantità (ton)" dataType="number"  [allowExporting]="true" [visible]="false">                    
            </dxi-column>
            <dxi-column dataField="numeroRilanci" caption="Numero rilanci" dataType="number"  [allowExporting]="true" [visible]="false">                    
            </dxi-column>
            <dxi-column dataField="areaGeografica" caption="Area geografica" dataType="string" [allowExporting]="true"  [visible]="false">
            </dxi-column>
            <dxi-column dataField="note" caption="Note" dataType="string" [visible]="false" [allowExporting]="true" >
            </dxi-column>
            <dxi-column dataField="dataOraFineAstaEffettiva" caption="Chiusura asta" dataType="string" [allowExporting]="true"  [visible]="false">
            </dxi-column>
            <dxi-column dataField="dataDisponibilitaLotto" caption="Data disponibilità lotto" dataType="string"  [allowExporting]="true" [visible]="false">
            </dxi-column>
            <dxi-column dataField="prezzoBase" caption="Tipologia" dataType="number" [visible]="false" [allowExporting]="true" >
            </dxi-column>
            <dxi-column dataField="prezzoUltimoRilancio" caption="Prezzo finale" dataType="number"  [allowExporting]="true" [visible]="false">
            </dxi-column>

            <dxo-toolbar>
                <dxi-item name="exportButton"></dxi-item>
                <dxi-item location="after">
                    <div *dxTemplate>
                        <span class="titleXLS2line mt-2" translate>download-riepilogo-asta</span>
                    </div>
                </dxi-item>
            </dxo-toolbar>
            
            <div *dxTemplate="let lotDetail of 'lotRowTemplate'">
                <app-dettaglio-lotto [lotDetail]="lotDetail.data" [statoAsta]="auction.statoAsta"></app-dettaglio-lotto>
            </div>
            
        </dx-data-grid>

    </div>
</div>