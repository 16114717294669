import { Location } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { FileWithContentUpload, NazioneItem, OrganizationItem, OrganizationOperatorCreateItem, OrganizationOperatorUpdateItem, OrganizationPlantItem, OrganizationUpdateItem, ProvinciaItem, UserOrgItem } from 'src/app/models/dataModels';
import { APIService } from 'src/app/services/APIService';
import { AuctionService } from 'src/app/services/auction.service';
import { CommonService } from 'src/app/services/common.service';
import { ConstantsModule } from 'src/app/services/constants.module';
import { LocalUtilService } from 'src/app/services/local-util.service';
import { MessagesService } from 'src/app/services/messages.service';
import { OrganizationService } from 'src/app/services/organization.service';
import { PopupComponent } from '../popup/popup.component';

@Component({
  selector: 'app-anagrafica-azienda',
  templateUrl: './anagrafica-azienda.component.html',
  styleUrls: ['./anagrafica-azienda.component.css']
})
export class AnagraficaAziendaComponent extends APIService implements OnInit {

  constructor(localUtil: LocalUtilService,
    public auctionService: AuctionService,
    private modalService: NgbModal,
    private messageService: MessagesService,
    location: Location, 
    router: Router,
    private route: ActivatedRoute,
    public commonService: CommonService,
    public organizationService: OrganizationService) {
    super(localUtil, location, router);
    console.log('AnagraficaAziendaComponent()');
  }

  @Input() anagAzienda: OrganizationItem;
  @Input() anagUserAzienda: UserOrgItem;
  nation_list: NazioneItem[] = [];
  province_list: ProvinciaItem[] = [];
  @Input() showCoripetButtons: boolean = true;

  userForm: FormGroup;
  organizationForm: FormGroup;

  rappresentanteLegale: FileWithContentUpload;
  flagRappresentanteLegale: boolean;

  visuraCamerale: FileWithContentUpload;
  flagVisuraCamerale: boolean;

  fidejussioneBancaria: FileWithContentUpload;
  flagFidejussioneBancaria: boolean;

  dichiarazione231_2001: FileWithContentUpload;
  flagDichiarazione231_2001: boolean;

  submittedUser: boolean = false;
  submittedOrganization: boolean = false;

  ngOnInit(): void {
    console.log('anagAzienda: ');
    console.log(this.anagAzienda);
    console.log('anagUserAzienda: ');
    console.log(this.anagUserAzienda);
    this.loadNazioni();
    this.loadProvince();
    this.initializeForms();
  }

  reloadBeans() {
    let user = JSON.parse(sessionStorage.getItem('user'));
    this.anagAzienda = user.organization_detail;
    this.anagUserAzienda = user.user_detail;
  }

  loadNazioni() {
    this.commonService.getNationsList().subscribe(
        data => {
          let nazioneVuota = new NazioneItem();
          nazioneVuota.codice="";
          nazioneVuota.descrizione=" - ";          
          this.nation_list.push(nazioneVuota);
          this.nation_list.push.apply(this.nation_list, data.list);
      },
        this.handleError(true)
      );
  }

  loadProvince() {
    this.province_list = [];
    this.commonService.getProvinceItalianeList().subscribe(
        data => {
          let provinciaVuota = new ProvinciaItem();
          provinciaVuota.codice=" - ";
          provinciaVuota.descrizione=" - ";          
          this.province_list.push(provinciaVuota);
          this.province_list.push.apply(this.province_list, data.list);
      },
        this.handleError(true)
      );
    }

    initializeForms() {

      this.userForm = new FormGroup({
        id: new FormControl(this.anagUserAzienda.id, []),
        cognome: new FormControl(this.anagUserAzienda.cognome, [Validators.required]),
        nome: new FormControl(this.anagUserAzienda.nome, [Validators.required]),
        dateRegistrazione: new FormControl(this.anagUserAzienda.dateRegistrazione, []),
        email: new FormControl(this.anagUserAzienda.email, [Validators.required]),
        idOrganization: new FormControl(this.anagUserAzienda.idOrganization, []),
        lingua: new FormControl(this.anagUserAzienda.lingua, []),
        mobile: new FormControl(this.anagUserAzienda.mobile, [Validators.required]),
        telefono: new FormControl(this.anagUserAzienda.telefono, [Validators.required]),
        ruolo: new FormControl(this.anagUserAzienda.ruolo, []),
      });

      if (this.anagAzienda.nationality == undefined || this.anagAzienda.nationality == '') {
        this.anagAzienda.nationality = this.anagAzienda.orgname.substr(0, 2);
      }

      if (this.anagAzienda.partitaIva == undefined || this.anagAzienda.partitaIva == '') {
        this.anagAzienda.partitaIva = this.anagAzienda.orgname.substr(3);
      }

      this.organizationForm = new FormGroup({
        id: new FormControl(this.anagAzienda.id, [Validators.required]),
        userId: new FormControl(this.anagAzienda.userId, [Validators.required]),
        orgname: new FormControl(this.anagAzienda.orgname, [Validators.required]),
        codiceFiscale: new FormControl(this.anagAzienda.codiceFiscale, [Validators.required]),
        ragioneSociale: new FormControl(this.anagAzienda.ragioneSociale, [Validators.required]),
        nationality: new FormControl(this.anagAzienda.nationality, [Validators.required]),
        partitaIva: new FormControl(this.anagAzienda.partitaIva, [Validators.required]),
        province: new FormControl(this.anagAzienda.provincia, [Validators.required]),
        zip: new FormControl(this.anagAzienda.cap, [Validators.required]),
        city: new FormControl(this.anagAzienda.citta, [Validators.required]),
        indirizzo: new FormControl(this.anagAzienda.indirizzo, [Validators.required]),
        phone: new FormControl(this.anagAzienda.telefono, [Validators.required]),
        fax: new FormControl(this.anagAzienda.fax, [Validators.required]),
        pec: new FormControl(this.anagAzienda.pec, [Validators.required, Validators.email]),
        visuraCamerale: new FormControl(this.anagAzienda.visuraCamerale, [Validators.required]),
        rappresentanteLegale: new FormControl(this.anagAzienda.rappresentanteLegale, [Validators.required]),
        fidejussioneBancaria: new FormControl(this.anagAzienda.fidejussioneBancaria, [Validators.required]),
        dichiarazione231_2001: new FormControl(this.anagAzienda.dichiarazione231_2001, [Validators.required]),
        totaleFidejussione: new FormControl(this.anagAzienda.totaleFidejussione, [Validators.required]),
        dataScadenzaFidejussione: new FormControl(this.anagAzienda.dataScadenzaFidejussione, [Validators.required]),
        capacitaProduttiva: new FormControl(this.anagAzienda.capacitaProduttiva, []),

        visuraCameraleValidated: new FormControl(this.anagAzienda.visuraCameraleValidated, []),
        rappresentanteLegaleValidated: new FormControl(this.anagAzienda.rappresentanteLegaleValidated, []),
        fidejussioneBancariaValidated: new FormControl(this.anagAzienda.fidejussioneBancariaValidated, []),
        dichiarazione231_2001Validated: new FormControl(this.anagAzienda.dichiarazione231_2001Validated, []),

        dataCreazione: new FormControl(this.anagAzienda.dataCreazione, []),
        status: new FormControl(this.anagAzienda.status, []),
      });
    }

    getRappresentanteLegaleFile(file) {
      if (file != undefined) {
        console.log('getRappresentanteLegaleFile() loaded file: ' + file.name);
      }else {
        console.log('getRappresentanteLegaleFile() unloaded file!');
      }
      this.rappresentanteLegale = new FileWithContentUpload();
      this.rappresentanteLegale.file = file;
      this.organizationForm.get('rappresentanteLegale').reset({ value: '' });
    }

    getVisuraCameraleFile(file) {
      if (file != undefined) {
        console.log('getVisuraCameraleFile() loaded file: ' + file.name);
      }else {
        console.log('getVisuraCameraleFile() unloaded file!');
      }
      this.visuraCamerale = new FileWithContentUpload();
      this.visuraCamerale.file = file;
      this.organizationForm.get('visuraCamerale').reset({ value: '' });
    }

    getFidejussioneBancariaFile(file) {
      if (file != undefined) {
        console.log('getFidejussioneBancariaFile() loaded file: ' + file.name);
      }else {
        console.log('getFidejussioneBancariaFile() unloaded file!');
      }
      this.fidejussioneBancaria = new FileWithContentUpload();
      this.fidejussioneBancaria.file = file;
      this.organizationForm.get('fidejussioneBancaria').reset({ value: '' });
    }

    getDichiarazione231_2001File(file) {
      if (file != undefined) {
        console.log('getDichiarazione231_2001File() loaded file: ' + file.name);
      }else {
        console.log('getDichiarazione231_2001File() unloaded file!');
      }
      this.dichiarazione231_2001 = new FileWithContentUpload();
      this.dichiarazione231_2001.file = file;
      this.organizationForm.get('dichiarazione231_2001').reset({ value: '' });
    }

    checkErrorOrganization(prop: string) {
      let output: boolean = !this.organizationForm.get(prop).valid && this.submittedOrganization;
      //console.log('prop: ' + prop  + ' valid: ' + this.plantForm.get(prop).valid + ' output: ' + output);
      return output;
    }

    controlUserHasError(nome) {
      return !this.userForm.get(nome).valid && this.submittedUser;
    }

    controlOrgHasError(nome) {
      return !this.organizationForm.get(nome).valid && this.submittedOrganization;
    }

    validateFieldUser(nome) {
      try {
        return this.userForm.get(nome).valid;
      } catch (err) {
        console.log('error on validateFieldUser(' + nome + ') err: ' + err);
        return true;
      }
    }

    validateFieldOrg(nome) {
      try {
        return this.organizationForm.get(nome).valid;
      } catch (err) {
        console.log('error on validateFieldOrg(' + nome + ') err: ' + err);
        return true;
      }
    }

    saveUser() {
      console.log('saveUser()');
      if (this.validateUser()) {

        console.log('saveUser() form valido');
        let op: OrganizationOperatorUpdateItem = new OrganizationOperatorUpdateItem();
        op = {
          nome: this.userForm.get('nome').value,
          cognome: this.userForm.get('cognome').value,
          email: this.userForm.get('email').value,
          lingua: this.userForm.get('lingua').value,
          telefono: this.userForm.get('telefono').value,
          mobile: this.userForm.get('mobile').value,
        };

        this.organizationService.updateUser(op).subscribe(
          (res:any) => {
            this.localUtil.showMessage("", ConstantsModule.INFO_TITLE, this.messageService.getLabelFromCode("GENERIC_SUCCESS_MESSAGE_SAVE"), true);
            let authParam = this.localUtil.authParams;
            authParam.user_detail.nome = res.detail.nome;
            authParam.user_detail.cognome = res.detail.cognome;
            authParam.user_detail.email = res.detail.email;
            authParam.user_detail.lingua = res.detail.lingua;
            authParam.user_detail.telefono = res.detail.telefono;
            authParam.user_detail.mobile = res.detail.mobile;
            if (!this.isAdmin() && !this.isCoripet()) {
              this.localUtil.setAuthParams(authParam);
            }
            if (this.localUtil.isUser()) {
              this.reloadBeans();
            }
          },
          this.handleError(true)
        );

      } else {
        this.localUtil.showMessage("", ConstantsModule.ERROR_TITLE, this.messageService.getLabelFromCode("ERROR_FORM_VALIDATION_MESSAGE"), true);
      }
    }

    saveOrganization() {
      console.log('saveOrganization()');
      if (this.validateOrganization()) {

        console.log('saveOrganization() form valido');
        let org: OrganizationUpdateItem = new OrganizationUpdateItem();
        org = {
          nazionalita: this.organizationForm.get('nationality').value,
          ragioneSociale: this.organizationForm.get('ragioneSociale').value,
          provincia: this.organizationForm.get('province').value,
          citta: this.organizationForm.get('city').value,
          cap: this.organizationForm.get('zip').value,
          indirizzo: this.organizationForm.get('indirizzo').value,
          telefono: this.organizationForm.get('phone').value,
          fax: this.organizationForm.get('fax').value,
          pec: this.organizationForm.get('pec').value,
          totaleFidejussione: 0,
          visuraCameraleUuid: this.organizationForm.get('visuraCamerale').value,
          rappresentanteLegaleUuid: this.organizationForm.get('rappresentanteLegale').value,
          fidejussioneBancariaUuid: this.organizationForm.get('fidejussioneBancaria').value,
          dichiarazione231_2001Uuid: this.organizationForm.get('dichiarazione231_2001').value,
        };

        this.organizationService.updateOrganization(this.anagAzienda.orgname, org).subscribe(
          (res:any) => {
            this.localUtil.showMessage("", ConstantsModule.INFO_TITLE, this.messageService.getLabelFromCode("GENERIC_SUCCESS_MESSAGE_SAVE"), true);

            let authParam = this.localUtil.authParams;
            authParam.organization_detail.nationality = res.detail.nazionalita;
            authParam.organization_detail.ragioneSociale = res.detail.ragioneSociale;
            authParam.organization_detail.partitalva = res.detail.partitalva;            
            authParam.organization_detail.province = res.detail.provincia;
            authParam.organization_detail.city = res.detail.citta;
            authParam.organization_detail.zip = res.detail.cap;
            authParam.organization_detail.indirizzo = res.detail.indirizzo;
            //console.log('authParam.organization_detail.telefono: ' + authParam.organization_detail.telefono + ' res.detail.telefono: ' + res.detail.telefono);
            authParam.organization_detail.phone = res.detail.telefono;
            authParam.organization_detail.fax = res.detail.fax;
            authParam.organization_detail.pec = res.detail.pec;
            authParam.organization_detail.totaleFidejussione = res.detail.totaleFidejussione;
            authParam.organization_detail.capacitaProduttiva = res.detail.capacitaProduttiva;
            authParam.organization_detail.dataScadenzaFidejussione = res.detail.dataScadenzaFidejussione;
            authParam.organization_detail.fidejussioneBancaria = res.detail.fidejussioneBancaria;
            authParam.organization_detail.fidejussioneBancariaValidated = res.detail.fidejussioneBancariaValidated;
            authParam.organization_detail.visuraCamerale = res.detail.visuraCamerale;
            authParam.organization_detail.visuraCameraleValidated = res.detail.visuraCameraleValidated;
            authParam.organization_detail.rappresentanteLegale = res.detail.rappresentanteLegale;
            authParam.organization_detail.rappresentanteLegaleValidated = res.detail.rappresentanteLegaleValidated;
            authParam.organization_detail.dichiarazione231_2001 = res.detail.dichiarazione231_2001;
            authParam.organization_detail.dichiarazione231_2001Validated = res.detail.dichiarazione231_2001Validated;

            if (!this.isAdmin() && !this.isCoripet()) {
              this.localUtil.setAuthParams(authParam);
            }
            if (this.localUtil.isUser()) {
              this.reloadBeans();
            }
          },
          this.handleError(true)
        );

      } else {
        this.localUtil.showMessage("", ConstantsModule.ERROR_TITLE, this.messageService.getLabelFromCode("ERROR_FORM_VALIDATION_MESSAGE"), true);
      }
    }

    validateUser() {
      console.log('validateUser()');
      this.submittedUser = true;
      let output = true;
      console.log(this.userForm);
      Object.keys(this.userForm.controls).forEach(key => {
        //console.log(key + ' ' + this.userForm.get(key).value + ' valid?: ' + this.userForm.get(key).valid);
        if (!this.userForm.get(key).valid) {
          output = false;
        }
      });      
      return output;
    }

    validateOrganization() {
      console.log('validateOrganization()');
      this.submittedOrganization = true;
      let output = true;
      console.log(this.organizationForm);
      Object.keys(this.organizationForm.controls).forEach(key => {
        //console.log(key + ' ' + this.organizationForm.get(key).value + ' valid?: ' + this.organizationForm.get(key).valid);
        if (!this.organizationForm.get(key).valid) {
          output = false;
        }
      });      
      return output;
    }

    getVisuraExternalLink(vatnumber: string) {
      //console.log('getVisuraExternalLink()');
      let output = '/' + ConstantsModule.REF_AZ_UPLOAD_VISURA_PATH;
      if (this.isCoripet()) {
        output = ConstantsModule.CORIPET_UPLOAD_VISURA_PATH + vatnumber;
      }
      /*if (vatnumber) {
        output += vatnumber;
      }*/
      //console.log(output);
      return output;
    }

    getLegaleExternalLink(vatnumber: string) {
      //console.log('getLegaleExternalLink()');
      let output = '/' + ConstantsModule.REF_AZ_UPLOAD_LEGALE_PATH;
      if (this.isCoripet()) {
        output = ConstantsModule.CORIPET_UPLOAD_LEGALE_PATH + vatnumber;
      }
      /*if (vatnumber) {
        output += vatnumber;
      }*/
      //console.log(output);
      return output;
    }

    getFideiussioneExternalLink(vatnumber: string) {
      //console.log('getFideiussioneExternalLink()');
      let output = '/' + ConstantsModule.REF_AZ_UPLOAD_FIDEIUSSIONE_PATH;
      if (this.isCoripet()) {
        output = ConstantsModule.CORIPET_AZ_UPLOAD_FIDEIUSSIONE_PATH + vatnumber;
      }
      /*if (vatnumber) {
        output += vatnumber;
      }*/
      //console.log(output);
      return output;
    }

    getDoc231ExternalLink(vatnumber: string) {
      //console.log('getDoc231ExternalLink()');
      let output = '/' + ConstantsModule.REF_AZ_UPLOAD_DOC_231_2001_PATH;
      if (this.isCoripet()) {
        output = ConstantsModule.CORIPET_UPLOAD_DOC_231_2001_PATH + vatnumber;
      }
      /*if (vatnumber) {
        output += vatnumber;
      }*/
      //console.log(output);
      return output;
    }

    approveOrganization() {

      this.organizationService.approveOrganization(this.anagAzienda.orgname).subscribe(
        data => {
          
          const modalRef = this.modalService.open(PopupComponent);
          modalRef.componentInstance.titolo = this.messageService.getLabelFromCode("INFO_TITLE");
          modalRef.componentInstance.messaggio = this.messageService.getMessageFromCode("OK_OPERATION");
          modalRef.componentInstance.tipo = 'message';
          modalRef.componentInstance.passEntry.subscribe((receivedEntry:any) => {
            this.reloadComponent();
          });

        }, this.handleError(true));
    }
  
    rejectOrganization() {
      
      this.organizationService.rejectOrganization(this.anagAzienda.orgname).subscribe(
        data => {
          
          const modalRef = this.modalService.open(PopupComponent);
          modalRef.componentInstance.titolo = this.messageService.getLabelFromCode("INFO_TITLE");
          modalRef.componentInstance.messaggio = this.messageService.getMessageFromCode("OK_OPERATION");
          modalRef.componentInstance.tipo = 'message';
          modalRef.componentInstance.passEntry.subscribe((receivedEntry:any) => {
            this.reloadComponent();
          });

        }, this.handleError(true));

    }

  }