<div id="aste-chiuse" class="content"> <!-- ngIf="isActiveComponent()"-->           
    <h2 translate>aste-chiuse.aste-chiuse</h2>
    <div id="auctions-list" class="auctions-list" style="display: contents !important;">
        <dx-data-grid 
            id="gridContainer"
            [dataSource]="auctions_list"
            keyExpr="id"
            [allowColumnReordering]="false"
            [allowColumnResizing]="false"
            [showBorders]="true"
            [columnHidingEnabled]="false"
            (onRowPrepared)="onRowPrepared($event)"
            (onContentReady)="onContentReady($event)"
            (onRowClick)="openDettaglio($event)"
            >

            <dxo-export [enabled]="false" fileName="auction_close_list"></dxo-export>  

            <dxo-load-panel [enabled]="true"></dxo-load-panel>
            <dxo-filter-row [visible]="false"></dxo-filter-row>
            
            <dxo-search-panel
                [visible]="false"
                [highlightCaseSensitive]="false">
            </dxo-search-panel>

            <!-- edit form and columns -->
            <dxi-column dataField="id" [allowEditing]="false" [width]="100" caption="{{'aste-chiuse.id' | translate}}" alignment="left" [visible]="false">
            </dxi-column>
            <dxi-column dataField="userId" caption="{{'aste-chiuse.userId' | translate}}" dataType="string" showEditorAlways="true" [visible]="false">
            </dxi-column>
            <dxi-column dataField="dataCreazione" caption="{{'aste-chiuse.dataCreazione' | translate}}" [width]="150" dataType="date" format="dd/MM/yyyy" showEditorAlways="true" [visible]="false">
            </dxi-column>
            <dxi-column dataField="dataModifica" caption="{{'aste-chiuse.dataModifica' | translate}}" dataType="date" format="dd/MM/yyyy" showEditorAlways="true" [visible]="false">
            </dxi-column>
            <dxi-column dataField="codiceAsta" caption="{{'aste-chiuse.codiceAsta' | translate}}" dataType="string" [visible]="true" cssClass="number">
            </dxi-column>
            <dxi-column dataField="statoAsta" caption="{{'aste-chiuse.statoAsta' | translate}}" dataType="string" [visible]="false">                    
            </dxi-column>
            <dxi-column dataField="dataPubblicazione" caption="{{'aste-chiuse.pubblicazione' | translate}}" dataType="string" [visible]="true" cssClass="publication-date">
            </dxi-column>
            <dxi-column dataField="dataOraInizioAsta" caption="{{'aste-chiuse.inizioAsta' | translate}}" dataType="string" [visible]="true" cssClass="auction-start">
            </dxi-column>
            <dxi-column dataField="dataOraFineAsta" caption="{{'aste-chiuse.conclusioneAsta' | translate}}" dataType="string" [visible]="true" cssClass="auction-end">
            </dxi-column>
            <dxi-column dataField="tipologiaAsta" caption="{{'aste-chiuse.tipologia' | translate}}" dataType="string" [visible]="true" cssClass="tipologia">
            </dxi-column>
            <dxi-column dataField="report" caption="{{'aste-chiuse.report' | translate}}" dataType="string" [visible]="true" cellTemplate="reportTemplate" cssClass="report">
                <div *dxTemplate="let reportTemplate of 'reportTemplate'">                    
                    <div class="loader_mask_column" [ngClass]="{'hidden': reportTemplate.data.uuid != undefined}">
                        <div class="loader_column"></div>
                    </div>
                    <article class="card-with-actions" [ngClass]="{'hidden': reportTemplate.data.uuid == undefined}">
                        <ul>
                            <li>
                                <div class="download-report-big download-file" (click)="downloadFile(reportTemplate.data.uuid, 'report-asta-' + reportTemplate.data.codiceAsta + '.pdf')" id="{{reportTemplate.data.codiceAsta}}" title="Report asta {{reportTemplate.data.codiceAsta}}" [attr.data-asta]="reportTemplate.data.codiceAsta" [attr.data-uuid]="reportTemplate.data.uuid"><i class="fal fa-file-pdf right10"></i><div class="filename-report hidden">report-asta-{{reportTemplate.data.codiceAsta}}.pdf</div></div>
                            </li>
                        </ul>
                    </article>
                </div>
            </dxi-column>
            
        </dx-data-grid>
    </div>
</div>