import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { environment } from '../environments/environment';
import { LocalUtilService } from './services/local-util.service';
import { DatashareService, Locale } from './services/datashare.service';
import { TranslateService } from '@ngx-translate/core';
import itMessages from "devextreme/localization/messages/it.json";
import { locale, loadMessages } from "devextreme/localization";

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit {
  title = 'aucircle-angular';

  pageUrl: string = 'dashboard';

  constructor(public localUtil: LocalUtilService,
    public http: HttpClient,
    public route: ActivatedRoute, private dataShare: DatashareService, private translate: TranslateService) {
    if (!dataShare.getLanguage()) {
      dataShare.setLanguage(Locale.IT);
    }
    translate.use(dataShare.getLanguage()!.toString());
    translate.setDefaultLang(dataShare.getLanguage()!.toString());
    this.http.get('/assets/appConfig.json')
      .toPromise()
      .then(data => {
        Object.keys(data).forEach(function (key) {
          /** Need to fix this */
          environment[key] = data[key];
        });
      });
    console.log('load itMessages');
    loadMessages(itMessages);
    console .log('navigator.language: ' + navigator.language);
    locale(navigator.language);
  }

  ngOnInit() {

  }

  showSpalla() {
    console.log(this.route.routeConfig);
    return false;
  }

  get islogged() {
    let output = false;
    try {
      let userDetail = sessionStorage.getItem('user');
      //console.log('islogged() userDetail1: ' + userDetail);
      userDetail = JSON.parse(userDetail);
      //console.log('islogged() userDetail2: ' + userDetail);
      if (userDetail != null) {
        output = true;
      }
    } catch (err) { }
    return output;
  }

  isUser() {
    let output = true;
    output = !this.localUtil.isAdmin() && !this.localUtil.isCoripet();
    return output;
  }

  isCoripet() {
    return this.localUtil.isCoripet();
  }

  isAdmin() {
    return this.localUtil.isAdmin();
  }

}
