import { Location } from '@angular/common';
import { Component, OnInit, ViewChild } from '@angular/core';
import { Route, Router, Routes } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { DxDataGridComponent } from 'devextreme-angular';
import { filter } from 'rxjs';
import { AppComponent } from 'src/app/app.component';
import { ActivityItem, AuctionItem, DocumentData, NazioneItem, OrganizationItem, OrganizationPlantItem, ProvinciaItem, UserItem, UserOperatorItem } from 'src/app/models/dataModels';
import { APIService } from 'src/app/services/APIService';
import { AuctionService } from 'src/app/services/auction.service';
import { AuthService } from 'src/app/services/auth.service';
import { CommonService } from 'src/app/services/common.service';
import { ConstantsModule } from 'src/app/services/constants.module';
import { LocalUtilService } from 'src/app/services/local-util.service';
import { MessagesService } from 'src/app/services/messages.service';
import { OrganizationService } from 'src/app/services/organization.service';

const getDataCreazione = function (rowData: any): number {
  return (new Date(rowData.dataCreazione)).getDay();
};

@Component({
  selector: 'app-admin-aziende',
  templateUrl: './aziende.component.html',
  styleUrls: ['./aziende.component.css']
})
export class AdminAziendeComponent extends APIService implements OnInit {



  constructor(public localService: LocalUtilService,
    public authService: AuthService,
    public auctionService: AuctionService,
    public organizationService: OrganizationService,
    public commonService: CommonService,
    private modalService: NgbModal,
    private messageService: MessagesService,
    location: Location, 
    router: Router) {
    super(localService, location, router);
  }

  ngOnInit(): void {
    console.log("inside")
  }

  
}
