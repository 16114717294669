import { Location } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import ExcellentExport, { ConvertOptions, SheetOptions } from 'excellentexport';
import { ConfirmComponent } from 'src/app/commons/components/confirm/confirm.component';
import { PopupComponent } from 'src/app/commons/components/popup/popup.component';
import { AuctionItem, AuctionLotItem, CentroSelezioneItem, NazioneItem } from 'src/app/models/dataModels';
import { APIService } from 'src/app/services/APIService';
import { AuctionService } from 'src/app/services/auction.service';
import { CommonService } from 'src/app/services/common.service';
import { ConstantsModule } from 'src/app/services/constants.module';
import { LocalUtilService } from 'src/app/services/local-util.service';
import { MessagesService } from 'src/app/services/messages.service';

@Component({
  selector: 'app-admin-asta-dettaglio',
  templateUrl: './dettaglio.component.html',
  styleUrls: ['./dettaglio.component.css']
})
export class AdminAstaDettaglioComponent extends APIService implements OnInit {

  constructor(localUtil: LocalUtilService,
    public auctionService: AuctionService,
    private modalService: NgbModal,
    private messageService: MessagesService,
    location: Location,
    router: Router,
    private route: ActivatedRoute,
    public commonService: CommonService) {
    super(localUtil, location, router);
    console.log('AdminAstaDettaglioComponent()');
  }

  auction: AuctionItem;
  codiceAsta: string;
  lot_list: AuctionLotItem[] = [];
  cs_list: CentroSelezioneItem[] = [];
  nation_list: NazioneItem[] = [];

  customizeItemTemplate(item: any) {
    item.template = "formItem";
  }

  ngOnInit(): void {
    this.codiceAsta = this.route.snapshot.paramMap.get('codiceAsta');
    console.log('codiceAsta: ' + this.codiceAsta);
    this.loadCentriSelezione();
    this.loadNazioni();
    this.loadAuctionDetails(undefined, false);
  }

  loadNazioni() {
    this.commonService.getNationsList().subscribe(
      data => {
        this.nation_list = data.list;
      });
  }

  loadCentriSelezione() {
    this.auctionService.getSelectionCenterList().subscribe(
      data => {
        this.cs_list = data.list;
      });
  }

  getLotByCode(codiceLotto: string) {
    let output = undefined;
    //console.log('getLotByCode() this.lot_list: ' + JSON.stringify(this.lot_list));
    if (this.lot_list != undefined) {
      this.lot_list.forEach((lotElem: any) => {
        if (lotElem.codiceLotto == codiceLotto) {
          output = lotElem;
        }
      });
    }
    return output;
  }

  gotoBack() {
    this.location.back();
  }

  loadAuctionDetails(textFilter, onlywon) {

    var qparam = '';

    this.auctionService.getAuctionLots(this.codiceAsta, qparam).subscribe(
      (data: any) => {
        console.log('data.auctionDetail: ' + JSON.stringify(data.auctionDetail));
        this.auction = data.auctionDetail;
        /*this.statoAsta = data.auctionDetail.statoAsta;
        this.tipologiaAsta = data.auctionDetail.tipologiaAsta;
        this.dataOraFineAsta = data.auctionDetail.dataOraFineAsta;*/
        if (data.lotList != undefined) {
          this.auction.numLotti = data.lotList.length;
          //console.log('lot_list: ' + data.lotList);

          console.log(this.auction.statoAsta);

          if (this.auction.statoAsta == ConstantsModule.AUCTION_STATUS_AVAILABLE) {
            this.manageAllOtherAuction(data.lotList);
          } else {
            this.manageAllOtherAuction(data.lotList);
          }


          console.log('lot_list: ' + this.lot_list.length);
        }
      });

  }

  manageAvailableAuction(lotList) {
    console.log('manageAvailableAuction()');
    this.manageAllOtherAuction(lotList);
  }

  manageAllOtherAuction(lotList) {
    this.lot_list = [];
    console.log('manageAllOtherAuction()');
    if (lotList) {
      lotList.forEach(item => {
        item.prezzoUltimoRilancio = this.localUtil.formatNumber2(item.prezzoUltimoRilancio);

        if (item.pagato == 0) {
          item.paid = "Non pagato";
          item.paidClass = "ko";
        } else if (item.pagato == 1) {
          item.paid = "Pagato";
          item.paidClass = "ok";
        }
        if (item.spedito == 0) {
          item.retired = "Non ritirato";
          item.retiredClass = "ko";
        } else if (item.spedito == 1) {
          item.retired = "Ritirato";
          item.retiredClass = "ok";
        }

        item.paidChecked = item.pagato == 1;
        item.retiredChecked = item.spedito == 1;
        item.notPaidChecked = item.pagato == 0;
        item.notRetiredChecked = item.spedito == 0;

        item.codiceLottoNoPoints = this.localUtil.getCodiceLottoNoPoints(item.codiceLotto);

        if (item.nomeOrganization == undefined) {
          item.nomeOrganization = '-';
        }

      });
      console.log('data.lotList: ');
      console.log(lotList);
      console.log(this.auction.statoAsta);
      if (this.auction.statoAsta == 'Disponibile') {
        console.log("Disponibile");
        lotList.forEach(data => {
          if ((this.inPubblicazione && data.statoLotto == 'In Pubblicazione') ||
            (this.pubblicato && data.statoLotto == 'Pubblicato') ||
            (this.rifiutato && data.statoLotto == 'Rifiutato')) {
            this.lot_list.push(data);
          }
        });
      } else if (this.auction.statoAsta == 'Chiusa') {
        console.log("Chiusa");
        lotList.forEach(data => {
          if (this.pagati && !this.spediti && data.pagato == 1 && data.spedito == 0) {
            this.lot_list.push(data);
          } else if (this.spediti && !this.pagati && data.spedito == 1 && data.pagato == 0) {
            this.lot_list.push(data);
          } else if (this.pagati && this.spediti && data.pagato == 1 && data.spedito == 1) {
            this.lot_list.push(data);
          } else if (!this.pagati && !this.spediti && data.pagato == 0 && data.spedito == 0) {
            this.lot_list.push(data);
          }
        });
      }
    }

  }

  pagati: boolean = true;
  spediti: boolean = true;
  inPubblicazione: boolean = true;
  pubblicato: boolean = true;
  rifiutato: boolean = true;
  filterCheckBox() {
    this.loadAuctionDetails(undefined, false);
  }

  onRowPrepared(info) {
    if (info.rowType === 'data') {
      //console.log('onRowPrepared() info.rowElement: ' + info.rowElement);
      //info.rowElement.addClass('auction');  
    }
  }

  onContentReady(event) {
    console.log('contenReady');
    $('.dx-datagrid-headers').remove();
    $('.dx-datagrid-rowsview').css('border-top', 'none');
    //$('.dx-datagrid-header-panel').css('border-bottom', 'none');
  }

  gotoListaAsteChiuse() {
    this.router.navigate([ConstantsModule.REF_AZ_ASTE_CHIUSE_PATH, {}]);
  }

  getSchedaTecnicaUrl(tipoProdotto: string) {
    return ConstantsModule.S3_URL + '/scheda_tecnica/scheda-tecnica-' + tipoProdotto + '.pdf';
  }

  openNote(note: string, codiceLotto: string) {
    const modalRef = this.modalService.open(PopupComponent);
    modalRef.componentInstance.tipo = 'openNote';
    modalRef.componentInstance.note = note;
    modalRef.componentInstance.codiceLotto = codiceLotto;
  }

  openCentriSelezione(codiceLotto: string) {
    const modalRef = this.modalService.open(PopupComponent);
    modalRef.componentInstance.tipo = 'openCentriSelezione';
    modalRef.componentInstance.titolo = 'Centri di selezione';
    modalRef.componentInstance.codiceLotto = codiceLotto;
    console.log('openCentriSelezione() codiceLotto: ' + codiceLotto);
    let lotElem = this.getLotByCode(codiceLotto);
    let csListPopup: CentroSelezioneItem[] = [];
    console.log(lotElem);
    if (lotElem != undefined && lotElem.centroSelezione != undefined) {
      lotElem.centroSelezione.forEach(csLottoElem => {
        let item = new CentroSelezioneItem();
        item.quantitaTon = lotElem.quantitaTon;
        item.indirizzo = '';
        item.pesoMedioCarico = '';

        let cs = this.localUtil.getSelectionCenterByCodice(csLottoElem.codiceCentroSelezione, this.cs_list);
        if (cs != undefined) {

          let nazione = this.localUtil.getNazioneByCodice(cs.nazione, this.nation_list);
          console.log('nazione: ' + JSON.stringify(nazione));
          let nazioneDescr = '';
          if (nazione != null && nazione != undefined && nazione != '') {
            nazioneDescr = nazione.descrizione;
            if (nazione.codice == 'IT') {
              nazioneDescr = 'Italia';
            }
            item.nazioneDescr = nazioneDescr;
          }
        }
        item.CER = cs.CER;
        item.cap = cs.cap;
        item.citta = cs.citta;
        item.provincia = cs.provincia;
        item.pesoMedioCarico = cs.pesoMedioCarico;
        item.nome = cs.nome;
        item.indirizzo = cs.indirizzo;
        csListPopup.push(item);
      });
    }
    modalRef.componentInstance.centriSelezione = csListPopup;

  }

  exportExcel(codiceAsta: string, titolo: string) {
    console.log('exportExcel() codiceAsta: ' + codiceAsta + ' titolo: ' + titolo);
    var code = codiceAsta;
    var exportTitle = titolo;
    var exportList = this.lot_list;

    //header xls title creations
    var html = "<thead>";
    var entriesDone = false;
    var toExlude = ['createDate', 'modifiedDate', 'statoLotto', 'codiceRifiutoCER', 'pesoMedio', 'imballaggio', 'dataOraFineAstaEffettiva', 'numeroRilanci', 'prezzoUltimoRilancio', 'spedito', 'pagato'];
    var idToExclude = [];
    this.lot_list.forEach(elem => {
      console.log('exportExcel() loop over lot elem: ' + JSON.stringify(elem));
      //utilities.printLog("loop over lot elem " + JSON.stringify($(this)));
      var p = elem;
      for (var key in p) {
        //utilities.printLog("key: " + key);
        if (p.hasOwnProperty(key) && !entriesDone) {
          //utilities.printLog(key + " -> " + p[key]);
          var jsonElem = p[key];
          let cont = 0;
          for (var keyElem in jsonElem) {
            if (cont == 0) {
              html += "<tr>";
            }
            if (!toExlude.includes(keyElem)) {
              //utilities.printLog("header: " + keyElem);
              html += "<td>" + keyElem + "</td>";
              entriesDone = true;
            } else {
              idToExclude.push(cont);
              //utilities.printLog('idToExclude cont: ' + cont + ' name: ' + keyElem);
            }
            cont++;
          }
          if (cont > 0) {
            html += "</tr>";
          }
        }
        break;
      }

    });

    html += "</thead>";

    //utilities.printLog('idToExclude: ' + idToExclude);
    //table creations
    html += "<tbody>";
    this.lot_list.forEach(elem => {
      //utilities.printLog("loop over lot elem " + JSON.stringify($(this)));
      console.log('exportExcel() loop over lot elem2: ' + JSON.stringify(elem));
      var p = elem;
      for (var key in p) {
        //utilities.printLog("key: " + key);
        if (p.hasOwnProperty(key)) {
          //utilities.printLog(key + " -> " + p[key]);
          var jsonElem: any = p[key];
          let cont = 0;
          for (var keyElem in jsonElem) {
            if (cont == 0) {
              html += "<tr>";
            }
            //utilities.printLog(keyElem + " -> " + jsonElem[keyElem]);
            if (!idToExclude.includes(cont)) {
              let rowcslist = '';
              if (keyElem != 'centroSelezione') {
                html += "<td>" + jsonElem[keyElem] + "</td>";
              } else {
                //jsonElem[keyElem].forEach(function (item) {
                for (let g = 0; g < jsonElem[keyElem].length; g++) {
                  let item = jsonElem[keyElem][g];
                  //utilities.printLog('for centroSelezione: ' + JSON.stringify(item));
                  let cs = this.localUtil.getSelectionCenterByCodice(item.codiceCentroSelezione, this.cs_list);
                  item.nome = '';
                  item.indirizzo = '';
                  if (cs !== undefined) {
                    item.nome = cs.nome;
                    item.indirizzo = cs.indirizzo + ' - ' + cs.cap + ' ' + cs.citta + ' (' + cs.provincia + ')'
                  }
                  let rowcs = '<b>' + item.codiceCentroSelezione + '</b> - ' + item.nome + ' - ' + item.quantitaTon + ' ton - ' + item.indirizzo + '<br/>';
                  rowcslist += rowcs;
                  //});
                }
                html += "<td>" + rowcslist + "</td>";
              }
            }
            cont++;
          }
          if (cont > 0) {
            html += "</tr>";
          }
        }
      }
    });

    html += "</tbody>";

    $("#export-table").html(html);
    console.log('exportExcel() pre export');
    let tableObj: any = $("#export-table");

    /*(<any>$("#export-table")).table2excel({
        name: exportTitle,
        filename: 'export_' + code + '.xls'
    });*/

    const a: any = document.createElement('a');
    a.download = "export.xlsx";
    a.style = 'display: none';
    document.body.appendChild(a);
    let blob = new Blob([html], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;' });
    //let url = window.URL.createObjectURL(blob);
    //a.href = url;
    //a.download = titolo;
    //a.click();
    //URL.revokeObjectURL(url);

    const options = {
      anchor: document.getElementById('anchor'),
      filename: 'export',
      format: 'xlsx'
    } as ConvertOptions;

    const sheets = [
      {
        name: 'Export',
        from: {
          table: document.getElementById('export-table')
        }
      }
    ] as SheetOptions[];
    const workbook = ExcellentExport.convert(options, sheets);
    const anchor = document.getElementById('anchor') as HTMLAnchorElement;

    console.log('anchor: ' + anchor);
    console.log('exportExcel() post export');
  }

  annullaAsta() {

    let params: any[] = [this.auction.codiceAsta];
    const modalRef = this.modalService.open(ConfirmComponent);
    modalRef.componentInstance.titolo = this.messageService.getLabelFromCode("INFO_TITLE");
    modalRef.componentInstance.messaggio = this.messageService.getLabelFromCode("CANCEL_AUCTION_MESSAGE", params);
    modalRef.componentInstance.passEntry.subscribe((receivedEntry: any) => {
      if (receivedEntry != undefined) {

        this.auctionService.cancelAuction(this.auction.codiceAsta).subscribe(
          res => {
            this.localUtil.showMessage("", ConstantsModule.INFO_TITLE, this.messageService.getLabelFromCode("SUCCESS_CANCEL_AUCTION_MESSAGE"), true);
            this.reloadComponent();
          },
          this.handleError(true)
        );
      }
    }, this.handleError(true));

  }

  approvaLotti() {

    let params: any[] = [this.auction.codiceAsta];
    const modalRef = this.modalService.open(ConfirmComponent);
    modalRef.componentInstance.titolo = this.messageService.getLabelFromCode("INFO_TITLE");
    modalRef.componentInstance.messaggio = this.messageService.getLabelFromCode("AUCTION_APPROVE_ALL_LOTS_MESSAGE", params);
    modalRef.componentInstance.passEntry.subscribe((receivedEntry: any) => {
      if (receivedEntry != undefined) {

        console.log('TODO create lotList');
        let lotList: string[] = [];
        this.lot_list.forEach(lot => {
          if (lot.statoLotto == ConstantsModule.AUCTION_LOT_STATUS_INPUBLISHING) {
            lotList.push(lot.codiceLotto);
          }
        });
        this.auctionService.approveLotList(this.auction.codiceAsta, lotList).subscribe(
          res => {
            this.localUtil.showMessage("", ConstantsModule.INFO_TITLE, this.messageService.getMessageFromCode("OK_OPERATION"), true);
            this.reloadComponent();
          },
          this.handleError(true)
        );
      }
    }, this.handleError(true));

  }

  annullaApprovaLotti() {

    let params: any[] = [this.auction.codiceAsta];
    const modalRef = this.modalService.open(ConfirmComponent);
    modalRef.componentInstance.titolo = this.messageService.getLabelFromCode("INFO_TITLE");
    modalRef.componentInstance.messaggio = this.messageService.getLabelFromCode("AUCTION_UNDO_APPROVE_ALL_LOTS_MESSAGE", params);
    modalRef.componentInstance.passEntry.subscribe((receivedEntry: any) => {
      if (receivedEntry != undefined) {

        this.auctionService.undoApproveLotList(this.auction.codiceAsta, this.lot_list).subscribe(
          res => {
            this.localUtil.showMessage("", ConstantsModule.INFO_TITLE, this.messageService.getMessageFromCode("OK_OPERATION"), true);
            this.reloadComponent();
          },
          this.handleError(true)
        );
      }
    }, this.handleError(true));

  }

  rigettaLotti() {

    let params: any[] = [this.auction.codiceAsta];
    const modalRef = this.modalService.open(ConfirmComponent);
    modalRef.componentInstance.titolo = this.messageService.getLabelFromCode("INFO_TITLE");
    modalRef.componentInstance.messaggio = this.messageService.getLabelFromCode("AUCTION_REJECT_ALL_LOTS_MESSAGE", params);
    modalRef.componentInstance.passEntry.subscribe((receivedEntry: any) => {
      if (receivedEntry != undefined) {

        this.auctionService.rejectLotList(this.auction.codiceAsta, this.lot_list).subscribe(
          res => {
            this.localUtil.showMessage("", ConstantsModule.INFO_TITLE, this.messageService.getMessageFromCode("OK_OPERATION"), true);
            this.reloadComponent();
          },
          this.handleError(true)
        );
      }
    }, this.handleError(true));

  }


}
