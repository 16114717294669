import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { DatashareService } from 'src/app/services/datashare.service';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.css']
})
export class FooterComponent implements OnInit {

  constructor(private translate: TranslateService, public dataShare: DatashareService) { }

  ngOnInit(): void {
  }

  setLocale(loc: string) {
    this.dataShare.setLocal('locale', loc);
    this.translate.use(loc);
    window.location.reload();
  }

  cssForLocale(loc: string) {
    return this.dataShare.getLocal('locale') == loc ? 'text-primary fw-bold' : 'text-dark';
  }

}
