<form id="form_modifyUserOrg" [formGroup]="userForm" class="form-group" *ngIf="anagUserAzienda">
    <section class="step-section focused">
        <div class="card dati-personali">
            <h2 translate>anagrafica-azienda.dati-personali-referente</h2>
            <div class="edit-card">
                <button><i class="fal fa-edit"></i></button>
            </div>
            <div class="d-flex">
                <div class="input me-2" [ngClass]="{'error': controlUserHasError('nome')}">
                    <div class="input-wrapper full">
                        <input type="text" id="name" required formControlName="nome"  maxlength="255">
                        <label for="name" translate>anagrafica-azienda.nome</label>
                    </div>
                </div>
                <div class="input" [ngClass]="{'error': controlUserHasError('cognome')}">
                    <div class="input-wrapper full">
                        <input type="text" id="surname" required formControlName="cognome" maxlength="255">
                        <label for="surname" translate>anagrafica-azienda.cognome</label>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="input" [ngClass]="{'error': controlUserHasError('email')}">
                    <div class="input-wrapper full">
                        <input type="email" id="email" required formControlName="email" readonly>
                        <label for="email" translate>anagrafica-azienda.indirizzo-email</label>
                    </div>
                </div>
            </div>
            
            <div class="d-flex">
                <div class="input me-2" [ngClass]="{'error': controlUserHasError('telefono')}">
                    <div class="input-wrapper full">
                        <input type="text" id="phone" required formControlName="telefono" maxlength="75">
                        <label for="phone" translate>anagrafica-azienda.telefono-fisso</label>
                    </div>
                </div>
                <div class="input" [ngClass]="{'error': controlUserHasError('mobile')}">
                    <div class="input-wrapper full">
                        <input type="text" id="mobile" required formControlName="mobile" maxlength="75">
                        <label for="mobile" translate>anagrafica-azienda.telefono-mobile</label>
                    </div>
                </div>
            </div>
            <div class="add-operator-actions">
                <div class="" *ngIf="isUser() || isCoripet()">
                    <button *ngIf="!isAnagAdminOrCoripet(anagAzienda)" class="btn btn-outline-info button-color" id="btn-save-pers" (click)="saveUser()">
                        <i class="fal fa-save"></i>
                        <span translate>anagrafica-azienda.salva</span>
                    </button>
                </div>
            </div>
        </div>
        <div class="infos">
            <div class="number-wrapper">
                <div class="number">01</div>
                <div class="label">
                    <span translate>anagrafica-azienda.dati-personali</span>
                </div>
            </div>
        </div>
    </section>
</form>
<form id="form_modifyUserOrg" [formGroup]="organizationForm" class="form-group" *ngIf="anagAzienda">
    <section class="step-section">        
        <div class="card dati-aziendali">
            <h2 translate>anagrafica-azienda.informazioni-azienda</h2>
            <div class="edit-card">
                <button><i class="fal fa-edit"></i></button>
            </div>
            <input type="hidden" id="orgname" required formControlName="orgname">
            <div class="row">
                <div class="input" [ngClass]="{'error': controlOrgHasError('ragioneSociale')}">
                    <div class="input-wrapper full">
                        <input type="text" required formControlName="ragioneSociale" id="company-name" maxlength="255">
                        <label for="company-name" translate>anagrafica-azienda.ragione-sociale</label>
                    </div>
                </div>
            </div>
            <div class="d-flex">
                <div class="input me-2" [ngClass]="{'error': controlOrgHasError('partitaIva')}">
                    <div class="input-wrapper full">
                        <input type="text" id="iva" required formControlName="partitaIva" maxlength="30" readonly>
                        <label for="iva" translate>anagrafica-azienda.partita-iva</label>
                    </div>
                </div>
                <div class="input" [ngClass]="{'error': controlOrgHasError('codiceFiscale')}">
                    <div class="input-wrapper full">
                        <input type="text" id="fiscal-code" required formControlName="codiceFiscale" readonly maxlength="75">
                        <label for="fiscal-code" translate>anagrafica-azienda.codice-fiscale</label>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="input" [ngClass]="{'error': controlOrgHasError('nationality')}" (mouseleave)="validateFieldOrg('nationality')">
                    <div class="input-wrapper full">
                        <select name="" id="new-plant-nationality" required formControlName="nationality" class="form-control">
                            <option *ngFor="let nation of nation_list; let i = index" [value]="nation.codice">{{nation.descrizione}}</option>
                        </select>
                        <label for="new-plant-nationality" translate>anagrafica-azienda.nazionalit<span>&nbsp;*</span></label>
                    </div>
                </div>
            </div>
            <div class="d-flex">
                <div class="input me-2" id="field-provincia" [ngClass]="{'error': controlOrgHasError('province')}" (mouseleave)="validateFieldOrg('province')">
                    <div class="input-wrapper full">
                        <select name="" id="new-plant-province" formControlName="province" class="form-control">
                            <option *ngFor="let provincia of province_list; let i = index" [value]="provincia.descrizione">{{provincia.descrizione}}</option>
                        </select>
                        <label for="new-plant-province" translate>anagrafica-azienda.provincia</label>
                    </div>
                </div>
                <div class="input" [ngClass]="{'error': controlOrgHasError('city')}" (mouseleave)="validateFieldOrg('city')">
                    <div class="input-wrapper full">
                        <input type="text" name="" id="new-plant-city" maxlength="255" required formControlName="city" class="form-control">
                        <label for="new-plant-city" translate>anagrafica-azienda.citt<span>&nbsp;*</span></label>
                    </div>
                </div>
            </div>
            <div class="d-flex">
                <div class="input me-2" [ngClass]="{'error': controlOrgHasError('indirizzo')}" (mouseleave)="validateFieldOrg('indirizzo')">
                    <div class="input-wrapper full">
                        <input type="text" id="company-address" required formControlName="indirizzo"  maxlength="255">
                        <label for="company-address" translate>anagrafica-azienda.indirizzo</label>
                    </div>
                </div>
                <div class="input" [ngClass]="{'error': controlOrgHasError('zip')}" (mouseleave)="validateFieldOrg('zip')">
                    <div class="input-wrapper full">
                        <input type="text" id="company-zip" required formControlName="zip" maxlength="5">
                        <label for="company-zip" translate>anagrafica-azienda.cap</label>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="input" [ngClass]="{'error': controlOrgHasError('pec')}" (mouseleave)="validateFieldOrg('pec')">
                    <div class="input-wrapper full">
                        <input type="email" id="company-pec-email"  required formControlName="pec" maxlength="255">
                        <label for="company-pec-email" translate>anagrafica-azienda.indirizzo-pec</label>
                        <!-- <div class="note">Inserire un indirizzo email valido</div> -->
                    </div>
                </div>
            </div>
            <div class="d-flex">
                <div class="input me-2" [ngClass]="{'error': controlOrgHasError('phone')}" (mouseleave)="validateFieldOrg('phone')">
                    <div class="input-wrapper full">
                        <input type="text" id="company-phone" required formControlName="phone" maxlength="75">
                        <label for="company-phone" translate>anagrafica-azienda.telefono-fisso</label>
                    </div>
                </div>
                <div class="input" [ngClass]="{'error': controlOrgHasError('fax')}" (mouseleave)="validateFieldOrg('fax')">
                    <div class="input-wrapper full">
                        <input type="text" id="company-fax" required formControlName="fax"  maxlength="75">
                        <label for="company-fax" translate>anagrafica-azienda.fax</label>
                    </div>
                </div>
            </div>
            <input type="hidden" formControlName="visuraCamerale">
            <input type="hidden" formControlName="rappresentanteLegale">
            <input type="hidden" formControlName="fidejussioneBancaria">
            <input type="hidden" formControlName="dichiarazione231_2001">

            <div class="add-operator-actions">
                <div class="" *ngIf="isUser() || isCoripet() && !isAnagAdminOrCoripet(anagAzienda)">
                    <button class="btn btn-outline-info button-color" id="btn-save-org" (click)="saveOrganization()"><i class="fal fa-save"></i> <span translate>anagrafica-azienda.salva</span></button>
                </div>
            </div>

        </div>
        <div class="infos">
            <div class="number-wrapper">
                <div class="number">02</div>
                <div class="label">
                    <span translate>anagrafica-azienda.informazioni-azienda</span>
                </div>
            </div>
        </div>
    </section>
</form>
<section class="step-section">
    <div class="card">
        <h2 translate>anagrafica-azienda.documenti</h2>
        
        <ul class="multiple-documents">
                    <li id="visura" class="">
                        <div class="doc-datas">
                            <app-file-box docname="visura-camerale.pdf" (file)="getVisuraCameraleFile($event)" [showError]="checkErrorOrganization('visuraCamerale')" [uuid]="anagAzienda.visuraCamerale" [status]="anagAzienda.visuraCameraleValidated" [externalLink]="getVisuraExternalLink(anagAzienda.orgname)" [vatnumber]="anagAzienda.orgname" [showCoripetButtons]="!isAdmin() && !isAnagAdminOrCoripet(anagAzienda)"></app-file-box>
                        </div>
                    </li>
                    <li id="legale" >                           
                        <div class="doc-datas">
                            <app-file-box docname="rappresentante-legale.pdf" (file)="getRappresentanteLegaleFile($event)" [showError]="checkErrorOrganization('rappresentanteLegale')" [uuid]="anagAzienda.rappresentanteLegale" [status]="anagAzienda.rappresentanteLegaleValidated" [externalLink]="getLegaleExternalLink(anagAzienda.orgname)" [vatnumber]="anagAzienda.orgname" [showCoripetButtons]="!isAdmin() && !isAnagAdminOrCoripet(anagAzienda)"></app-file-box>
                        </div>
                    </li>
                    <li id="fideiussione">
                        <div class="doc-datas">
                            <app-file-box docname="fideiussione-bancaria.pdf" (file)="getFidejussioneBancariaFile($event)" [showError]="checkErrorOrganization('fidejussioneBancaria')" [uuid]="anagAzienda.fidejussioneBancaria" [status]="anagAzienda.fidejussioneBancariaValidated" [externalLink]="getFideiussioneExternalLink(anagAzienda.orgname)" [vatnumber]="anagAzienda.orgname" [showCoripetButtons]="!isAdmin() && !isAnagAdminOrCoripet(anagAzienda)"></app-file-box>
                        </div>
                    </li>
                    <li id="dichiarazione" >
                        <div class="doc-datas">
                            <app-file-box docname="dichiarazione-231-2001.pdf" (file)="getDichiarazione231_2001File($event)" [showError]="checkErrorOrganization('dichiarazione231_2001')" [uuid]="anagAzienda.dichiarazione231_2001" [status]="anagAzienda.dichiarazione231_2001Validated" [externalLink]="getDoc231ExternalLink(anagAzienda.orgname)" [vatnumber]="anagAzienda.orgname" [showCoripetButtons]="!isAdmin() && !isAnagAdminOrCoripet(anagAzienda)"></app-file-box>
                        </div>
                    </li>
        </ul>
    </div>
    <div class="infos">
        <div class="number-wrapper">
            <div class="number">03</div>
            <div class="label">
                <span translate>anagrafica-azienda.documenti</span>
            </div>
        </div>
    </div>
</section>
<section class="step-section focused">
    
    <div class="actions operator-actions " [ngClass]="{'top20': isCoripet()}">
        <div *ngIf="isCoripet() && showCoripetButtons && !isAnagAdminOrCoripet(anagAzienda)">
            <button  class="btn success btn-approve-document" (click)="approveOrganization()"><i class="fal fa-check"></i>
                <span translate>anagrafica-azienda.approva-azienda</span>
            </button>
            <button class="btn error btn-reject-document" (click)="rejectOrganization()"><i class="fal fa-times"></i>
                <span translate>anagrafica-azienda.rifiuta-azienda</span>
            </button>
        </div>
    </div>

</section>