import { Location } from '@angular/common';
import { Component, OnInit, ViewChild } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { DxDataGridComponent } from 'devextreme-angular';
import dxTextBox from 'devextreme/ui/text_box';
import { ConfirmComponent } from 'src/app/commons/components/confirm/confirm.component';
import { CodiceDescrizioneItem, OrganizationItem, OrganizationOperatorCreateItem, OrganizationOperatorItem, OrganizationOperatorUpdateItem, UserItem, UserOperatorItem } from 'src/app/models/dataModels';
import { APIService } from 'src/app/services/APIService';
import { AuthService } from 'src/app/services/auth.service';
import { ConstantsModule } from 'src/app/services/constants.module';
import { LocalUtilService } from 'src/app/services/local-util.service';
import { MessagesService } from 'src/app/services/messages.service';
import { OrganizationService } from 'src/app/services/organization.service';

@Component({
  selector: 'app-elenco-operatori',
  templateUrl: './elenco-operatori.component.html',
  styleUrls: ['./elenco-operatori.component.css']
})
export class ElencoOperatoriComponent extends APIService implements OnInit {

  @ViewChild("datagridOperatori", { static: false }) datagridOperatori: DxDataGridComponent;
  operators_list: UserItem[] = [];
  adminFormOpened: boolean = false;
  admin: OrganizationOperatorItem;
  adminForm: FormGroup;
  adminSubmitted: boolean = false;
  coripetFormOpened: boolean = false;
  coripetSubmitted: boolean = false;
  coripet: OrganizationOperatorItem;
  coripetForm: FormGroup;

  full_role_list: CodiceDescrizioneItem[] = [{ codice: 'OPER_AZIENDALE', descrizione: 'Operatore aziendale' },
  { codice: 'REF_AZIENDALE', descrizione: 'Referente aziendale' },
  { codice: 'OPERATORE', descrizione: 'Operatore Coripet' },
  { codice: 'OPER_PLASTICFINDER', descrizione: 'Admin' }
  ];

  admin_role_list: CodiceDescrizioneItem[] = [
    { codice: 'OPERATORE', descrizione: 'Operatore Coripet' },
    { codice: 'OPER_PLASTICFINDER', descrizione: 'Admin' }
  ];

  role_list: CodiceDescrizioneItem[] = [];

  language_list: CodiceDescrizioneItem[] = [{ codice: 'IT', descrizione: 'Italiano' },
  { codice: 'EN', descrizione: 'Inglese' }
  ];

  organizations_list: OrganizationItem[] = [];

  constructor(public localService: LocalUtilService,
    public authService: AuthService,
    public organizationService: OrganizationService,
    private modalService: NgbModal,
    private messageService: MessagesService,
    location: Location,
    router: Router) {
    super(localService, location, router);

    this.role_list = this.full_role_list;
    this.admin = new OrganizationOperatorItem();
    this.coripet = new OrganizationOperatorItem();
    this.enableOperator = this.enableOperator.bind(this);
    this.disableOperator = this.disableOperator.bind(this);
    //this.initializeForm();
  }

  ngOnInit(): void {
    console.log('init()');
    this.listOperators();
    this.listOrganizations();
    this.getConfigurationSetup();
  }

  onInitNewRow(e) {
    console.log('onInitNewRow()');
    this.role_list = this.admin_role_list;
    this.datagridOperatori.instance.columnOption("email", "allowEditing", true);
    //non funziona visible true/false
    this.datagridOperatori.instance.columnOption("dataRegistrazione", "visible", false);
    this.datagridOperatori.instance.columnOption("ragioneSociale", "visible", false);
  }

  onEditingStart(e) {
    this.datagridOperatori.instance.collapseAll(-1);
    //this.dataGrid.instance.cancelEditData();
    console.log('onEditingStart()');
    this.role_list = this.full_role_list;
    this.datagridOperatori.instance.columnOption("email", "allowEditing", false);
    this.datagridOperatori.instance.columnOption("password", "allowEditing", false);
    this.datagridOperatori.instance.columnOption("confermaPassword", "allowEditing", false);
    this.datagridOperatori.instance.columnOption("role", "allowEditing", false);
  }

  onEditorPreparing(e) {
    console.log(e);
    if (e.parentType == 'dataRow' && (e.dataField == "password" || e.dataField == 'confermaPassword')) {
      e.editorOptions.mode = "password";
    }
  }

  onEditorPrepared(e) {
    console.log(e);
    let localUtil = this.localUtil;
    if (e.parentType == 'dataRow' && e.dataField == 'role') {
      e.editorElement.dxSelectBox('instance').option('onValueChanged', function (ex) {
        console.log('role changed: ');
        console.log(ex);
        e.component.cellValue(0, "role", ex.value);
        if (ex.value && ex.value == ConstantsModule.ROLE_ADMIN) {
          e.component.cellValue(0, "ragioneSociale", localUtil.adminOrgname);
        } else if (ex.value && ex.value == ConstantsModule.ROLE_CORIPET) {
          e.component.cellValue(0, "ragioneSociale", localUtil.coripetOrgname);
        } else {
          e.component.cellValue(0, "ragioneSociale", '');
        }
      });
    }
  }

  getConfigurationSetup() {

    if (this.isAdmin()) {
      this.organizationService.getAdminInfo().subscribe(
        (data: any) => {
          this.localUtil.adminOrgname = data.vatnumber;
          console.log('set admin ragioneSociale to ' + this.localUtil.adminOrgname);
        }, this.handleError(true)
      );
    }

    this.organizationService.getCoripetInfo().subscribe(
      (data: any) => {
        this.localUtil.coripetOrgname = data.vatnumber;
        console.log('set coripet ragioneSociale to ' + this.localUtil.coripetOrgname);
      }, this.handleError(true)
    );
  }

  getLanguageDescr(data): string {
    if (data.lingua == 'IT') {
      data.linguaDescr = 'Italiano';
    } else if (data.lingua == 'EN') {
      data.linguaDescr = 'Inglese';
    }
    return data.lingua;
  }

  getRagioneSociale(data): string {
    data.ragioneSocialePlain = data.ragioneSociale;
    return data.ragioneSociale;
  }

  listOrganizations() {

    this.organizationService.getOrganizationList().subscribe(
      data => {

        data.list.forEach((org: OrganizationItem) => {
          try {

            let dataSplitted: any[] = org.dataCreazione.split('/');//AAAA/MM/GG
            if (dataSplitted) {
              org.dataCreazione = dataSplitted[2] + '/' + dataSplitted[1] + '/' + dataSplitted[0];
            }
            this.organizations_list.push(org);
          }
          catch (err) {
            let params: any[] = [org.userId];
            this.localUtil.showError("", ConstantsModule.ERROR_TITLE, this.messageService.getLabelFromCode("E:GENERIC", params));
          }
        });
      },
      this.handleError(true)
    );
  }

  refAziendaleCheckBox() {
    this.listOperators();
  }

  operAziendaleCheckBox() {
    this.listOperators();
  }

  operCoripetCheckBox() {
    this.listOperators();
  }

  refAziendale: boolean = true;
  operAziendale: boolean = true;
  operCoripet: boolean = true;

  listOperators() {
    this.operators_list = [];
    let list_to_add: UserItem[] = [];
    this.organizationService.getUserList().subscribe(
      (data: any) => {
        console.log('setting operators list: ' + data.list.length);
        if (data.list) {
          data.list.forEach((item, index) => {

            if (item.deleted == "Y") {
              item.cssClass = "disabled";
              item.status = "Eliminato";
            } else if (item.disabled == "Y") {
              item.cssClass = "disabled";
              item.status = "Disabilitato";
            } else if (item.validated == "N") {
              item.cssClass = "pending";
              item.status = "In attesa di verifica";
            } else if (item.disabled == "N") {
              item.cssClass = "active";
              item.status = "Attivo";
            }

            if (item.role == "OPERATORE") {
              item.roleDescr = "Operatore Coripet";
            } else if (item.role == "OPER_PLASTICFINDER") {
              item.roleDescr = "Admin";
            } else if (item.role == "OPER_AZIENDALE") {
              item.roleDescr = "Operatore Aziendale";
            } else if (item.role == "REF_AZIENDALE") {
              item.roleDescr = "Referente Aziendale";
            }
            console.log(item);
            list_to_add.push(item);

          });

        }
        //this.operators_list = list_to_add;
        let list = [];
        list = list_to_add;
        list.forEach(data => {
          if (
            (this.refAziendale && data.role === 'REF_AZIENDALE') ||
            (this.operAziendale && data.role === 'OPER_AZIENDALE') ||
            (this.operCoripet && data.role === 'OPERATORE')
          ) {
            this.operators_list.push(data);
          }
        });

        if (!this.refAziendale && !this.operAziendale && !this.operCoripet) {
          this.operators_list = [];
        }


      });
  }

  onRowExpanding(e) {
    console.log(e);
    this.datagridOperatori.instance.cancelEditData();
    this.datagridOperatori.instance.collapseAll(-1);
  }

  onRowDelete(e) {
    console.log(e);
    console.log('onRowDelete()');
    let user: UserItem = e.data;
    console.log(user);
    this.organizationService.deleteUser(user.email).subscribe(
      data => {
        this.localUtil.showMessage("", ConstantsModule.INFO_TITLE, this.messageService.getMessageFromCode("OK_OPERATION"), true);
        this.listOperators();
      }, this.handleError(true)
    );
  }

  onRowUpdate(e) {
    console.log(e);
    let user: UserItem = $.extend({}, e.oldData, e.newData);
    console.log(user);
    this.organizationService.updateUser(user).subscribe(
      data => {
        this.localUtil.showMessage("", ConstantsModule.INFO_TITLE, this.messageService.getMessageFromCode("OK_OPERATION"), true);
        this.listOperators();
      }, this.handleError(true)
    );
  }

  validatePassword(e, pwd: string, confirmPwd: string) {
    console.log('validatePassword()');
    console.log(e);
    let output = false;
    if (pwd && confirmPwd) {
      if (pwd != confirmPwd) {
        //ERROR_AZ_OP_CREATE_PASSWORD_NOT_EQUAL
        this.localUtil.showMessage("", ConstantsModule.ERROR_TITLE, this.messageService.getLabelFromCode("ERROR_AZ_OP_CREATE_PASSWORD_NOT_EQUAL"));
      } else if (pwd.length < 6) {
        //ERROR_AZ_OP_CREATE_PASSWORD_LENGTH
        this.localUtil.showMessage("", ConstantsModule.ERROR_TITLE, this.messageService.getLabelFromCode("ERROR_AZ_OP_CREATE_PASSWORD_LENGTH"));
      } else {
        output = true;
      }
    }
    if (!output) {
      e.cancel = true;
    }
    return output;
  }

  onRowInsert(e) {
    console.log(e);
    let user: OrganizationOperatorCreateItem = e.data;

    console.log(user);
    if (this.validatePassword(e, e.data.password, e.data.confermaPassword)) {
      if (user.role == ConstantsModule.ROLE_ADMIN) {
        this.organizationService.createAdminUser(e.data.ragioneSociale, user).subscribe(
          data => {
            this.localUtil.showMessage("", ConstantsModule.INFO_TITLE, this.messageService.getMessageFromCode("OK_OPERATION"), true);
            this.listOperators();
          }, this.handleError(true)
        );
      } else if (user.role == ConstantsModule.ROLE_CORIPET) {
        this.organizationService.createCoripetUser(e.data.ragioneSociale, user).subscribe(
          data => {
            this.localUtil.showMessage("", ConstantsModule.INFO_TITLE, this.messageService.getMessageFromCode("OK_OPERATION"), true);
            this.listOperators();
          }, this.handleError(true)
        );
      } else {
        this.localUtil.showMessage("", ConstantsModule.INFO_TITLE, this.messageService.getLabelFromCode("WRONG_ROLE"), true);
      }
    }
  }

  getOperatorStatusClass(itemOp: UserOperatorItem) {
    let output = '';
    if (itemOp.disabled == "Y") {
      output = "disabled";
      itemOp.status = "Disabilitato";
    } else if (itemOp.validated == "") {
      output = "pending";
      itemOp.status = "In attesa di verifica";
    } else if (itemOp.validated == "N") {
      output = "disabled";
      itemOp.status = "Rifiutato";
    } else if (itemOp.validated == "Y") {
      output = "active";
      itemOp.status = "Attivo";
    }
    return output;
  }

  getOperatorStatus(itemOp: UserOperatorItem) {
    let output = '';
    if (itemOp.disabled == "Y") {
      output = "Disabilitato";
    } else if (itemOp.validated == "") {
      output = "In attesa di verifica";
    } else if (itemOp.validated == "N") {
      output = "Rifiutato";
    } else if (itemOp.validated == "Y") {
      output = "Attivo";
    }
    return output;
  }

  onContentReady(event) {
    //$('.dx-datagrid-headers').hide();
    //$('.dx-hidden').hide();
    //$('.operators-list colgroup col').attr('style', 'width: auto;');
  }

  getRolesList() {
    return this.role_list;
  }



  aggiornaOperatore(data) {

    this.organizationService.updateUser(data).subscribe(
      (res) => {
        this.localUtil.showMessage("", ConstantsModule.INFO_TITLE, this.messageService.getLabelFromCode("GENERIC_SUCCESS_MESSAGE_SAVE"), true);
        this.listOperators();
      },
      this.handleError(true)
    );

  }

  disabilitaOperatore(data) {

    console.log(data);

    this.organizationService.disableUser(data.email).subscribe(
      (res) => {
        this.localUtil.showMessage("", ConstantsModule.INFO_TITLE, this.messageService.getLabelFromCode("SUCCESS_USER_DISABLED"), true);
        this.listOperators();
      },
      this.handleError(true)
    );

  }

  abilitaOperatore(data) {

    console.log(data);

    this.organizationService.enableUser(data.email).subscribe(
      (res) => {
        this.localUtil.showMessage("", ConstantsModule.INFO_TITLE, this.messageService.getLabelFromCode("SUCCESS_USER_ENABLED"), true);
        this.listOperators();
      },
      this.handleError(true)
    );

  }

  isOperatorEnabled(e) {
    if (e.row.data.status == ConstantsModule.OPERATOR_STATUS_ACTIVE) {
      return false;
    } else {
      return true;
    }
  }

  isOperatorDisabled(e) {
    if (e.row.data.status == ConstantsModule.OPERATOR_STATUS_NOT_ACTIVE) {
      return false;
    } else {
      return true;
    }
  }

  enableOperator(e) {
    console.log('enableOperator()');
    console.log(e);
    this.abilitaOperatore(e.row.data);
  }

  disableOperator(e) {
    console.log('disableOperator()');
    console.log(e);
    this.disabilitaOperatore(e.row.data);
  }

  eliminaOperatore(data) {

    let params: any[] = [data.nome, data.cognome, data.email];
    const modalRef = this.modalService.open(ConfirmComponent);
    modalRef.componentInstance.titolo = this.messageService.getLabelFromCode("DELETE_OPERATOR_TITLE");
    modalRef.componentInstance.messaggio = this.messageService.getLabelFromCode("DELETE_OPERATOR_MESSAGE", params);
    modalRef.componentInstance.passEntry.subscribe((receivedEntry: any) => {
      if (receivedEntry != undefined) {

        //let confirm = receivedEntry.trim();// non serve nessun parametro di ritorno da popup confirm

        this.organizationService.deleteUser(data.email).subscribe(
          (res) => {
            this.localUtil.showMessage("", ConstantsModule.INFO_TITLE, this.messageService.getLabelFromCode("SUCCESS_OPERATOR_DELETE"), true);
            this.listOperators();
          },
          this.handleError(true)
        );

      }
    });


  }

  onRowClick(e) {
    console.log(e.data);
    this.router.navigate(['operatore-aziende-dettaglio.html/', e.data.idOrganization]);
  }

  onRowPrepared(info) {
    if (info.rowType === 'data') {
      //console.log('onRowPrepared() info.rowElement: ' + info.rowElement);    
      info.rowElement.addClass('auction');
    }
  }

}