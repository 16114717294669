import { Location } from '@angular/common';
import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { DxDataGridComponent } from 'devextreme-angular';
import ExcellentExport, { ConvertOptions, SheetOptions } from 'excellentexport';
import { PopupComponent } from 'src/app/commons/components/popup/popup.component';
import { AuctionItem, AuctionLotItem, CentroSelezioneItem, NazioneItem } from 'src/app/models/dataModels';
import { APIService } from 'src/app/services/APIService';
import { AuctionService } from 'src/app/services/auction.service';
import { CommonService } from 'src/app/services/common.service';
import { ConstantsModule } from 'src/app/services/constants.module';
import { LocalUtilService } from 'src/app/services/local-util.service';
import { MessagesService } from 'src/app/services/messages.service';

@Component({
  selector: 'app-aste-vinte-dettaglio',
  templateUrl: './aste-vinte-dettaglio.component.html',
  styleUrls: ['./aste-vinte-dettaglio.component.css']
})
export class AsteVinteDettaglioComponent extends APIService implements OnInit {
    
  @ViewChild("dataGridLots", { static: false }) dataGridLots: DxDataGridComponent;

  constructor(localUtil: LocalUtilService,
    public auctionService: AuctionService,
    private modalService: NgbModal,
    private messageService: MessagesService,
    location: Location, 
    router: Router,
    private route: ActivatedRoute,
    public commonService: CommonService) {
    super(localUtil, location, router);
    console.log('AsteVinteDettaglioComponent()');
  }

  auction: AuctionItem;
  codiceAsta: string;
  lot_list: AuctionLotItem[] = [];
  cs_list: CentroSelezioneItem[] = [];
  nation_list: NazioneItem[] = [];
  exportComplete: boolean = false;

  customizeItemTemplate(item: any) {
    item.template = "formItem";
  }

  ngOnInit(): void {
    this.codiceAsta = this.route.snapshot.paramMap.get('codiceAsta');
    console.log('codiceAsta: ' +  this.codiceAsta);
    this.loadCentriSelezione();
    this.loadNazioni();
    this.loadAuctionDetails(undefined, true);
  }

  loadNazioni() {
    this.commonService.getNationsList().subscribe(
        data => {
          this.nation_list = data.list;
      });
  }

  loadCentriSelezione() {
    this.auctionService.getSelectionCenterList().subscribe(
      data => {
        this.cs_list = data.list;
    });
  }

  getLotByCode(codiceLotto:string) {
    let output = undefined;
    //console.log('getLotByCode() this.lot_list: ' + JSON.stringify(this.lot_list));
    if (this.lot_list != undefined) {
        this.lot_list.forEach((lotElem:any) => {
            if (lotElem.codiceLotto == codiceLotto) {
                output = lotElem;
            }
        });
    }
    return output;
  }

  loadAuctionDetails(textFilter, onlywon) {      
      console.log('loadAuctionDetails() onlywon = ' + onlywon);
      var qparam = '';
      if (onlywon != undefined) {
          qparam = '?onlywon=' + onlywon;
      }


      this.auctionService.getAuctionLots(this.codiceAsta, qparam).subscribe(
        (data:any) => { 
          console.log('data.auctionDetail: ' + JSON.stringify(data.auctionDetail));
          this.auction = data.auctionDetail;
          /*this.statoAsta = data.auctionDetail.statoAsta;
          this.tipologiaAsta = data.auctionDetail.tipologiaAsta;
          this.dataOraFineAsta = data.auctionDetail.dataOraFineAsta;*/
          if (data.lotList != undefined) {
            this.auction.numLotti = data.lotList.length;
            //console.log('lot_list: ' + data.lotList);

            data.lotList.forEach(item => {
                item.prezzoUltimoRilancio = this.localUtil.formatNumber2(item.prezzoUltimoRilancio);
                item.classAdmin='hidden';
                let toInsert = false;
                if (textFilter != undefined && textFilter != null && textFilter != '') {
                    if (JSON.stringify(item).indexOf(textFilter) > -1) {
                        toInsert = true;
                    }
                } else {
                    toInsert = true;
                }

                item.toCssClass = function () {
                    return function (text, render) {
                        //utilities.printLog("toCssClass " + render(text) + " " + render);
                        return render(text).toLowerCase().split(' ').join('-');
                    }
                }
                if (item.pagato == 0) {
                    item.paid = "Non pagato";
                    item.paidClass = "ko";
                } else if (item.pagato == 1) {
                    item.paid = "Pagato";
                    item.paidClass = "ok";                            
                }
                if (item.spedito == 0) {
                    item.retired = "Non ritirato";
                    item.retiredClass = "ko";                            
                } else if (item.spedito == 1) {
                    item.retired = "Ritirato";
                    item.retiredClass = "ok";
                }
                
                item.existsCS = function () {
                    return (this.centroSelezione != undefined && this.centroSelezione.length > 0);
                }
                item.existsScheda = function () {
                    return (this.schedaTecnica != '' && this.schedaTecnica != undefined);
                }
                item.notExistsScheda = function () {
                    return (this.schedaTecnica == '' || this.schedaTecnica == undefined);
                }
                
                item.notExistsScheda = function () {
                    return (this.schedaTecnica == '' || this.schedaTecnica == undefined);
                }
                
                item.isOperator = function () {
                    var isOperator = true;
                    var user = this.localUtil
                    if (user.ruolo != ConstantsModule.OPER_PLASTICFINDER && user.ruolo != ConstantsModule.OPERATORE) {
                        isOperator = false;
                    }
                    return isOperator;
                }
                
                item.isNotOperator = function () {
                    var isNotOperator = true;
                    var user = JSON.parse(JSON.parse(sessionStorage.getItem("user")).user_detail);
                    if (user.ruolo == ConstantsModule.OPER_PLASTICFINDER || user.ruolo == ConstantsModule.OPERATORE) {
                        isNotOperator = false;
                    }
                    return isNotOperator;
                }
                
                
                item.paidChecked = item.pagato == 1;
                item.retiredChecked = item.spedito == 1;
                item.notPaidChecked = item.pagato == 0;
                item.notRetiredChecked = item.spedito == 0;

                item.codiceLottoNoPoints = this.localUtil.getCodiceLottoNoPoints(item.codiceLotto);

                if (item.nomeOrganization == undefined) {
                    item.nomeOrganization = '-';
                }
                
            });

            this.lot_list = data.lotList;
            console.log('lot_list: ' + this.lot_list.length);
          }
        });
        
  }

  onContentReady(event) {
    console.log('contenReady');
    $('.dx-datagrid-headers').remove();
    $('.dx-datagrid-rowsview').css('border-top', 'none');
    $('.dx-datagrid-header-panel').css('border-bottom', 'none');
  }

  gotoListaAsteVinte() {
    this.router.navigate([ConstantsModule.REF_AZ_ASTE_VINTE_PATH, {}]);
  }

  getSchedaTecnicaUrl(tipoProdotto: string) {
      return ConstantsModule.S3_URL + '/scheda_tecnica/scheda-tecnica-' + tipoProdotto + '.pdf';
  }

  openNote(note:string, codiceLotto: string) {
    const modalRef = this.modalService.open(PopupComponent);
    modalRef.componentInstance.tipo = 'openNote';
    modalRef.componentInstance.note = note;
    modalRef.componentInstance.codiceLotto = codiceLotto;    
  }

  openCentriSelezione(codiceLotto: string) {
    const modalRef = this.modalService.open(PopupComponent);
    modalRef.componentInstance.tipo = 'openCentriSelezione';
    modalRef.componentInstance.titolo = 'Centri di selezione';
    modalRef.componentInstance.codiceLotto = codiceLotto;
    console.log('openCentriSelezione() codiceLotto: ' + codiceLotto);
    let lotElem = this.getLotByCode(codiceLotto);
    let csListPopup: CentroSelezioneItem[] = [];
    console.log(lotElem);
    if (lotElem != undefined && lotElem.centroSelezione != undefined) {
        lotElem.centroSelezione.forEach(csLottoElem => {
            let item = new CentroSelezioneItem();
            item.quantitaTon = lotElem.quantitaTon;
            item.indirizzo = '';
            item.pesoMedioCarico = '';

            let cs = this.localUtil.getSelectionCenterByCodice(csLottoElem.codiceCentroSelezione, this.cs_list);
            if (cs != undefined) {
                
                let nazione = this.localUtil.getNazioneByCodice(cs.nazione, this.nation_list);
                console.log('nazione: ' + JSON.stringify(nazione));
                let nazioneDescr = '';
                if (nazione != null && nazione != undefined && nazione != '') {
                    nazioneDescr = nazione.descrizione;
                    if (nazione.codice == 'IT') {
                        nazioneDescr = 'Italia';
                    }
                    item.nazioneDescr = nazioneDescr;
                }
            }
            item.CER = cs.CER;
            item.cap = cs.cap;
            item.citta = cs.citta;
            item.provincia = cs.provincia;
            item.pesoMedioCarico = cs.pesoMedioCarico;
            item.nome = cs.nome;
            item.indirizzo = cs.indirizzo;
            csListPopup.push(item);
        });
    }
    modalRef.componentInstance.centriSelezione = csListPopup;

  }

  onExporting(e) {
    this.exportComplete = false;
    e.component.beginUpdate();
    for (let i=0; i< this.dataGridLots.instance.columnCount(); i++) {
      if (this.dataGridLots.instance.columnOption(i).type != 'buttons' && this.dataGridLots.instance.columnOption(i).dataField != undefined) {
        this.dataGridLots.instance.columnOption(i, 'visible', true);
      }
    }
  }

  onExported(e) {
    for (let i=0; i< this.dataGridLots.instance.columnCount(); i++) {
      if (this.dataGridLots.instance.columnOption(i).type != 'buttons' && this.dataGridLots.instance.columnOption(i).dataField != undefined) {
        this.dataGridLots.instance.columnOption(i, 'visible', false);
      }
    }
    this.dataGridLots.instance.columnOption(1, 'visible', true);
    e.component.endUpdate();
    this.exportComplete = true;
  }

}
