<div id="dashboard" class="content top100"> <!-- ngIf="isActiveComponent()"-->
    <!-- <h2 translate>dashboardPage.dashboard</h2> -->

    <app-active-auction-banner *ngIf="isEnabledToAuction()"></app-active-auction-banner>
    <app-next-auction-countdown *ngIf="isEnabledToAuction()"></app-next-auction-countdown>

    <div class="auctions-list" #masonry>
        <div class="row">
            <div class="col-lg-4">
                <!-- azienda validata -->
                <span *ngIf="boxOrgInvalid != undefined">
                    <div class="auction-item profile-error">
                        <article class="card-with-actions">
                            <h4>{{boxOrgInvalid.titolo}}</h4>
                            <div class="percentage-zero">{{boxOrgInvalid.testo}}</div>
                        </article>
                    </div>
                </span>
                <!-- percentuale box -->
                <span *ngFor="let item of boxDocumenti; let i = index">
                    <div *ngIf="item.documento == 'progress'" class="auction-item"
                        class="auction-item profile-progress {{item.classe}}"
                        [ngClass]="{'profile-progress': item.percentageNum == 100, 'profile-error': item.percentageNum < 100}">
                        <article class="card-with-actions">
                            <h4>{{item.titolo}}</h4>
                            <div class="percentage">{{item.percentage}}</div>
                            <p>{{item.testo}}</p>
                        </article>
                    </div>
                </span>
            </div>
            <div class="col-lg-4">
                <div class="auction-long-item auction-item">
                    <article class="card-with-actions">
                        <h1 translate>dashboardPage.come-funziona-aucircle</h1>
                        <div class="article-content-left">
                            <p translate>dashboardPage.qui-puoi-scaricare</p>
                        </div>
                        <div class="article-content-right">
                            <ul>
                                <li>
                                    <a href="assets/regolamenti/04_Regolamento_e_Condizioni Generali aste PET CORIPET.pdf"
                                        target="_blank" class="download">
                                        <div class="download-regolamento" data="regolamento"
                                            data-uuid="04_Regolamento_e_Condizioni Generali aste PET CORIPET.pdf"><i
                                                class="fal fa-file-pdf right10"></i>
                                            <div class="filename-download">
                                                <p translate>
                                                    dashboardPage.regolamento-e-condizioni-generali-aste-PET-CORIPET</p>
                                            </div>
                                        </div>
                                    </a>
                                </li>
                                <li>
                                    <a href="assets/regolamenti/CORIPET_portale aste_Informativa Privacy_20180107.pdf"
                                        target="_blank" class="download">
                                        <div class="download-regolamento" data="regolamento"
                                            data-uuid="CORIPET_portale aste_Informativa Privacy_20180107.pdf"><i
                                                class="fal fa-file-pdf right10"></i>
                                            <div class="filename-download">
                                                <p translate>dashboardPage.informativa-privacy</p>
                                            </div>
                                        </div>
                                    </a>
                                </li>
                                <li>
                                    <a href="assets/regolamenti/Aucircle-documentazione-generale.pdf" target="_blank"
                                        class="download">
                                        <div class="download-regolamento" data="regolamento"
                                            data-uuid="Aucircle-documentazione-generale.pdf"><i
                                                class="fal fa-file-pdf right10"></i>
                                            <div class="filename-download">
                                                <p translate>dashboardPage.linee-guida</p>
                                            </div>
                                        </div>
                                    </a>
                                </li>
                            </ul>
                        </div>

                    </article>
                    <span class="regolamento-action">
                        <span class="arrow">
                            &nbsp;
                        </span>
                    </span>
                </div>
            </div>

            <div class="col-lg-4">
                <span *ngFor="let item of boxArchivio; let i = index">
                    <div class="auction-item">
                        <article class="card-with-actions">
                            <h1 translate>dashboardPage.archivio-aste</h1>
                            <div class="download-description" translate>
                                dashboardPage.vedi-le-informazioni
                            </div>
                            <ul>
                                <li>
                                    <div class="download-report-big download-file align-items-center"
                                        (click)="downloadFile(item.uuid, item.filename)" id="{{item.id}}" data="report"
                                        title="Report asta {{item.codiceAsta}}"><i class="fal fa-file-pdf right10 mt-3"></i>
                                        <div class="filename-report">
                                            <p translate>dashboardPage.scarica-ultimo-report-dell-asta</p>
                                            {{item.codiceAsta}}
                                        </div>
                                    </div>
                                </li>
                            </ul>
                        </article>
                        <span class="card-action">
                            <span class="arrow">
                                <a routerLink="/aste-chiuse.html"><i class="far fa-arrow-right"></i></a>
                            </span>
                        </span>
                    </div>
                </span>
            </div>
        </div>

          <!-- impianti -->
        <div class="row">
            <span *ngFor="let item of boxImpianti; let i = index">
                <div *ngIf="item.available" class="auction-item" [ngClass]="{'hidden': item.classe == 'success'}">
                    <a routerLink="/azienda-impianti">
                        <article class="card-with-actions">
                            <h1>Impianti e produttivit&agrave;</h1>
                            <h2 class="box status {{item.classe}}">{{item.titolo}}</h2>
                            <div class="number">{{i+5}}</div>
                            <p>{{item.testo}}</p>
                        </article>
                        <span class="card-action">
                            <span class="arrow">
                                <i class="far fa-arrow-right"></i>
                            </span>
                        </span>
                    </a>
                </div>
            </span>
        </div>



        
       
        

      
        <!-- regolamenti -->

        <!-- archivio aste -->

    </div>
<!-- documenti -->
<div class="auctions-list" #masonry style="margin-top:  2rem!important; margin-bottom: 2rem!important;">
    <div *ngFor="let item of boxDocumenti; let i = index">
        <div class="auction-item" [style.display]="item.documento == 'progress' ? 'none' : 'block'" *ngIf="item.documento != 'progress'">
            <a [routerLink]="[getRouteForItem(item)]">
                <article class="card-with-actions">
                    <h1>Documento {{item.documento}}</h1>
                    <h2 class="box status {{item.classe}}">{{item.titolo}}</h2>
                    <div class="number">{{i}}</div>
                    <p>{{item.testo}}</p>
                </article>
                <span class="card-action">
                    <span class="arrow">
                        <i class="far fa-arrow-right"></i>
                    </span>
                </span>
            </a>
        </div>
    </div>
</div>



</div>
<script>
    $(document).ready(function () {
        $('.auctions-list').masonry({ itemSelector: '.auction-item', columnWidth: 330, gutter: 30 });
    });
</script>