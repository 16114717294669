<div class="p-4 bottom80">
    <h2 translate>operatori.operatori</h2>
    <div id="operators-list" class="auctions-list">
        <dx-data-grid id="gridContainerOperators" [dataSource]="operators_list" #datagridOperatori keyExpr="id"
            [allowColumnReordering]="true" [allowColumnResizing]="true" [showBorders]="true"
            [columnHidingEnabled]="false" (onEditingStart)="onEditingStart($event)"
            (onEditorPreparing)="onEditorPreparing($event)" (onEditorPrepared)="onEditorPrepared($event)"
            (onInitNewRow)="onInitNewRow($event)" (onRowUpdating)="onRowUpdate($event)"
            (onRowInserting)="onRowInsert($event)" (onRowRemoving)="onRowDelete($event)"
            (onRowExpanding)="onRowExpanding($event)"
            (onRowPrepared)="onRowPrepared($event)"><!--(onRowClick)="onRowClick($event)" (onContentReady)="onContentReadyOperators($event)" (onEditingStart)="onRowEditPlant($event)"-->

            <dxo-column-chooser [enabled]="true" [width]="300" [allowSearch]="true" [enabled]="true" mode="dragAndDrop"
                title="Seleziona colonne"></dxo-column-chooser>
            <dxo-export [enabled]="true" fileName="operators_list"></dxo-export>

            <dxo-load-panel [enabled]="true"></dxo-load-panel>
            <dxo-filter-row [visible]="false"></dxo-filter-row>
            <dxo-header-filter [visible]="false"></dxo-header-filter>
            <dxo-scrolling mode="infinite"></dxo-scrolling>

            <dxo-search-panel [width]="250" [visible]="true" [highlightCaseSensitive]="false"
                placeholder="{{'search'|translate}}">
            </dxo-search-panel>

            <!-- edit form and columns -->
            <dxi-column dataField="id" [formItem]="{ visible: false }" [allowEditing]="false"
                caption="{{'operatori.Id' | translate}}" alignment="left" [visible]="false">
            </dxi-column>
            <dxi-column dataField="nome" caption="{{'operatori.Nome' | translate}}" [allowEditing]="true"
                dataType="string" [visible]="true" cssClass="nome">
                <dxi-validation-rule type="required"></dxi-validation-rule>
            </dxi-column>
            <dxi-column dataField="cognome" caption="{{'operatori.Cognome' | translate}}" [allowEditing]="true"
                dataType="string" [visible]="true" cssClass="cognome">
                <dxi-validation-rule type="required"></dxi-validation-rule>
            </dxi-column>
            <dxi-column dataField="dataRegistrazione" [formItem]="{ visible: false }" [allowEditing]="false"
                caption="{{'operatori.Data registrazione' | translate}}" dataType="string" format="dd/MM/yyyy"
                [visible]="false">
            </dxi-column>
            <dxi-column dataField="role" caption="{{'operatori.Ruolo' | translate}}" [allowEditing]="true"
                dataType="string" [visible]="true"  cssClass="ruolo">
                <dxo-lookup [dataSource]="getRolesList()" valueExpr="codice" displayExpr="descrizione"></dxo-lookup>
            </dxi-column>
            <dxi-column dataField="email" caption="{{'operatori.E-mail' | translate}}" [allowEditing]="false"
                dataType="string" [visible]="false">
                <dxi-validation-rule type="required"></dxi-validation-rule>
                <dxi-validation-rule type="email"></dxi-validation-rule>
            </dxi-column>
            <dxi-column dataField="telefono" caption="{{'operatori.Telefono-fisso' | translate}}" [allowEditing]="true"
                dataType="string" [visible]="false">
                <dxi-validation-rule type="required"></dxi-validation-rule>
            </dxi-column>
            <dxi-column dataField="mobile" caption="{{'operatori.Telefono-mobile' | translate}}" [allowEditing]="true"
                dataType="string" [visible]="false">
                <dxi-validation-rule type="required"></dxi-validation-rule>
            </dxi-column>
            <dxi-column dataField="status" [formItem]="{ visible: false }" [allowEditing]="false"
                caption="{{'operatori.Status' | translate}}" dataType="string" [visible]="true" cssClass="status"
                cellTemplate="templateOperatorStatus">
                <div *dxTemplate="let templateOperatorStatus of 'templateOperatorStatus'">
                    <span class="{{templateOperatorStatus.data.cssClass}}">{{templateOperatorStatus.data.status}}</span>
                </div>
            </dxi-column>
            <dxi-column dataField="password" caption="{{'operatori.Password' | translate}}" [allowEditing]="true"
                dataType="password" [visible]="false">
            </dxi-column>
            <dxi-column dataField="confermaPassword" caption="{{'operatori.Conferma-password' | translate}}"
                [allowEditing]="true" dataType="password" [visible]="false">
            </dxi-column>
            <dxi-column dataField="lingua" caption="{{'operatori.Lingua' | translate}}"
                [calculateCellValue]="getLanguageDescr" [allowEditing]="true" dataType="string" [visible]="true" cssClass="lingua">
                <dxi-validation-rule type="required"></dxi-validation-rule>
                <dxo-lookup [dataSource]="language_list" valueExpr="codice" displayExpr="descrizione"></dxo-lookup>
            </dxi-column>
            <dxi-column dataField="ragioneSociale" [formItem]="{ visible: false }"
                caption="{{'operatori.Ragione-sociale-azienda' | translate}}" [allowEditing]="false" dataType="string"
                [visible]="true" [allowExporting]="true" cssClass="ragione-sociale referentName">
            </dxi-column>
            <dxi-column dataField="ragioneSocialePlain" [calculateCellValue]="getRagioneSociale"
                [formItem]="{ visible: false }" caption="{{'operatori.Ragione-sociale-azienda' | translate}}"
                [allowExporting]="true" dataType="string" [visible]="false">
            </dxi-column>
            <dxi-column dataField="validated" [formItem]="{ visible: false }" [allowEditing]="false"
                caption="{{'operatori.Validated' | translate}}" dataType="string" [visible]="false">
            </dxi-column>
            <dxi-column dataField="disabled" caption="{{'operatori.Disabled' | translate}}" [allowEditing]="false"
                [formItem]="{ visible: false }" dataType="string" [visible]="false">
            </dxi-column>
            <dxi-column dataField="deleted" caption="{{'operatori.Deleted' | translate}}" [allowEditing]="false"
                [formItem]="{ visible: false }" dataType="string" [visible]="false">
            </dxi-column>

            <dxi-column type="buttons" [width]="110">
                <dxi-button name="edit"></dxi-button>
                <dxi-button name="delete"></dxi-button>
                <dxi-button hint="Abilita" icon="check" [visible]="isOperatorEnabled"
                    [onClick]="enableOperator"></dxi-button>
                <dxi-button hint="Disabilita" icon="clearsquare" [visible]="isOperatorDisabled"
                    [onClick]="disableOperator"></dxi-button>
            </dxi-column>

            <dxo-toolbar>
                <dxi-item location="before">
                    <div *dxTemplate>
                        <div class="mt-2">
                            <div class="input full form-check form-check-inline">
                                <input class="form-check-input" type="checkbox" id="refAziendale" class="filter"
                                    value="Referenti aziendali" [(ngModel)]="refAziendale"
                                    (click)="refAziendaleCheckBox()">
                                <label class="form-check-label" for="refAziendale" translate>Referenti aziendali</label>
                            </div>
                            <div class="input full form-check form-check-inline">
                                <input class="form-check-input" type="checkbox" id="operAziendale" class="filter"
                                    value="Operatori aziendali" [(ngModel)]="operAziendale"
                                    (click)="operAziendaleCheckBox()">
                                <label class="form-check-label" for="operAziendale" translate>Operatori
                                    aziendali</label>
                            </div>
                            <div class="input full form-check form-check-inline">
                                <input class="form-check-input" type="checkbox" id="operCoripet" class="filter"
                                    value="Operatori Coripet" [(ngModel)]="operCoripet" (click)="operCoripetCheckBox()">
                                <label class="form-check-label" for="operCoripet" translate>Operatori Coripet</label>
                            </div>
                        </div>
                    </div>
                </dxi-item>
                <dxi-item location="before" name="searchPanel"></dxi-item>
                <dxi-item name="exportButton"></dxi-item>
            </dxo-toolbar>

            <dxo-master-detail [enabled]="false" template="detailOperator"></dxo-master-detail>

            <!--  <div *dxTemplate="let detailOperator of 'detailOperator'">

                <dx-form [(formData)]="detailOperator.data">
                
                    <dxi-item itemType="group" caption="Dati operatore" [colSpan]="20" cssClass="top20 detail-form">
                        <dxi-item itemType="group" [colCount]="2" cssClass="detail-form-section">
                            <dxi-item dataField="nome" [label]="{text: 'Nome'}"></dxi-item>
                            <dxi-item dataField="cognome" [label]="{text: 'Cognome'}"></dxi-item>
                        </dxi-item>
                        <dxi-item itemType="group" [colCount]="2" cssClass="detail-form-section">
                            <dxi-item dataField="email" [colSpan]="10"></dxi-item>
                            <dxi-item dataField="ragioneSociale" [colSpan]="10"></dxi-item>
                        </dxi-item>
                        <dxi-item itemType="group" [colCount]="2" cssClass="detail-form-section">
                            <dxi-item dataField="telefono" [colSpan]="9"></dxi-item>
                            <dxi-item dataField="mobile" [colSpan]="9"></dxi-item>
                        </dxi-item>
                        <dxi-item itemType="group" [colCount]="1" cssClass="detail-form-section">
                            <dxi-item dataField="linguaDescr" [label]="{text: 'Lingua'}" [colSpan]="2"></dxi-item>
                        </dxi-item>                            
                    </dxi-item>

                </dx-form>
            </div> -->

            <dxo-editing [allowUpdating]="true" [allowAdding]="true" [allowDeleting]="true" mode="form">

                <dxo-texts confirmDeleteMessage="Sei sicuro di voler eliminare questo operatore ?"></dxo-texts>

                <dxo-form [colCount]="20">

                    <dxi-item itemType="group" caption="Dati operatore" [colSpan]="20" cssClass="top20 detail-form">
                        <dxi-item itemType="group" [colCount]="2" cssClass="detail-form-section">
                            <dxi-item dataField="nome" [label]="{ text: 'operatori.Nome' | translate }"></dxi-item>

                            <dxi-item dataField="cognome" [label]="{text: 'operatori.Cognome' | translate }"></dxi-item>
                        </dxi-item>
                        <dxi-item itemType="group" [colCount]="2" cssClass="detail-form-section">
                            <dxi-item dataField="email" [label]="{text: 'operatori.E-mail' | translate }"
                                [colSpan]="13"></dxi-item>
                            <dxi-item dataField="role" [label]="{text: 'operatori.Ruolo' | translate }"
                                [colSpan]="7"></dxi-item>
                        </dxi-item>
                        <dxi-item itemType="group" [colCount]="2" cssClass="detail-form-section">
                            <dxi-item dataField="telefono" [label]="{text: 'operatori.Telefono-fisso' | translate }"
                                [colSpan]="10"></dxi-item>
                            <dxi-item dataField="mobile" [label]="{text: 'operatori.Telefono-mobile' | translate }"
                                [colSpan]="10"></dxi-item>
                        </dxi-item>
                        <dxi-item itemType="group" [colCount]="2" cssClass="detail-form-section">
                            <dxi-item dataField="password" [label]="{text: 'operatori.Password' | translate }"
                                [colSpan]="10"></dxi-item>
                            <dxi-item dataField="confermaPassword"
                                [label]="{text: 'operatori.Conferma-password' | translate }" [colSpan]="10"></dxi-item>
                        </dxi-item>

                    </dxi-item>

                </dxo-form>

            </dxo-editing>

        </dx-data-grid>
    </div>
</div>