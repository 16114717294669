<div class="p-3">
    <div class="add-item-section add-item-section-user">
        <div class="add-operator-panel" [ngClass]="{'hidden': !isNewEditing(), 'show': isNewEditing()}">
            <h3 *ngIf="firstButton === 'admin'" translate>Nuovo Admin</h3>
            <h3 *ngIf="secondButton === 'operator'" translate>Nuovo operatore Coripet</h3>
            <form id="form_createOperator" [formGroup]="addUserForm" class="form-group">
                <section class="step-section focused">
                    <div class="card">
                        <div class="d-flex">
                            <div class="input me-2" [ngClass]="{'error': controlHasError('nome')}">
                                <div class="input-wrapper full">
                                    <input required formControlName="nome" type="text" name="nome" id="nome"
                                        maxlength="75">
                                    <label for="" translate>Nome *</label>
                                </div>
                            </div>
                            <div class="input" [ngClass]="{'error': controlHasError('cognome')}">
                                <div class="input-wrapper full">
                                    <input required formControlName="cognome" type="text" name="cognome" id="cognome"
                                        maxlength="75">
                                    <label for="" translate>Cognome *</label>
                                </div>
                            </div>
                        </div>
                        <div class="input" [ngClass]="{'error': controlHasError('email')}">
                            <div class="input-wrapper full">
                                <input required formControlName="email" type="email" name="email" id="email"
                                    maxlength="75">
                                <label for="" translate>indirizzo email *</label>
                            </div>
                        </div>
                        <div class="d-flex">
                            <div class="input me-2" [ngClass]="{'error': controlHasError('password')}">
                                <div class="input-wrapper full">
                                    <input required formControlName="password" type="password" name="password"
                                        id="password" maxlength="75">
                                    <label for="" translate>Password *</label>
                                </div>
                                <span class="error"
                                    *ngIf="controlHasError('password')">{{getControlErrorMessage('password')}}</span>
                            </div>
                            <div class="input" [ngClass]="{'error': controlHasError('confermaPassword')}">
                                <div class="input-wrapper full">
                                    <input required formControlName="confermaPassword" type="password"
                                        name="confermaPassword" id="confermaPassword" maxlength="75">
                                    <label for="" translate>Conferma password *</label>
                                </div>
                                <span class="error"
                                    *ngIf="controlHasError('confermaPassword')">{{getControlErrorMessage('confermaPassword')}}</span>
                            </div>
                        </div>
                        <div class="d-flex">
                            <div class="input me-2" [ngClass]="{'error': controlHasError('telefono')}">
                                <div class="input-wrapper full">
                                    <input required formControlName="telefono" type="text" name="telefono" id="telefono"
                                        maxlength="75">
                                    <label for="" translate>Telefono *</label>
                                </div>
                            </div>
                            <div class="input" [ngClass]="{'error': controlHasError('mobile')}">
                                <div class="input-wrapper full">
                                    <input required formControlName="mobile" type="text" name="mobile" id="mobile"
                                        maxlength="75">
                                    <label for="" translate>Telefono mobile *</label>
                                </div>
                            </div>
                        </div>
                        <div class="input" [ngClass]="{'error': controlHasError('lingua')}">
                            <div class="input-wrapper full">
                                <select name="lingua" id="lingua" required formControlName="lingua"
                                    class="form-control">
                                    <option value="IT">Italy</option>
                                    <option value="EN">English</option>
                                </select>
                                <label for="" translate>Lingua *</label>
                            </div>
                        </div>
                    </div>
                    <div class="infos">
                        <div class="number-wrapper">
                            <div class="number">01</div>
                            <div class="label">
                                <span translate>Dati operatore</span>
                            </div>
                        </div>
                    </div>
                </section>
            </form>
        </div>
        <div class="container add-operator-actions" [ngClass]="{'hidden': !isNewEditing(), 'show': isNewEditing()}">
            <div class="pb-5 text-end">
                <button class="btn" id="btn_cancelCreate" (click)="annullaNuovoOperatore()"><span
                        translate>azienda-operatori.annulla</span></button>
                <button class=" btn" style="border-color: orange;color: orange;" type="submit" id="btn_createOperator"
                    (click)="salva()"><i class="fal fa-save"></i> <span
                        translate>azienda-operatori.salva</span></button>
            </div>
        </div>
        <div class="row">
            <div class="col-6">
                <button class="add add-operator new-operator-toggler" (click)="firstToogleButton()">
                    <article>
                        <div class="icon"><i class="fal fa-plus-circle"></i></div>
                        <div class="text"><span translate>Aggiuni admin</span></div>
                    </article>
                </button>
            </div>
            <div class="col-6">
                <button class="add add-operator new-operator-toggler" (click)="secondToogleButton()">
                    <article>
                        <div class="icon"><i class="fal fa-plus-circle"></i></div>
                        <div class="display">
                            <div class="text"><span translate>Aggiuni operatore</span></div>
                            <span><img src="assets/img/coripet.png" alt="" style="width: 90px; height: 25px;"></span>
                        </div>
                    </article>
                </button>
            </div>
        </div>
    </div>
</div>
<div class="px-3">
    <hr>
</div>

<div class="p-4"> <!-- ngIf="isActiveComponent()"-->
    <h2 translate>dashboardPage.dashboard</h2>
    <dx-accordion
        #accordion
        [dataSource]="pivot_accordion"
        [collapsible]="true"
        [multiple]="false"
        [animationDuration]="300"
        [selectedIndex]="undefined"
        (onItemClick)="bindPivotChart()"
        id="accordion-container">

        <div *dxTemplate="let item of 'title'">
            <div class="header">{{ item }}</div>
        </div>
        <div *dxTemplate="let item of 'item'">

            <div id="pivotgrid">
                <dx-chart id="sales-chart">
                    <dxo-common-series-settings type="bar"></dxo-common-series-settings>
                    <dxo-size [height]="200"></dxo-size>
                    <dxo-adaptive-layout [width]="450"></dxo-adaptive-layout>
                    <dxo-tooltip
                        [enabled]="true"
                        format="currency"
                        [customizeTooltip]="customizeTooltip"
                    ></dxo-tooltip>
                </dx-chart>
        
                <dx-pivot-grid
                    id="pivotgrid"
                    [allowSortingBySummary]="true"
                    [allowFiltering]="true"
                    [showBorders]="true"
                    [allowSortingBySummary]="true"
                    [showColumnGrandTotals]="false"
                    [showRowGrandTotals]="false"
                    [showRowTotals]="false"
                    [showColumnTotals]="false"
                    [dataSource]="pivotGridDataSource"
                    (onContentReady)="onContentReady($event)"
                    #pivotGrid
                >
                    <dxo-field-chooser [enabled]="true" [height]="400"></dxo-field-chooser>
                    <dxo-scrolling mode="virtual"></dxo-scrolling>
                </dx-pivot-grid>
            </div>

        </div>
    </dx-accordion>


    <!-- <div id="auctions-list" class="auctions-list">
        <dx-data-grid id="gridContainer" [dataSource]="dashBoardList" keyExpr="id" [allowColumnReordering]="false"
            [allowColumnResizing]="false" [showBorders]="true" [columnHidingEnabled]="false"
            (onRowPrepared)="onRowPrepared($event)" (onRowClick)="onRowClick($event)"
            (onContentReady)="onContentReady($event)">

            <dxi-column dataField="codiceAsta" cssClass="number" dataType="string" [visible]="true"
                cssClass="number"></dxi-column>
            <dxi-column dataField="id"></dxi-column>
            <dxi-column dataField="tipoProdotto"></dxi-column>
            <dxi-column dataField="areaGeografica"></dxi-column>
            <dxi-column dataField="codiceLotto"></dxi-column>
            <dxi-column dataField="createDate"></dxi-column>

        </dx-data-grid>
    </div> -->

    <h2 class="mt40" translate>dashboardPage.aste-disponibili</h2>
    <div id="aste-disponibili" class="auctions-list"  style="display: contents;">
        <dx-data-grid id="gridContainer" [dataSource]="auctions_list" keyExpr="id" [allowColumnReordering]="false"
            [allowColumnResizing]="false" [showBorders]="true" [columnHidingEnabled]="false"
            (onRowPrepared)="onRowPrepared($event)" (onContentReady)="onContentReady($event)"
            (onRowClick)="onRowClick($event)" (onRowPrepared)="onRowPrepared($event)">

            <dxo-load-panel [enabled]="true"></dxo-load-panel>
            <dxo-filter-row [visible]="false"></dxo-filter-row>

            <dxo-search-panel [visible]="false" [highlightCaseSensitive]="false">
            </dxo-search-panel>

            <!-- edit form and columns -->
            <dxi-column dataField="id" [allowEditing]="false" [width]="100" caption="{{'dashboardPage.id' | translate}}"
                alignment="left" [visible]="false">
            </dxi-column>
            <dxi-column dataField="userId" caption="{{'dashboardPage.userId' | translate}}" dataType="string"
                showEditorAlways="true" [visible]="false">
            </dxi-column>
            <dxi-column dataField="dataCreazione" caption="{{'dashboardPage.dataCreazione' | translate}}" [width]="150"
                dataType="date" format="dd/MM/yyyy" showEditorAlways="true" [visible]="false">
            </dxi-column>
            <dxi-column dataField="dataModifica" caption="{{'dashboardPage.dataModifica' | translate}}" dataType="date"
                format="dd/MM/yyyy" showEditorAlways="true" [visible]="false">
            </dxi-column>
            <dxi-column dataField="codiceAsta" caption="{{'dashboardPage.codiceAsta' | translate}}" dataType="string"
                [visible]="true" cssClass="auction-code number">
            </dxi-column>
            <dxi-column dataField="dataPubblicazione" caption="{{'dashboardPage.dataPubblicazione' | translate}}"
                dataType="string" [visible]="true" cssClass="publication-date">
            </dxi-column>
            <dxi-column dataField="dataOraInizioAsta" caption="{{'dashboardPage.dataOraInizioAsta' | translate}}"
                dataType="string" [visible]="true" cssClass="auction-start">
            </dxi-column>
            <dxi-column dataField="dataOraFineAsta" caption="{{'dashboardPage.dataOraFineAsta' | translate}}"
                dataType="string" [visible]="true" cssClass="auction-end-pubblica">
            </dxi-column>
            <dxi-column dataField="tipologiaAsta" caption="{{'dashboardPage.tipologiaAsta' | translate}}"
                dataType="string" [visible]="true" cssClass="tipologia-pubblica">
            </dxi-column>
            <dxi-column dataField="statoAsta" caption="{{'dashboardPage.statoAsta' | translate}}" dataType="string"
                [visible]="true" cellTemplate="statusTemplate">
            </dxi-column>

            <div *dxTemplate="let statusTemplate of 'statusTemplate'">
                <div class="stato-asta status {{statusTemplate.data.cssClass}}">{{statusTemplate.data.statoAsta}}</div>
            </div>

        </dx-data-grid>
    </div>

    <h2 class="" translate>dashboardPage.aste-in-bozza</h2>
    <div id="auctions-list" class="auctions-list" style="display: contents;">
        <dx-data-grid id="gridContainer" [dataSource]="bozza_List" keyExpr="id" [allowColumnReordering]="false"
            [allowColumnResizing]="false" [showBorders]="true" [columnHidingEnabled]="false"
            (onRowPrepared)="onRowPrepared($event)" (onContentReady)="onContentReady($event)"
            (onRowClick)="onRowClick($event)" (onRowPrepared)="onRowPrepared($event)">

            <dxo-load-panel [enabled]="true"></dxo-load-panel>
            <dxo-filter-row [visible]="false"></dxo-filter-row>

            <dxo-search-panel [visible]="false" [highlightCaseSensitive]="false">
            </dxo-search-panel>

            <!-- edit form and columns -->
            <dxi-column dataField="id" [allowEditing]="false" [width]="100" caption="{{'dashboardPage.id' | translate}}"
                alignment="left" [visible]="false">
            </dxi-column>
            <dxi-column dataField="userId" caption="{{'dashboardPage.userId' | translate}}" dataType="string"
                showEditorAlways="true" [visible]="false">
            </dxi-column>
            <dxi-column dataField="dataCreazione" caption="{{'dashboardPage.dataCreazione' | translate}}" [width]="150"
                dataType="date" format="dd/MM/yyyy" showEditorAlways="true" [visible]="false">
            </dxi-column>
            <dxi-column dataField="dataModifica" caption="{{'dashboardPage.dataModifica' | translate}}" dataType="date"
                format="dd/MM/yyyy" showEditorAlways="true" [visible]="false">
            </dxi-column>
            <dxi-column dataField="codiceAsta" caption="{{'dashboardPage.codiceAsta' | translate}}" dataType="string"
                [visible]="true" cssClass="number">
            </dxi-column>
            <dxi-column dataField="dataPubblicazione" caption="{{'dashboardPage.dataPubblicazione' | translate}}"
                dataType="string" [visible]="true" cssClass="publication-date">
            </dxi-column>
            <dxi-column dataField="dataOraInizioAsta" caption="{{'dashboardPage.dataOraInizioAsta' | translate}}"
                dataType="string" [visible]="true" cssClass="auction-start">
            </dxi-column>
            <dxi-column dataField="dataOraFineAsta" caption="{{'dashboardPage.dataOraFineAsta' | translate}}"
                dataType="string" [visible]="true" cssClass="auction-end-pubblica">
            </dxi-column>
            <dxi-column dataField="tipologiaAsta" caption="{{'dashboardPage.tipologiaAsta' | translate}}"
                dataType="string" [visible]="true" cssClass="tipologia-pubblica">
            </dxi-column>
            <dxi-column dataField="statoAsta" caption="{{'dashboardPage.statoAsta' | translate}}" dataType="string"
                [visible]="true" cellTemplate="statusTemplate">
            </dxi-column>

            <div *dxTemplate="let statusTemplate of 'statusTemplate'">
                <div class="status {{statusTemplate.data.cssClass}}">{{statusTemplate.data.statoAsta}}</div>
            </div>

        </dx-data-grid>
    </div>

    <div class="activities bottom50">
        <article>
            <h2 translate>dashboardPage.attivita</h2>
            <ul class="activities-list" id="activities-list">
                <li *ngFor="let activity of activities_list; let i = index">
                    <div class="date">{{activity.createDate}}</div>
                    <div class="description" *ngIf="isCoripet()">
                        <a
                            [routerLink]="['/operatore-aziende.html/', activity.idOrganization]">{{activity.nome}}&nbsp;{{activity.cognome}}</a>
                        &egrave; stato {{activity.operazione}}&nbsp;{{activity.desinenza}} <a
                            [routerLink]="['/operatore-operatori.html/']">lista Operatori</a> dell'azienda <a
                            [routerLink]="['/operatore-aziende.html/', activity.idOrganization]">{{activity.organizationName}}</a>
                    </div>
                    <div class="description" *ngIf="isAdmin()">
                        <a
                            [routerLink]="['/admin-aziende.html/', activity.idOrganization]">{{activity.nome}}&nbsp;{{activity.cognome}}</a>
                        <span translate>dashboardPage.status </span>
                        <span translate>dashboardPage.{{activity.operazione}} </span>
                        <span translate>dashboardPage.{{activity.desinenza}} </span> <a
                            [routerLink]="['/admin-operatori.html/']"><span
                                translate>dashboardPage.lista-operatori</span></a>
                        <span translate> dashboardPage.dell-azienda </span> <a
                            [routerLink]="['/admin-aziende.html/', activity.idOrganization]">{{activity.organizationName}}</a>
                    </div>
                    <div class="description" *ngIf="!isCoripet() && !isAdmin()">
                        <a>{{activity.nome}}&nbsp;{{activity.cognome}}</a> &egrave; stato
                        {{activity.operazione}}&nbsp;{{activity.desinenza}} <a href="operatore-operatori.html">lista
                            Operatori</a> dell'azienda <a
                            href="operatore-aziende-dettaglio.html?id={{activity.idOrganization}}">{{activity.organizationName}}</a>
                    </div>
                </li>
            </ul>
        </article>
    </div>

</div>