import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';
import { LocalUtilService } from './local-util.service';
import { ConstantsModule } from './constants.module';
import { Location } from '@angular/common';
import { ClientService } from './client.service';
import { Observable, of } from 'rxjs';
import { environment } from 'src/environments/environment';
import { RegistrationConsentsItem, RegistrationStep2Item } from '../models/dataModels';

@Injectable({
    providedIn: 'root'
})
export class RegistrationService extends ClientService {

    constructor(protected localUtilService: LocalUtilService, 
                protected location: Location, 
                httpClient: HttpClient,
                private router: Router) {
            super(localUtilService, router, httpClient);
    }

    genericObservable: Observable<{}>;

    
    checkVatnumberExist(internationalVatnumber: string) {
        //this.localUtil.printLog('service.logout() logoutData: ' + JSON.stringify(logoutData));
        return this.httpClient.get<any>(ConstantsModule.API_HOST + ConstantsModule.API_ORGANIZATION_PREFIX + ConstantsModule.API_PREFIX + '/check/' + internationalVatnumber, this.httNotLogged);
    }

    createOrganization(step2: RegistrationStep2Item) {
        //this.localUtil.printLog('service.logout() logoutData: ' + JSON.stringify(logoutData));
        return this.httpClient.post<any>(ConstantsModule.API_HOST + ConstantsModule.API_ORGANIZATION_PREFIX + ConstantsModule.API_PREFIX + '/full/create', step2, this.httNotLogged);
    }

    updateUserConsents(consents: RegistrationConsentsItem) {
        //this.localUtil.printLog('service.logout() logoutData: ' + JSON.stringify(logoutData));
        return this.httpClient.put<any>(ConstantsModule.API_HOST + ConstantsModule.API_USER_PREFIX + ConstantsModule.API_PREFIX + '/update', consents, this.httpOptionsWithParams);
    }

    checkConsensiUtente(userId: string) {
        return this.httpClient.get<any>(ConstantsModule.API_HOST + ConstantsModule.API_ORGANIZATION_PREFIX + ConstantsModule.API_PREFIX + '/consents/' + userId + '/check', this.httpOptionsWithParams);
    }

}
