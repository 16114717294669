import { Location } from '@angular/common';
import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { Route, Router, Routes } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { DxDataGridComponent } from 'devextreme-angular';
import { AuctionLot, AuctionLotItem, CentroDiSelezioneItem, CentroDiSelezioneLottoItem, CentroSelezioneItem, NazioneItem, ProvinciaItem } from 'src/app/models/dataModels';
import { APIService } from 'src/app/services/APIService';
import { AuctionService } from 'src/app/services/auction.service';
import { AuthService } from 'src/app/services/auth.service';
import { CommonService } from 'src/app/services/common.service';
import { ConstantsModule } from 'src/app/services/constants.module';
import { LocalUtilService } from 'src/app/services/local-util.service';
import { MessagesService } from 'src/app/services/messages.service';
import { OrganizationService } from 'src/app/services/organization.service';
import { PopupComponent } from '../popup/popup.component';

@Component({
  selector: 'app-elenco-cs-lotto',
  templateUrl: './elenco-cs-lotto.component.html',
  styleUrls: ['./elenco-cs-lotto.component.css']
})
export class ElencoCsLottoComponent extends APIService implements OnInit {

  @ViewChild("dataGridCs", { static: false }) dataGridCs: DxDataGridComponent;
  @Input() cs: CentroDiSelezioneLottoItem[] = [];
  cs_list: CentroDiSelezioneItem[] = []; //full list
  @Output() monitorEvent: EventEmitter<CentroDiSelezioneLottoItem[]> = new EventEmitter();
  nation_list: NazioneItem[] = [];
  province_list: ProvinciaItem[] = [];
  @Input() readonly: boolean = false;
  modelEdit: CentroDiSelezioneLottoItem;
  @Input() lot: AuctionLotItem;

  constructor(public localService: LocalUtilService,
    public authService: AuthService,
    public auctionService: AuctionService,
    public organizationService: OrganizationService,
    public commonService: CommonService,
    private modalService: NgbModal,
    private messageService: MessagesService,
    location: Location,
    router: Router) {
    super(localService, location, router);
  }

  ngOnInit(): void {
    console.log('ngOnInit()');
    this.loadSelectionCenter();
    console.log(this.cs);
  }

  getIndirizzo(cs: CentroDiSelezioneItem) {
    let output = '';
    if (cs != undefined && cs.indirizzo != undefined) {
      output = cs.indirizzo + " - " + cs.cap + " - " + cs.citta + "(" + cs.provincia + ") - " + cs.nazione;
    }
    return output;
  }

  setCsItem() {
    if (this.cs != undefined && this.cs.length > 0 && this.cs_list != undefined && this.cs_list.length > 0) {
      //console.log('setCsItem() if');
      this.cs.forEach((csLot: CentroDiSelezioneLottoItem) => {
        //console.log('setCsItem() loop');
        csLot.centroSelezione = this.getSelectionCenterByCode(csLot.codiceCentroSelezione);
      });
    }
  }

  getSelectionCenterByCode(code: string) {
    let output = undefined;
    if (this.cs_list != undefined) {
      this.cs_list.forEach((csItem: CentroDiSelezioneItem) => {
        if (csItem.codice == code) {
          output = csItem;
        }
      });
    }
    //console.log('getSelectionCenterByCode() output: ' + JSON.stringify(output));
    return output;
  }

  loadSelectionCenter() {
    this.commonService.getSelectionCenterList().subscribe(
      data => {
        this.cs_list.push.apply(this.cs_list, data.list);

        if (this.cs != undefined && this.cs.length > 0) {
          //console.log('ngOnInit() if');
          this.setCsItem();
        } else {
          //console.log('ngOnInit() else');
        }

      },
      this.handleError(true)
    );
  }

  onRowRemoved(e) {
    console.log('onRowRemoved()');
    console.log(e);
    console.log(this.cs);
    this.monitorEvent.emit(this.cs);
  }

  onRowInserting(e) {
    console.log('onRowInserting()');
    this.modelEdit = $.extend(this.modelEdit, e.data);
    console.log(e);
  }

  onRowInserted(e) {
    console.log('onRowInserted()');
    this.cs[this.cs.length - 1] = this.modelEdit;
    this.monitorEvent.emit(this.cs);
  }

  onRowExpanding(e) {
    this.dataGridCs.instance.cancelEditData();
    this.dataGridCs.instance.collapseAll(-1);
  }

  onEditingStart(e) {
    this.dataGridCs.instance.cancelEditData();
    this.dataGridCs.instance.collapseAll(-1);
    this.modelEdit = e.data;
  }

  onInitNewRow(e) {
    this.initNewCsRow();
  }

  initNewCsRow() {
    this.modelEdit = new CentroDiSelezioneLottoItem();
    this.modelEdit.centroSelezione = new CentroDiSelezioneItem();
    this.modelEdit.codiceAsta = this.lot.codiceAsta;
    this.modelEdit.codiceLotto = this.lot.codiceLotto;
    console.log('initNewCsRow() this.modelEdit: ' + JSON.stringify(this.modelEdit));
  }

  selectCsFromList(e, codiceLotto: string) {
    //console.log('selectCsFromList() codiceLotto: ' + codiceLotto);
    console.log(e);

    const modalRef = this.modalService.open(PopupComponent);
    modalRef.componentInstance.tipo = 'openCentriSelezioneFull';
    modalRef.componentInstance.titolo = 'Centri di selezione';
    modalRef.componentInstance.codiceLotto = codiceLotto;
    modalRef.componentInstance.centriSelezione = this.cs_list;
    modalRef.componentInstance.passEntry.subscribe((cs: CentroDiSelezioneItem) => {
      if (cs != undefined) {
        console.log(cs);
        modalRef.close();
        this.modelEdit.centroSelezione = cs;
        this.modelEdit.codiceCentroSelezione = cs.codice;
        this.modelEdit.quantitaTon = 0;
        console.log(this.modelEdit);
      }
    }, this.handleError(true));
    this.fitContent();

  }

  getColumnValue(columnName: string, cellTemplate: any) {
    let output = '';
    output = this.resolveField(cellTemplate.data, columnName);
    if (output == undefined) {
      output = this.resolveField(this.modelEdit, columnName);
    }
    return output;
  }

  resolveField(data: any, field: string): any {
    if (data && field) {
      let fields: string[] = field.split('.');
      let value = data;
      for (let i = 0, len = fields.length; i < len; ++i) {
        if (value == null) {
          return null;
        } else {
          const pos: string = fields[i].match(/\d+/g)?.[0];
          if (pos != null) {
            const property: any = fields[i].match(/^\w+/g);
            value = value[property][pos];
          } else {
            value = value[fields[i]];
          }
        }
      }
      return value;
    } else {
      return undefined;
    }
  }


  fitContent() {
    $('.modal-dialog').css('max-width', 'fit-content');
    $('.modal-content').css('max-width', 'none');
  }

  onRowPrepared(info) {
    if (info.rowType === 'data') {
      console.log('onRowPrepared() info.rowElement: ' + info.rowElement);
      info.rowElement.addClass('auction');
    }
  }

}
