import { ConstantsModule } from "../services/constants.module";

export class MenuListItem {
    key: string;
    page: string;
    path: string;
    active: boolean;
    isLanding: boolean;
    openChild: boolean;
    parent: string;
    childs: MenuListItem[]
}

export class NewMenuListItem {
    id: number;
    key: string;
    page: string;
    label: string;
    path: string;
    active: boolean;
    isLanding: boolean;
    openChild: boolean = false;
    father: number;
    order: number;
    childs: NewMenuListItem[];
    parent: string;
}

export class AuctionItem {
    id: string;
    codiceAsta: string;
    statoAsta: string;
    tipologiaAsta: string;
    lottiDaValidare: string;
    statoLotti: string;
    numLotti: string;
    dataDisponibilita: string;
    dataOraInizioAsta: string;
    dataOraFineAsta: string;
    dataPubblicazione: string;
    lots?: AuctionLotItem[];
    sum_lots_to_publish: number;
    cssClass?: string;
    daValidare?: string;
}

export class AuctionItemPublished {
    codiceAsta: string;
    dataInizioAsta: string;
    oraInizioAsta: string;
    dataFineAsta: string;
    oraFineAsta: string;
    visible: boolean;
}

export class AuctionItemOpened {
    codiceAsta: string;
    dataInizioAsta: string;
    oraInizioAsta: string;
    dataFineAsta: string;
    oraFineAsta: string;
    visible: boolean;
}

export class AuctionList {
    list: AuctionItemPublished[];
}

export class AuctionTipologyItem {
    codice: string;
    descrizione: string;
    motore: string;
    setup?: AuctionSetupItem[] = [];
    bet?: AuctionBetSetupItem[] = [];
    formOpened: boolean = false;
}

export class AuctionSetupItem {
    id: number;
    dataInizio: string;
    dataFine: string;
    numeroAsteAnno: number;
    percRule2: number;
    percRule3: number;
    tipologiaAsta: string;
    properties: AuctionSetupPropItem[];
    active: boolean = false;
    motore: string;
}

export class AuctionBetSetupItem {
    auctionBetSetupId: number;
    userId: number;
    createDate: string;
    modifiedDate: string;
    taglioRilancio: number;
    tipologia: string;

    getTaglioRilancioEur() {
        return this.taglioRilancio + ' €';
    }
}

export class AuctionBetSetupCreateItem {
    slot: string;
}

export class AuctionSetupPropItem {
    code: string;
    value: string;
}

/* export class AuctionSetupCreateItem {
    dataInizio: string;
    dataFine: string;
    numeroAsteAnno: number;
    percRule2: number;
    percRule3: number;
    tipologiaAsta: string;
} */

export class AuctionSetupUpdateItem {
    id: number;
    dataInizio: string;
    dataFine: string;
    numeroAsteAnno: number;
    percRule2: number;
    percRule3: number;
    tipologiaAsta: string;
}

export class AuctionCreateItem {
    dataDisponibilita: string;
    dataPubblicazione: string;
    dataOraInizioAsta: string;
    dataOraFineAsta: string;
    tipologiaAsta: string;
}

/* export class AuctionTipologyCreateItem {
    codice: string;
    descrizione: string;
    motore: string;
} */

export class AuctionLotItem {

    constructor() {
        this.isDisabled = true;
    }

    id: number;
    userId: number;
    createDate: string;
    modifiedDate: string;
    idOrganization: string;
    nomeOrganization: string;
    codiceLotto: string;
    tipoProdotto: string;
    areaGeografica: string;
    quantitaTon: number;
    prezzoBase: number;
    statoLotto: string;
    schedaTecnica: string;
    codiceAsta: string;
    codiceRifiutoCER: string;
    dataDisponibilitaLotto: number;
    pesoMedio: number;
    note: string;
    codiceProdotto: string;
    imballaggio: string;
    dataOraFineAstaEffettiva: string;
    numeroRilanci: number;
    prezzoUltimoRilancio: number;
    spedito: number;
    pagato: number;
    centroSelezione: CentroSelezioneItem[];
    centroSelezioneLottoItem?: CentroDiSelezioneLottoItem[];
    cssClass?: string;
    paid?: string;
    paidClass: string;
    retired?: string;
    retiredClass?: string;
    paidChecked?: boolean;
    retiredChecked?: boolean;
    notPaidChecked?: boolean;
    notRetiredChecked?: boolean;
    codiceLottoNoPoints: string;
    isClosed?: boolean = false;
    isDisabled?: boolean = false;
    isExtratime?: boolean = false;
    isWinning?: boolean = false;
    favourite: boolean = false;
    numContendentiUnici?: string;
    rilanciLotto?: RilancioLottoItem[];
    bindModel?: object;
}

export class RilancioLottoItem {
    id: number;
    prezzoRilancio: number;
    idLot: number;
    createDate: string;
}

export class RegistrationStep1Item {
    companyNation: string;
    vatnumber: string;
    internationalVatnumber: string;
}

export class RegistrationStep2Item {
    companyNation: string;
    partitaIva: string;
    internationalVatnumber: string;

    nome: string;
    cognome: string;
    email: string;
    lingua: string;

    telefono: string;
    mobile: string;
    password: string;
    confermaPassword: string;

    checkPrivacy: string;
    checkRules: string;
    checkAcceptance: string;

    orgname: string;
    nazionalita: string;
    ragioneSociale: string;
    codiceFiscale: string;

    provincia: string;
    citta: string;
    cap: string;
    indirizzo: string;
    org_telefono: string;
    fax: string;
    pec: string;
}

export class RegistrationConsentsItem {
    nome: string;
    cognome: string;
    email: string;
    lingua: string;
    telefono: string;
    mobile: string;
    checkPrivacy: string;
    checkRules: string;
    checkAcceptance: string;
}

export class NazioneItem {
    id: number;
    codice: string;
    descrizione: string;
}

export class ProvinciaItem {
    id: number;
    codice: string;
    descrizione: string;
}

export class DocumentData {
    uuid: string;
    status: string;
    cssClass: string;
}

export class AreaGeograficaItem {
    id: number;
    area: string;
    checked?: boolean = true;
}

export class MaterialTypeItem {
    id: number;
    type_material: string;
    checked?: boolean = true;
}

export class CentroSelezioneItem {
    nome: string;
    CER: string;
    pesoMedioCarico: string;
    indirizzo: string;
    citta: string;
    provincia: string;
    cap: string;
    nazioneDescr: string;
    codice: string;
    codiceCentroSelezione: string;
    quantitaTon: number;
    codiceLotto: string;
    codiceAsta: string;
}

export class CentroSelezioneLottoItem {
    codiceCentroSelezione: string;
    quantitaTon: number;
    codiceLotto: string;
    codiceAsta: string;
}

export class BoxItem {
    classe: string;
    titolo: string;
    testo: string;
    documento: string;
    percentage: string;
    percentageNum: number;
    path: string;
}

export class BoxArchivio {
    id: string;
    codiceAsta: string;
    uuid: string;
    filename: string;
}

export class PivotLotItem {
    id: number;
    areaGeografica: string;
    codiceAsta: string;
    prezzoBaseAsta: number;
    prezzoUltimoRilancio: number;
    numeroRilanci: number;
    data: Date;
}

export class ItemInterface {
    editing?: boolean;
    menuOpened?: boolean;
    formOpened?: boolean;
    newEditing?: boolean;
    statusClass?: string;
    tab?: string;
}

export class AuctionSetupCreateItem extends ItemInterface {
    dataInizio: string;
    dataFine: string;
    numeroAsteAnno: number;
    percRule2: number;
    percRule3: number;
    tipologiaAsta: string;
}

export class AuctionDettaglioItem extends ItemInterface {
    areaGeografica: string;
    centroSelezione: any;
    codiceLotto: string;
    codiceProdotto: string;
    codiceRifiutoCER: string;
    dataDisponibilitaLotto: string;
    note: string;
    pesoMedio: string;
    prezzoBase: number;
    quantitaTon: string;
    schedaTecnica: string;
    tipoProdotto: string;


}

export class OrganizationOperatorCreateItem extends ItemInterface {
    nome: string;
    cognome: string;
    email: string;
    lingua: string;
    telefono: string;
    mobile: string;
    password: string;
    confermaPassword: string;
    role: string;
}

export class AsteItem extends ItemInterface {
    dataDisponibilita: string;
    dataPubblicazione: string;
    dataOraInizioAsta: string;
    dataOraFineAsta: string;
    tipologiaAsta: string;
}

export class AuctionTipologyCreateItem extends ItemInterface {
    codice: string;
    descrizione: string;
    motore: string;
}

export class OrganizationOperatorItem extends ItemInterface {
    id: number;
    nome: string;
    cognome: string;
    email: string;
    lingua: string = 'IT';
    telefono: string;
    mobile: string;
    role: string;
    dataRegistrazione: string;
    validated: string;
    disabled: string;
    idOrganization: number;
    ragioneSociale: string;
    vatnumber: string;
    checkRules: string;
    checkPrivacy: string;
    password: string;
    confermaPassword: string;
    status: string;
    cssClass: string;
}

export class CentroSelezioneDetailItem extends ItemInterface {
    id: number;
    userId: number;
    createDate: string;
    modifiedDate: string;
    codice: string;
    nome: string;
    indirizzo: string;
    cap: string;
    citta: string;
    provincia: string;
    nazione: string;
    areaGeografica: string;
    pesoMedioCarico: string;
    CER: string;
    capacitaTotale: string;
    note: string;
}

export class CentroSelezioneUpdateItem extends ItemInterface {
    codice: string;
    nome: string;
    indirizzo: string;
    cap: string;
    citta: string;
    provincia: string;
    nazione: string;
    areaGeografica: string;
    pesoMedioCarico: string;
    CER: string;
    capacitaTotale: string;
    note: string;
}

export class CentroSelezioneInsertItem extends ItemInterface {
    codice: string;
    nome: string;
    indirizzo: string;
    cap: string;
    citta: string;
    provincia: string;
    nazione: string;
    areaGeografica: string;
    pesoMedioCarico: string;
    CER: string;
    capacitaTotale: string;
    note: string;
}

export class CentroDiSelezioneItem extends ItemInterface {
    id: number;
    userId: number;
    createDate: string;
    modifiedDate: string;
    codice: string;
    nome: string;
    indirizzo: string;
    cap: string;
    citta: string;
    provincia: string;
    nazione: string;
    areaGeografica: string;
    pesoMedioCarico: string;
    CER: string;
    capacitaTotale: string;
    note: string;
}

export class CentroDiSelezioneLottoItem {
    codiceCentroSelezione: string;
    quantitaTon: number;
    codiceLotto: string;
    codiceAsta: string;
    centroSelezione: CentroDiSelezioneItem;
}

export class AuctionLot {
    id: number;
    userId: string;
    createDate: string;
    modifiedDate: string;
    idOrganization: string;
    nomeOrganization: string;
    codiceLotto: string;
    tipoProdotto: string;
    areaGeografica: string;
    quantitaTon: string;
    prezzoBase: number;
    statoLotto: string;
    schedaTecnica: string;
    codiceAsta: string;
    codiceRifiutoCER: string;
    dataDisponibilitaLotto: string;
    pesoMedio: string;
    note: string;
    codiceProdotto: string;
    imballaggio: string;
    indirizzo: string;

    dataOraFineAstaEffettiva: string;
    numeroRilanci: number;
    prezzoUltimoRilancio: number;

    spedito: boolean;
    pagato: boolean;

    centroSelezione: SelectionCenterItem[];
}

export class SelectionCenterItem {
    codiceCentroSelezione: string;
    quantitaTon: number;
    codiceLotto: string;
    codiceAsta: string;
}

/* export class OrganizationOperatorCreateItem {
    nome: string;
    cognome: string;
    email: string;
    lingua: string;
    telefono: string;
    mobile: string;
    password: string;
    role: string;
} */

export class OrganizationOperatorUpdateItem {
    nome: string;
    cognome: string;
    email: string;
    lingua: string;
    telefono: string;
    mobile: string;
}

export class OrganizationUpdateItem {
    nazionalita: string;
    ragioneSociale: string;
    provincia: string;
    citta: string;
    cap: string;
    indirizzo: string;
    telefono: string;
    fax: string;
    pec: string;
    totaleFidejussione: number;
    visuraCameraleUuid: string;
    rappresentanteLegaleUuid: string;
    fidejussioneBancariaUuid: string;
    dichiarazione231_2001Uuid: string;
}

export class OrganizationPlantItem extends ItemInterface {
    id: number;
    createDate: string;
    nomeImpianto: string;
    nazionalita: string;
    provincia: string;
    citta: string;
    cap: string;
    indirizzo: string;
    capacitaProduttivaAnnua: number;
    pec: string;
    certificazioneImpiantoValid: string;

    certificazioneIso9001: string = '';
    certificazioneIso9001Status: string;
    certificazioneIso9001CssClass: string;
    certificazioneIso9001Valid: string = '';

    certificazioneIso14001: string = '';
    certificazioneIso14001Status: string;
    certificazioneIso14001CssClass: string;
    certificazioneIso14001Valid: string = '';

    docAutorizzazione: string = '';
    docAutorizzazioneStatus: string;
    docAutorizzazioneCssClass: string;
    docAutorizzazioneValid: string = '';

    nomeRifSpedizione: string;
    telefonoRifSpedizione: string;
    mobileRifSpedizione: string;
    noteTrasportatore: string;
    emailRifSpedizione: string;
    status: string;
    statusDescrDocAuth?: string;
    statusDescr14001?: string;
    statusDescr9001?: string;
    fileClassDocAuth?: string;
    fileClass14001?: string;
    fileClass9001?: string;
    statusDoc?: string;
}

export class OrganizationPlantCreateItem extends ItemInterface {
    createDate: string;
    nomeImpianto: string;
    nazionalita: string;
    provincia: string;
    citta: string;
    cap: string;
    indirizzo: string;
    capacitaProduttivaAnnua: number;
    pec: string;
    certificazioneImpiantoValid: string;
    certificazioneIso9001: string;
    certificazioneIso9001Valid: string;
    certificazioneIso14001: string;
    certificazioneIso14001Valid: string;
    docAutorizzazione: string;
    docAutorizzazioneValid: string;
    nomeRifSpedizione: string;
    telefonoRifSpedizione: string;
    mobileRifSpedizione: string;
    noteTrasportatore: string;
    emailRifSpedizione: string;
}

export class FileWithContentUpload {
    file: File;
    contentType: string;
    frameworkId: string;
    vatnumber: string;
    codiceAsta: string;
    codiceLotto: string;
}

export class CodiceDescrizioneItem {
    codice: string;
    descrizione: string;
}

export class OrganizationItem extends ItemInterface {
    id: number;
    userId: number;
    parentOrganizationId: number;
    orgname: string;
    codiceFiscale: string;
    ragioneSociale: string;
    nationality: string;
    partitaIva: string;
    provincia: string;
    citta: string;
    indirizzo: string;
    cap: string;
    address1: string;
    telefono: string;
    fax: string;
    pec: string;
    visuraCamerale: string;
    visuraCameraleStatus: string;
    visuraCameraleCssClass: string;
    rappresentanteLegale: string;
    rappresentanteLegaleStatus: string;
    rappresentanteLegaleCssClass: string;
    fidejussioneBancaria: string;
    fidejussioneBancariaStatus: string;
    fidejussioneBancariaCssClass: string;
    dichiarazione231_2001: string;
    dichiarazione231_2001Status: string;
    dichiarazione231_2001CssClass: string;
    totaleFidejussione: number;
    dataScadenzaFidejussione: string;
    capacitaProduttiva: number;
    visuraCameraleValidated: string;
    rappresentanteLegaleValidated: string;
    fidejussioneBancariaValidated: string;
    dichiarazione231_2001Validated: string;
    validated: string;
    disabled: string;
    dataCreazione: string;
    status: string;
    stato: string; //validata non validata descr
    referente?: string;
    userOrganization?: UserItem;
    plants?: OrganizationPlantItem[];
    operators?: OrganizationOperatorItem[];//UserOperatorItem
}

export class UserOrgItem {
    id: number;
    cognome: string;
    nome: string;
    dateRegistrazione: string;
    email: string;
    idOrganization: number;
    lingua: string;
    mobile: string;
    telefono: string;
    ruolo: string;
}

export class OrganizationDocumentsUpdateItem {
    dataScadenzaFidejussione: string;
    totaleFidejussione: number;
    visuraCameraleUuid: string;
    rappresentanteLegaleUuid: string;
    fidejussioneBancariaUuid: string;
    dichiarazione231_2001Uuid: string;
}

export class UserItem extends ItemInterface {
    checkAcceptance: string;
    checkAcceptanceData: string;
    checkPrivacy: string;
    checkPrivacyData: string;
    checkRules: string;
    checkRulesData: string;
    cognome: string;
    dateRegistrazione: string;
    deleted: string;
    disabled: string;
    email: string;
    id: number;
    idOrganization: number;
    lingua: string;
    mobile: string;
    nome: string;
    ragioneSociale: string;
    ruolo: string;
    telefono: string;
    validated: string;
}

export class ActivityItem {
    id: number;
    createDate: string;
    modifiedDate: string;
    idOrganization: number;
    operation: string;
    idUser: number;
    nome: string;
    cognome: string;
    organizationName: string;
    operazione: string;
    desinenza: string;
}

export class UserOperatorItem {
    id: number;
    nome: string;
    cognome: string;
    email: string;
    lingua: string;

    telefono: string;
    mobile: string;
    role: string;

    dataRegistrazione: string;
    validated: string;
    disabled: string;
    deleted: string;

    idOrganization: number;
    ragioneSociale: string;

    checkRules: string;
    checkRulesData: string;
    checkPrivacy: string;
    checkPrivacyData: string;
    checkAcceptance: string;
    checkAcceptanceData: string;
    status?: string;
    statusClass?: string;
}

export class AuctionLotUpdateItem extends AuctionLotItem {

}

export class AuctionLotCreateItem extends AuctionLotItem {

}

export class SchedaTecnicaItem {
    document: string;
}

export class GetAuctionLotsResponse {
    auctionDetail: AuctionItem;
    lotList: AuctionLotItem[];
    serverTime: string;
    dataPrimoLottoInScadenza: string;
    dataUltimoLottoInScadenza: string;
}

export class GetAuctionLotUserFavouriteResponse {
    list: AuctionLotFavElem[];
}

export class GetSelectionCenterListResponse {
    list: CentroSelezioneItem[];
}



export class AuctionLotFavElem {
    id: number;
    userId: number;
    createDate: string;
    modifiedDate: string;
    auctionCode: string;
    lotCode: string;
}

export class AuctionLotTimerItem {
    codiceLotto: string;
    isExtratime: boolean = false;
    isClosed: boolean = false;
}

export class MakeOfferRequest {
    actualPrice: number;
    myOffer: number;
}

export class SliderValue {
    type: string;
    curValue: number;
    resValue: number;
    maxValue: number;
    curPerc: number;
}

export class MatRule {
    ruleEligibility: number;
    ruleTotal: number;
    typeMaterial: string;
    winning: number;
}

export class CapData {
    rule1: Rule1Item;
    rule2: Rule2Item;
    rule3: Rule3Item[];
}

export class Rule1Item {
    residualEligibility: number;
    ruleEligibility: number;
    totalEligibility: number;
    winning: number;
    oldWon: number;
    cost: number;
}

export class Rule2Item {
    ruleTotal: number;
    ruleEligibility: number;
    winning: number;
}

export class Rule3Item {
    ruleTotal: number;
    ruleEligibility: number;
    winning: number;
    typeMaterial: string;
}

export class AuthParams {
    token: string;
    welcome: string;
    user_detail: UserDetail;
    organization_detail: OrgDetail;
}

export class UserDetail {
    id: number;
    nome: string;
    cognome: string;
    email: string;
    dateRegistrazione: string;
    lingua: string;
    telefono: string;
    mobile: string;
    ruolo: string;
    idOrganization: number;
}

export class OrganizationDetailItem {
    id: number;
    userId: number;
    parentOrganizationId: number;
    orgname: string;
    codiceFiscale: string;
    ragioneSociale: string;
    nazionalita: string;
    partitaIva: string;
    provincia: string;
    citta: string;
    indirizzo: string;
    cap: string;
    telefono: string;
    fax: string;
    pec: string;
    visuraCamerale: string;
    rappresentanteLegale: string;
    fidejussioneBancaria: string;
    dichiarazione231_2001: string;
    totaleFidejussione: number;
    dataScadenzaFidejussione: string;
    visuraCameraleValidated: string;
    rappresentanteLegaleValidated: string;
    fidejussioneBancariaValidated: string;
    dichiarazione231_2001Validated: string;
    validated: string;
    disabled: string;
    dataCreazione: string;
    status: string;
}

export class OrgDetail {
    id: number;
    userId: number;
    parentOrganizationId: number;
    orgname: string;
    codiceFiscale: string;
    ragioneSociale: string;
    nationality: string;
    partitalva: string;
    province: string;
    city: string;
    indirizzo: string;
    zip: string;
    address1: string;
    phone: string;
    fax: string;
    pec: string;
    validated: string;
    visuraCamerale: string;
    rappresentanteLegale: string;
    fidejussioneBancaria: string;
    totaleFidejussione: number;
    dataScadenzaFidejussione: string;
    capacitaProduttiva: number;
    visuraCameraleValidated: string;
    rapprestanteLegaleValidated: string;
    fidejussioneBancariaValidated: string;
    dichiarazione231_2001: string;
    dichiarazione231_2001Validated: string;
    dataCreazione: string;
    status: string;
}

export class GetAuctionSetupResponse {
    list: AuctionSetupItem[];
}

export class HeaderStickyItem {
    value: boolean;
    motoreTipologiaAsta: string;
}

export class HeaderDetail {
    topHeader: boolean = true;
    dashboardHeader: boolean = false;
    capacityHeader: boolean = false;
    stickyHeader: boolean = false;
    view: string = ConstantsModule.AUC_VIEW_BOX;
}

export class AuctionBoxTimer {
    name: string;
    label: string;
    dataOraFineAsta: string;
    dataOraFineAstaEffettiva: string;
}