<div id="auctions-list" class="auctions-list">
    <dx-data-grid #dataGridBet id="gridContainer" [dataSource]="bet" keyExpr="auctionBetSetupId"
        [allowColumnReordering]="true" [allowColumnResizing]="true" [showBorders]="true" [columnHidingEnabled]="false"
        (onRowInserting)="onRowInsertBet($event)" (onRowRemoving)="onRowDeleteBet($event)"
        (onCellPrepared)="onCellPreparedBet($event)" (onRowPrepared)="onRowPrepared($event)">

        <dxo-load-panel [enabled]="true"></dxo-load-panel>
        <dxo-filter-row [visible]="true"></dxo-filter-row>
        <dxo-header-filter [visible]="true"></dxo-header-filter>
        <dxo-scrolling mode="infinite"></dxo-scrolling>

        <dxi-column dataField="auctionBetSetupId" caption="Id" [allowEditing]="false" dataType="string"
            [visible]="false">
        </dxi-column>
        <dxi-column dataField="taglioRilancio" caption="Valore taglio" [allowEditing]="true" dataType="number"
            [visible]="true">
            <dxi-validation-rule type="custom" [validationCallback]="ruleNumberPositive"
                message="Il valore deve essere maggiore di zero"></dxi-validation-rule>
            <dxi-validation-rule type="required"></dxi-validation-rule>
        </dxi-column>

        <dxo-editing [allowUpdating]="false" [allowAdding]="true" [allowDeleting]="true" mode="row">

            <dxo-texts confirmDeleteMessage="Sei sicuro di voler eliminare questo taglio di rilancio ?"></dxo-texts>

        </dxo-editing>
    </dx-data-grid>
</div>