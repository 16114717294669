import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';
import { LocalUtilService } from './local-util.service';
import { ConstantsModule } from './constants.module';
import { APIService } from './APIService';
import { Location } from '@angular/common';
import { ClientService } from './client.service';
import { Observable, of } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
    providedIn: 'root'
})
export class DashboardService extends ClientService {

    constructor(protected localUtilService: LocalUtilService, 
                protected location: Location, 
                httpClient: HttpClient,
                private router: Router) {
            super(localUtilService, router, httpClient);
    }

    genericObservable: Observable<{}>;

    checkDocumentExists(doctype: string, vatnumber: string) : Observable<any> {
        return this.httpClient.get<any>(ConstantsModule.API_HOST + ConstantsModule.API_ORGANIZATION_PREFIX + ConstantsModule.API_PREFIX + '/documents/' + doctype + '/' + vatnumber, this.httpOptions);
    }

    checkFrameworkExists(vatnumber: string) : Observable<any> {
        return this.httpClient.get<any>(ConstantsModule.API_HOST + ConstantsModule.API_ORGANIZATION_PREFIX + ConstantsModule.API_PREFIX + '/framework/' + vatnumber, this.httpOptions);
    }

    listaAste(statoAsta:string) : Observable<any> {
        return this.httpClient.get<any>(ConstantsModule.API_HOST + ConstantsModule.API_AUCTION_PREFIX + ConstantsModule.API_PREFIX + '/list?statoAsta=' + statoAsta, this.httpOptions);
    }

    getAuctionReportNew(codiceAsta: string) : Observable<any> {
        return this.httpClient.get<any>(ConstantsModule.API_HOST + ConstantsModule.API_AUCTION_PREFIX + ConstantsModule.API_PREFIX + '/report-new/' + codiceAsta, this.httpOptions);
    }

    getPivotLotsList() : Observable<any> {
        return this.httpClient.get<any>(ConstantsModule.API_HOST + ConstantsModule.API_AUCTION_PREFIX + ConstantsModule.API_PREFIX + '/pivot/list', this.httpOptions);
    }
    
}
