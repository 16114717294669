import { HttpClient, HttpClientModule } from '@angular/common/http';
import { Component, NgModule, OnInit } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { DxAccordionModule, DxBulletModule, DxButtonModule, DxDataGridModule, DxDateBoxModule, DxDropDownBoxModule, DxFormModule, DxHtmlEditorModule, DxLoadIndicatorModule, DxNumberBoxModule, DxPopupModule, DxScrollViewModule, DxSelectBoxModule, DxTemplateModule, DxTextAreaModule, DxTextBoxModule, DxToolbarModule, DxTooltipModule } from 'devextreme-angular';
import { AppRoutingModule } from '../app-routing.module';
import { CommonsModule } from '../commons/commons.component';
import { ElencoOperatoriComponent } from '../commons/components/elenco-operatori/elenco-operatori.component';
import { CoripetAsteComponent } from './components/aste/aste.component';
import { CoripetAstaDettaglioComponent } from './components/aste/dettaglio/dettaglio.component';
import { CoripetAziendeComponent } from './components/aziende/aziende.component';
import { CoripetAziendaDettaglioComponent } from './components/aziende/dettaglio/azienda-dettaglio.component';
import { CoripetCentriDiSelezioneComponent } from './components/centri-di-selezione/centri-di-selezione.component';
import { CoripetDashboardComponent } from './components/dashboard/dashboard.component';
import { CoripetOperatoriComponent } from './components/operatori/operatori.component';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { CKEditorModule } from '@ckeditor/ckeditor5-angular';

@Component({
  selector: 'app-coripet',
  templateUrl: './coripet.component.html',
  styleUrls: ['./coripet.component.css']
})
export class CoripetComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}

@NgModule({
  declarations: [
    CoripetDashboardComponent,
    CoripetAziendeComponent,
    CoripetAsteComponent,
    CoripetAstaDettaglioComponent,
    CoripetCentriDiSelezioneComponent,
    CoripetDashboardComponent,
    CoripetAziendaDettaglioComponent,
    CoripetOperatoriComponent,
  ],
  imports: [
    DxPopupModule,
    DxScrollViewModule,
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,
    NgbModule,
    CommonsModule,
    AppRoutingModule,
    DxDataGridModule, DxButtonModule, DxBulletModule,
    DxSelectBoxModule, DxLoadIndicatorModule, DxTemplateModule, DxFormModule,
    DxTextAreaModule,
    DxTextBoxModule,
    DxDropDownBoxModule,
    CKEditorModule,
    DxDateBoxModule,
    DxFormModule,
    DxTooltipModule, DxTemplateModule,
    DxAccordionModule,
    DxHtmlEditorModule,
    DxToolbarModule,
    DxTextAreaModule,
    DxNumberBoxModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    }), 
  ],
  exports: [
    CoripetDashboardComponent,
    CoripetAziendeComponent,
    CoripetAsteComponent,
    CoripetAstaDettaglioComponent,
    CoripetCentriDiSelezioneComponent,
    CoripetAziendaDettaglioComponent,
    CoripetOperatoriComponent,
  ],
  providers: [],
  bootstrap: [CoripetComponent]
})
export class CoripetModule { }

export function HttpLoaderFactory(http: HttpClient): TranslateHttpLoader {
  return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}