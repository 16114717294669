import { Location } from '@angular/common';
import { Component, OnInit, HostListener, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { DocumentData, FileWithContentUpload, NazioneItem, OrganizationDetailItem, OrganizationDocumentsUpdateItem, OrganizationItem, OrganizationOperatorCreateItem, OrganizationPlantCreateItem, OrganizationPlantItem, OrganizationUpdateItem, ProvinciaItem } from 'src/app/models/dataModels';
import { APIService } from 'src/app/services/APIService';
import { AuthService } from 'src/app/services/auth.service';
import { CommonService } from 'src/app/services/common.service';
import { ConstantsModule } from 'src/app/services/constants.module';
import { LocalUtilService } from 'src/app/services/local-util.service';
import { MessagesService } from 'src/app/services/messages.service';
import { OrganizationService } from 'src/app/services/organization.service';
import { PopupComponent } from '../popup/popup.component';

@Component({
  selector: 'app-upload-mod-org',
  templateUrl: './upload-mod-org.component.html',
  styleUrls: ['./upload-mod-org.component.css']
})
export class UploadModOrgComponent extends APIService implements OnInit {

  constructor(public localService: LocalUtilService,
    public authService: AuthService,
    public organizationService: OrganizationService,
    private modalService: NgbModal,
    private messageService: MessagesService,
    location: Location, 
    router: Router,
    public route: ActivatedRoute,
    public commonService: CommonService) {
    super(localService, location, router);
    console.log('UploadModOrg231Component()');
  }

  dichiarazione231_2001: FileWithContentUpload;
  vatnumber: string;
  existsDocument: DocumentData;
  
  ngOnInit(): void {
    this.checkDichiarazione231Doc();
    let vatnumber = this.route.snapshot.paramMap.get('vatnumber');
    if (vatnumber != undefined) {
      this.vatnumber = vatnumber;
      console.log('UploadModOrg231Component() set vatnumber to ' + this.vatnumber);
    } else {
      if (this.isUser() && vatnumber == undefined) {
        this.vatnumber = this.localUtil.authParams.organization_detail.orgname;
      } 
    }
  }

  back() {
    this.location.back();
  }

  getDichiarazione231File(file) {
    if (file != undefined) {
      console.log('getDichiarazione231File() loaded file: ' + file.name);
    }else {
      console.log('getDichiarazione231File() unloaded file!');
    }
    this.dichiarazione231_2001 = new FileWithContentUpload();
    this.dichiarazione231_2001.file = file;
  }

  documentUpload() {
    this.back();
  }

  uploadDichiarazione231() {
    if (this.dichiarazione231_2001 != undefined && this.dichiarazione231_2001.file != undefined) {
      this.dichiarazione231_2001.contentType = 'ORG';
      this.dichiarazione231_2001.vatnumber = this.vatnumber;
      
      this.commonService.documentUpload(this.dichiarazione231_2001).subscribe(
        data => {
          data = JSON.parse(data);
          console.log('uploadDichiarazione231 uploaded! file_uuid: ' + data.file_uuid);
          console.log(data);
          this.updateDichiarazione231(this.dichiarazione231_2001.vatnumber, data.file_uuid);
        }, this.handleError(true));
    } else {
      this.localUtil.showMessage("", ConstantsModule.INFO_TITLE, this.messageService.getLabelFromCode("UPLOAD_FORM_FILE_NOT_LOADED"), true);
    }
  }

  updateDichiarazione231(vatnumber: string, uuid: string) {
    
        let updateBean:OrganizationDocumentsUpdateItem = new OrganizationDocumentsUpdateItem();
        updateBean.dichiarazione231_2001Uuid = uuid;

        this.organizationService.updateOrganizationDocuments(vatnumber, updateBean).subscribe(
          (data:any) => {
            
            if (this.isUser()) {
              let authParam = this.localUtil.authParams;
              authParam.organization_detail.dichiarazione231_2001 = data.detail.dichiarazione231_2001;
              authParam.organization_detail.dichiarazione231_2001Validated = "";
              this.localUtil.setAuthParams(authParam);
            }

            const modalRef = this.modalService.open(PopupComponent);
            modalRef.componentInstance.titolo = this.messageService.getLabelFromCode("INFO_TITLE");
            modalRef.componentInstance.messaggio = this.messageService.getLabelFromCode("GENERIC_SUCCESS_MESSAGE_SAVE");
            modalRef.componentInstance.tipo = 'message';
            modalRef.componentInstance.passEntry.subscribe((receivedEntry:any) => {
              this.back();
            });
      },
      this.handleError(true));
  }

  checkDichiarazione231Doc() {
    console.log('checkDichiarazione231Doc()');
    let organization_detail = this.localUtil.getSessionOrganization();
    console.log('organization_detail:');
    console.log(organization_detail);
    if (organization_detail != '' && organization_detail != undefined) {
      if (organization_detail.dichiarazione231_2001 != '' &&
          organization_detail.dichiarazione231_2001Validated == '') {
            //EXISTS AND NOT YET VALIDATED
            console.log('dichiarazione 231 - 2001 EXISTS AND NOT YET VALIDATED');            
            this.existsDocument = new DocumentData();
            this.existsDocument.uuid = organization_detail.dichiarazione231_2001;
            this.existsDocument.status = '';
      } else if (organization_detail.dichiarazione231_2001 != '' &&
                 organization_detail.dichiarazione231_2001Validated == 'Y') {
            //EXISTS AND VALIDATED
            console.log('dichiarazione 231 - 2001 EXISTS AND VALIDATED');
            this.existsDocument = new DocumentData();
            this.existsDocument.uuid = organization_detail.dichiarazione231_2001;
            this.existsDocument.status = 'Y';
      } else if (organization_detail.dichiarazione231_2001 != '' &&
                 organization_detail.dichiarazione231_2001Validated == 'N') {
            //EXISTS AND NOT VALIDATED
            this.existsDocument = new DocumentData();
            console.log('dichiarazione 231 - 2001 EXISTS AND NOT VALIDATED');
            this.existsDocument.uuid = organization_detail.dichiarazione231_2001;
            this.existsDocument.status = 'N';
      } else {
            //UNKNOWN STATUS
            console.log('dichiarazione 231 - 2001 UNKNOWN STATUS');
            this.existsDocument = new DocumentData();
            this.existsDocument.uuid = '';
            this.existsDocument.status = '';
      }  
    }
  }

}