import { Injectable } from "@angular/core";
import { CanActivate } from "@angular/router";
import { LocalUtilService } from "./local-util.service";

@Injectable({
providedIn: 'root'
})
export class IsUserCanActivate implements CanActivate {
    
    constructor(public localUtil: LocalUtilService) {

    }
    
    canActivate() {
        return this.localUtil.isUser();
    }

}

@Injectable({
    providedIn: 'root'
    })
export class IsCoripetCanActivate implements CanActivate {
    
    constructor(public localUtil: LocalUtilService) {

    }
    
    canActivate() {
        return this.localUtil.isCoripet();
    }
    
}

@Injectable({
    providedIn: 'root'
    })
export class IsAdminCanActivate implements CanActivate {
    
    constructor(public localUtil: LocalUtilService) {

    }
    
    canActivate() {
        return this.localUtil.isAdmin();
    }
    
}

@Injectable({
    providedIn: 'root'
    })
export class IsNotLogged implements CanActivate {
    
    constructor(public localUtil: LocalUtilService) {

    }
    
    canActivate() {
        return this.localUtil.isNotLogged();
    }
    
}