<div class="p-4">
    <h2 translate>dashboardPage.aziende-da-validare</h2>
    <div id="auctions-list" class="auctions-list" style="display: contents;">
        <dx-data-grid id="gridContainer" [dataSource]="organizations_list" keyExpr="id" [allowColumnReordering]="true"
            [allowColumnResizing]="true" [showBorders]="true" [columnHidingEnabled]="false" [columnAutoWidth]="true"
            (onEditingStart)="onRowUpdateOrganization($event)" (onRowPrepared)="onRowPreparedOrganization($event)"
            (onContentReady)="onContentReady($event)" (onRowClick)="openDettaglioOrganization($event)"
            (onCellPrepared)="onCellPrepared($event)" (onRowPrepared)="onRowPrepared($event)">

            <dxo-load-panel [enabled]="true"></dxo-load-panel>
            <dxo-filter-row [visible]="false"></dxo-filter-row>

            <dxo-search-panel [visible]="false" [highlightCaseSensitive]="false">
            </dxo-search-panel>

            <!-- edit form and columns -->


            <dxi-column dataField="id" [allowEditing]="false" caption="{{'dashboardPage.id' | translate}}"
                alignment="left" [visible]="false"></dxi-column>
            <dxi-column dataField="userId" caption="{{'dashboardPage.userId' | translate}}" dataType="string"
                showEditorAlways="true" [visible]="false"></dxi-column>
            <dxi-column dataField="ragioneSociale" caption="{{'dashboardPage.ragioneSociale' | translate}}"
                dataType="string" [visible]="true" cssClass="name"></dxi-column>
            <dxi-column dataField="dataCreazione" caption="{{'dashboardPage.dataCreazione' | translate}}"
                dataType="string" [visible]="true" cssClass="creation-date" cellTemplate="regTemplate"></dxi-column>
            <dxi-column dataField="referente" caption="{{'dashboardPage.referente' | translate}}" dataType="string"
                [visible]="true" cssClass="referent" cellTemplate="referentTemplate"></dxi-column>
            <dxi-column dataField="status" caption="{{'dashboardPage.status' | translate}}" dataType="string"
                [visible]="true" cssClass="status registrata"></dxi-column>
            <dxi-column dataField="totaleFidejussione" caption="{{'dashboardPage.totaleFidejussione' | translate}}"
                dataType="string" [visible]="true" cssClass="funds" cellTemplate="fidTemplate"></dxi-column>
            <dxi-column dataField="capacitaProduttiva" caption="{{'dashboardPage.capacitaProduttiva' | translate}}"
                dataType="string" [visible]="true" cssClass="capacity" cellTemplate="capacityTemplate"></dxi-column>

            <dxo-editing mode="form" [allowUpdating]="false" [allowDeleting]="false" [allowAdding]="false">
            </dxo-editing>
            <!--dxi-column type="buttons" cssClass="buttons">
                <dxi-button
                    hint="Modifica"
                    icon="edit"
                    [onClick]="openDettaglioOrganization">
                </dxi-button>
            </dxi-column-->

            <div *dxTemplate="let regTemplate of 'regTemplate'">
                <span translate>dashboardPage.registrata-il</span> {{regTemplate.data.dataCreazione}}
            </div>

            <div *dxTemplate="let referentTemplate of 'referentTemplate'">
                <a>{{referentTemplate.data.referente}}</a>
            </div>

            <div *dxTemplate="let fidTemplate of 'fidTemplate'">
                <a>{{formatNumber(fidTemplate.data.totaleFidejussione)}} &euro;</a>
            </div>

            <div *dxTemplate="let capacityTemplate of 'capacityTemplate'">
                <a>{{formatNumber(capacityTemplate.data.capacitaProduttiva)}} t</a>
            </div>

        </dx-data-grid>
    </div>
    <h2 class="top50" translate>dashboardPage.aste-disponibili</h2>
    <div id="auctions-list" class="auctions-list" style="display: contents;">
        <dx-data-grid id="gridContainer" [dataSource]="auctions_list" keyExpr="id" [allowColumnReordering]="false"
            [allowColumnResizing]="false" [showBorders]="true" [columnHidingEnabled]="false"
            (onRowPrepared)="onRowPreparedAuction($event)" (onContentReady)="onContentReady($event)"
            (onRowClick)="openDettaglioAuction($event)" (onRowPrepared)="onRowPrepared($event)">

            <dxo-load-panel [enabled]="true"></dxo-load-panel>
            <dxo-filter-row [visible]="false"></dxo-filter-row>

            <dxo-search-panel [visible]="false" [highlightCaseSensitive]="false">
            </dxo-search-panel>

            <!-- edit form and columns -->
            <dxi-column dataField="id" [allowEditing]="false" [width]="100" caption="{{'dashboardPage.id' | translate}}" alignment="left" [visible]="false">
            </dxi-column>
            <dxi-column dataField="userId" caption="{{'dashboardPage.userId' | translate}}" dataType="string" showEditorAlways="true" [visible]="false">
            </dxi-column>
            <dxi-column dataField="dataCreazione" caption="{{'dashboardPage.dataCreazione' | translate}}" [width]="150" dataType="date" format="dd/MM/yyyy" showEditorAlways="true" [visible]="false">
            </dxi-column>
            <dxi-column dataField="dataModifica" caption="{{'dashboardPage.dataModifica' | translate}}" dataType="date" format="dd/MM/yyyy" showEditorAlways="true" [visible]="false">
            </dxi-column>
            <dxi-column dataField="codiceAsta" caption="{{'dashboardPage.codiceAsta' | translate}}" dataType="string" [visible]="true" cssClass="auction-code">
            </dxi-column>
            <dxi-column dataField="dataPubblicazione" caption="{{'dashboardPage.dataPubblicazione' | translate}}" dataType="string" [visible]="true" cssClass="publication-date">
            </dxi-column>
            <dxi-column dataField="dataOraInizioAsta" caption="{{'dashboardPage.dataOraInizioAsta' | translate}}" dataType="string" [visible]="true" cssClass="auction-start">
            </dxi-column>
            <dxi-column dataField="dataOraFineAsta" caption="{{'dashboardPage.dataOraFineAsta' | translate}}" dataType="string" [visible]="true" cssClass="auction-end-pubblica">
            </dxi-column>
            <dxi-column dataField="tipologiaAsta" caption="{{'dashboardPage.tipologiaAsta' | translate}}" dataType="string" [visible]="true" cssClass="tipologia-pubblica">
            </dxi-column>
            <dxi-column dataField="statoAsta" caption="{{'dashboardPage.statoAsta' | translate}}" dataType="string" [visible]="true" cellTemplate="statusTemplate">
            </dxi-column>

            <div *dxTemplate="let statusTemplate of 'statusTemplate'">
                <div class="status {{statusTemplate.data.cssClass}}">{{statusTemplate.data.statoAsta}}</div>
            </div>

        </dx-data-grid>
    </div>

    <div class="activities bottom50">
        <article>
            <h2>Attivit&agrave;</h2>
            <ul class="activities-list" id="activities-list">
                <li *ngFor="let activity of activities_list; let i = index">
                    <div class="date">{{activity.createDate}}</div>
                    <div class="description" *ngIf="isCoripet()">
                        <a
                            [routerLink]="['/operatore-aziende.html/', activity.idOrganization]">{{activity.nome}}&nbsp;{{activity.cognome}}</a>
                        &egrave; stato {{activity.operazione}}&nbsp;{{activity.desinenza}} <a
                            [routerLink]="['/operatore-operatori.html/']">lista Operatori</a> dell'azienda <a
                            [routerLink]="['/operatore-aziende.html/', activity.idOrganization]">{{activity.organizationName}}</a>
                    </div>
                    <div class="description" *ngIf="isAdmin()">
                        <a
                            [routerLink]="['/admin-aziende.html/', activity.idOrganization]">{{activity.nome}}&nbsp;{{activity.cognome}}</a>
                        &egrave; stato {{activity.operazione}}&nbsp;{{activity.desinenza}} <a
                            [routerLink]="['/admin-aziende.html/', activity.idOrganization, 'operatori']">lista
                            Operatori</a> dell'azienda <a
                            [routerLink]="['/admin-aziende.html/', activity.idOrganization]">{{activity.organizationName}}</a>
                    </div>
                    <div class="description" *ngIf="!isCoripet() && !isAdmin()">
                        <a>{{activity.nome}}&nbsp;{{activity.cognome}}</a> &egrave; stato
                        {{activity.operazione}}&nbsp;{{activity.desinenza}} <a href="operatore-operatori.html">lista
                            Operatori</a> dell'azienda <a
                            href="operatore-aziende-dettaglio.html?id={{activity.idOrganization}}">{{activity.organizationName}}</a>
                    </div>
                </li>
            </ul>
        </article>
    </div>
</div>