import { Location } from '@angular/common';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import { Component, NgModule, OnInit } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { Router } from '@angular/router';
import { CKEditorModule } from '@ckeditor/ckeditor5-angular';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { AppRoutingModule } from '../app-routing.module';
import { APIService } from '../services/APIService';
import { LocalUtilService } from '../services/local-util.service';
import { AnagraficaAziendaComponent } from './components/anagrafica-azienda/anagrafica-azienda.component';
import { ConfirmComponent } from './components/confirm/confirm.component';
import { DettaglioAziendaImpiantoComponent } from './components/dettaglio-azienda-impianto/dettaglio-azienda-impianto.component';
import { DettaglioLottoComponent } from './components/dettaglio-lotto/dettaglio-lotto.component';
import { FileBoxComponent } from './components/file-box/file-box.component';
import { FooterComponent } from './components/footer/footer.component';
import { HeadComponent } from './components/head/head.component';
import { HeaderComponent } from './components/header/header.component';
import { LoaderComponent } from './components/loader/loader.component';
import { LoginComponent } from './components/login/login.component';
import { PopupComponent } from './components/popup/popup.component';
import { SpallaComponent } from './components/spalla/spalla.component';
import { UploadFideiussioneComponent } from './components/upload-fideiussione/upload-fideiussione.component';
import { UploadLegaleRappresentanteComponent } from './components/upload-legale/upload-legale.component';
import { UploadModOrgComponent } from './components/upload-mod-org/upload-mod-org.component';
import { UploadVisuraComponent } from './components/upload-visura/upload-visura.component';
import { FlatpickrModule } from 'angularx-flatpickr';
import { DettaglioAziendaOperatoreComponent } from './components/dettaglio-azienda-operatore/dettaglio-azienda-operatore.component';
import { CentriDiSelezioneComponent } from './components/centri-di-selezione/centri-di-selezione.component';
import { DxButtonModule, DxDataGridModule, DxDropDownButtonModule, DxFormModule, DxPopupModule, DxScrollViewModule, DxTemplateModule, DxToolbarModule, DxTooltipModule } from 'devextreme-angular';
import { SetupPropertiesComponent } from './components/setup-properties/setup-properties.component';
import { SetupBetComponent } from './components/setup-bet/setup-bet.component';
import { ElencoOperatoriComponent } from './components/elenco-operatori/elenco-operatori.component';
import { ElencoImpiantiComponent } from './components/elenco-impianti/elenco-impianti.component';
import { ElencoOperatoriFullComponent } from './components/elenco-operatori-full/elenco-operatori-full.component';
import { AziendeComponent } from './components/aziende/aziende.component';
import { ElencoLottiComponent } from './components/elenco-lotti/elenco-lotti.component';
import { ElencoCsLottoComponent } from './components/elenco-cs-lotto/elenco-cs-lotto.component';
import { NextAuctionCountdownComponent } from './components/next-auction-countdown/next-auction-countdown.component';
import { ActiveAuctionBannerComponent } from './components/active-auction-banner/active-auction-banner.component';
import { TimerComponent } from './components/timer/timer.component';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';

@Component({
  selector: 'app-commons',
  templateUrl: './commons.component.html',
  styleUrls: ['./commons.component.css']
})
export class CommonsComponent extends APIService implements OnInit {

  constructor(public localService: LocalUtilService,
    location: Location, 
    router: Router) { 
    super(localService, location, router);    
  }

  ngOnInit(): void {
  }

}

@NgModule({
  declarations: [
    FooterComponent,
    HeadComponent,
    HeaderComponent,
    LoaderComponent,
    LoginComponent,
    PopupComponent,
    ConfirmComponent,
    SpallaComponent,
    DettaglioLottoComponent,
    DettaglioAziendaImpiantoComponent,
    DettaglioAziendaOperatoreComponent,
    FileBoxComponent,
    AnagraficaAziendaComponent,
    UploadVisuraComponent,
    UploadLegaleRappresentanteComponent,
    UploadModOrgComponent,
    UploadFideiussioneComponent,
    CentriDiSelezioneComponent,
    SetupPropertiesComponent,
    SetupBetComponent,
    ElencoOperatoriComponent,
    ElencoImpiantiComponent,
    ElencoOperatoriFullComponent,
    AziendeComponent,
    ElencoLottiComponent,
    ElencoCsLottoComponent,
    NextAuctionCountdownComponent,
    ActiveAuctionBannerComponent,
    TimerComponent
  ],
  imports: [     
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,
    NgbModule,
    CKEditorModule,
    FlatpickrModule.forRoot(),
    DxDataGridModule,
    DxDropDownButtonModule,
    DxFormModule,
    DxToolbarModule,
    DxButtonModule,
    DxTemplateModule,
    DxTooltipModule,
    DxPopupModule,
    DxScrollViewModule,
    
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    }), 
  ],
  exports: [
    FooterComponent,
    HeadComponent,
    HeaderComponent,
    LoaderComponent,
    LoginComponent,
    PopupComponent,
    ConfirmComponent,
    SpallaComponent,
    DettaglioLottoComponent,
    DettaglioAziendaImpiantoComponent,
    DettaglioAziendaOperatoreComponent,
    FileBoxComponent,
    AnagraficaAziendaComponent,
    UploadVisuraComponent,
    UploadLegaleRappresentanteComponent,
    UploadModOrgComponent,
    UploadFideiussioneComponent,
    CentriDiSelezioneComponent,
    SetupPropertiesComponent,
    SetupBetComponent,
    ElencoOperatoriComponent,
    ElencoImpiantiComponent,
    ElencoOperatoriFullComponent,
    AziendeComponent,
    ElencoLottiComponent,
    ElencoCsLottoComponent,
    NextAuctionCountdownComponent,
    ActiveAuctionBannerComponent,
    TimerComponent
  ],
  providers: [],
  bootstrap: [CommonsModule]
})
export class CommonsModule { }

export function HttpLoaderFactory(http: HttpClient): TranslateHttpLoader {
  return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}