<div class="content bottom80 auction-details asta-pubblica" *ngIf="auction"> <!-- ngIf="isActiveComponent()"-->
    <h2><span class="status disponibile" style="font-size: 32px;"
            translate>aste-pubbliche-dettaglio.asta-pubblica</span> {{auction.codiceAsta}} - {{auction.tipologiaAsta}}
    </h2>

    <div class="auction-detail last">
        <div class="detail-info">
            <span class="title" translate>aste-pubbliche-dettaglio.pet-misto</span>
            <h2 id="mistoLot">{{totaleMisto}}</h2>
            <span id="tonMisto" class="weight">{{tonMisto}} ton</span>
        </div>
    </div>

    <div class="auction-detail">
        <div class="detail-info">
            <span class="title" translate>aste-pubbliche-dettaglio.pet-trasparente</span>
            <h2 id="transLot">{{totaleTrasparente}}</h2>
            <span id="tonTrans" class="weight">{{tonTrasparente}} ton</span>
        </div>
    </div>

    <div class="auction-detail">
        <div class="detail-info">
            <span class="title" translate>aste-pubbliche-dettaglio.pet-colorato</span>
            <h2 id="colorLot">{{totaleColorato}}</h2>
            <span id="tonCol" class="weight">{{tonColorato}} ton</span>
        </div>
    </div>

    <div class="auction-detail">
        <div class="detail-info">
            <span class="title" translate>aste-pubbliche-dettaglio.pet-azzurrato</span>
            <h2 id="blueLot">{{totaleAzzurrato}}</h2>
            <span id="tonAzz" class="weight">{{tonAzzurrato}} ton</span>
        </div>
    </div>

    <div class="auction-detail">
        <div class="detail-info">
            <span class="title" translate>aste-pubbliche-dettaglio.lotti-totali</span>
            <h2 id="totalLot">{{lot_list.length}}</h2>
            <span id="tonTotal" class="weight">{{totaleTon}} ton</span>
        </div>
    </div>

    <div class="auctions-infos">
        <span translate>aste-pubbliche-dettaglio.inizio-asta</span>&nbsp;<b>{{auction.dataOraInizioAsta}}</b><br />
        <!-- <b>{{lot_list.length}}</b>&nbsp;<span translate>aste-pubbliche-dettaglio.lotti-disponibili</span> -->
        <span translate>aste-pubbliche-dettaglio.fine-asta</span>&nbsp;<b>{{auction.dataOraFineAsta}}</b>
    </div>

    <!-- <i class="fas fa-angle-left back-button" (click)="gotoListaAstePubbliche()"><span>Torna all'elenco aste</span></i> -->
    <div class="lots-list new-lots-list won" id='lots-list'>

        <dx-data-grid #dataGridLots id="gridContainer" [dataSource]="lot_list" keyExpr="id" [columnAutoWidth]="true"
            [showBorders]="false" (onExporting)="onExporting($event)" (onExported)="onExported($event)"
            (onContentReady)="onContentReady($event)">

            <dxo-export [enabled]="true" fileName="auction_lots_list"></dxo-export>

            <dxo-load-panel [enabled]="true"></dxo-load-panel>
            <dxo-filter-row [visible]="false"></dxo-filter-row>

            <dxo-search-panel [visible]="false" [highlightCaseSensitive]="false">
            </dxo-search-panel>

            <!-- edit form and columns -->
            <dxi-column dataField="id" [allowEditing]="false" [width]="100" caption="Id" alignment="left"
                [visible]="false">
            </dxi-column>
            <dxi-column dataField="codiceLotto" caption="" dataType="string" [visible]="true"
                cellTemplate="lotRowTemplate">
            </dxi-column>
            <dxi-column dataField="codiceAsta" caption="Codice asta" dataType="string" showEditorAlways="true"
                [visible]="false">
            </dxi-column>
            <dxi-column dataField="dataCreazione" caption="Data creazione" [width]="150" dataType="date"
                format="dd/MM/yyyy" showEditorAlways="true" [visible]="false">
            </dxi-column>
            <dxi-column dataField="dataModifica" caption="Data modifica" dataType="date" format="dd/MM/yyyy"
                showEditorAlways="true" [visible]="false">
            </dxi-column>
            <dxi-column dataField="tipoProdotto" caption="Tipo prodotto" dataType="string" [visible]="false"
                cellTemplate="lotRowTemplate"><!--cellTemplate="lotRowTemplate"-->
            </dxi-column>
            <dxi-column dataField="quantitaTon" caption="Quantità (ton)" dataType="number" [visible]="false">
            </dxi-column>
            <dxi-column dataField="numeroRilanci" caption="Numero rilanci" dataType="number" [visible]="false">
            </dxi-column>
            <dxi-column dataField="areaGeografica" caption="Area geografica" dataType="string" [visible]="false">
            </dxi-column>
            <dxi-column dataField="note" caption="Note" dataType="string" [visible]="false">
            </dxi-column>
            <dxi-column dataField="dataOraFineAstaEffettiva" caption="Chiusura asta" dataType="string"
                [visible]="false">
            </dxi-column>
            <dxi-column dataField="dataDisponibilitaLotto" caption="Data disponibilità lotto" dataType="string"
                [visible]="false">
            </dxi-column>
            <dxi-column dataField="prezzoBase" caption="Tipologia" dataType="number" [visible]="false">
            </dxi-column>
            <dxi-column dataField="prezzoUltimoRilancio" caption="Prezzo finale" dataType="number" [visible]="false">
            </dxi-column>

            <dxo-toolbar>
                <dxi-item name="exportButton"></dxi-item>
                <dxi-item location="after">
                    <div *dxTemplate>
                        <span class="titleXLS2line mt-2" translate>download-riepilogo-asta</span>
                    </div>
                </dxi-item>
            </dxo-toolbar>


            <div *dxTemplate="let lotDetail of 'lotRowTemplate'">
                <app-dettaglio-lotto [lotDetail]="lotDetail.data" [statoAsta]="auction.statoAsta"></app-dettaglio-lotto>
                <!--div class="lot">
                    <article>
                        <div class="full">
                            <div class="lot-row">
                                <div class="lot-cell">
                                    <div class="lot-number">
                                        <div class="num">
                                            {{lotDetail.data.codiceLotto}}
                                        </div>
                                    </div>
                                </div>
                                <div class="lot-cell">
                                    <div class="type type-public">
                                        Tipo: <b>{{lotDetail.data.tipoProdotto}}</b>
                                        <div class="download-scheda" id="{{lotDetail.data.codiceAsta}}" title="Scheda tecnica" (click)="downloadFromS3Url(getSchedaTecnicaUrl(lotDetail.data.tipoProdotto))" [attr.data-uuid]="getSchedaTecnicaUrl(lotDetail.data.tipoProdotto)">
                                            <i class="fal fa-file-pdf right10"></i>
                                            <div class="filename-report hidden">scheda-tecnica-{{lotDetail.data.tipoProdotto}}.pdf</div>
                                        </div>
                                    </div>
                                </div>
                                <div class="lot-cell small">
                                    <div class="quantity">Quantit&agrave;: <b>{{lotDetail.data.quantitaTon}} t</b></div>
                                </div>
                                <div class="lot-cell not-so-small">
                                    <div class="auction-close">Base d'asta: <b>{{lotDetail.data.prezzoBase}} &euro;/t</b></div>
                                </div>
                            </div>
                            <div class="lot-row">
                                <div class="lot-cell">
                                    <div class="area">Area: <b>{{lotDetail.data.areaGeografica}}</b></div>
                                </div>
                                <div class="lot-cell">
                                    <div class="product-code">Codice prodotto: <b>{{lotDetail.data.codiceProdotto}}</b></div>
                                </div>
                                <div class="lot-cell">
                                    <a name="openCentriSelezione" class="note" (click)="openCentriSelezione(lotDetail.data.codiceLotto)" [attr.data-id-lot]="lotDetail.data.codiceAsta">Centri di selezione</a>
                                </div>
                                <div class="lot-cell">
                                    <a name="openNote" class="note" (click)="openNote(lotDetail.data.note, lotDetail.data.codiceLotto)" [attr.data-id-lot]="lotDetail.data.codiceAsta"> Note</a>
                                    <span class="hidden" id="note-{{lotDetail.data.codiceAsta}}">{{lotDetail.data.note}}</span>
                                </div>
                                <div class="lot-cell">
                                    <div class="auction-close">Data disponibilit&agrave; lotto: <b>{{lotDetail.data.dataDisponibilitaLotto}}</b></div>
                                </div>
                            </div>
                        </div>
                        <div class="right">
                                                         
                        </div>
                    </article>
                </div-->
            </div>

        </dx-data-grid>

    </div>
</div>