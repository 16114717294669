<dx-popup [width]="1000" [showTitle]="true" title="{{'dettaglio.aggiungi-centro-di-selezione'|translate}}"
  [dragEnabled]="false" [showCloseButton]="true" [(visible)]="this.showPopup">
  <div *dxTemplate="let data of 'content'">
    <dx-scroll-view width="100%" height="100%">
      <div class="container">
        <div *ngIf="grid">
          <dx-data-grid id="gridContainer" [dataSource]="cs_list" keyExpr="id" [showColumnHeaders]="false">
            <dxi-column dataField="codice"></dxi-column>
            <dxi-column dataField="nome"></dxi-column>
            <dxi-column dataField="indirizzo"></dxi-column>
            <dxi-column dataField="codice" cellTemplate="template"></dxi-column>
            <div *dxTemplate="let cellInfo of 'template'">
              <dx-button stylingMode="contained" text="Seleziona" [width]="200" type="default"
                (onClick)="click(cellInfo)">
              </dx-button>
            </div>
            <dxo-paging [pageSize]="40"> </dxo-paging>
          </dx-data-grid>
        </div>
        <div *ngIf="form">
          <div class="input mt-5">
            <div class="input-wrapper full">
              <input type="text" id="codiceLotto" [(ngModel)]="quantita">
              <label for="newOperator--codiceLotto" translate>dettaglio.quantità</label>
            </div>
            <p *ngIf="mandatory" class="fw-bold text-danger mt-2 mb-5" translate>
              dettaglio.inserisci-la-quantita`-di-tonnellate</p>
          </div>
          <p class="fw-bold mb-4" translate>dettaglio.centro-di-selezione</p>
          <p>{{editDetails}}</p>
        </div>
      </div>
    </dx-scroll-view>
  </div>
  <dxi-toolbar-item class="px-5" *ngIf="button" widget="dxButton" toolbar="bottom" location="after"
    [options]="saveButtonOptions">
  </dxi-toolbar-item>
  <dxi-toolbar-item class="px-5" widget="dxButton" toolbar="bottom" location="after" [options]="closeButtonOptions">
  </dxi-toolbar-item>
  <dxi-toolbar-item class="px-5" *ngIf="button" widget="dxButton" toolbar="bottom" location="after"
    [options]="backButtonOptions">
  </dxi-toolbar-item>
</dx-popup>
<div class="p-3" *ngIf="auction && auction.statoAsta == ConstantsModule.AUCTION_STATUS_CLOSED? false: true">
  <div class="add-item-section add-item-section-user" *ngIf="auction && auction.statoAsta == ConstantsModule.AUCTION_STATUS_AVAILABLE">
    <div class="add-operator-panel" [ngClass]="{'hidden': !isNewEditing(), 'show': isNewEditing()}">
      <h3 translate>dettaglio.dettaglio-lotto</h3>
      <form id="form_createOperator" [formGroup]="auctionDettaglioForm" class="form-group">
        <section *ngIf="firstSection" class="step-section focused">
          <div class="card">
            <div class="input" [ngClass]="{'error': controlHasError('codiceLotto')}">
              <div class="input-wrapper full">
                <input required formControlName="codiceLotto" type="text" id="codiceLotto" maxlength="75">
                <label for="newOperator--codiceLotto" translate>dettaglio.codice-lotto</label>
              </div>
            </div>

            <div class="input" [ngClass]="{'error': controlHasError('codiceProdotto')}">
              <div class="input-wrapper full">
                <input required formControlName="codiceProdotto" type="text" id="codiceProdotto" maxlength="75">
                <label for="newOperator--codiceProdotto" translate>dettaglio.codice-prodotto</label>
              </div>
            </div>

            <div class="input" [ngClass]="{'error': controlHasError('tipoProdotto')}">
              <div class="input-wrapper full">
                <select name="tipoProdotto" id="tipoProdotto" required formControlName="tipoProdotto"
                  class="form-control">
                  <option *ngFor="let lot of type_material; let i = index" [value]="lot.type_material">
                    {{lot.type_material}}</option>
                </select>
                <label for="tipoProdotto" translate>dettaglio.tipo-prodotto</label>
              </div>
            </div>

            <div class="input" [ngClass]="{'error': controlHasError('areaGeografica')}">
              <div class="input-wrapper full">
                <select name="areaGeografica" id="areaGeografica" required formControlName="areaGeografica"
                  class="form-control">
                  <option *ngFor="let lot of geo_area; let i = index" [value]="lot.area">
                    {{lot.area}}</option>
                </select>
                <label for="newOperator--areaGeografica" translate>dettaglio.area-geografica</label>
              </div>
            </div>

            <div class="input" [ngClass]="{'error': controlHasError('quantitaTon')}">
              <div class="input-wrapper full">
                <input required formControlName="quantitaTon" type="number" id="quantitaTon" maxlength="75">
                <label for="newOperator--quantitaTon" translate>dettaglio.quantita-ton</label>
              </div>
            </div>

            <div class="input" [ngClass]="{'error': controlHasError('prezzoBase')}">
              <div class="input-wrapper full">
                <input required formControlName="prezzoBase" type="number" id="prezzoBase" maxlength="75">
                <label for="newOperator--prezzoBase" translate>dettaglio.base-d'asta-in</label>
              </div>
            </div>

            <div class="input" [ngClass]="{'error': controlHasError('dataDisponibilitaLotto')}">
              <div class="input-wrapper full">
                <input required formControlName="dataDisponibilitaLotto" type="date" id="dataDisponibilitaLotto"
                  maxlength="75">
                <label for="newOperator--dataDisponibilitaLotto" translate>dettaglio.data-disponibilita-lotto</label>
              </div>
            </div>

            <ckeditor id="noteText" nome="noteText" [editor]="Editor" formControlName="note"></ckeditor>

            <div class="row mt-4">
              <p class="fw-bold col-4" translate>dettaglio.codice-centro-di-selezione</p>
              <p class="fw-bold col-4" translate>dettaglio.quantita</p>
              <p class="fw-bold col-4" translate>dettaglio.azioni</p>
            </div>

            <div class="row mt-4" *ngFor="let item of this.asteDetails; let i = index">
              <p class="col-4">{{item.codiceCentroSelezione}}</p>
              <p class="col-4">{{item.quantitaTon}}</p>
              <button class="col-4 btn btn-primary" (click)="rimuovi(i)"><span
                  translate>dettaglio.rimuovi</span></button>
            </div>

            <div class="pt-5">
              <button class="btn" style="border-color: orange;color: orange;" type="submit" (click)="openPopup()"><i
                  class="fal fa-save"></i>
                <span translate>dettaglio.aggiungi-centro-di-selezione</span></button>
            </div>

          </div>
          <div class="infos">
            <div class="number-wrapper">
              <div class="number">01</div>
              <div class="label">
                <span translate>dettaglio.dati-lotto</span>
              </div>
            </div>
          </div>
        </section>
      </form>
      <section *ngIf="secondSection" class="step-section focused">
        <div class="card">
          <div class="container-half">
            <div class="lots-list new-lots-list admin-buttons">
              <!--div class="lot"-->
              <article class="popup">
                <div class="buttons ingrid buttons-left">

                  <div class="doc-datas">
                    <app-file-box docname="excel-lotti.xlsx" [showCoripetButtons]="false"
                      (file)="getExcelLottiFile($event)"></app-file-box>
                  </div>
                  <br />
                  <button class="box__file btn btncarica success popup" style="border-color: green;color: green;"
                    type="submit" id="btn_createOperator" (click)="uploadDocument()"><i class="fal fa-save"></i>
                    <span translate>azienda-operatori.salva</span></button>
                </div>
              </article>
            </div>
          </div>
        </div>
        <div class="infos">
          <div class="number-wrapper">
            <div class="number">02</div>
            <div class="label">
              <span translate>anagrafica-azienda.dati-personali</span>
            </div>
          </div>
        </div>
      </section>

    </div>
    <div class="container add-operator-actions" [ngClass]="{'hidden': !isNewEditing(), 'show': isNewEditing()}">
      <div class="pb-5 text-end">
        <button class="btn" id="btn_cancelCreate" (click)="annullaNuovoOperatore()"><span
            translate>azienda-operatori.annulla</span></button>
        <button class=" btn" style="border-color: orange;color: orange;" type="submit" id="btn_createOperator"
          (click)="salvaAuctionDettaglio()"><i class="fal fa-save"></i> <span
            translate>azienda-operatori.salva</span></button>
      </div>
    </div>
    <button class="add add-operator new-operator-toggler" (click)="toggleNuovoOperatore()">
      <article>
        <div class="icon"><i class="fal fa-plus-circle"></i></div>
        <div class="text"><span translate>dettaglio.aggiungi-lotto</span></div>
      </article>
    </button>
  </div>
</div>
<div class="px-3">
  <hr>
</div>

<div class="p-4 auction-details m-0" *ngIf="auction">
  <div class="row">
    <div class="col-6">
      <h2><span class="status {{auction.statoAsta}}"><span [ngClass]="auction.statoAsta == 'Chiusa' ? '':'text-success'"
            style="font-size: 32px;"><span translate>dettaglio.asta</span>
            {{auction.statoAsta}}</span></span> {{auction.codiceAsta}} -
        {{auction.tipologiaAsta}}</h2>

      <div class="auctions-infos">
        <span translate>dettaglio.l'asta-inizier</span>&nbsp;<span translate>dettaglio.il</span>&nbsp;
        <!-- <b>{{auction.dataOraFineAsta}}</b> <span translate>dettaglio.e-si-concluder</span>&agrave; <span
          translate>dettaglio.il</span> -->
        <b>{{auction.dataOraFineAsta}}</b><br />
        <b>{{auction.numLotti}}</b>&nbsp;<span translate>dettaglio.lotti-disponibili</span>
      </div>
    </div>
    <div class="col-6 text-end" *ngIf="auction && auction.statoAsta == ConstantsModule.AUCTION_STATUS_CLOSED? false: true">
      <i class="pointer fa fa-file-excel-o me-3" style="font-size:38px;color:green" (click)="downloadTemplateExcelLotti()"></i>
      <input type="file" (change)="UploadExcelLottiFile($event)"
        class="btncarica box__file btn success btn-primary mb-3">
      <div> <button class="btn btn-outline-success btncarica" style="" (click)="caricaExcelLotti()"><span
            translate>dettaglio.carica</span></button>
      </div>
    </div>
  </div>

  <!-- <i class="fas fa-angle-left back-button" (click)="gotoBack()"><span>Torna all'elenco aste</span></i> -->

  <div class="lots-list new-lots-list admin-buttons"
    *ngIf="auction && auction.statoAsta == ConstantsModule.AUCTION_STATUS_AVAILABLE">
    <div class="lot">

      <article>
        <div class="buttons ingrid">
          <button class="btn btn-primary richiediApprovazioneBozze" type="button" (click)="requestApproveAllLots()">
            <span translate>dettaglio.richiedi-pubblicazione-di-tutti-i-lotti-bozza</span>
          </button>
          <button class="btn btn-primary annullaRichiestaApprovazione" type="button"
            (click)="undoRequestApproveAllLots()">
            <span translate>dettaglio.annulla-richiesta-pubblicazione-di-tutti-i-lotti-bozza</span>
          </button>
          <!--    <button class="btn btn-primary caricaExcelLotti" type="button" (click)="loadExcelLotti()">
            <span translate>dettaglio.carica-excel</span>
          </button> -->
        </div>
      </article>

    </div>
  </div>

  <div class="lots-list new-lots-list won bottom50" id='lots-list' [ngClass]="{'coripet-lots-list': isCoripet()}">

    <dx-data-grid id="gridContainer" [dataSource]="lot_list" keyExpr="id" [columnAutoWidth]="true" [showBorders]="false"
      (onRowPrepared)="onRowPrepared($event)" (onContentReady)="onContentReady($event)"
      (onStartEditing)="onStartEditing($event)"><!--dataRowTemplate="dataRowTemplateName"-->

      <dxo-load-panel [enabled]="true"></dxo-load-panel>
      <dxo-filter-row [visible]="false"></dxo-filter-row>
      <dxo-export [enabled]="auction && auction.statoAsta == ConstantsModule.AUCTION_STATUS_CLOSED? true: false"
        fileName="auctions_list"></dxo-export>

      <dxo-search-panel [visible]="auction && auction.statoAsta == ConstantsModule.AUCTION_STATUS_CLOSED? false: true"
        [highlightCaseSensitive]="false">
      </dxo-search-panel>

      <!-- edit form and columns -->
      <dxi-column dataField="id" [allowEditing]="false" [width]="100" caption="Id" alignment="left" [visible]="false">
      </dxi-column>
      <dxi-column dataField="codiceLotto" caption="" dataType="string" [visible]="true" cellTemplate="lotRowTemplate">
      </dxi-column>
      <dxi-column dataField="codiceAsta" caption="Codice asta" dataType="string" showEditorAlways="true"
        [visible]="false" cssClass="number">
      </dxi-column>
      <dxi-column dataField="dataCreazione" caption="Data creazione" [width]="150" dataType="date" format="dd/MM/yyyy"
        showEditorAlways="true" [visible]="false">
      </dxi-column>
      <dxi-column dataField="dataModifica" caption="Data modifica" dataType="date" format="dd/MM/yyyy"
        showEditorAlways="true" [visible]="false">
      </dxi-column>
      <dxi-column dataField="tipoProdotto" caption="Tipo prodotto" dataType="string" [visible]="false"
        cellTemplate="lotRowTemplate"><!--cellTemplate="lotRowTemplate"-->
      </dxi-column>
      <dxi-column dataField="quantitaTon" caption="Quantità (ton)" dataType="number" [visible]="false">
      </dxi-column>
      <dxi-column dataField="numeroRilanci" caption="Numero rilanci" dataType="number" [visible]="false">
      </dxi-column>
      <dxi-column dataField="areaGeografica" caption="Area geografica" dataType="string" [visible]="false">
      </dxi-column>
      <dxi-column dataField="note" caption="Note" dataType="string" [visible]="false">
      </dxi-column>
      <dxi-column dataField="dataOraFineAstaEffettiva" caption="Chiusura asta" dataType="string" [visible]="false">
      </dxi-column>
      <dxi-column dataField="dataDisponibilitaLotto" caption="Data disponibilità lotto" dataType="string"
        [visible]="false">
      </dxi-column>
      <dxi-column dataField="prezzoBase" caption="Tipologia" dataType="number" [visible]="false">
      </dxi-column>
      <dxi-column dataField="prezzoUltimoRilancio" caption="Prezzo finale" dataType="number" [visible]="false">
      </dxi-column>

      <dxo-toolbar *ngIf="auction && auction.statoAsta == ConstantsModule.AUCTION_STATUS_CLOSED? false: true">
        <dxi-item location="before">
          <div *dxTemplate>
            <div class="mt-2">
              <div class="input full form-check form-check-inline">
                <input class="form-check-input" type="checkbox" id="daapprovare" class="filter" value="daapprovare"
                [(ngModel)]="daapprovare" (click)="filterCheckBox()">
                <label class="form-check-label" for="daapprovare" translate>dettaglio.da-approvare</label>
              </div>
              <div class="input full form-check form-check-inline">
                <input class="form-check-input" type="checkbox" id="approvati" class="filter" value="approvati" 
                [(ngModel)]="approvati" (click)="filterCheckBox()">
                <label class="form-check-label" for="approvati" translate>dettaglio.approvati</label>
              </div>
              <div class="input full form-check form-check-inline">
                <input class="form-check-input" type="checkbox" id="rifiutati" class="filter" value="rifiutati"
                [(ngModel)]="rifiutati" (click)="filterCheckBox()">
                <label class="form-check-label" for="rifiutati" translate>dettaglio.rifiutati</label>
              </div>
              <div class="input full form-check form-check-inline">
                <input class="form-check-input" type="checkbox" id="bozza" class="filter" value="bozza"
                [(ngModel)]="bozza" (click)="filterCheckBox()">
                <label class="form-check-label" for="bozza" translate>dettaglio.bozza</label>
              </div>
            </div>
          </div>
        </dxi-item>
        <dxi-item location="before" name="searchPanel"></dxi-item>
      </dxo-toolbar>

      <div *dxTemplate="let lotDetail of 'lotRowTemplate'">
        <app-dettaglio-lotto [lotDetail]="lotDetail.data" [statoAsta]="auction.statoAsta"></app-dettaglio-lotto>
      </div>


    </dx-data-grid>

  </div>
</div>