import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';
import { LocalUtilService } from './local-util.service';
import { ConstantsModule } from './constants.module';
import { APIService } from './APIService';
import { ClientService } from './client.service';
import { Observable } from 'rxjs';
import { FileWithContentUpload } from '../models/dataModels';


@Injectable({
    providedIn: 'root'
})
export class CommonService extends ClientService {

    constructor(protected localUtilService: LocalUtilService, 
                httpClient: HttpClient,
                protected router: Router) {
            super(localUtilService, router, httpClient);
    }

    getNationsList() {
        return this.httpClient.get<any>(ConstantsModule.API_HOST + ConstantsModule.API_UTIL_PREFIX + ConstantsModule.API_PREFIX + '/nation/list', this.httpOptions);
    }
    
    getProvinceItalianeList() {
        return this.httpClient.get<any>(ConstantsModule.API_HOST + ConstantsModule.API_UTIL_PREFIX + ConstantsModule.API_PREFIX + '/province/IT/list', this.httNotLogged);        
    }

    getAreaGeograficaList() {
        return this.httpClient.get<any>(ConstantsModule.API_HOST + ConstantsModule.API_UTIL_PREFIX + ConstantsModule.API_PREFIX + '/geo/list', this.httpOptions);        
    }

    getMaterialTypesList() {
        return this.httpClient.get<any>(ConstantsModule.API_HOST + ConstantsModule.API_UTIL_PREFIX + ConstantsModule.API_PREFIX + '/material/list', this.httpOptions);        
    }

    documentUpload(doc: FileWithContentUpload) {
        const formData = new FormData();
        formData.append('file', doc.file);
        formData.append('contentType', doc.contentType);
        formData.append('frameworkId', doc.frameworkId);
        formData.append('vatnumber', doc.vatnumber);
        formData.append('codiceAsta', doc.codiceAsta);
        formData.append('codiceLotto', doc.codiceLotto);        
        return this.httpClient.post<any>(ConstantsModule.API_HOST + ConstantsModule.API_UPLOAD_PREFIX + ConstantsModule.API_PREFIX + '/document', formData, this.httpUploadOptions);
    }

    getSelectionCenterList() : Observable<any> {
        return this.httpClient.get<any>(ConstantsModule.API_HOST + ConstantsModule.API_CS_PREFIX + ConstantsModule.API_PREFIX + '/list', this.httpOptionsWithParams);
    }
}
