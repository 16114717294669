import { Injectable, Input } from '@angular/core';
import { ActivatedRoute, Router, Routes } from '@angular/router';
//import * as $ from 'jquery';
import $ from 'jquery';
import { Observable, Subject } from 'rxjs';
import { ConstantsModule } from './constants.module';
import { MessagesService } from './messages.service';
import * as moment from 'moment'; // add this 1 of 4
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { PopupComponent } from '../commons/components/popup/popup.component';
import { AuctionBoxTimer, AuctionSetupItem, AuthParams, CapData, HeaderDetail, HeaderStickyItem, RegistrationStep1Item, SliderValue } from '../models/dataModels';

@Injectable({
    providedIn: 'root'
})
export class LocalUtilService {

    constructor(private httpClient: HttpClient,
                public router: Router,
                public messages: MessagesService,
                private modalService: NgbModal,
                private route: ActivatedRoute) { }
    
    //loader
    loader: boolean = false;
    disableLoading: boolean = false;
    loaderMonitor = new Subject<boolean>();
    successMessageMonitor = new Subject<string>();
    validationErrorMessageMonitor = new Subject<string>();
    errorMessageMonitor = new Subject<string>();
    logoutMonitor = new Subject<boolean>();
    
    userHeaderMonitor = new Subject<boolean>();
    
    //slider capacity header value monitor
    companySubHeaderValueMonitor = new Subject<SliderValue>();
    auctionCurrentCost = new Subject<string>();
    auctionHeaderSticky = new Subject<HeaderStickyItem>();
    auctionBoxHeaderSticky = new Subject<boolean>();
    auctionReloadLots = new Subject<boolean>();
    lotsLoaderMonitor = new Subject<boolean>();

    companyCapacitySubHeaderMonitor = new Subject<boolean>();

    _listUserAuctionConfig: AuctionSetupItem[] = [];

    _listConfigurazioni: any[] = [];
    
    regStep1: RegistrationStep1Item;

    _adminOrgname: string = 'ITplasticfinder';
    _coripetOrgname: string = 'ITsystemuser';
    
    _activeAuctionCode: string;

    headerMonitor = new Subject<HeaderDetail>();

    timersMonitor = new Subject<AuctionBoxTimer[]>();
    timersEndCallback = new Subject<boolean>();
    serverTimeMonitor = new Subject<string>();

    private padTo2Digits(num) {
        return num.toString().padStart(2, '0');
    }
      
    formatDate(dateString: string) {
        let date = new Date(dateString);
        return [
        this.padTo2Digits(date.getDate()),
        this.padTo2Digits(date.getMonth() + 1),
        date.getFullYear(),
        ].join('/');
    }
    
    getDashboardPath() {
        let output = '';

        if (this.isAdmin()) {
            output = ConstantsModule.REF_ADMIN_DASHBOARD_PATH;
          } else if (this.isCoripet()) {
            output = ConstantsModule.REF_CORIPET_DASHBOARD_PATH;
          } else {
            output = ConstantsModule.REF_AZ_DASHBOARD_PATH;
          }
        return output;
    }

    launchLogout() {
        this.logoutMonitor.next(true);
    }

    subscribeLogout() : Observable<boolean> {
        return this.logoutMonitor;
    }

    setRoutes(routes: Routes) {
        console.log('setRoutes() data: ' + JSON.stringify(routes));
        sessionStorage.setItem('routes', JSON.stringify(routes));
    }

    getRoutes() :Routes {
        let routesList:Routes = [];
        let routesObj:any = undefined;
        try {
            routesObj = sessionStorage.getItem('routes');
            if (routesObj) {
                routesList = JSON.parse(routesObj);
            }
        } finally {
            return routesList;
        }
    }

    setAuthParams(data:any) {
        sessionStorage.setItem('user', JSON.stringify(data));        
    }

    removeAuthParams() {
        sessionStorage.removeItem('user');
    }

    get authParams() {
        let user = undefined;
        try {
            user = sessionStorage.getItem('user');
            if (user) {
                user = JSON.parse(user);
            }
        } finally {
            return user;
        }
    }

    get ruolo(): string {
        let data:any = undefined;
        let ruolo = '';
        try {
            data = sessionStorage.getItem('user');
            if (data) {
                data = JSON.parse(data);
                ruolo = data.user_detail.ruolo;
            }
        } finally {
            return ruolo;
        }
    }

    set configurazioni(list) {
        this._listConfigurazioni = list;
    }

    get configurazioni(): any[] {
        return this._listConfigurazioni;
    }

    set configurationUserAuction(list: AuctionSetupItem[]) {
        this._listUserAuctionConfig = list;
    }

    get configurationUserAuction(): AuctionSetupItem[] {
        return this._listUserAuctionConfig;
    }

    getUserAuctionConfigByName(list:AuctionSetupItem[], propName:any) {
        //console.log('getConfigByName() propName: ' + propName);
        let output = "";
        if (list != undefined && list.length > 0) {
            list.forEach(config => {
            /*if (config.keyz == propName) {
                output = config.valuez;
                //console.log('getConfigByName() propName: ' + propName + ' = ' + output);
            }*/
            });
        }
        return output;
    }

    showError(code:any, title:any, message:any, showdetailbutton?:any) {
        return this.showMessage(code, title, message, showdetailbutton);
    }

    showMessage(code:any, title:any, message:any, showdetailbutton?:any) {
        this.hideLoader();
        this.printLog('showMessage() code: ' + code + ', title: ' + title + ', message: ' + message);
        let msg = message;
        this.printLog('message: ' + message);
        if (code == undefined || code == null) {
            code = "E:GENERIC";
            try {
                msg = this.messages.getMessageFromCode(code);
            } catch (err) {
                this.printLog('code message not found for ' + code);
            }
        } else {
            msg = this.messages.getMessageFromCode(code);
        }
        if (msg == undefined || msg == '') {
            msg = message;
        }
        const modalRef = this.modalService.open(PopupComponent);
        modalRef.componentInstance.titolo = title;
        modalRef.componentInstance.messaggio = msg;
        modalRef.componentInstance.tipo = 'message';
        this.printLog('showMessage() end');
    }

    showPopupLate() {
        //console.log('showPopupLate()');
        $('#open-modal-dialog').click();
    }

    reloadUserHeaderParameters() {
        this.userHeaderMonitor.next(true);
    }

    subscribeUserHeaderParameters(): Observable<boolean> {
        return this.userHeaderMonitor.asObservable();
    }

    showLoader() {
        this.loader = true;
        this.loaderMonitor.next(true);
    }

    hideLoader() {
        this.loader = false;
        this.loaderMonitor.next(false);
    }

    isLoading() {
        return this.loader;
    }

    isDisableLoading() {
        return this.disableLoading;
    }

    setDisableLoading() {
        this.disableLoading = true;
    }

    clearDisableLoading() {
        this.disableLoading = false;
    }

    isLoadingMonitor(): Observable<boolean> {
        return this.loaderMonitor.asObservable();
    }

    isOrganizationOperator() {
        try {
            let output = false;
            if (this.ruolo == ConstantsModule.ROLE_OPER_AZIENDALE) {
                output = true;
            }
            return output;
        } catch (err) {
            this.printLog('err: ' + err);
            return false;
        }
    }

    isOrganizationReference() {
        try {
            let output = false;
            if (this.ruolo == ConstantsModule.ROLE_REF_AZIENDALE) {
                output = true;
            }
            return output;
        } catch (err) {
            this.printLog('err: ' + err);
            return false;
        }
    }
    
    isAdmin() {
        try {
            let output = false;
            if (this.ruolo == ConstantsModule.ROLE_ADMIN) {
                output = true;
            }
            return output;
        } catch (err) {
            this.printLog('err: ' + err);
            return false;
        }
    }

    isCoripet() {
        try {
            let output = false;
            if (this.ruolo == ConstantsModule.ROLE_CORIPET) {
                output = true;
            }
            return output;
        } catch (err) {
            this.printLog('err: ' + err);
            return false;
        }
    }

    isUser() {
        try {
            let output = false;
            if (this.ruolo == ConstantsModule.ROLE_OPER_AZIENDALE || this.ruolo == ConstantsModule.ROLE_REF_AZIENDALE) {
                output = true;
            }
            return output;
        } catch (err) {
            this.printLog('err: ' + err);
            return false;
        }
        return (!this.isCoripet() && !this.isAdmin());
    }

    isLogged(){
        return this.authParams != undefined;
    }

    isNotLogged() {
        return this.authParams == undefined;
    }

    printLog(log:any) {
        if (ConstantsModule.SHOW_LOG) {
            console.log(log);
        }
    }

    getConfigByName(propName:any) {
        //console.log('getConfigByName() propName: ' + propName);
        let output = "";
        if (this._listConfigurazioni != undefined && this._listConfigurazioni.length > 0) {
        this._listConfigurazioni.forEach(config => {
            if (config.keyz == propName) {
                output = config.valuez;
                //console.log('getConfigByName() propName: ' + propName + ' = ' + output);
            }
        });
        }
        return output;
    }

    handleError<T>(showPopup:any) {
        this.hideLoader();
        return (res: any) => {
            console.error(res.error);
            
            if (showPopup != undefined) {
                if (!showPopup) {
                    return;
                }
            }

            let message = res.error.message;

            if (res.error.fields != undefined) {
                if (res.error.fields.length > 0) {
                    message = 'Validation failed for:<br/>';
                    res.error.fields.forEach((element:any) => {
                        message += '<br/>' + element.field + " - " + element.message;
                    });
                }
            }
            this.showMessage(res.error.code, ConstantsModule.ERROR_TITLE, message, true);
        };
    }

    setSessionProp(key:any, val:any) {
        sessionStorage.setItem(key, val);
    }

    getSessionProp(key:any) {
        console.log('getSessionProp(' + key + ')');
        let output:any = '';
        if (sessionStorage.getItem(key)) {
            output = sessionStorage.getItem(key);
        }
        return output;
    }

    getSessionOrganization() {
        console.log('getSessionOrganization()');
        let output:any = '';
        if (sessionStorage.getItem('user')) {
            let user:any = JSON.parse(sessionStorage.getItem('user'));
            output = user.organization_detail;
        }
        return output;
    }

    formatCurrency(input:any, blur:any) {
        //utilities.printLog('formatCurrency() input: ' + input);
        // appends $ to value, validates decimal side
        // and puts cursor back in right position.
        
        // get input value
        var input_val = input.toString();
        
        // don't validate empty input
        if (input_val === "") { return; }
        
        // original length
        var original_len = input_val.length;
        
            
        // check for decimal
        if (input_val.indexOf(",") >= 0) {
        
            // get position of first decimal
            // this prevents multiple decimals from
            // being entered
            var decimal_pos = input_val.indexOf(",");
        
            // split number by decimal point
            var left_side = input_val.substring(0, decimal_pos);
            var right_side = input_val.substring(decimal_pos);
        
            // add commas to left side of number
            left_side = this.formatNumber(left_side);
        
            // validate right side
            right_side = this.formatNumber(right_side);
            
            // On blur make sure 2 numbers after decimal
            if (blur === "blur") {
            right_side += "00";
            }
            
            // Limit decimal to only 2 digits
            right_side = right_side.substring(0, 2);
        
            // join number by .
            input_val = left_side + "," + right_side;// + "&euro;";
        
        } else {
            // no decimal entered
            // add commas to number
            // remove all non-digits
            input_val = this.formatNumber(input_val);
            input_val = input_val + ",00";// &euro;";
        }
        
        // send updated string to input
        return input_val;
    }

    formatNumber(n:any) {
        // format number 1000000 to 1,234,567
        //console.log('formatNumber() n: ' + n);
        let output = n;
        let operator = n.toString();
        //console.log('formatNumber() operator: ' + operator);
        if (operator.indexOf("-") == 0) {
            operator = "-";
        } else {
            operator = "";
        }
        output = operator + n.replace(/\D/g, "").replace(/\B(?=(\d{3})+(?!\d))/g, ".");
        return output;
    }

    formatNumber2(input:any) {
        //console.log('formatNumber2(): ' + input);
        // appends $ to value, validates decimal side
        // and puts cursor back in right position.
        
        if (input == undefined) {
            return 0;
        }
        // get input value
        var input_val = input.toString();
        
        // don't validate empty input
        if (input_val === "") { return; }
        
        // original length
        var original_len = input_val.length;
        
            
        // check for decimal
        if (input_val.indexOf(",") >= 0) {
        
            // get position of first decimal
            // this prevents multiple decimals from
            // being entered
            var decimal_pos = input_val.indexOf(",");
        
            // split number by decimal point
            var left_side = input_val.substring(0, decimal_pos);
            var right_side = input_val.substring(decimal_pos);
        
            // add commas to left side of number
            left_side = this.formatNumber(left_side);
        
            // validate right side
            right_side = this.formatNumber(right_side);
            
            // On blur make sure 2 numbers after decimal
            /*if (blur === "blur") {
                right_side += "00";
            }*/
            
            // Limit decimal to only 2 digits
            right_side = right_side.substring(0, 2);
        
            // join number by .
            input_val = left_side + "," + right_side;
        
        } else {
            // no decimal entered
            // add commas to number
            // remove all non-digits
            input_val = this.formatNumber(input_val);
        }
        //console.log('input_val: ' + input_val);
        // send updated string to input
        return input_val;
    }

    numberWithCommas(x) {
        return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
    }

    getCodiceLottoNoPoints(codiceLotto) {
        let out = this.replaceAll(codiceLotto, ".", "");
        //utilities.printLog('out: ' + out);
        return out;
    }

    replaceAll(str, cerca, sostituisci) {
        return str.split(cerca).join(sostituisci);
    }

    downloadFile(uuid: string, filename: string): void{
        console.log('downloadFile() uuid: ' + uuid + ' filename: ' + filename );
        try {
            const baseUrl = ConstantsModule.API_HOST + ConstantsModule.API_UPLOAD_PREFIX + ConstantsModule.API_PREFIX + '/document/' + uuid;
            const token = this.authParams.token;
            const headers = new HttpHeaders().set('authorization','Bearer ' + token);
            this.httpClient.get(baseUrl,{responseType: 'arraybuffer',headers:headers}).subscribe(
                (response: any) => {
                    let type = 'application/pdf';
                    if (filename.toLowerCase().endsWith('.pdf')) {
                        type = 'application/pdf';
                    } else if (filename.toLowerCase().endsWith('.xlsx')) {
                        type = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';
                    } else if (filename.toLowerCase().endsWith('.xls')) {
                        type = 'application/vnd.ms-excel';
                    } else if (filename.toLowerCase().endsWith('.doc')) {
                        type = 'application/msword';
                    } else if (filename.toLowerCase().endsWith('.docx')) {
                        type = 'application/vnd.openxmlformats-officedocument.wordprocessingml.document';
                    }
                    this.downLoadFileExec(response, type, filename)
                }, error => {
                    this.showMessage("", ConstantsModule.ERROR_TITLE, this.messages.getMessageFromCode("E:DOWNLOAD_ERROR") + filename + " (" + error.message + ")", true);
                });
            } catch (err) {
                this.showMessage("", ConstantsModule.ERROR_TITLE, this.messages.getMessageFromCode("E:DOWNLOAD_ERROR") + filename, true);
            }
    }

    private downLoadFileExec(data: any, type: string, filename: string) {
        const a:any = document.createElement('a');
        a.style = 'display: none';
        document.body.appendChild(a);
        console.log(data);
        let blob = new Blob([data], { type: type});
        let url = window.URL.createObjectURL(blob);
        a.href = url;
        a.download = filename;
        a.click();
        URL.revokeObjectURL(url);
    }

    downloadFileFromS3(urlFile: string): void{
        const a:any = document.createElement('a');
        a.style = 'display: none';
        document.body.appendChild(a);
        let url = window.open(urlFile);
    }

    htmlDecode(input){
        var e = document.createElement('div');
        e.innerHTML = input;
        return e.childNodes[0].nodeValue;
    }

    getSelectionCenterByCodice(codice:string, list:any) {
        //utilities.printLog('getSelectionCenterByCodice(' + codice + ')');
        try {
            //utilities.printLog('getSelectionCenterByCodice list: ' + list);
            let output = undefined;

            if (list != undefined) {
                
                for (let i=0; i<list.length; i++) {
                    
                    let item = list[i];
                    if (item.codice == codice) {
                        output = item;
                        break;
                    }
                    
                }
            }

            return output;
        } catch (err) {
            console.error('getSelectionCenterByCodice(' + codice + ') err: ' + err);
        }
        return undefined;
    }

    getNazioneByCodice(codice:string, list:any) {
        
        let output = undefined;

        if (list != undefined) {
            
            for (let i=0; i<list.length; i++) {
                
                let item = list[i];
                if (item.codice == codice) {
                    output = item;
                    break;
                }
                
            }
        }

        return output;
    }

    scrollTop() {
        window.scroll({ 
                top: 0, 
                left: 0, 
                behavior: 'smooth' 
        }); 
    }

    set registrationStep1(data: RegistrationStep1Item) {
        this.regStep1 = data;
    }

    get registrationStep1(): RegistrationStep1Item {
        return this.regStep1;
    }

    get adminOrgname(): string {
        return this._adminOrgname;
    }

    set adminOrgname(orgname: string) {
        this._adminOrgname = orgname;
    }

    get coripetOrgname(): string {
        return this._coripetOrgname;
    }

    set coripetOrgname(orgname: string) {
        this._coripetOrgname = orgname;
    }

    set activeAuctionCode(auctionCode: string) {
        this._activeAuctionCode = auctionCode;
    }

    get activeAuctionCode(): string {
        return this._activeAuctionCode;
    }

    sliderCompanySubHeaderMonitor() {
        return this.companySubHeaderValueMonitor;
    }

    setValueSliderCompanySubHeader(sliderValue: SliderValue) {
        this.companySubHeaderValueMonitor.next(sliderValue);
    }

    capData: CapData;

    get CapData():CapData {
        return this.capData;
    }

    set CapData(capData:CapData) {
        this.capData = capData;
    }

    setCurrentCost(currentCost: string) {
        this.auctionCurrentCost.next(currentCost);
    }

    getCurrentCostMonitor() {
        return this.auctionCurrentCost;
    }

    showHeaderSticky(motoreTipologiaAsta: string) {
        let headerStickyItem: HeaderStickyItem = new HeaderStickyItem();
        headerStickyItem.value = true;
        headerStickyItem.motoreTipologiaAsta = motoreTipologiaAsta;
        this.auctionHeaderSticky.next(headerStickyItem);
    }

    hideHeaderSticky(motoreTipologiaAsta: string) {
        let headerStickyItem: HeaderStickyItem = new HeaderStickyItem();
        headerStickyItem.value = false;
        headerStickyItem.motoreTipologiaAsta = motoreTipologiaAsta;
        this.auctionHeaderSticky.next(headerStickyItem);
    }

    getAuctionHeaderStickyMonitor() {
        return this.auctionHeaderSticky;
    }

    getAuctionReloadLotsMonitor() {
        return this.auctionReloadLots;
    }

    reloadLots() {
        return this.auctionReloadLots.next(true);
    }

    getLotsLoaderMonitor() {
        return this.lotsLoaderMonitor;
    }

    lotsLoaderCalled() {
        this.lotsLoaderMonitor.next(true);
    }

    subscribeHeaderMonitor(): Observable<HeaderDetail> {
        return this.headerMonitor.asObservable();
    }

    setHeaderMonitor(headerDetail: HeaderDetail) {
        this.headerMonitor.next(headerDetail);
    }

    getTimersMonitor(): Observable<AuctionBoxTimer[]> {
        return this.timersMonitor.asObservable();
    }

    setTimers(timers: AuctionBoxTimer[]) {
        this.timersMonitor.next(timers);
    }

    getTimersCallbackMonitor(): Observable<boolean> {
        return this.timersEndCallback.asObservable();
    }

    raiseTimersEndCallback() {
        this.timersEndCallback.next(true);
    }

    setServerTime(serverTime: string) {
        this.serverTimeMonitor.next(serverTime);
    }

    getServerTimeMonitor(): Observable<string> {
        return this.serverTimeMonitor.asObservable();
    }

    isBoxView()  {
        return (this.router.url.indexOf(ConstantsModule.USER_ASTE_IN_CORSO_BOX)) > -1;
    }

    isListView() {
        return (this.router.url.indexOf(ConstantsModule.USER_ASTE_IN_CORSO)) > -1;
    }

    isEnabledToAuction() {
        let enabled: boolean = false;
        let organization = this.authParams.organization_detail;
        if (organization != undefined) {
          enabled = organization.validated != undefined && organization.validated == 'Y';
        }
        return enabled;
      }
    
}
