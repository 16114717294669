<div class="content bottom80 auction-details" *ngIf="auction"> <!-- ngIf="isActiveComponent()"-->           
    <h2><span class="status disponibile" style="font-size: 32px;" translate>aste-vinte-dettaglio.asta-vinta</span> {{auction.codiceAsta}} - {{auction.tipologiaAsta}}</h2>

    <div class="auctions-infos">
        <span translate>aste-vinte-dettaglio.l-asta-si</span> &egrave; <span translate>aste-vinte-dettaglio.conclusa-il</span>&nbsp;<b>{{auction.dataOraFineAsta}}</b><br/>
        <b>{{lot_list.length}}</b>&nbsp; <span translate>aste-vinte-dettaglio.lotti-vinti</span>
    </div>

    <!-- <i class="fas fa-angle-left back-button" (click)="gotoListaAsteVinte()"><span translate>aste-vinte-dettaglio.torna-all-elenco-aste</span></i> -->
    <div class="lots-list new-lots-list won" id='lots-list'>
        
        <dx-data-grid #dataGridLots
            id="gridContainer"
            [dataSource]="lot_list"
            keyExpr="id"
            [columnAutoWidth]="true"
            [showBorders]="false"
            (onExporting)="onExporting($event)"
            (onExported)="onExported($event)"
            (onContentReady)="onContentReady($event)"
            >

            <dxo-export [enabled]="true" fileName="auction_lots_list"></dxo-export>

            <dxo-load-panel [enabled]="true"></dxo-load-panel>
            <dxo-filter-row [visible]="false"></dxo-filter-row>
            
            <dxo-search-panel
                [visible]="false"
                [highlightCaseSensitive]="false">
            </dxo-search-panel>

            <!-- edit form and columns -->
            <dxi-column dataField="id" [allowEditing]="false" [width]="100" caption="{{'aste-vinte-dettaglio.id' | translate}}" alignment="left" [visible]="false">                
            </dxi-column>
            <dxi-column dataField="codiceLotto" caption="{{'aste-vinte-dettaglio.codice-lotto' | translate}}" dataType="string" [visible]="true" cellTemplate="lotRowTemplate">
            </dxi-column>
            <dxi-column dataField="codiceAsta" caption="{{'aste-vinte-dettaglio.codice-asta' | translate}}" dataType="string" showEditorAlways="true" [visible]="false">
            </dxi-column>
            <dxi-column dataField="dataCreazione" caption="{{'aste-vinte-dettaglio.data-creazione' | translate}}" [width]="150" dataType="date" format="dd/MM/yyyy" showEditorAlways="true" [visible]="false">
            </dxi-column>
            <dxi-column dataField="dataModifica" caption="{{'aste-vinte-dettaglio.data-modifica' | translate}}" dataType="date" format="dd/MM/yyyy" showEditorAlways="true" [visible]="false">
            </dxi-column>
            <dxi-column dataField="tipoProdotto" caption="{{'aste-vinte-dettaglio.tipo-prodotto' | translate}}" dataType="string" [visible]="false" cellTemplate="lotRowTemplate"><!--cellTemplate="lotRowTemplate"-->
            </dxi-column>
            <dxi-column dataField="quantitaTon" caption="{{'aste-vinte-dettaglio.quantità-ton' | translate}}" dataType="number" [visible]="false">                    
            </dxi-column>
            <dxi-column dataField="numeroRilanci" caption="{{'aste-vinte-dettaglio.numero-rilanci' | translate}}" dataType="number" [visible]="false">                    
            </dxi-column>
            <dxi-column dataField="areaGeografica" caption="{{'aste-vinte-dettaglio.area-geografica' | translate}}" dataType="string" [visible]="false">
            </dxi-column>
            <dxi-column dataField="note" caption="{{'aste-vinte-dettaglio.note' | translate}}" dataType="string" [visible]="false">
            </dxi-column>
            <dxi-column dataField="dataOraFineAstaEffettiva" caption="{{'aste-vinte-dettaglio.chiusura-asta' | translate}}" dataType="string" [visible]="false">
            </dxi-column>
            <dxi-column dataField="dataDisponibilitaLotto" caption="{{'aste-vinte-dettaglio.data-disponibilità-lotto' | translate}}" dataType="string" [visible]="false">
            </dxi-column>
            <dxi-column dataField="prezzoBase" caption="{{'aste-vinte-dettaglio.tipologia' | translate}}" dataType="number" [visible]="false">
            </dxi-column>
            <dxi-column dataField="prezzoUltimoRilancio" caption="{{'aste-vinte-dettaglio.prezzo-finale' | translate}}" dataType="number" [visible]="false">
            </dxi-column>

            <dxo-toolbar>
                <dxi-item name="exportButton"></dxi-item>
                <dxi-item location="after">
                    <div *dxTemplate>
                        <span class="titleXLS2line mt-2" translate>download-riepilogo-asta</span>
                    </div>
                </dxi-item>
            </dxo-toolbar>
            
            <div *dxTemplate="let lotDetail of 'lotRowTemplate'">
                <app-dettaglio-lotto [lotDetail]="lotDetail.data" [statoAsta]="auction.statoAsta"></app-dettaglio-lotto>
                <!--div class="lot">
                    <article>
                        <ul class="lot-header">
                            <li class="lot-number">{{lotDetail.data.codiceLotto}}
                                <div class="download-scheda" id="{{lotDetail.data.codiceAsta}}" title="Scheda tecnica" (click)="downloadFromS3Url(getSchedaTecnicaUrl(lotDetail.data.tipoProdotto))" [attr.data-uuid]="getSchedaTecnicaUrl(lotDetail.data.tipoProdotto)">
                                    <i class="fal fa-file-pdf right10"></i>
                                    <div class="filename-report hidden">scheda-tecnica-{{lotDetail.data.tipoProdotto}}.pdf</div>
                                </div>
                            </li>
                            <li class="type">Tipo: <b>{{lotDetail.data.tipoProdotto}}</b></li>
                            <li class="quantity">Quantità: <b>{{lotDetail.data.quantitaTon}} t</b></li>
                            <li class="area">Area: <b>{{lotDetail.data.areaGeografica}}</b></li>
                        </ul>
                        <ul class="lot-body">
                            <li>Rilanci: <b>{{lotDetail.data.numeroRilanci}}</b></li>
                            <li>Chiusura asta: <b>{{lotDetail.data.dataOraFineAstaEffettiva}}</b></li>
                            <li class="hidden">Vincitore: <a href="#">-</a></li>
                            <li>Data disponibilità lotto: <b>{{lotDetail.data.dataDisponibilitaLotto}}</b></li>
                        </ul>
                        <ul class="lot-footer">
                            <ul class="prices">
                                <li class="">Base d'asta: <b>{{lotDetail.data.prezzoBase}} €</b></li>
                                <li class="ok">Totale: <b>{{lotDetail.data.prezzoUltimoRilancio}} €</b></li>
                            </ul>
                            <ul class="note">
                                <div class="lot-cell">
                                    <a name="openNote" class="note" (click)="openNote(lotDetail.data.note, lotDetail.data.codiceLotto)" [attr.data-id-lot]="lotDetail.data.codiceAsta"> Note</a>
                                    <span class="hidden" id="note-{{lotDetail.data.codiceAsta}}">{{lotDetail.data.note}}</span>
                                </div>
                            </ul>
                            <ul class="cs">
                                <li class="">&nbsp;</li>
                                
                                <div class="lot-cell">
                                    <a name="openCentriSelezione" class="note" (click)="openCentriSelezione(lotDetail.data.codiceLotto)" [attr.data-id-lot]="lotDetail.data.codiceAsta">Centri di selezione</a>
                                </div>
                            </ul>
                            <ul class="status">
                                < solo per admin user >
                                <--div class="row-inline">
                                    <div class="lot-row">
                                        <div *ngIf="lotDetail.data.pagato == 0" class="lot-cell paid ko">
                                            Non pagato
                                        </div>
                                        <div *ngIf="lotDetail.data.pagato == 1" class="lot-cell paid ok">
                                            Pagato
                                        </div>
                                    </div>
                                    <div class="lot-row">
                                        <div *ngIf="lotDetail.data.spedito == 0" class="lot-cell paid ko">
                                            Non ritirato
                                        </div>
                                        <div *ngIf="lotDetail.data.spedito == 1" class="lot-cell paid ok">
                                            Ritirato
                                        </div>
                                    </div>
                                </div>
                            </ul>
                        </ul>                    
                    </article>
                </div-->
            </div>
            
        </dx-data-grid>

    </div>
</div>