import { Component, OnInit } from '@angular/core';
import { APIService } from 'src/app/services/APIService';
import { AuthService } from 'src/app/services/auth.service';
import { Location } from '@angular/common';
import { Router, Routes } from '@angular/router';
import { LocalUtilService } from 'src/app/services/local-util.service';
import { ConstantsModule } from 'src/app/services/constants.module';
import { FormsModule } from '@angular/forms';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { MessagesService } from 'src/app/services/messages.service';
import { PopupComponent } from '../popup/popup.component';
import { RegistrationService } from 'src/app/services/registration.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent extends APIService {

  constructor(public localService: LocalUtilService,
      public authService: AuthService,
      public registrationService: RegistrationService,
      private modalService: NgbModal,
      private messageService: MessagesService,
      location: Location, 
      router: Router) { 
      super(localService, location, router);
  }

  username: string;
  password: string;

  doLogin() {
    console.log('doLogin()');
    
    let loginData = {
      username : this.username,
      passwd : this.password
    };
    
    this.authService.login(loginData).subscribe(
      data => {
        console.log(data);          
        if (data.token != undefined) {
          console.log('data token != null');
          this.localService.setAuthParams(data);
          
          this.gotoLanding();

        } else {
          this.handleError(true);
        }
      },
      this.handleError(true)
    );
    return false;
  }

  gotoLanding() {
    
    this.router.navigate([this.localUtil.getDashboardPath()])
      .then(() => {
        window.location.reload();
      });

  }

  forgotPassword() {
    const modalRef = this.modalService.open(PopupComponent);
    modalRef.componentInstance.titolo = this.messageService.getLabelFromCode("FORGOT_PASSWORD_TITLE");
    modalRef.componentInstance.messaggio = this.messageService.getLabelFromCode("FORGOT_PASSWORD_REQUEST");
    modalRef.componentInstance.tipo = 'forgotPassword';
    modalRef.componentInstance.passEntry.subscribe((receivedEntry:any) => {
      if (receivedEntry != undefined) {

        let email = receivedEntry.trim();

        this.authService.rememberPwd(email).subscribe(
          data => {
            this.localUtil.showMessage("", ConstantsModule.INFO_TITLE, this.messageService.getLabelFromCode("FORGOT_PASSWORD_SUCCESS"), true);
          },
          this.handleError(true)
        );

      }
    });
    

  }

}
