<div id="aste-pubbliche" class="content"> <!-- ngIf="isActiveComponent()"-->           
    <h2 translate>aste-pubbliche.aste-pubbliche</h2>
    <div id="auctions-list" class="auctions-list">
        <dx-data-grid 
            id="gridContainer"
            [dataSource]="auctions_list"
            keyExpr="id"
            [allowColumnReordering]="false"
            [allowColumnResizing]="false"
            [showBorders]="true"
            [columnHidingEnabled]="false"
            (onRowPrepared)="onRowPrepared($event)"
            (onContentReady)="onContentReady($event)"
            (onRowClick)="openDettaglio($event)"
            >
            <dxo-export [enabled]="false" fileName="auction_published_list"></dxo-export>  

            <dxo-load-panel [enabled]="true"></dxo-load-panel>
            <dxo-filter-row [visible]="false"></dxo-filter-row>
            
            <dxo-search-panel
                [visible]="false"
                [highlightCaseSensitive]="false">
            </dxo-search-panel>

            <!-- edit form and columns -->
            <dxi-column dataField="id" [allowEditing]="false" [width]="100" caption="{{'aste-pubbliche.id' | translate}}" alignment="left" [visible]="false">
            </dxi-column>
            <dxi-column dataField="userId" caption="{{'aste-pubbliche.userId' | translate}}" dataType="string" showEditorAlways="true" [visible]="false">
            </dxi-column>
            <dxi-column dataField="dataCreazione" caption="{{'aste-pubbliche.dataCreazione' | translate}}" [width]="150" dataType="date" format="dd/MM/yyyy" showEditorAlways="true" [visible]="false">
            </dxi-column>
            <dxi-column dataField="dataModifica" caption="{{'aste-pubbliche.dataModifica' | translate}}" dataType="date" format="dd/MM/yyyy" showEditorAlways="true" [visible]="false">
            </dxi-column>
            <dxi-column dataField="codiceAsta" caption="{{'aste-pubbliche.codiceAsta' | translate}}" dataType="string" [visible]="true" cssClass="number">
            </dxi-column>
            <dxi-column dataField="statoAsta" caption="{{'aste-pubbliche.statoAsta' | translate}}" dataType="string" [visible]="false">                    
            </dxi-column>
            <dxi-column dataField="dataPubblicazione" caption="{{'aste-pubbliche.pubblicazione' | translate}}" dataType="string" [visible]="true" cssClass="publication-date">
            </dxi-column>
            <dxi-column dataField="dataOraInizioAsta" caption="{{'aste-pubbliche.inizioAsta' | translate}}" dataType="string" [visible]="true" cssClass="auction-start">
            </dxi-column>
            <dxi-column dataField="dataOraFineAsta" caption="{{'aste-pubbliche.conclusioneAsta' | translate}}" dataType="string" [visible]="true" cssClass="auction-end-pubblica">
            </dxi-column>
            <dxi-column dataField="tipologiaAsta" caption="{{'aste-pubbliche.tipologia' | translate}}" dataType="string" [visible]="true" cssClass="tipologia-pubblica">
            </dxi-column>
            
        </dx-data-grid>
    </div>
</div>