import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';
import { LocalUtilService } from './local-util.service';
import { ConstantsModule } from './constants.module';
import { Location } from '@angular/common';
import { ClientService } from './client.service';
import { Observable, of } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
    providedIn: 'root'
})
export class AuthService extends ClientService {

    constructor(protected localUtilService: LocalUtilService, 
                protected location: Location, 
                httpClient: HttpClient,
                private router: Router) {
            super(localUtilService, router, httpClient);
    }

    genericObservable: Observable<{}>;

    /*LOGIN.01.R*/
    login(loginData:any) : Observable<any> {
        this.localUtil.printLog('service.login() loginData: ' + JSON.stringify(loginData));
        return this.httpClient.post<any>(ConstantsModule.API_HOST_AUTH + ConstantsModule.API_PREFIX + '/login', loginData, this.httNotLogged);
    }

    /*LOGIN*/
    rememberPwd(email: string) {
        //this.localUtil.printLog('service.logout() logoutData: ' + JSON.stringify(logoutData));
        return this.httpClient.post<any>(ConstantsModule.API_HOST_AUTH + ConstantsModule.API_PREFIX + '/rememberpwd/' + email, this.httNotLogged);
    }
    
    /*LOGIN.03.R*/
    logout() {
        //this.localUtil.printLog('service.logout() logoutData: ' + JSON.stringify(logoutData));
        return this.httpClient.post<any>(ConstantsModule.API_HOST_AUTH + ConstantsModule.API_PREFIX + '/logout', undefined, this.httpOptions);
    }

    /*LOGIN.03.R*/
    getMenuList() {
        //this.localUtil.printLog('service.logout() logoutData: ' + JSON.stringify(logoutData));
        return this.httpClient.get<any>(ConstantsModule.API_HOST + ConstantsModule.API_MENU_PREFIX + ConstantsModule.API_PREFIX + '/list', this.httpOptionsWithParams);
    }

    getMenuListByPage(page:string) {
        //this.localUtil.printLog('service.logout() logoutData: ' + JSON.stringify(logoutData));
        return this.httpClient.get<any>(ConstantsModule.API_HOST + ConstantsModule.API_MENU_PREFIX + ConstantsModule.API_PREFIX + '/list/' + page, this.httpOptionsWithParams);
    }

    /*MENU.11.R*/
    getNewMenuList(father: number) {
        //this.localUtil.printLog('service.logout() logoutData: ' + JSON.stringify(logoutData));
        return this.httpClient.get<any>(ConstantsModule.API_HOST + ConstantsModule.API_MENU_PREFIX + ConstantsModule.API_PREFIX + '/' + father + '/list', this.httpOptionsWithParams);
    }

    get isAuthenticated(): boolean {
        return (this.localUtil.authParams != null);
    }

    get isAdmin(): boolean {
        return this.localUtil.isAdmin();
    }

    get isCoripet(): boolean {
        return this.localUtil.isCoripet();
    }


}
