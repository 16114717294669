<div class="content">
    <form id="registerConsents" class="content form-group" [formGroup]="consensiForm" *ngIf="consensiForm && registrazioneConsensi">
        <!-- AGGIUNGERE FOCUSED SE SI VUOLE "ILLUMINARE" IL PANNELLO -->
        <section class="step-section-black step-section focused">
            <div class="card">
                <h2>Salvataggio consensi</h2>
                <div class="number">
                    Per poter proseguire devi accettare i seguenti consensi
                </div>
            </div>
            <div class="infos">
                <div class="number-wrapper">
                    <div class="number">01</div>
                    <div class="label">
                        Consensi utente
                    </div>
                </div>
            </div>
        </section>      
        <section class="step-section terms">
            <div class="card">
                <h2>Informativa Privacy - Portale Aste Telematiche Coripet *</h2>
                <div class="row">
                    <div class="input" [ngClass]="{'error': controlHasError('checkPrivacy')}" (mouseleave)="validateField('checkPrivacy')">
                        <div class="input-wrapper full full">
                            <input type="checkbox" id="check-personal-datas" class="big" formControlName="checkPrivacy">
                            <label for="check-personal-datas">Cliccando QUI si dichiara di aver preso visione e di accettare integralmente la nostra <a href="CORIPET_portale aste_Informativa Privacy_20180107.pdf" target="_blank">informativa sulla privacy</a> e la nostra <a href="cookie-policy.pdf" target="_blank">informativa sui Cookie</a></label>
                        </div>
                    </div>
                </div>


            </div>
            <div class="infos">
                <div class="number-wrapper">
                    <div class="number">04</div>
                    <div class="label">
                        Trattamento dei dati
                    </div>
                </div>
            </div>
        </section>
        <section class="step-section terms">
            <div class="card">
                <h2>Regolamento aste telematiche - Condizioni generali per la vendita *</h2>
                <div class="row">
                    <div class="input" [ngClass]="{'error': controlHasError('checkRules')}" (mouseleave)="validateField('checkRules')">
                        <div class="input-wrapper full full">
                            <input type="checkbox" id="check-general-conditions" class="big" formControlName="checkRules">
                            <label for="check-general-conditions">
                                Cliccando QUI si dichiara di aver letto e accettato i termini e le condizioni del <a href="04_Regolamento_e_Condizioni Generali aste PET CORIPET.pdf" target="_blank">Regolamento Parte A e delle Condizioni Generali Parte B</a> e conseguentemente di impegnarsi ad osservare le disposizioni.
                            </label>
                        </div>
                    </div>
                </div>


            </div>
            <div class="infos">
                <div class="number-wrapper">
                    <div class="number">05</div>
                    <div class="label">
                        Condizioni generali di vendita
                    </div>
                </div>
            </div>
        </section>
        <section class="step-section terms">
            <div class="card">
                <h2>Accettazione espressa ai sensi degli art. 1341 e ss. c. c. *</h2>
                <div class="row">
                    <div class="input" [ngClass]="{'error': controlHasError('checkAcceptance')}" (mouseleave)="validateField('checkAcceptance')">
                        <div class="input-wrapper full full">
                            <input type="checkbox" id="check-auction-rules" class="big" formControlName="checkAcceptance">
                            <label for="check-auction-rules">
                                Cliccando QUI, si dichiara ai sensi degli art. 1341 e ss. c.c. di aver letto e accettare espressamente: gli art. 3.4 (Tolleranza quantitativa dei Prodotti), 4.2 (Restrizioni all'utilizzo delle Credenziali), 4.3 e 9.2 (Limitazioni di responsabilit&agrave; di CORIPET e del Fornitore del Sistema), 5.9 e 11 (Facolt&agrave; di sospendere l'Accreditamento e di sospendere, rinviare e annullare l'Asta), 5.11 e 6.2-6.3 e 12 (Decadenze ed esclusioni), 7 (Revoca dell'Accreditamento), 10 (Limitazioni all'aggiudicazione e altre restrizioni contrattuali), 13.3 (Deroga alla competenza giurisdizionale) della PARTE A - Regolamento aste telematiche CORIPET e gli art. 3 ("Ritiro e trasporto"), 4 ("Condizioni per il ritiro: requisiti, impegni e garanzie dell'Aggiudicatario"), 7 ("Pagamento e fatturazione"), 8 ("Clausola risolutiva espressa"), 9 ("Impossibilit&agrave; della prestazione - forza maggiore"), 10 ("Divieti per l'Aggiudicatario"), 11 ("Prevalenza delle presenti Condizioni") e 13 ("Foro Competente") della Parte B - Condizioni generali per la vendita all'asta dei contenitori in PET per liquidi.
                            </label>
                        </div>
                    </div>
                </div>


            </div>
            <div class="infos">
                <div class="number-wrapper">
                    <div class="number">06</div>
                    <div class="label">
                        Regolamento aste
                    </div>
                </div>
            </div>
        </section>
        <div class="main-actions">
            <button class="btn primary" id="btn_registrationStep2" (click)="saveUserConsents()">Salva</button>
        </div>
    </form>
</div>