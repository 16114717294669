import { DatePipe, Location } from '@angular/common';
import { Component, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { DxDataGridComponent } from 'devextreme-angular';
import { formatDate } from 'devextreme/localization';
import moment from 'moment';
import { AsteItem, AuctionCreateItem, AuctionItem, AuctionLotItem, AuctionTipologyItem } from 'src/app/models/dataModels';
import { APIService } from 'src/app/services/APIService';
import { AuctionService } from 'src/app/services/auction.service';
import { AuthService } from 'src/app/services/auth.service';
import { ConstantsModule } from 'src/app/services/constants.module';
import { LocalUtilService } from 'src/app/services/local-util.service';
import { MessagesService } from 'src/app/services/messages.service';

@Component({
  selector: 'app-admin-aste',
  templateUrl: './aste.component.html',
  styleUrls: ['./aste.component.css']
})
export class AdminAsteComponent extends APIService implements OnInit { //extends APIService manda in errore il componente

  @ViewChild("dataGridAste", { static: false }) dataGridAste: DxDataGridComponent;
  asteForm: FormGroup;
  aste: AsteItem = new AsteItem();
  submitted: boolean = false;

  constructor(public localService: LocalUtilService,
    private modalService: NgbModal,
    private messageService: MessagesService,
    public auctionService: AuctionService,
    location: Location,
    router: Router) {
    super(localService, location, router);

    this.asteForm = new FormGroup({
      dataDisponibilita: new FormControl(this.aste.dataDisponibilita, [Validators.required]),
      dataPubblicazione: new FormControl(this.aste.dataPubblicazione, [Validators.required]),
      dataOraInizioAsta: new FormControl(this.aste.dataOraInizioAsta, [Validators.required]),
      dataOraFineAsta: new FormControl(this.aste.dataOraFineAsta, [Validators.required]),
      tipologiaAsta: new FormControl(this.aste.tipologiaAsta, [Validators.required]),
    })
  }

  auctions_list: AuctionItem[] = [];
  tipology_list: AuctionTipologyItem[] = [];

  ngOnInit(): void {
    console.log('init()');
    this.listAuctions();
    this.listAuctionTipology();
  }

  isNewEditing() {
    return (this.aste.newEditing == true);
  }

  toggleNuovaAsta() {
    this.aste.newEditing = !this.aste.newEditing;
    if (this.aste.newEditing) {
      this.cleanValidation();
    }
  }

  cleanValidation() {
    this.asteForm.markAllAsTouched();
  }

  annullaNuovoOperatore() {
    this.aste = new AsteItem();
    this.aste.newEditing = false;
  }

  controlHasError(nome) {
    return !this.asteForm.get(nome).valid && this.submitted;
  }

  date = new Date();
  pipe = new DatePipe('en-US');
  salvaAuctionDettaglio() {
    if (this.validate()) {
      let request: AsteItem = new AsteItem();
      let dataDisponibilita = this.pipe.transform(this.asteForm.get('dataDisponibilita').value, 'dd/MM/yyyy HH:mm');
      let dataPubblicazione = this.pipe.transform(this.asteForm.get('dataPubblicazione').value, 'dd/MM/yyyy HH:mm');
      let dataOraInizioAsta = this.pipe.transform(this.asteForm.get('dataOraInizioAsta').value, 'dd/MM/yyyy HH:mm');
      let dataOraFineAsta = this.pipe.transform(this.asteForm.get('dataOraFineAsta').value, 'dd/MM/yyyy HH:mm');
      request = {
        dataDisponibilita: dataDisponibilita,
        dataPubblicazione: dataPubblicazione,
        dataOraInizioAsta: dataOraInizioAsta,
        dataOraFineAsta: dataOraFineAsta,
        tipologiaAsta: this.asteForm.get('tipologiaAsta').value,
      }
      console.log(request);
      this.auctionService.createAuction(request).subscribe(dt => {
        this.localUtil.showMessage("", ConstantsModule.INFO_TITLE, this.messageService.getLabelFromCode("SUCCESS_CREATE_AUCTION_MESSAGE"), true);
        this.toggleNuovaAsta();
        this.listAuctions();
      }, this.handleError(true));
    }
  }

  validate() {
    this.submitted = true;
    let output = true;
    console.log(this.asteForm);
    Object.keys(this.asteForm.controls).forEach(key => {
      if (!this.asteForm.get(key).valid) {
        output = false;
      }
    });
    return output;
  }

  listAuctionTipology() {

    this.auctionService.listAuctionTipology().subscribe(
      (data) => {
        //console.log('setting auction tipology list: ' + data.list.length);
        this.tipology_list = data.list;
      });

  }

  attive: boolean = true;
  concluse: boolean = true;
  pubbliche: boolean = true;
  bozza: boolean = true;
  annullate: boolean = true;

  listAuctions() {
    this.auctions_list = [];
    this.auctionService.listaAste(undefined).subscribe(
      (data) => {
        //console.log('setting auction list: ' + data.list.length);
        data.list.forEach((auction: AuctionItem) => {
          //preimpostazione data con momentjs sembra l'unico modo per visualizzare la data e ora in modo corretto
          //su devextreme
          auction.dataDisponibilita = moment(auction.dataDisponibilita, 'DD/MM/YYYY HH:mm', true).format();
          auction.dataPubblicazione = moment(auction.dataPubblicazione, 'DD/MM/YYYY HH:mm', true).format();
          auction.dataOraInizioAsta = moment(auction.dataOraInizioAsta, 'DD/MM/YYYY HH:mm', true).format();
          auction.dataOraFineAsta = moment(auction.dataOraFineAsta, 'DD/MM/YYYY HH:mm', true).format();
        });
        data.list.forEach(data => {
          if (
            (this.attive && data.statoAsta == "In Corso") ||
            (this.concluse && data.statoAsta == "Chiusa") ||
            (this.pubbliche && data.statoAsta == "Pubblica") ||
            (this.bozza && data.statoAsta == "Bozza") ||
            (this.annullate && data.statoAsta == "Annullata")
          ) {
            this.auctions_list.push(data);
          }
        });
        this.loadToPublishLots();
      });
  }

  filterCheckBox() {
    this.listAuctions();
  }

  loadToPublishLots() {
    if (this.auctions_list) {
      this.auctions_list.forEach(auction => {
        auction.cssClass = auction.statoAsta.replace(' ', '');
        this.auctionService.getAuctionLots(auction.codiceAsta, '').subscribe(
          data => {
            var somma = 0;
            if (data.lotList != undefined) {
              data.lotList.forEach(itemLot => {
                if (itemLot.statoLotto == ConstantsModule.AUCTION_LOT_STATUS_INPUBLISHING) {
                  somma++;
                }
              });
            }
            auction.lots = data.lotList;
            auction.sum_lots_to_publish = somma;
            auction.daValidare = somma + ' lotti';
            // set fields
            this.setLotsFields(auction);
          }
        );
      });
    }
  }

  setLotsFields(auction) {
    let lotList: AuctionLotItem[] = auction.lots;
    //console.log('setLotsFields()');
    if (lotList) {
      lotList.forEach(item => {
        item.prezzoUltimoRilancio = this.localUtil.formatNumber2(item.prezzoUltimoRilancio);

        if (item.pagato == 0) {
          item.paid = "Non pagato";
          item.paidClass = "ko";
        } else if (item.pagato == 1) {
          item.paid = "Pagato";
          item.paidClass = "ok";
        }
        if (item.spedito == 0) {
          item.retired = "Non ritirato";
          item.retiredClass = "ko";
        } else if (item.spedito == 1) {
          item.retired = "Ritirato";
          item.retiredClass = "ok";
        }

        item.paidChecked = item.pagato == 1;
        item.retiredChecked = item.spedito == 1;
        item.notPaidChecked = item.pagato == 0;
        item.notRetiredChecked = item.spedito == 0;

        item.codiceLottoNoPoints = this.localUtil.getCodiceLottoNoPoints(item.codiceLotto);

        if (item.nomeOrganization == undefined) {
          item.nomeOrganization = '-';
        }

      });
      //console.log('data.lotList: ');
      //console.log(lotList);
    }

  }

  onRowClick(e) {
    console.log(e);
    this.router.navigate(['admin-aste-dettaglio.html/', e.data.codiceAsta]);
    if (this.dataGridAste.instance.isRowExpanded(e.key)) {
      this.dataGridAste.instance.collapseRow(e.key);
    } else {
      this.dataGridAste.instance.cancelEditData();
      this.dataGridAste.instance.collapseAll(-1);
      this.dataGridAste.instance.expandRow(e.key);
    }
  }

  onRowExpanding(e) {
    this.dataGridAste.instance.cancelEditData();
    this.dataGridAste.instance.collapseAll(-1);
  }

  onRowPreparedAuction(info) {
    if (info.rowType === 'data') {
      //console.log('onRowPrepared() info.rowElement: ' + info.rowElement);
      info.rowElement.addClass('auction');
    }
  }

  onRowInsert(e) {
    let auction: AuctionCreateItem = e.data;

    console.log('PRE: ' + JSON.stringify(auction));

    //auction.dataOraInizioAsta = this.convertItalianDateTimeToGTM(auction.dataOraInizioAsta.split(' ')[0] + ' ' + auction.dataOraInizioAsta.split(' ')[1]);
    //auction.dataOraInizioAsta = moment(auction.dataOraInizioAsta, 'DD/MM/YYYY HH:mm', true).format();

    //auction.dataOraFineAsta = this.convertItalianDateTimeToGTM(auction.dataOraFineAsta.split(' ')[0] + ' ' + auction.dataOraFineAsta.split(' ')[1]);    
    //auction.dataDisponibilita = this.convertItalianDateTimeToGTM(auction.dataDisponibilita.split(' ')[0] + ' ' + auction.dataDisponibilita.split(' ')[1]);  
    //auction.dataPubblicazione = this.convertItalianDateTimeToGTM(auction.dataPubblicazione.split(' ')[0] + ' ' + auction.dataPubblicazione.split(' ')[1]);

    console.log('POST: ' + JSON.stringify(auction));

    this.auctionService.createAuction(auction).subscribe(
      data => {
        this.localUtil.showMessage("", ConstantsModule.INFO_TITLE, this.messageService.getLabelFromCode("SUCCESS_CREATE_AUCTION_MESSAGE"), true);
        this.listAuctions();
      }, this.handleError(true)
    );
  }

  onContentReady(event) {

  }

  openDettaglioAuction(e) {
    //console.log('event.target: ' + e.event.target.className);
    let className = e.event.target.className;
    if (e.rowType === "data" && className.toLowerCase().indexOf('pdf') < 0) {
      this.router.navigate([ConstantsModule.ADMIN_ASTE_DETTAGLIO_PATH + e.data.codiceAsta, {}]);
      //event.component.editRow(e.rowIndex);
    }
  }

}

