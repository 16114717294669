import { Location } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ConfirmComponent } from 'src/app/commons/components/confirm/confirm.component';
import { PopupComponent } from 'src/app/commons/components/popup/popup.component';
import { AreaGeograficaItem, CentroDiSelezioneItem, CentroSelezioneDetailItem, CentroSelezioneInsertItem, CentroSelezioneUpdateItem, NazioneItem, ProvinciaItem } from 'src/app/models/dataModels';
import { APIService } from 'src/app/services/APIService';
import { AuthService } from 'src/app/services/auth.service';
import { CentroSelezioneService } from 'src/app/services/centroSelezione.service';
import { CommonService } from 'src/app/services/common.service';
import { ConstantsModule } from 'src/app/services/constants.module';
import { LocalUtilService } from 'src/app/services/local-util.service';
import { MessagesService } from 'src/app/services/messages.service';
import { OrganizationService } from 'src/app/services/organization.service';

@Component({
  selector: 'app-coripet-centri-di-selezione',
  templateUrl: './centri-di-selezione.component.html',
  styleUrls: ['./centri-di-selezione.component.css']
})
export class CoripetCentriDiSelezioneComponent extends APIService implements OnInit {

  
  constructor(public localService: LocalUtilService,
    public authService: AuthService,
    public organizationService: OrganizationService,
    public centroSelezioneService: CentroSelezioneService,
    private modalService: NgbModal,
    private messageService: MessagesService,
    public commonService: CommonService,
    location: Location, 
    router: Router) {
    super(localService, location, router);
        
  }

  ngOnInit(): void {    
  }

}
