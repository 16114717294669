<div id="upload-fideiussione" class="content">
    <!-- AGGIUNGERE FOCUSED SE SI VUOLE "ILLUMINARE" IL PANNELLO -->
    <section class="step-section focused">
        <div class="card">
            <h2 translate>fideiussione.fideiussione-bancaria</h2>
            <div class="doc-datas doc-form-upload-fideiussione">
                <app-file-box [uuid]="existsDocument != undefined ? existsDocument.uuid : undefined" [status]="existsDocument != undefined ? existsDocument.status : ''" docname="fideiussione-bancaria.pdf" (file)="getFideiussioneBancariaFile($event)" [showError]="false" [showCoripetButtons]="false"></app-file-box>
                <div class="form">
                    <div class="input">
                        <div class="input-wrapper full">
                            <input type="number" id="amount" [(ngModel)]="totaleFidejussione">
                            <label for="amount" translate>fideiussione.importo-fideiussione-in-euro</label>
                        </div>
                    </div>
                    <div class="input">
                        <div class="input-wrapper full">
                            <input type="text" 
                                   mwlFlatpickr 
                                   dateFormat="d/m/Y"
                                   [(ngModel)]="dataScadenzaFidejussione" >
                            <label for="expiration" translate>fideiussione.data-di-scadenza</label>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="infos">
            <div class="number-wrapper">
                <div class="number">01</div>
                <div class="label">
                    <span translate>fideiussione.fideiussione-bancaria</span>
                </div>
            </div>
        </div>
    </section>
    <div class="main-actions">
        <a (click)="back()" class="btn" translate>fideiussione.annulla</a>
        <button class="btn btn-outline-primary button-color" (click)="uploadFideiussione()" id="btn_fileUpload" data-sector="fideiussione"><span translate>fideiussione.invia</span></button>
    </div>
</div>