<!-- Global site tag (gtag.js) - Google Analytics -->
<script async src="https://www.googletagmanager.com/gtag/js?id=UA-61415428-4"></script>
<script>
  window.dataLayer = window.dataLayer || [];
  function gtag(){dataLayer.push(arguments);}
  gtag('js', new Date());

  gtag('config', 'UA-61415428-4');
</script>
<div class="loader_mask" *ngIf="loaderVisible">
    <div class="loader"></div>
</div>
<style>
    .loader_mask {
          width: 100%;
          height: 100%;
          z-index: 9999;                
          background: #fff;
          position: fixed;
          top: 0px;
          left: 0px;
      }
    .loader {
          border: 16px solid #f3f3f3; /* Light grey */
          border-top: 16px solid #3498db; /* Blue */
          border-radius: 50%;
          width: 120px;
          height: 120px;
          animation: spin 2s linear infinite;
          z-index: 9999;
          text-align: center;
          margin-left: 47%;
          margin-top: 18%;
          position: absolute;
      }

      @keyframes spin {
          0% { transform: rotate(0deg); }
          100% { transform: rotate(360deg); }
      }
</style>