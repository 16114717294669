import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';
import { LocalUtilService } from './local-util.service';
import { ConstantsModule } from './constants.module';
import { APIService } from './APIService';
import { ClientService } from './client.service';
import { Observable } from 'rxjs';
import { CentroDiSelezioneItem, CentroSelezioneInsertItem, CentroSelezioneUpdateItem, FileWithContentUpload } from '../models/dataModels';


@Injectable({
    providedIn: 'root'
})
export class CentroSelezioneService extends ClientService {

    constructor(protected localUtilService: LocalUtilService, 
                httpClient: HttpClient,
                protected router: Router) {
            super(localUtilService, router, httpClient);
    }

    updateCentroSelezione(cs:CentroSelezioneUpdateItem) {
        return this.httpClient.put<any>(ConstantsModule.API_HOST + ConstantsModule.API_CS_PREFIX + ConstantsModule.API_PREFIX + '/update', cs, this.httpOptions);
    }

    createCentroSelezione(cs:CentroSelezioneInsertItem) {
        return this.httpClient.post<any>(ConstantsModule.API_HOST + ConstantsModule.API_CS_PREFIX + ConstantsModule.API_PREFIX + '/create', cs, this.httpOptions);
    }

    deleteCentroSelezione(codice: string) {
        return this.httpClient.delete<any>(ConstantsModule.API_HOST + ConstantsModule.API_CS_PREFIX + ConstantsModule.API_PREFIX + '/' + codice + '/delete', this.httpOptions);
    }
    
}
