import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Injectable({
  providedIn: 'root'
})
export class MessagesService {

  constructor(private translate: TranslateService) { 

    this.messages.set("OK_OPERATION","Operazione eseguita correttamente");
    this.messages.set("LOT_CREATE","Salvataggio lotto eseguito correttamente Caricare la scheda tecnica");
    this.messages.set("E:LOGIN_ERR","Autenticazione fallita");
    this.messages.set("E:AUTHENTICATION","Non autenticato");
    this.messages.set("E:AUTHORIZATION_FAIL","Sessione scaduta");
    
    this.messages.set("E:GENERIC","errore.generico");
    this.messages.set("E:ERR_GENERIC","Errore generico durante l'operazione.");
    this.messages.set("E:ERR_GENERIC","Errore generico durante l'operazione.");
    this.messages.set("E:REQ_LOT_PUBL_LIST_EMPTY", "Non ci sono lotti in stato 'Bozza' da approvare");
    this.messages.set("E:REQ_LOT_UNPUBL_LIST_EMPTY", "Non ci sono lotti in stato 'In Pubblicazione' da annullare");
    this.messages.set("E:INVALID_ORG", "Azienda non valida");
    this.messages.set("E:DOWNLOAD_ERROR", "Impossible scaricare il file ");
    
    this.messages.set("E:AUCLOTS_PAYD_ERR_ORG_NOT_EXISTS", "Non è possibile aggiornare un lotto non aggiudicato");
    this.messages.set("E:AUCLOTS_SENT_ERR_ORG_NOT_EXISTS", "Non è possibile aggiornare un lotto non aggiudicato");
    this.messages.set("E:ORG_FULL_USR_EXISTS", "Esiste già un utente con l'indirizzo email inserito");    
    this.messages.set("E:AUC_CREATE_SETUP_NOT_EXISTS", "Le impostazioni di setup delle aste annuali non sono presenti");
    this.messages.set("E:DEL_AUC_SETUP_USED_ERR", "Il setup asta non può essere cancellato perchè attivo");
    this.messages.set("E:AUC_OFFER_TIMEOUT", "L'asta per questo lotto è terminata");    
    this.messages.set("E:AUC_OFFER_ALREADY_MINE", "Ti stai già aggiudicando il lotto");
    this.messages.set("E:AUC_OFFER_MUST_BE_UPPER_ERR", "L'offerta deve essere maggiore del prezzo attuale");
    this.messages.set("E:AUC_OFFER_REFRESH_ERR", "Un altro utente ha già rilanciato su questo lotto. Ricarica la pagina per poter rilanciare");
    this.messages.set("E:ERR_UPDATE_AUCTION_SETUP_NO_ACTIVE_ROW_EXISTS", "Il setup asta non ha un elemento attivo.<br/>Crea o modifica una riga esistente per coprire la data odierna");
    

    //label
    this.labels.set("FORGOT_PASSWORD_TITLE","loginPage.recupera-password");
    this.labels.set("FORGOT_PASSWORD_REQUEST","Inserisci il tuo indirizzo email");
    this.labels.set("FORGOT_PASSWORD_SUCCESS","Richiesta di recupero password inviata, controlla nella tua mail");
    //this.labels.set("GENERIC_SUCCESS_MESSAGE_SAVE","Dati salvati correttamente" );

   
    this.labels.set("GENERIC_SUCCESS_MESSAGE_SAVE",this.translate.instant("GENERIC_SUCCESS_MESSAGE_SAVE"));
    this.labels.set("SUCCESS_CREATE_AUCTION_TIPOLOGY_MESSAGE","Tipologia asta creata correttamente");
    this.labels.set("SUCCESS_DELETE_AUCTION_TIPOLOGY_MESSAGE","Tipologia asta cancellata correttamente");    
    this.labels.set("SUCCESS_CREATE_AUCTION_SETUP_MESSAGE","Setup asta creato correttamente");
    this.labels.set("SUCCESS_UPDATE_AUCTION_SETUP_MESSAGE","Setup asta aggiornato correttamente");
    this.labels.set("SUCCESS_DELETE_AUCTION_SETUP_MESSAGE","Setup asta cancellato correttamente");    
    this.labels.set("SUCCESS_DELETE_AUCTION_BET_SLOT_MESSAGE","Taglio di rilancio cancellato correttamente");
    this.labels.set("SUCCESS_CREATE_AUCTION_BET_SLOT_MESSAGE","Taglio di rilancio creato correttamente");
    this.labels.set("SUCCESS_UPDATE_LOT_MESSAGE","Lotto aggiornato correttamente");
    this.labels.set("SUCCESS_DELETE_LOT_MESSAGE","Lotto cancellato correttamente");
    this.labels.set("SUCCESS_CREATE_LOT_MESSAGE","Lotto creato correttamente");    
    
    this.labels.set("DELETE_OPERATOR_TITLE","Elimina operatore");
    this.labels.set("DELETE_AUCTION_TIPOLOGY_TITLE","Elimina tipologia asta");
    this.labels.set("DELETE_AUCTION_SETUP_TITLE","Elimina setup asta");
    
    this.labels.set("DELETE_OPERATOR_MESSAGE","Sei sicuro di voler eliminare l'operatore ? (%1 - %2 - %3)");
    this.labels.set("DELETE_AUCTION_TIPOLOGY_MESSAGE","Sei sicuro di voler eliminare la tipologia asta ? (%1 - %2)");

    this.labels.set("DELETE_FRAMEWORK_TITLE","Elimina impianto");
    this.labels.set("DELETE_FRAMEWORK_MESSAGE","Sei sicuro di voler cancellare l'impianto %1 ?");
    this.labels.set("DELETE_CS_TITLE","Elimina centro di selezione");
    this.labels.set("DELETE_CS_MESSAGE","Sei sicuro di voler eliminare il centro di selezione ? (%1 - %2 - %3)");
    this.labels.set("CANCEL_AUCTION_MESSAGE","Sei sicuro di voler annullare l'asta %1 ?");
    this.labels.set("AUCTION_APPROVE_ALL_LOTS_MESSAGE","Sei sicuro di voler approvare la pubblicazione tutti i lotti ?");
    this.labels.set("AUCTION_UNDO_APPROVE_ALL_LOTS_MESSAGE","Sei sicuro di voler annullare la pubblicazione tutti i lotti ?");
    this.labels.set("AUCTION_REJECT_ALL_LOTS_MESSAGE","Sei sicuro di voler rifiutare la pubblicazione di tutti i lotti ?");

    this.labels.set("AUCTION_REQUEST_APPROVE_ALL_LOTS_MESSAGE","Sei sicuro di voler richiedere la pubblicazione tutti i lotti ?");
    this.labels.set("AUCTION_UNDO_REQUEST_APPROVE_ALL_LOTS_MESSAGE","Sei sicuro di voler annullare la richiesta di pubblicazione tutti i lotti ?");

    this.labels.set("AUCTION_DELETE_LOT_MESSAGE","Sei sicuro di voler cancellare il lotto %1 ?");

    this.labels.set("CANCEL_CS_MESSAGE","Sei sicuro di voler cancellare il centro di selezione %1 - %2 ?");

    this.labels.set("SUCCESS_CANCEL_AUCTION_MESSAGE","Asta annullata correttamente");
    this.labels.set("SUCCESS_CREATE_AUCTION_MESSAGE","Asta creata correttamente");
    this.labels.set("SUCCESS_OPERATOR_DISABLE","Operatore disabilitato correttamente");
    this.labels.set("SUCCESS_OPERATOR_ENABLE","Operatore abilitato correttamente");
    this.labels.set("SUCCESS_OPERATOR_DELETE","Operatore cancellato correttamente");
    this.labels.set("SUCCESS_CS_DELETE","Centro di selezione cancellato correttamente");
    this.labels.set("SUCCESS_FRAMEWORK_INSERT","Impianto creato correttamente");   
    this.labels.set("SUCCESS_FRAMEWORK_DELETE","Impianto cancellato correttamente");
    this.labels.set("SUCCESS_FRAMEWORK_UPDATE","Impianto aggiornato correttamente");
    this.labels.set("SUCCESS_OPERATOR_UPDATE","Operatore aggiornato correttamente");
    this.labels.set("ERROR_FORM_VALIDATION_MESSAGE","Compilare tutti campi obbligatori");    
    this.labels.set("ERROR_AZ_OP_CREATE_PASSWORD_LENGTH","La password deve essere lunga almeno 6 caratteri");
    this.labels.set("ERROR_AZ_OP_CREATE_PASSWORD_NOT_EQUAL","Le password non coincidono");
    this.labels.set("ERROR_FIDEIUSSIONE_WRONG_TOTALE","L'importo della fideiussione deve essere maggiore di 0");
    this.labels.set("ERROR_FIDEIUSSIONE_WRONG_DATA","La data scadenza fideiussione è obbligatoria]");


    this.labels.set("DATI_PERSONALI_UPDATED","Dati personali aggiornati");
    this.labels.set("REGISTRATION_SUCCESS", "Registrazione completata correttamente");
    this.labels.set("SAVE_CONSENT_SUCCESS", "Consensi salvati correttamente");

    this.labels.set("DESCR_DOC_MANCANTE","Mancante");
    this.labels.set("DESCR_DOC_MANCANTI","Mancanti");
    this.labels.set("DESCR_DOC_DA_APPROVARE","Da approvare");
    this.labels.set("DESCR_DOC_APPROVATO","Approvato");
    this.labels.set("DESCR_DOC_RIFIUTATO","Rifiutato");
    this.labels.set("RIFIUTATO","Non validata");
    this.labels.set("ORG_NOT_VALID_MESSAGE","L'azienda non è abilitata a partecipare alle aste");
    this.labels.set("ORG_NOT_VALID_TITLE","Azienda non abilitata");
    this.labels.set("ORG_REG_STEP1_PIVA_EXISTS", "La partita IVA inserita è già stata registrata, contatta il tuo referente aziendale");
    this.labels.set("USER_NOT_FOUND","Utente non trovato (%1)");
    this.labels.set("ORG_NOT_FOUND","Azienda non trovata");

    this.labels.set("ATTESA_VERIFICA","In attesa di verifica");
    this.labels.set("VALIDATA","Validata");
    this.labels.set("DOC_DA_APPROVARE","Documenti da approvare");
    this.labels.set("AZIENDA_REGISTRATA","Registrata");
    this.labels.set("VALIDATA","Validata");
    this.labels.set("DA_APPROVARE",this.translate.instant("DA_APPROVARE"));

    //this.labels.set("DA_APPROVARE","Da approvare");
    this.labels.set("APPROVATO","Approvato");
    this.labels.set("RIFIUTATO","Rifiutato");
    this.labels.set("NON_CARICATO","Non caricato");
    this.labels.set("INFO_TITLE","Info");
    this.labels.set("EXCEL_LOTS_TITLE","Caricamento lotti da file excel");
    this.labels.set("WRONG_ROLE","Ruolo errato durante l'inserimento dell'operatores");
    this.labels.set("SUCCESS_USER_ENABLED","Utente abilitato correttamente");
    this.labels.set("SUCCESS_USER_DISABLED","Utente disabilitato correttamente");
    this.labels.set("LOAD_ORG_DOCUMENT","Caricamento documento - ");
    this.labels.set("MAKE_OFFER_CONFIRM","Sei sicuro di voler rilanciare a %1 €/ton per il lotto %2 ?");

    this.labels.set("UPLOAD_FORM_FILE_NOT_LOADED","Seleziona o trascina il file da caricare e premi il bottone di azione");

    this.labels.set("AUCTION_CLOSED", "L'asta è terminata");
    this.labels.set("SALVA","common.salva");
    this.labels.set("IMPIANTO","common.impianto");
  }

  messages = new Map<String, String>();

  labels = new Map<String, String>();

  getMessageFromCode(code:string, params?: any[]): string {
    let output = '';
    let message:any = this.messages.get(code);
    if (message != undefined) {
      output = message;
    }
    if (params != undefined) {
      let indexPar:number = 1;
      params.forEach(par => {
        output = output.replace('%'+ indexPar, par);
        indexPar++;
      });
    }
    return output;
  }

  getLabelFromCode(code:string, params?: any[]): string {
    let output = '';
    let label:any = this.labels.get(code);
    if (label != undefined) {
      output = label;
    }
    if (params != undefined) {
      let indexPar:number = 1;
      params.forEach(par => {
        output = output.replace('%'+ indexPar, par);
        indexPar++;
      });
    }
    return output;
  }
  
}
