<div class="p-3">
    <div class="add-item-section add-item-section-user">
        <div class="add-operator-panel" [ngClass]="{'hidden': !isNewEditing(), 'show': isNewEditing()}">
            <h3 translate>centri-di-selezione.nuovo-centro-di-selezione</h3>
            <form id="form_createOperator" [formGroup]="csForm" class="form-group">
                <div class="input" [ngClass]="{'error': controlHasError('codice')}">
                    <div class="input-wrapper full">
                        <input required formControlName="codice" type="text" id="codice" maxlength="75">
                        <label for="newOperator--codice" translate>centri-di-selezione.codice <span>*</span></label>
                    </div>
                </div>
                <div class="input" [ngClass]="{'error': controlHasError('nome')}">
                    <div class="input-wrapper full">
                        <input required formControlName="nome" type="text" id="nome" maxlength="75">
                        <label for="newOperator--nome" translate>centri-di-selezione.nome <span>*</span></label>
                    </div>
                </div>
                <div class="input" [ngClass]="{'error': controlHasError('indirizzo')}">
                    <div class="input-wrapper full">
                        <input required formControlName="indirizzo" type="text" id="indirizzo" maxlength="75">
                        <label for="newOperator--indirizzo" translate>centri-di-selezione.indirizzo
                            <span>*</span></label>
                    </div>
                </div>
                <div class="input" [ngClass]="{'error': controlHasError('cap')}">
                    <div class="input-wrapper full">
                        <input required formControlName="cap" type="text" id="cap" maxlength="75">
                        <label for="newOperator--cap" translate>centri-di-selezione.cap <span>*</span></label>
                    </div>
                </div>
                <div class="input" [ngClass]="{'error': controlHasError('citta')}">
                    <div class="input-wrapper full">
                        <input required formControlName="citta" type="text" id="citta" maxlength="75">
                        <label for="newOperator--citta" translate>centri-di-selezione.città <span>*</span></label>
                    </div>
                </div>
                <div class="input" [ngClass]="{'error': controlHasError('nazione')}">
                    <div class="input-wrapper full">
                        <select name="nazione" id="nazione" required formControlName="nazione" class="form-control">
                            <option *ngFor="let lot of nation_list; let i = index" [value]="lot.codice">
                                {{lot.descrizione}}</option>
                        </select>
                        <label for="newOperator--nazione" translate>centri-di-selezione.nazione <span>*</span></label>
                    </div>
                </div>
                <div class="input" [ngClass]="{'error': controlHasError('provincia')}">
                    <div class="input-wrapper full">
                        <select name="provincia" id="provincia" required formControlName="provincia"
                            class="form-control">
                            <option *ngFor="let lot of province_list; let i = index" [value]="lot.descrizione">
                                {{lot.descrizione}}</option>
                        </select>
                        <label for="newOperator--provincia" translate>centri-di-selezione.provincia
                            <span>*</span></label>
                    </div>
                </div>
                <div class="input" [ngClass]="{'error': controlHasError('areaGeografica')}">
                    <div class="input-wrapper full">
                        <select name="areaGeografica" id="areaGeografica" required formControlName="areaGeografica"
                            class="form-control">
                            <option *ngFor="let lot of geo_list; let i = index" [value]="lot.area">
                                {{lot.area}}</option>
                        </select>
                        <label for="newOperator--areaGeografica" translate>centri-di-selezione.area-geografica
                            <span>*</span></label>
                    </div>
                </div>
                <div class="input" [ngClass]="{'error': controlHasError('pesoMedioCarico')}">
                    <div class="input-wrapper full">
                        <input required formControlName="pesoMedioCarico" type="text" id="pesoMedioCarico"
                            maxlength="75">
                        <label for="newOperator--pesoMedioCarico" translate>centri-di-selezione.peso-medio-carico
                            <span>*</span></label>
                    </div>
                </div>
                <div class="input" [ngClass]="{'error': controlHasError('CER')}">
                    <div class="input-wrapper full">
                        <input required formControlName="CER" type="text" id="CER" maxlength="75">
                        <label for="newOperator--CER" translate>centri-di-selezione.cer <span>*</span></label>
                    </div>
                </div>
                <div class="input" [ngClass]="{'error': controlHasError('capacitaTotale')}">
                    <div class="input-wrapper full">
                        <input required formControlName="capacitaTotale" type="text" id="capacitaTotale" maxlength="75">
                        <label for="newOperator--capacitaTotale" translate>centri-di-selezione.capacità-totale-impianto
                            <span>*</span></label>
                    </div>
                </div>
                <div class="input" [ngClass]="{'error': controlHasError('note')}">
                    <div class="input-wrapper full">
                        <!-- <input required formControlName="note" type="text" id="note" maxlength="75"> -->
                        <label for="newOperator--note" translate>centri-di-selezione.note <span>*</span></label>
                        <textarea required formControlName="note" type="text" id="note" rows="3"></textarea>
                    </div>
                </div>
            </form>
        </div>
        <div class="container add-operator-actions" [ngClass]="{'hidden': !isNewEditing(), 'show': isNewEditing()}">
            <div class="pb-5 text-end">
                <button class="btn" id="btn_cancelCreate" (click)="annullaNuovoOperatore()"><span
                        translate>azienda-operatori.annulla</span></button>
                <button class=" btn" style="border-color: orange;color: orange;" type="submit" id="btn_createOperator"
                    (click)="salvaCs()"><i class="fal fa-save"></i> <span
                        translate>azienda-operatori.salva</span></button>
            </div>
        </div>
        <button class="add add-operator new-operator-toggler" (click)="toggleNuovoOperatore()">
            <article>
                <div class="icon"><i class="fal fa-plus-circle"></i></div>
                <div class="text"><span translate>centri-di-selezione.crea-centro-di-selezione</span></div>
            </article>
        </button>
    </div>
</div>

<div class="p-4 pt-0 bottom80">
    <h2 translate>centri-di-selezione.centri-di-selezione</h2>
    <div id="centri-selezione-list" class="auctions-list">
        <dx-data-grid #dataGridCs id="gridContainer" [dataSource]="cs_list" keyExpr="id" [allowColumnReordering]="false"
            [allowColumnResizing]="true" [showBorders]="true" [columnHidingEnabled]="false" [columnAutoWidth]="true"
            (onEditingStart)="onEditingStart($event)" (onInitNewRow)="onInitNewRow($event)"
            (onRowExpanding)="onRowExpanding($event)" (onRowUpdating)="onRowUpdate($event)"
            (onRowInserting)="onRowInsert($event)" (onRowPrepared)="onRowPrepared($event)">

            <dxo-column-chooser [enabled]="true" [width]="300" [allowSearch]="true" [enabled]="true" mode="dragAndDrop"
                title="Seleziona colonne"></dxo-column-chooser>
            <dxo-export [enabled]="true" fileName="cs_list"></dxo-export>

            <dxo-load-panel [enabled]="true"></dxo-load-panel>
            <dxo-filter-row [visible]="false"></dxo-filter-row>
            <dxo-header-filter [visible]="false"></dxo-header-filter>
            <dxo-scrolling mode="infinite"></dxo-scrolling>

            <dxo-search-panel [width]="250" [visible]="true" [highlightCaseSensitive]="false"
                placeholder="{{'search'|translate}}">
            </dxo-search-panel>

            <!-- edit form and columns -->
            <dxi-column dataField="id" [allowEditing]="false" caption="{{ 'centri-di-selezione.id' | translate }}"
                alignment="left" [visible]="false">
            </dxi-column>
            <dxi-column dataField="createDate" caption="{{ 'centri-di-selezione.data-creazione' | translate }}"
                dataType="string" format="dd/MM/yyyy" [visible]="false">
            </dxi-column>
            <dxi-column dataField="modifiedDate" caption="{{ 'centri-di-selezione.data-modifica' | translate }}"
                dataType="string" format="dd/MM/yyyy" [visible]="false">
            </dxi-column>
            <dxi-column dataField="codice" caption="{{ 'centri-di-selezione.codice' | translate }}" cssClass="codice" dataType="string"
                [visible]="true">
                <dxi-validation-rule type="required"></dxi-validation-rule>
            </dxi-column>
            <dxi-column dataField="nome" caption="{{ 'centri-di-selezione.nome' | translate }}" dataType="string" cssClass="nome WrappedColumnClass" 
                [visible]="true">
                <dxi-validation-rule type="required"></dxi-validation-rule>
            </dxi-column>
            <dxi-column dataField="indirizzo" caption="{{ 'centri-di-selezione.indirizzo' | translate }}"
                dataType="string" [visible]="true" cssClass="indirizzo WrappedColumnClass">
                <dxi-validation-rule type="required"></dxi-validation-rule>
            </dxi-column>
            <dxi-column dataField="cap" caption="{{ 'centri-di-selezione.cap' | translate }}" dataType="string"
                [visible]="false">
                <dxi-validation-rule type="required"></dxi-validation-rule>
            </dxi-column>
            <dxi-column dataField="citta" caption="{{ 'centri-di-selezione.città' | translate }}" dataType="string"
                [visible]="false">
                <dxi-validation-rule type="required"></dxi-validation-rule>
            </dxi-column>
            <dxi-column dataField="nazione" caption="{{ 'centri-di-selezione.nazione' | translate }}"
                [calculateCellValue]="getNazioneDescr" dataType="string" [visible]="false">
                <dxi-validation-rule type="required"></dxi-validation-rule>
                <dxo-lookup [dataSource]="nation_list" valueExpr="codice" displayExpr="descrizione">
                </dxo-lookup>
            </dxi-column>
            <dxi-column dataField="nazioneDescr" caption="{{ 'centri-di-selezione.nazioneDescr' | translate }}"
                dataType="string" [visible]="false">
            </dxi-column>

            <dxi-column dataField="provincia" caption="{{ 'centri-di-selezione.provincia' | translate }}"
                dataType="string" [visible]="false">
                <dxi-validation-rule type="required"></dxi-validation-rule>
                <dxo-lookup [dataSource]="province_list" valueExpr="descrizione" displayExpr="descrizione">
                </dxo-lookup>
            </dxi-column>
            <dxi-column dataField="areaGeografica" caption="{{ 'centri-di-selezione.area-geografica' | translate }}"
                [width]="130" dataType="string" [visible]="true" cssClass="area">
                <dxi-validation-rule type="required"></dxi-validation-rule>
                <dxo-lookup [dataSource]="geo_list" valueExpr="area" displayExpr="area">
                </dxo-lookup>
            </dxi-column>
            <dxi-column dataField="CER" caption="{{ 'centri-di-selezione.cer' | translate }}" dataType="string"
                [visible]="false">
                <dxi-validation-rule type="required"></dxi-validation-rule>
            </dxi-column>
            <dxi-column dataField="pesoMedioCarico" caption="{{ 'centri-di-selezione.peso-medio' | translate }}"
                dataType="string" [visible]="true" cssClass="peso">
                <dxi-validation-rule type="required"></dxi-validation-rule>
            </dxi-column>
            <dxi-column dataField="capacitaTotale"
                caption="{{ 'centri-di-selezione.capacità-totale-impianto' | translate }}" dataType="string"
                [visible]="false" cellTemplate="capacityTemplate">
                <dxi-validation-rule type="required"></dxi-validation-rule>
            </dxi-column>
            <dxi-column dataField="note" caption="{{ 'centri-di-selezione.note' | translate }}" dataType="string"
                [visible]="false">
            </dxi-column>

            <dxi-column type="buttons" [width]="110">
                <dxi-button name="edit"></dxi-button>
                <dxi-button name="delete" [onClick]="onRowDelete"></dxi-button>
            </dxi-column>

            <dxo-toolbar>
                <dxi-item location="before" name="searchPanel"></dxi-item>
                <dxi-item name="exportButton"></dxi-item>
            </dxo-toolbar>

            <div *dxTemplate="let capacityTemplate of 'capacityTemplate'">
                <a>{{formatNumber(capacityTemplate.data.capacitaTotale)}} ton</a>
            </div>

            <dxo-master-detail [enabled]="false" template="detailCs"></dxo-master-detail>

            <div *dxTemplate="let detailCs of 'detailCs'">

                <dx-form [(formData)]="detailCs.data">

                    <dxi-item itemType="group"
                        caption="{{ 'centri-di-selezione.dati-centro-di-selezione' | translate }}" [colSpan]="20"
                        cssClass="top20 detail-form">
                        <dxi-item itemType="group" [colCount]="4" cssClass="detail-form-section">
                            <dxi-item dataField="codice"></dxi-item>
                            <dxi-item dataField="nome"></dxi-item>
                            <dxi-item dataField="indirizzo"></dxi-item>
                            <dxi-item dataField="cap"></dxi-item>
                        </dxi-item>
                        <dxi-item itemType="group" [colCount]="3" cssClass="detail-form-section">
                            <dxi-item dataField="citta"></dxi-item>
                            <dxi-item dataField="nazioneDescr" [label]="{text: 'Nazione'}"></dxi-item>
                            <dxi-item dataField="provincia"></dxi-item>
                        </dxi-item>
                        <dxi-item itemType="group" [colCount]="4" cssClass="detail-form-section">
                            <dxi-item dataField="areaGeografica"></dxi-item>
                            <dxi-item dataField="pesoMedioCarico"></dxi-item>
                            <dxi-item dataField="CER"></dxi-item>
                            <dxi-item dataField="capacitaTotale"></dxi-item>
                        </dxi-item>
                    </dxi-item>

                </dx-form>
            </div>

            <dxo-editing [allowUpdating]="true" [allowAdding]="true" [allowDeleting]="true" mode="form">

                <dxo-texts
                    confirmDeleteMessage="Sei sicuro di voler eliminare questo centro di selezione ?"></dxo-texts>

                <dxo-form [colCount]="20">

                    <dxi-item itemType="group"
                        caption="{{ 'centri-di-selezione.dati-centro-di-selezione' | translate }}" [colSpan]="20"
                        cssClass="top20 detail-form">
                        <dxi-item itemType="group" [colCount]="4" cssClass="detail-form-section">
                            <dxi-item dataField="codice"></dxi-item>
                            <dxi-item dataField="nome"></dxi-item>
                            <dxi-item dataField="indirizzo"></dxi-item>
                            <dxi-item dataField="cap"></dxi-item>
                        </dxi-item>
                        <dxi-item itemType="group" [colCount]="3" cssClass="detail-form-section">
                            <dxi-item dataField="citta"></dxi-item>
                            <dxi-item dataField="nazione"></dxi-item>
                            <dxi-item dataField="provincia"></dxi-item>
                        </dxi-item>
                        <dxi-item itemType="group" [colCount]="4" cssClass="detail-form-section">
                            <dxi-item dataField="areaGeografica"></dxi-item>
                            <dxi-item dataField="pesoMedioCarico"></dxi-item>
                            <dxi-item dataField="CER"></dxi-item>
                            <dxi-item dataField="capacitaTotale"></dxi-item>
                        </dxi-item>
                    </dxi-item>

                </dxo-form>

            </dxo-editing>

        </dx-data-grid>
    </div>
</div>