import { Location } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ConfirmComponent } from 'src/app/commons/components/confirm/confirm.component';
import { PopupComponent } from 'src/app/commons/components/popup/popup.component';
import { OrganizationOperatorCreateItem, OrganizationOperatorItem, RegistrationConsentsItem, RegistrationStep2Item, UserOperatorItem } from 'src/app/models/dataModels';
import { APIService } from 'src/app/services/APIService';
import { AuthService } from 'src/app/services/auth.service';
import { ConstantsModule } from 'src/app/services/constants.module';
import { LocalUtilService } from 'src/app/services/local-util.service';
import { MessagesService } from 'src/app/services/messages.service';
import { OrganizationService } from 'src/app/services/organization.service';
import { RegistrationService } from 'src/app/services/registration.service';

@Component({
  selector: 'app-consensi',
  templateUrl: './consensi.component.html',
  styleUrls: ['./consensi.component.css']
})
export class ConsensiComponent extends APIService implements OnInit {

  consensiForm: FormGroup;
  registrazioneConsensi: RegistrationConsentsItem = new RegistrationConsentsItem();
  submitted: boolean = false;

  constructor(public localService: LocalUtilService,
    public authService: AuthService,
    public registrationService: RegistrationService,
    private modalService: NgbModal,
    private messageService: MessagesService,
    location: Location, 
    router: Router) {
    super(localService, location, router);
  
  }

  ngOnInit(): void {
    console.log('consensi component');
    if (this.registrazioneConsensi != undefined) {
     this.initializeForm();
    }
  }

  initializeForm() {
    console.log('initializeForm()');
    this.consensiForm = new FormGroup({
      checkPrivacy: new FormControl(this.registrazioneConsensi.checkPrivacy, [Validators.required]),
      checkRules: new FormControl(this.registrazioneConsensi.checkRules, [Validators.required]),
      checkAcceptance: new FormControl(this.registrazioneConsensi.checkAcceptance, [Validators.required]),
    });
  }

  saveUserConsents() {

    this.submitted = true;
    if (this.validate()) {

      var obj = this.localUtil.authParams.user_detail;

      if (obj != undefined) {

        this.registrazioneConsensi.checkPrivacy = 'Y';
        this.registrazioneConsensi.checkRules = 'Y';
        this.registrazioneConsensi.checkAcceptance = 'Y';
  
        this.registrazioneConsensi.nome = obj.nome;
        this.registrazioneConsensi.cognome = obj.cognome;
        this.registrazioneConsensi.email = obj.email;
        this.registrazioneConsensi.lingua = obj.lingua;
        this.registrazioneConsensi.telefono = obj.telefono;
        this.registrazioneConsensi.mobile = obj.mobile;

        this.registrationService.updateUserConsents(this.registrazioneConsensi).subscribe(
          data => {

            this.localUtil.registrationStep1 = undefined;
            const modalRef = this.modalService.open(PopupComponent);
            modalRef.componentInstance.titolo = this.messageService.getLabelFromCode("INFO_TITLE");
            modalRef.componentInstance.messaggio = this.messageService.getLabelFromCode("SAVE_CONSENT_SUCCESS");
            modalRef.componentInstance.tipo = 'message';
            modalRef.componentInstance.passEntry.subscribe((receivedEntry:any) => {
              this.router.navigate([this.localUtil.getDashboardPath(), {}]);
            });

          }, this.handleError(true)
        );

      } else {
        this.localUtil.showMessage("", ConstantsModule.ERROR_TITLE, this.messageService.getMessageFromCode("E:GENERIC"), true);
      }
    
    } else {
      this.localUtil.showMessage("", ConstantsModule.ERROR_TITLE, this.messageService.getLabelFromCode("ERROR_FORM_VALIDATION_MESSAGE"), true);
    }

  }

  validateField(nome) {
    return this.consensiForm.get(nome).valid;
  }

  getControlValue(nome) {
    return this.consensiForm.get(nome).value;
  }

  controlHasError(nome) {
    return !this.consensiForm.get(nome).valid && this.submitted;
  }

  validate() {
    console.log('validate()');
    let output = true;

    Object.keys(this.consensiForm.controls).forEach(key => {
      //console.log(key + ' ' + this.operatorForm.get(key).value + ' valid?: ' + this.operatorForm.get(key).valid);
      if (!this.consensiForm.get(key).valid) {
        console.log(key + ' ' + this.consensiForm.get(key).value + ' valid?: ' + this.consensiForm.get(key).valid);      
        output = false;
      }
    });
    return output;
  }
  
}
