<div class="operator-body card-body" [ngClass]="{'hidden': !showDetail(), 'show': showDetail()}">
    <form id="form_createFramework" [formGroup]="operatorForm" class="form-group">
        <div class="body-wrapper" class='form-visible'>
            <div class="d-flex">
                <div class="input me-2" [ngClass]="{'error': controlHasError('nome')}" (mouseleave)="validateField('nome')">
                    <div class="input-wrapper full">
                        <input type="text" formControlName="nome" value="{{operatorDetail.nome}}">
                        <label translate>operatore-aziende-dettaglio.nome</label>
                    </div>
                </div>
                <div class="input" [ngClass]="{'error': controlHasError('cognome')}" (mouseleave)="validateField('cognome')">
                    <div class="input-wrapper full">
                        <input type="text" formControlName="cognome" value="{{operatorDetail.cognome}}">
                        <label translate>operatore-aziende-dettaglio.cognome</label>
                    </div>
                </div>
            </div>
            <div class="d-flex">
                <div class="input me-2" [ngClass]="{'error': controlHasError('email')}" (mouseleave)="validateField('email')">
                    <div class="input-wrapper full">
                        <input type="text" formControlName="email" value="{{operatorDetail.email}}" readonly>
                        <label translate>operatore-aziende-dettaglio.Email</label>
                    </div>
                </div>
                <div class="input" [ngClass]="{'error': controlHasError('lingua')}" (mouseleave)="validateField('lingua')">
                    <div class="input-wrapper full">
                        <select class="op-lingua" [(ngModel)]="operatorDetail.lingua" formControlName="lingua" name="op-lingua">
                            <option value="IT">Italiano</option>
                            <option value="EN">Inglese</option>
                        </select>
                        <label for="op-lingua" translate>operatore-aziende-dettaglio.lingua</label>
                    </div>
                </div>
            </div>
            <div class="d-flex">
                <div class="input me-2" [ngClass]="{'error': controlHasError('telefono')}" (mouseleave)="validateField('telefono')">
                    <div class="input-wrapper full">
                        <input type="text" formControlName="telefono" value="{{operatorDetail.telefono}}">
                        <label translate>operatore-aziende-dettaglio.telefono-fisso</label>
                    </div>
                </div>
                <div class="input" [ngClass]="{'error': controlHasError('mobile')}" (mouseleave)="validateField('mobile')">
                    <div class="input-wrapper full">
                        <input type="text" formControlName="mobile" value="{{operatorDetail.mobile}}">
                        <label translate>operatore-aziende-dettaglio.telefono-mobile</label>
                    </div>
                </div>
            </div>
        </div>
    </form>
    <div class="add-operator-actions" *ngIf="(isAdmin())&& showCoripetButtons">
        <div class="main-actions full">
            <button class="btn success btn-approve-plant" (click)="abilitaOperatore()"><i class="fal fa-check"></i>
                <span translate>operatore-aziende-dettaglio.abilita</span></button>
            <button class="btn error btn-reject-plant" (click)="disabilitaOperatore()"><i class="fal fa-do-not-enter"></i>
                <span translate>operatore-aziende-dettaglio.disabilita</span></button>
            <button class="btn error btn-reject-plant" (click)="eliminaOperatore()"><i class="fal fa-times"></i>
                <span translate>operatore-aziende-dettaglio.elimina</span></button>
            <button class="btn btn-primary primary" type="submit" (click)="salvaOperatore()" id="btn_createPlant"><i class="fal fa-save"></i><span translate>operatore-aziende-dettaglio.salva</span></button>
        </div>
    </div>
</div>