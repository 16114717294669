import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AdminAsteComponent } from './admin/components/aste/aste.component';
import { AdminAstaDettaglioComponent } from './admin/components/aste/dettaglio/dettaglio.component';
import { AdminAziendaDettaglioComponent } from './admin/components/aziende/dettaglio/azienda-dettaglio.component';
import { AdminCentriDiSelezioneComponent } from './admin/components/centri-di-selezione/centri-di-selezione.component';
import { AdminDashboardComponent } from './admin/components/dashboard/dashboard.component';
import { AdminOperatoriComponent } from './admin/components/operatori/operatori.component';
import { AdminSetupComponent } from './admin/components/setup/setup.component';
import { LoginComponent } from './commons/components/login/login.component';
import { UploadFideiussioneComponent } from './commons/components/upload-fideiussione/upload-fideiussione.component';
import { UploadLegaleRappresentanteComponent } from './commons/components/upload-legale/upload-legale.component';
import { UploadModOrgComponent } from './commons/components/upload-mod-org/upload-mod-org.component';
import { UploadVisuraComponent } from './commons/components/upload-visura/upload-visura.component';
import { CoripetAsteComponent } from './coripet/components/aste/aste.component';
import { CoripetAstaDettaglioComponent } from './coripet/components/aste/dettaglio/dettaglio.component';
import { CoripetAziendeComponent } from './coripet/components/aziende/aziende.component';
import { CoripetAziendaDettaglioComponent } from './coripet/components/aziende/dettaglio/azienda-dettaglio.component';
import { CoripetCentriDiSelezioneComponent } from './coripet/components/centri-di-selezione/centri-di-selezione.component';
import { CoripetDashboardComponent } from './coripet/components/dashboard/dashboard.component';
import { CoripetOperatoriComponent } from './coripet/components/operatori/operatori.component';
import { RegistrazioneStep1Component } from './public/components/registrazione-step1/registrazione-step1.component';
import { RegistrazioneStep2Component } from './public/components/registrazione-step2/registrazione-step2.component';
import { AuthGuardService } from './services/authguard.service';
import { IsAdminCanActivate, IsCoripetCanActivate, IsNotLogged, IsUserCanActivate } from './services/IsCanActivate';
import { AsteChiuseComponent } from './user/components/aste/aste-chiuse/aste-chiuse.component';
import { AsteChiuseDettaglioComponent } from './user/components/aste/aste-chiuse/dettaglio/aste-chiuse-dettaglio.component';
import { AsteInCorsoBoxComponent } from './user/components/aste/aste-in-corso-box/aste-in-corso-box.component';
import { AsteInCorsoComponent } from './user/components/aste/aste-in-corso/aste-in-corso.component';
import { AstePubblicheComponent } from './user/components/aste/aste-pubbliche/aste-pubbliche.component';
import { AstePubblicheDettaglioComponent } from './user/components/aste/aste-pubbliche/dettaglio/aste-pubbliche-dettaglio.component';
import { AsteVinteComponent } from './user/components/aste/aste-vinte/aste-vinte.component';
import { AsteVinteDettaglioComponent } from './user/components/aste/aste-vinte/dettaglio/aste-vinte-dettaglio.component';
import { AziendaAnagraficaComponent } from './user/components/azienda/anagrafica/azienda-anagrafica.component';
import { AziendaImpiantiComponent } from './user/components/azienda/impianti/azienda-impianti.component';
import { AziendaOperatoriComponent } from './user/components/azienda/operatori/azienda-operatori.component';
import { ConsensiComponent } from './user/components/consensi/consensi.component';
import { DashboardComponent } from './user/components/dashboard/dashboard.component';
import { AdminAziendeComponent } from './admin/components/aziende/aziende.component';
import { AziendaDettaglioComponent } from './admin/components/azienda-dettaglio/azienda-dettaglio.component';
import { AziendeComponent } from './admin/components/aziende-common/aziende.component';

const routes: Routes = [
  /*{
    path: '', pathMatch: 'full', component: LoginComponent,
    data: {
      title: 'Login'
    }, canActivate: []
  },*/
  {
    path: '', pathMatch: 'full',
    redirectTo: 'login'
  },
  {
    path: 'login', pathMatch: 'full', component: LoginComponent,
    data: {
      title: 'Login'
    }, canActivate: []
  },
  {
    path: 'dashboard.html', pathMatch: 'full', component: DashboardComponent,
    data: {
      title: 'Dashboard'
    }, canActivate: [IsUserCanActivate]
  },
  {
    path: 'aste-pubbliche.html', pathMatch: 'full', component: AstePubblicheComponent,
    data: {
      title: 'Aste pubbliche'
    }, canActivate: [IsUserCanActivate]
  },
  {
    path: 'aste-pubbliche-dettaglio/:codiceAsta', pathMatch: 'full', component: AstePubblicheDettaglioComponent,
    data: {
      title: 'Aste pubbliche dettaglio'
    }, canActivate: [IsUserCanActivate]
  },
  {
    path: 'aste-chiuse.html', pathMatch: 'full', component: AsteChiuseComponent,
    data: {
      title: 'Aste chiuse'
    }, canActivate: [IsUserCanActivate]
  },
  {
    path: 'aste-chiuse-dettaglio/:codiceAsta', pathMatch: 'full', component: AsteChiuseDettaglioComponent,
    data: {
      title: 'Aste chiuse dettaglio'
    }, canActivate: [IsUserCanActivate]
  },
  {
    path: 'aste-in-corso.html', pathMatch: 'full', component: AsteInCorsoComponent,
    data: {
      title: 'Aste in corso'
    }, canActivate: [IsUserCanActivate]
  },
  {
    path: 'aste-in-corso/:codiceAsta', pathMatch: 'full', component: AsteInCorsoComponent,
    data: {
      title: 'Aste in corso'
    }, canActivate: [IsUserCanActivate]
  },
  {
    path: 'aste-in-corso-box/:codiceAsta', pathMatch: 'full', component: AsteInCorsoBoxComponent,
    data: {
      title: 'Aste in corso'
    }, canActivate: [IsUserCanActivate]
  },
  {
    path: 'aste-vinte.html', pathMatch: 'full', component: AsteVinteComponent,
    data: {
      title: 'Aste vinte'
    }, canActivate: [IsUserCanActivate]
  },
  {
    path: 'aste-vinte-dettaglio/:codiceAsta', pathMatch: 'full', component: AsteVinteDettaglioComponent,
    data: {
      title: 'Aste vinte dettaglio'
    }, canActivate: [IsUserCanActivate]
  },
  {
    path: 'azienda-operatori.html', pathMatch: 'full', component: AziendaOperatoriComponent,
    data: {
      title: 'Operatori'
    }, canActivate: [IsUserCanActivate]
  },
  {
    path: 'azienda-impianti.html', pathMatch: 'full', component: AziendaImpiantiComponent,
    data: {
      title: 'Impianti'
    }, canActivate: [IsUserCanActivate]
  },
  {
    path: 'azienda-anagrafica_aziendale.html', pathMatch: 'full', component: AziendaAnagraficaComponent,
    data: {
      title: 'Anagrafica'
    }, canActivate: [IsUserCanActivate]
  },
  {
    path: 'upload-visura.html', pathMatch: 'full', component: UploadVisuraComponent,
    data: {
      title: 'Visura camerale'
    }, canActivate: [IsUserCanActivate]
  },
  {
    path: 'upload-documento-rappresentante.html', pathMatch: 'full', component: UploadLegaleRappresentanteComponent,
    data: {
      title: 'Documento rappresentante legale'
    }, canActivate: [IsUserCanActivate]
  },
  {
    path: 'upload-dichiarazione-231.html', pathMatch: 'full', component: UploadModOrgComponent,
    data: {
      title: 'Dichiarazione 231 - 2001'
    }, canActivate: [IsUserCanActivate]
  },
  {
    path: 'upload-fideiussione.html', pathMatch: 'full', component: UploadFideiussioneComponent,
    data: {
      title: 'Fideiussione bancaria'
    }, canActivate: [IsUserCanActivate]
  },
  {
    path: 'operatore-dashboard.html', pathMatch: 'full', component: CoripetDashboardComponent,
    data: {
      title: 'Dashboard'
    }, canActivate: [IsCoripetCanActivate]
  },
  {
    path: 'operatore-operatori.html', pathMatch: 'full', component: CoripetOperatoriComponent,
    data: {
      title: 'Operatori'
    }, canActivate: [IsCoripetCanActivate]
  },
  {
    path: 'operatore-aziende.html', pathMatch: 'full', component: AziendeComponent,
    data: {
      title: 'Aziende'
    }, canActivate: []
  },
  {
    path: 'operatore-aziende.html/:id', pathMatch: 'full', component: CoripetAziendeComponent,
    data: {
      title: 'Aziende'
    }, canActivate: [IsCoripetCanActivate]
  },
  {
    path: 'operatore-aziende-dettaglio.html/:vatnumber', pathMatch: 'full', component: AziendaDettaglioComponent,
    data: {
      title: 'Dettaglio azienda'
    }, canActivate: []
  },
  {
    path: 'operatore-aste.html', pathMatch: 'full', component: CoripetAsteComponent,
    data: {
      title: 'Aste'
    }, canActivate: [IsCoripetCanActivate]
  },
  {
    path: 'operatore-aste/:codiceAsta', pathMatch: 'full', component: CoripetAsteComponent,
    data: {
      title: 'Aste'
    }, canActivate: [IsCoripetCanActivate]
  },
  {
    path: 'operatore-aste-dettaglio.html/:codiceAsta', pathMatch: 'full', component: CoripetAstaDettaglioComponent,
    data: {
      title: 'Dettaglio asta'
    }, canActivate: [IsCoripetCanActivate]
  },
  {
    path: 'coripet-upload-visura/:vatnumber', pathMatch: 'full', component: UploadVisuraComponent,
    data: {
      title: 'Visura camerale'
    }, canActivate: [IsCoripetCanActivate]
  },
  {
    path: 'coripet-upload-legale/:vatnumber', pathMatch: 'full', component: UploadLegaleRappresentanteComponent,
    data: {
      title: 'Documento rappresentante legale'
    }, canActivate: [IsCoripetCanActivate]
  },
  {
    path: 'coripet-azienda-fideiussione/:vatnumber', pathMatch: 'full', component: UploadFideiussioneComponent,
    data: {
      title: 'Fideiussione bancaria'
    }, canActivate: [IsCoripetCanActivate]
  },
  {
    path: 'coripet-upload-dichiarazione-231/:vatnumber', pathMatch: 'full', component: UploadModOrgComponent,
    data: {
      title: 'Dichiarazione 231 - 2001'
    }, canActivate: [IsCoripetCanActivate]
  },
  {
    path: 'upload-fideiussione.html', pathMatch: 'full', component: UploadFideiussioneComponent,
    data: {
      title: 'Fideiussione bancaria'
    }, canActivate: [IsUserCanActivate]
  },
  {
    path: 'admin-centriselezione.html', pathMatch: 'full', component: CoripetCentriDiSelezioneComponent,
    data: {
      title: 'Centri di selezione'
    }, canActivate: []
  },
  {
    path: 'admin-dashboard.html', pathMatch: 'full', component: AdminDashboardComponent,
    data: {
      title: 'Dashboard'
    }, canActivate: [IsAdminCanActivate]
  },
  {
    path: 'admin-operatori.html', pathMatch: 'full', component: AdminOperatoriComponent,
    data: {
      title: 'Operatori'
    }, canActivate: [IsAdminCanActivate]
  },
  {
    path: 'admin-aziende.html', pathMatch: 'full', component: AdminAziendeComponent,
    data: {
      title: 'Aziende'
    }, canActivate: [IsAdminCanActivate]
  },
  {
    path: 'admin-aziende.html/:id', pathMatch: 'full', component: AdminAziendeComponent,
    data: {
      title: 'Aziende'
    }, canActivate: [IsAdminCanActivate]
  },
  /* {
    path: 'operatore-aziende.html', pathMatch: 'full', component: AdminAziendeComponent,
    data: {
      title: 'Aziende'
    }, canActivate: [IsAdminCanActivate]
  }, */
  /* {
    path: 'operatore-aziende-dettaglio.html/:vatnumber', pathMatch: 'full', component: AdminAziendaDettaglioComponent,
    data: {
      title: 'Dettaglio azienda'
    }, canActivate: [IsAdminCanActivate]
  }, */
  {
    path: 'admin-aste.html', pathMatch: 'full', component: AdminAsteComponent,
    data: {
      title: 'Aste'
    }, canActivate: [IsAdminCanActivate]
  },
  {
    path: 'admin-aste-dettaglio.html/:codiceAsta', pathMatch: 'full', component: AdminAstaDettaglioComponent,
    data: {
      title: 'Dettaglio asta'
    }, canActivate: [IsAdminCanActivate]
  },
  {
    path: 'admin-setup.html', pathMatch: 'full', component: AdminSetupComponent,
    data: {
      title: 'Setup'
    }, canActivate: [IsAdminCanActivate]
  },
  {
    path: 'admin-centriselezione.html', pathMatch: 'full', component: AdminCentriDiSelezioneComponent,
    data: {
      title: 'Centri di selezione'
    }, canActivate: [IsAdminCanActivate]
  },
  {
    path: 'registrazione/step1', pathMatch: 'full', component: RegistrazioneStep1Component,
    data: {
      title: 'Registrazione step1'
    }, canActivate: []
  },
  {
    path: 'registrazione/step2', pathMatch: 'full', component: RegistrazioneStep2Component,
    data: {
      title: 'Registrazione step2'
    }, canActivate: []
  },
  {
    path: 'consensi-utente', pathMatch: 'full', component: ConsensiComponent,
    data: {
      title: 'Consensi utente'
    }, canActivate: [IsUserCanActivate]
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { useHash: false, enableTracing: false })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
