import { Location } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { OrganizationOperatorUpdateItem, UserOperatorItem } from 'src/app/models/dataModels';
import { APIService } from 'src/app/services/APIService';
import { AuctionService } from 'src/app/services/auction.service';
import { CommonService } from 'src/app/services/common.service';
import { ConstantsModule } from 'src/app/services/constants.module';
import { LocalUtilService } from 'src/app/services/local-util.service';
import { MessagesService } from 'src/app/services/messages.service';
import { OrganizationService } from 'src/app/services/organization.service';
import { ConfirmComponent } from '../confirm/confirm.component';
import { PopupComponent } from '../popup/popup.component';

@Component({
  selector: 'app-dettaglio-azienda-operatore',
  templateUrl: './dettaglio-azienda-operatore.component.html',
  styleUrls: ['./dettaglio-azienda-operatore.component.css']
})
export class DettaglioAziendaOperatoreComponent extends APIService implements OnInit {

  constructor(localUtil: LocalUtilService,
    public auctionService: AuctionService,
    private modalService: NgbModal,
    private messageService: MessagesService,
    public organizationService: OrganizationService,
    location: Location, 
    router: Router,
    private route: ActivatedRoute,
    public commonService: CommonService) {
    super(localUtil, location, router);
    //console.log('DettaglioAziendaOperatoreComponent()');
    
  }

  @Input() operatorDetail: UserOperatorItem;
  @Input() showDetailForm: boolean = false;
  @Input() vatnumber: string;
  @Input() showCoripetButtons: boolean = true;
  
  operatorForm: FormGroup;
  submitted: boolean = false;
  
  ngOnInit(): void {
    let vatnumber = this.route.snapshot.paramMap.get('vatnumber');
    if (vatnumber != undefined) {
      this.vatnumber = vatnumber;
      //console.log('DettaglioAziendaOperatoreComponent() set vatnumber to ' + this.vatnumber);
    }

    if (this.operatorDetail != undefined) {
      this.initializeForm();
    }

  }

  initializeForm() {
    //console.log('initializeForm()');
    this.operatorForm = new FormGroup({
      id: new FormControl(this.operatorDetail.id, []),
      dataRegistrazione: new FormControl(this.operatorDetail.dataRegistrazione, []),
      nome: new FormControl(this.operatorDetail.nome, [Validators.required]),
      cognome: new FormControl(this.operatorDetail.cognome, [Validators.required]),
      email: new FormControl(this.operatorDetail.email, [Validators.required, Validators.email]),
      lingua: new FormControl(this.operatorDetail.lingua, [Validators.required]),
      telefono: new FormControl(this.operatorDetail.telefono, [Validators.required]),
      mobile: new FormControl(this.operatorDetail.mobile, [Validators.required]),
    });
  }

  validateField(nome) {
    return this.operatorForm.get(nome).valid;
  }

  controlHasError(nome) {
      return !this.operatorForm.get(nome).valid && this.submitted;
  }

  showDetail() {
    return this.showDetailForm;
  }

  validate() {
    this.submitted = true;
    let output = true;
    console.log(this.operatorForm);
    Object.keys(this.operatorForm.controls).forEach(key => {
      console.log(key + ' ' + this.operatorForm.get(key).value);
      if (!this.operatorForm.get(key).valid) {
        output = false;
        console.log(key + ' ' + this.operatorForm.get(key).value + ' not valid');
      } else {
        this.operatorDetail[key] = this.operatorForm.get(key).value;
      }
    });
    return output;
  }

  salvaOperatore() {
    if (this.validate()) {
      console.log('salvaImpianto() form valido');
      this.updateOperator();
    } else {
      this.localUtil.showMessage("", ConstantsModule.ERROR_TITLE, this.messageService.getLabelFromCode("ERROR_FORM_VALIDATION_MESSAGE"), true);
    }
  }

  updateOperator() {
    let operator: OrganizationOperatorUpdateItem = new OrganizationOperatorUpdateItem();
    operator.nome = this.operatorDetail.nome;
    operator.cognome = this.operatorDetail.cognome;
    operator.lingua = this.operatorDetail.lingua;
    operator.email = this.operatorDetail.email;
    operator.telefono = this.operatorDetail.telefono;
    operator.mobile = this.operatorDetail.mobile;
    
    this.organizationService.updateOperator(operator).subscribe(
      (data) => {

        const modalRef = this.modalService.open(PopupComponent);
        modalRef.componentInstance.titolo = this.messageService.getLabelFromCode("INFO_TITLE");
        modalRef.componentInstance.messaggio = this.messageService.getLabelFromCode("SUCCESS_OPERATOR_UPDATE");
        modalRef.componentInstance.tipo = 'message';
        modalRef.componentInstance.passEntry.subscribe((receivedEntry:any) => {
          this.reloadComponent();
        });

    },
    this.handleError(true));
  }

  disabilitaOperatore() {

    this.organizationService.disableUser(this.operatorDetail.email).subscribe(
      (res) => {

        const modalRef = this.modalService.open(PopupComponent);
        modalRef.componentInstance.titolo = this.messageService.getLabelFromCode("INFO_TITLE");
        modalRef.componentInstance.messaggio = this.messageService.getLabelFromCode("SUCCESS_OPERATOR_DISABLE");
        modalRef.componentInstance.tipo = 'message';
        modalRef.componentInstance.passEntry.subscribe((receivedEntry:any) => {
          this.reloadComponent();
        });

      },
      this.handleError(true)
    );

  }

  abilitaOperatore() {

    this.organizationService.enableUser(this.operatorDetail.email).subscribe(
      (res) => {

        const modalRef = this.modalService.open(PopupComponent);
        modalRef.componentInstance.titolo = this.messageService.getLabelFromCode("INFO_TITLE");
        modalRef.componentInstance.messaggio = this.messageService.getLabelFromCode("SUCCESS_OPERATOR_ENABLE");
        modalRef.componentInstance.tipo = 'message';
        modalRef.componentInstance.passEntry.subscribe((receivedEntry:any) => {
          this.reloadComponent();
        });

      },
      this.handleError(true)
    );

  }

  eliminaOperatore() {

    let params:any[] = [this.operatorDetail.nome, this.operatorDetail.cognome, this.operatorDetail.email];
    const modalRef = this.modalService.open(ConfirmComponent);
    modalRef.componentInstance.titolo = this.messageService.getLabelFromCode("DELETE_OPERATOR_TITLE");
    modalRef.componentInstance.messaggio = this.messageService.getLabelFromCode("DELETE_OPERATOR_MESSAGE", params);
    modalRef.componentInstance.passEntry.subscribe((receivedEntry:any) => {
      if (receivedEntry != undefined) {

        //let confirm = receivedEntry.trim();// non serve nessun parametro di ritorno da popup confirm

        this.organizationService.deleteUser(this.operatorDetail.email).subscribe(
          (res) => {
            
            const modalRef = this.modalService.open(PopupComponent);
            modalRef.componentInstance.titolo = this.messageService.getLabelFromCode("INFO_TITLE");
            modalRef.componentInstance.messaggio = this.messageService.getLabelFromCode("SUCCESS_OPERATOR_DELETE");
            modalRef.componentInstance.tipo = 'message';
            modalRef.componentInstance.passEntry.subscribe((receivedEntry:any) => {
              this.reloadComponent();
            });
            
          },
          this.handleError(true)
        );

      }
    });


  }

}
