<div class="p-4 admin-company-detail">

    <section class="company-infos-section" id="company-infos-section" *ngIf="organization">
        <h2><span translate>azienda-dettaglio.informazioni-azienda</span> {{organization.ragioneSociale}}</h2>
<!--         <i class="fas fa-angle-left back-button" (click)="gotoBack()"><span>Torna</span></i>
 -->        <div class="companies-list">
            <dx-data-grid id="gridContainer" [dataSource]="organizations_list" keyExpr="id"
                [allowColumnReordering]="false" [allowColumnResizing]="false" [showBorders]="true"
                [columnHidingEnabled]="false" (onContentReady)="onContentReady($event)">

                <dxo-load-panel [enabled]="true"></dxo-load-panel>
                <dxo-filter-row [visible]="false"></dxo-filter-row>

                <dxo-search-panel [visible]="false" [highlightCaseSensitive]="false">
                </dxo-search-panel>

                <!-- edit form and columns -->
                <dxi-column dataField="id" [allowEditing]="false" [width]="100" caption="Id" alignment="left"
                    [visible]="false">
                </dxi-column>
                <dxi-column dataField="userId" caption="UserId" dataType="string" showEditorAlways="true"
                    [visible]="false">
                </dxi-column>
                <dxi-column dataField="dataCreazione" caption="Data creazione" [width]="150" dataType="date"
                    format="dd/MM/yyyy" showEditorAlways="true" [visible]="false">
                </dxi-column>
                <dxi-column dataField="dataModifica" caption="Data modifica" dataType="date" format="dd/MM/yyyy"
                    showEditorAlways="true" [visible]="false">
                </dxi-column>
                <dxi-column dataField="nome" caption="Nome" dataType="string" [visible]="true"
                    cellTemplate="lotRowTemplate">
                </dxi-column>
                <dxi-column dataField="cognome" caption="Cognome" dataType="string" [visible]="false">
                </dxi-column>
                <dxi-column dataField="email" caption="Email" dataType="string" [visible]="false">
                </dxi-column>
                <dxi-column dataField="lingua" caption="Lingua" dataType="string" [visible]="false">
                </dxi-column>
                <dxi-column dataField="telefono" caption="Telefono" dataType="string" [visible]="false">
                </dxi-column>
                <dxi-column dataField="mobile" caption="Mobile" dataType="string" [visible]="false">
                </dxi-column>
                <dxi-column dataField="role" caption="Ruolo" dataType="string" [visible]="false">
                </dxi-column>
                <dxi-column dataField="status" caption="Stato" dataType="string" [visible]="false">
                </dxi-column>
                <dxi-column dataField="dataRegistrazione" caption="Data registrazione" dataType="string"
                    [visible]="false">
                </dxi-column>

                <div *dxTemplate="let lotRowTemplate of 'lotRowTemplate'">
                    <div class="company">
                        <article id="{{lotRowTemplate.data.id}}">
                            <ul class="company-header card-header">
                                <li name="name" class="name">{{lotRowTemplate.data.ragioneSociale}}</li>
                                <li name="createDate" class="creation-date">Registrata il
                                    {{lotRowTemplate.data.dataCreazione}}</li>
                                <li name="referent" class="referent"><a>{{lotRowTemplate.data.referente}}</a></li>
                                <li name="status" class="status {{lotRowTemplate.data.statusClass}}">
                                    {{lotRowTemplate.data.status}}</li>
                                <li name="funds" class="funds">{{lotRowTemplate.data.totaleFidejussione}} &euro;</li>
                                <li name="capacity" class="capacity">{{lotRowTemplate.data.capacitaProduttiva}} t</li>
                                <!--li name="organization" class="company"><a href="operatore-aziende-dettaglio.html?id={{idOrganization}}">{{ragioneSociale}}</a></li-->
                                <li class="actions" [ngClass]="{'menu-open': isMenuOpened(lotRowTemplate.data)}">
                                    <div class="actions-icons">
                                        <div class="card-body-toggler"
                                            [ngClass]="{'open': isFormOpened(lotRowTemplate.data)}"
                                            (click)="toggleDettaglioOrg(lotRowTemplate.data)">
                                            <!-- <i class="fal fa-expand-alt"></i> -->
                                        </div>
                                    </div>
                                </li>
                            </ul>
                            <div class="company-body card-body"
                                *ngIf="organization && userOrganization && isFormOpened(lotRowTemplate.data)">
                                <app-anagrafica-azienda [anagAzienda]="organization" [showCoripetButtons]="!isAdmin()"
                                    [anagUserAzienda]="userOrganization"></app-anagrafica-azienda>
                            </div>
                        </article>
                    </div>
                </div>

            </dx-data-grid>
        </div>
    </section>

    <section class="company-infos-section-plant" *ngIf="organization">
        <h2><span translate>azienda-dettaglio.impianti-di-riciclo</span> {{organization.ragioneSociale}}</h2>
        <div id="plants-list" class="plants-list">
            <dx-data-grid id="gridContainer" [dataSource]="plants_list" keyExpr="id" [allowColumnReordering]="false"
                [allowColumnResizing]="false" [showBorders]="true" [columnHidingEnabled]="false"
                (onContentReady)="onContentReady($event)">

                <dxo-load-panel [enabled]="true"></dxo-load-panel>
                <dxo-filter-row [visible]="false"></dxo-filter-row>

                <dxo-search-panel [visible]="false" [highlightCaseSensitive]="false">
                </dxo-search-panel>

                <!-- edit form and columns -->
                <dxi-column dataField="id" [allowEditing]="false" [width]="100" caption="Id" alignment="left"
                    [visible]="false">
                </dxi-column>
                <dxi-column dataField="userId" caption="UserId" dataType="string" showEditorAlways="true"
                    [visible]="false">
                </dxi-column>
                <dxi-column dataField="dataCreazione" caption="Data creazione" [width]="150" dataType="date"
                    format="dd/MM/yyyy" showEditorAlways="true" [visible]="false">
                </dxi-column>
                <dxi-column dataField="dataModifica" caption="Data modifica" dataType="date" format="dd/MM/yyyy"
                    showEditorAlways="true" [visible]="false">
                </dxi-column>
                <dxi-column dataField="nomeImpianto" caption="Nome impianto" dataType="string" [visible]="true"
                    cellTemplate="lotRowTemplate">
                </dxi-column>
                <dxi-column dataField="cognome" caption="Cognome" dataType="string" [visible]="false">
                </dxi-column>
                <dxi-column dataField="email" caption="Email" dataType="string" [visible]="false">
                </dxi-column>
                <dxi-column dataField="lingua" caption="Lingua" dataType="string" [visible]="false">
                </dxi-column>
                <dxi-column dataField="telefono" caption="Telefono" dataType="string" [visible]="false">
                </dxi-column>
                <dxi-column dataField="mobile" caption="Mobile" dataType="string" [visible]="false">
                </dxi-column>
                <dxi-column dataField="role" caption="Ruolo" dataType="string" [visible]="false">
                </dxi-column>
                <dxi-column dataField="stato" caption="Stato" dataType="string" [visible]="false">
                </dxi-column>
                <dxi-column dataField="dataRegistrazione" caption="Data registrazione" dataType="string"
                    [visible]="false">
                </dxi-column>

                <div *dxTemplate="let plantDetail of 'lotRowTemplate'">
                    <div class="plant" id="{{plantDetail.data.id}}">
                        <article>
                            <ul class="plant-header card-header">
                                <li class="name">{{plantDetail.data.nomeImpianto}}</li>
                                <li class="creation-date">Registrato il {{plantDetail.data.createDate}}</li>
                                <li class="status {{plantDetail.data.statusClass}}">{{plantDetail.data.status}}</li>
                                <li class="actions" [ngClass]="{'menu-open': isMenuOpened(plantDetail.data)}">
                                    <div class="actions-icons">
                                        <div class="card-menu-toggler"
                                            [ngClass]="{'open': isMenuOpened(plantDetail.data)}"
                                            (click)="toggleMenu(plantDetail.data)" *ngIf="isUser()">
                                        </div>
                                        <div class="card-body-toggler"
                                            [ngClass]="{'open': isFormOpened(plantDetail.data)}"
                                            (click)="toggleDettaglioPlant(plantDetail.data)">
                                        </div>
                                    </div>
                                    <div class="plant-menu">
                                        <ul>
                                            <li class="modify-plant" name="modify-plant"
                                                [ngClass]="{'hidden': isEditing(plantDetail.data)}"
                                                (click)="modificaImpianto(plantDetail.data)">
                                                <div class="icon"><i class="fal fa-edit"></i></div>
                                                <div class="text">Modifica</div>
                                            </li>
                                            <li class="delete-plant" [ngClass]="{'hidden': isEditing(plantDetail.data)}"
                                                (click)="eliminaImpianto(plantDetail.data)">
                                                <div class="icon"><i class="fal fa-times"></i></div>
                                                <div class="text">Elimina</div>
                                            </li>
                                        </ul>
                                    </div>
                                </li>
                            </ul>
                            <app-dettaglio-azienda-impianto [plantDetail]="plantDetail.data" [showCoripetButtons]="!isAdmin()"
                                [showDetailForm]="isFormOpened(plantDetail.data)"></app-dettaglio-azienda-impianto>
                        </article>
                    </div>
                </div>

            </dx-data-grid>
        </div>
    </section>

    <section class="company-infos-section-operators bottom50" *ngIf="organization">
        <h2><span translate>azienda-dettaglio.operatori</span> {{organization.ragioneSociale}}</h2>
        <div id="operators-list" class="operators-list admin">
            <dx-data-grid id="gridContainer" [dataSource]="operators_list" keyExpr="id" [allowColumnReordering]="false"
                [allowColumnResizing]="false" [showBorders]="true" [columnHidingEnabled]="false"
                (onContentReady)="onContentReady($event)">

                <dxo-load-panel [enabled]="true"></dxo-load-panel>
                <dxo-filter-row [visible]="false"></dxo-filter-row>

                <dxo-search-panel [visible]="false" [highlightCaseSensitive]="false">
                </dxo-search-panel>

                <!-- edit form and columns -->
                <dxi-column dataField="id" [allowEditing]="false" [width]="100" caption="Id" alignment="left"
                    [visible]="false">
                </dxi-column>
                <dxi-column dataField="dataRegistrazione" caption="Data registrazione" [width]="150" dataType="date"
                    format="dd/MM/yyyy" showEditorAlways="true" [visible]="false">
                </dxi-column>
                <dxi-column dataField="nome" caption="Nome impianto" dataType="string" [visible]="true"
                    cellTemplate="lotRowTemplate">
                </dxi-column>
                <dxi-column dataField="cognome" caption="Cognome" dataType="string" [visible]="false">
                </dxi-column>
                <dxi-column dataField="email" caption="Email" dataType="string" [visible]="false">
                </dxi-column>
                <dxi-column dataField="lingua" caption="Lingua" dataType="string" [visible]="false">
                </dxi-column>
                <dxi-column dataField="telefono" caption="Telefono" dataType="string" [visible]="false">
                </dxi-column>
                <dxi-column dataField="mobile" caption="Mobile" dataType="string" [visible]="false">
                </dxi-column>
                <dxi-column dataField="role" caption="Ruolo" dataType="string" [visible]="false">
                </dxi-column>
                <dxi-column dataField="validated" caption="Validato" dataType="string" [visible]="false">
                </dxi-column>
                <dxi-column dataField="disabled" caption="Disabilitato" dataType="string" [visible]="false">
                </dxi-column>
                <dxi-column dataField="deleted" caption="Cancellato" dataType="string" [visible]="false">
                </dxi-column>
                <dxi-column dataField="idOrganization" caption="Id azienda" dataType="number" [visible]="false">
                </dxi-column>
                <dxi-column dataField="ragioneSociale" caption="Ragione sociale azienda" dataType="string" [visible]="false">
                </dxi-column>
                <dxi-column dataField="checkRules" caption="Consenso regole" dataType="string" [visible]="false">
                </dxi-column>
                <dxi-column dataField="checkRulesData" caption="Data consenso regole" dataType="string" [visible]="false">
                </dxi-column>
                <dxi-column dataField="checkPrivacy" caption="Consenso privacy" dataType="string" [visible]="false">
                </dxi-column>
                <dxi-column dataField="checkPrivacyData" caption="Data consenso privacy" dataType="string" [visible]="false">
                </dxi-column>
                <dxi-column dataField="checkAcceptance" caption="Consenso altro" dataType="string" [visible]="false">
                </dxi-column>
                <dxi-column dataField="checkAcceptanceData" caption="Data consenso altro" dataType="string" [visible]="false">
                </dxi-column>
                <dxi-column dataField="status" caption="Stato" dataType="string" [visible]="false">
                </dxi-column>
                <dxi-column dataField="statusClass" caption="Stato" dataType="string" [visible]="false">
                </dxi-column>


                <div *dxTemplate="let operatorDetail of 'lotRowTemplate'">
                    <div class="operator">
                        <article id="{{operatorDetail.data.id}}">
                            <ul class="operator-header card-header">
                                <li name="name" class="name">{{operatorDetail.data.nome}}&nbsp;{{operatorDetail.data.cognome}}</li>
                                <li name="createDate" class="creation-date">utente dal {{operatorDetail.data.dataRegistrazione}}</li>
                                <li name="role" class="role">Operatore Aziendale</li>
                                <li name="status" class="status {{operatorDetail.data.statusClass}}">{{operatorDetail.data.status}}</li>
                                <li name="organization" class="company"><a href="admin-aziende-dettaglio.html?id=142313">{{operatorDetail.data.ragioneSociale}}</a></li>
                                <li class="actions">
                                    <div class="actions-icons">
                                        <div class="card-body-toggler"
                                            [ngClass]="{'open': isFormOpened(operatorDetail.data)}"
                                            (click)="toggleDettaglioOperator(operatorDetail.data)">
                                        </div>
                                    </div>                                    
                                </li>
                            </ul>
                            <app-dettaglio-azienda-operatore [operatorDetail]="operatorDetail.data" [showCoripetButtons]="!isAdmin()"
                            [showDetailForm]="isFormOpened(operatorDetail.data)"></app-dettaglio-azienda-operatore>
                        </article>
                    </div>                    
                </div>

            </dx-data-grid>
        </div>
    </section>

</div>