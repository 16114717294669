
    <div id="container" class="loginPage" *ngIf="localUtil.authParams == undefined"><!--  style="display: none;" -->
        <main id="home">
            <div class="logo">
                Coripet
            </div>
            <div class="login-wrapper cutting-edge">
                <div class="sign-in">
                    <h4 translate>loginPage.effettua-l-accesso</h4>
                    <form id="loginForm" class="sign-in-form">
                        <div class="row">
                            <div class="input">
                                <div class="input-wrapper full">
                                    <input type="email" [(ngModel)]="username" [ngModelOptions]="{standalone: true}" id="login--email">
                                    <label for="email" translate>loginPage.email</label>
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="input">
                                <div class="input-wrapper full">
                                    <input type="password" [(ngModel)]="password" [ngModelOptions]="{standalone: true}" id="login--password">
                                    <label for="password" translate>loginPage.password</label>
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <button class="btn success" name="login" (click)="doLogin()" translate>loginPage.login</button>
                            <!--input type="submit" class="btn success" value="Login"-->
                        </div>
                        <div class="forgot-password">
                            <a id="forgotPassword" (click)="forgotPassword()" translate>loginPage.hai-dimenticato-la-password?</a>
                        </div>
                    </form>
                </div>
                <div class="divider">
                    <div class="arrow">
                        <div class="arrow-wrapper">
                            <i class="far fa-arrow-right"></i>
                        </div>
                    </div>
                </div>
                <div class="sign-up">
                    <h4 translate>loginPage.non-hai-un-account?</h4>
                    <div class="sign-up-form">
                        <p translate>loginPage.partecipa-alle-aste
                        </p>
                        <div class="cta">
                            <a routerLink="/registrazione/step1" class="btn primary" translate>loginPage.registra-la-tua-azienda</a>
                        </div>
                        <p translate>
                            loginPage.possono-richiedere
                        </p>
                        <ul class="default">
                            <li><b translate>loginPage.imprese-italia</b></li>
                            <li><b translate>loginPage.imprese-paesi</b></li>
                        </ul>
                    </div>

                </div>
            </div>
        </main>
        
    <app-footer></app-footer>
        
<script>
$(document).ready(function() {
    utilities.printLog("ready index");
    $('body input').each(function (idx, elem) {utilities.checkInputPlaceholder(elem);});
});
</script>