<div class="p-4 auction-details" *ngIf="auction">
    <h2><span class="normalSize status {{auction.statoAsta}}"></span>
        <span class="normalSize status {{auction.statoAsta}}" translate>dashboardPage.asta
        </span>
        <span class="normalSize status {{auction.statoAsta}}" translate>
            dashboardPage.{{auction.statoAsta}} </span>
        <span>{{auction.codiceAsta}} - {{auction.tipologiaAsta}}</span>
    </h2>

    <div class="auctions-infos {{auction.statoAsta}}">
        <span translate>admin-aste-dettaglio.L'asta-inizier</span> <!-- <b> {{auction.dataOraFineAsta}}</b> <span>
            translate>admin-aste-dettaglio.e-si-concluder</span> --><b> {{auction.dataOraInizioAsta}}</b><br />
        <b>{{auction.numLotti}}</b>&nbsp;<span translate>admin-aste-dettaglio.lotti-disponibili</span>
    </div>

    <!-- <i *ngIf="auction.statoAsta == 'Chiusa'" class="fas fa-angle-left back-button" (click)="gotoBack()"><span>Torna
            all'elenco aste</span></i> -->

    <div class="lots-list new-lots-list admin-buttons" *ngIf="auction.statoAsta == 'Chiusa'?false:true">
        <div class="lot">

            <article>
                <div class="d-flex">
                    <button class="btn btn-danger" type="button" id="cancelAuction" (click)="annullaAsta()">
                        <i class="fa fa-ban"></i>
                        Annulla Asta
                    </button>
                    <button class="btn btn-primary btn-approve-all-lot" type="button" id="approveAllLots"
                        (click)="approvaLotti()">
                        <i class="fa fa-check"></i>
                        Approva tutti i lotti in stato In Pubblicazione
                    </button>
                    <button class="btn btn-primary btn-undo-approve-all-lot" type="button" id="undoApproveAllLots"
                        (click)="annullaApprovaLotti()">
                        <i class="fa fa-check"></i>
                        Annulla approvazione di tutti i lotti
                    </button>
                    <button class="btn btn-primary btn-rejected-all-lot" type="button" id="rejectApproveAllLots"
                        (click)="rigettaLotti()">
                        <i class="fa fa-check"></i>
                        Rifiuta approvazione di tutti i lotti
                    </button>
                </div>
                <div class="mt-3">

                </div>
            </article>

        </div>
    </div>

    <div class="lots-list new-lots-list won bottom50" id='lots-list' [ngClass]="{'coripet-lots-list': isCoripet()}">

        <dx-data-grid id="gridContainer" [dataSource]="lot_list" keyExpr="id" [columnAutoWidth]="true"
            [showBorders]="false" (onRowPrepared)="onRowPrepared($event)"
            (onContentReady)="onContentReady($event)"><!--dataRowTemplate="dataRowTemplateName"-->

            <dxo-load-panel [enabled]="true"></dxo-load-panel>
            <dxo-filter-row [visible]="false"></dxo-filter-row>
            <dxo-export [enabled]="true" fileName="auctions_list"></dxo-export>

            <dxo-search-panel [visible]="true" [highlightCaseSensitive]="false">
            </dxo-search-panel>

            <!-- edit form and columns -->
            <dxi-column dataField="id" [allowEditing]="false" [width]="100" caption="Id" alignment="left"
                [visible]="false">
            </dxi-column>
            <dxi-column dataField="codiceLotto" caption="" dataType="string" [visible]="true"
                cellTemplate="lotRowTemplate">
            </dxi-column>
            <dxi-column dataField="codiceAsta" caption="Codice asta" dataType="string" showEditorAlways="true"
                [visible]="false">
            </dxi-column>
            <dxi-column dataField="dataCreazione" caption="Data creazione" [width]="150" dataType="date"
                format="dd/MM/yyyy" showEditorAlways="true" [visible]="false">
            </dxi-column>
            <dxi-column dataField="dataModifica" caption="Data modifica" dataType="date" format="dd/MM/yyyy"
                showEditorAlways="true" [visible]="false">
            </dxi-column>
            <dxi-column dataField="tipoProdotto" caption="Tipo prodotto" dataType="string" [visible]="false"
                cellTemplate="lotRowTemplate"><!--cellTemplate="lotRowTemplate"-->
            </dxi-column>
            <dxi-column dataField="quantitaTon" caption="Quantità (ton)" dataType="number" [visible]="false">
            </dxi-column>
            <dxi-column dataField="numeroRilanci" caption="Numero rilanci" dataType="number" [visible]="false">
            </dxi-column>
            <dxi-column dataField="areaGeografica" caption="Area geografica" dataType="string" [visible]="false">
            </dxi-column>
            <dxi-column dataField="note" caption="Note" dataType="string" [visible]="false">
            </dxi-column>
            <dxi-column dataField="dataOraFineAstaEffettiva" caption="Chiusura asta" dataType="string"
                [visible]="false">
            </dxi-column>
            <dxi-column dataField="dataDisponibilitaLotto" caption="Data disponibilità lotto" dataType="string"
                [visible]="false">
            </dxi-column>
            <dxi-column dataField="prezzoBase" caption="Tipologia" dataType="number" [visible]="false">
            </dxi-column>
            <dxi-column dataField="prezzoUltimoRilancio" caption="Prezzo finale" dataType="number" [visible]="false">
            </dxi-column>

            <dxo-toolbar>
                <dxi-item location="before">
                    <div *dxTemplate>
                        <div class="mt-2" *ngIf="auction.statoAsta == 'Chiusa'? false: true">
                            <div class="input full form-check form-check-inline">
                                <input class="form-check-input" type="checkbox" id="daapprovare" class="filter"
                                    value="daapprovare" [(ngModel)]="inPubblicazione" (click)="filterCheckBox()">
                                <label class="form-check-label" for="daapprovare"
                                    translate>dettaglio.da-approvare</label>
                            </div>
                            <div class="input full form-check form-check-inline">
                                <input class="form-check-input" type="checkbox" id="approvati" class="filter"
                                    value="approvati" [(ngModel)]="pubblicato" (click)="filterCheckBox()">
                                <label class="form-check-label" for="approvati" translate>dettaglio.approvati</label>
                            </div>
                            <div class="input full form-check form-check-inline">
                                <input class="form-check-input" type="checkbox" id="rifiutati" class="filter"
                                    value="rifiutati" [(ngModel)]="rifiutato" (click)="filterCheckBox()">
                                <label class="form-check-label" for="rifiutati" translate>dettaglio.rifiutati</label>
                            </div>
                        </div> 
                        <div class="mt-2" *ngIf="auction.statoAsta == 'Chiusa'? true: false">
                            <div class="input full form-check form-check-inline">
                                <input class="form-check-input" type="checkbox" id="approvati" class="filter"
                                    value="approvati" [(ngModel)]="pagati" (click)="filterCheckBox()">
                                <label class="form-check-label" for="approvati" translate>Pagati</label>
                            </div>
                            <div class="input full form-check form-check-inline">
                                <input class="form-check-input" type="checkbox" id="rifiutati" class="filter"
                                    value="rifiutati" [(ngModel)]="spediti" (click)="filterCheckBox()">
                                <label class="form-check-label" for="rifiutati" translate>Spediti</label>
                            </div>
                        </div>
                    </div>
                </dxi-item>
                <dxi-item location="before" name="searchPanel"></dxi-item>
                <dxi-item location="after" name="exportButton"></dxi-item>
            </dxo-toolbar>

            <div *dxTemplate="let lotDetail of 'lotRowTemplate'">
                <app-dettaglio-lotto [lotDetail]="lotDetail.data" [statoAsta]="auction.statoAsta"></app-dettaglio-lotto>
            </div>


        </dx-data-grid>

    </div>
</div>