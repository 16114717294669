import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-aziende',
  templateUrl: './aziende.component.html',
  styleUrls: ['./aziende.component.css']
})
export class AziendeComponent implements OnInit {

  role: any;

  constructor() {
    this.role = JSON.parse(sessionStorage.getItem('user')).user_detail.ruolo;
    console.log(this.role);
  }

  ngOnInit(): void {
  }

}
