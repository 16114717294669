import { AfterViewInit, Component, OnInit } from '@angular/core';
import { Location } from '@angular/common';
import { Route, Router, Routes } from '@angular/router';
import { LocalUtilService } from 'src/app/services/local-util.service';
import { AuthService } from 'src/app/services/auth.service';
import { APIService } from 'src/app/services/APIService';
import { ConstantsModule } from 'src/app/services/constants.module';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { RegistrationStep1Item } from 'src/app/models/dataModels';
import { RegistrationService } from 'src/app/services/registration.service';
import { MessagesService } from 'src/app/services/messages.service';

@Component({
  selector: 'app-registrazione-step1',
  templateUrl: './registrazione-step1.component.html',
  styleUrls: ['./registrazione-step1.component.css']
})
export class RegistrazioneStep1Component extends APIService implements OnInit {

  constructor(localUtil: LocalUtilService,
    public registrationService: RegistrationService,
    public messageService: MessagesService,
    location: Location,
    router: Router,
    public authService: AuthService) { 
        super(localUtil, location, router);
  }
  
  step1: FormGroup;
  registrationStep1: RegistrationStep1Item;
  submitted: boolean = false;

  ngOnInit(): void {
    this.registrationStep1 = new RegistrationStep1Item();
    if (this.localUtil.registrationStep1 != undefined) {
      this.registrationStep1 = this.localUtil.registrationStep1;
    }
    this.initializeForm();
  }

  initializeForm() {
    //console.log('initializeForm()');
    this.step1 = new FormGroup({
      companyNation: new FormControl(this.registrationStep1.companyNation, [Validators.required]),
      vatnumber: new FormControl(this.registrationStep1.vatnumber, [Validators.required]),
      internationalVatnumber: new FormControl(this.registrationStep1.internationalVatnumber, [Validators.required]),
    });
  }

  generateVatNumber() {
    let vatnumber = this.step1.get('vatnumber').value;
    if (vatnumber == undefined) {
      vatnumber = '';
    }
    this.step1.get('internationalVatnumber').setValue(this.step1.get('companyNation').value + vatnumber);
    //this.initializeForm();
  }

  validate() {
    console.log('validate()');
    let output = true;

    Object.keys(this.step1.controls).forEach(key => {
      //console.log(key + ' ' + this.operatorForm.get(key).value + ' valid?: ' + this.operatorForm.get(key).valid);
      if (!this.step1.get(key).valid) {
        console.log(key + ' ' + this.step1.get(key).value + ' valid?: ' + this.step1.get(key).valid);      
        output = false;
      }
    });
    return output;
  }

  gotoStep2() {
    
    this.submitted = true;
    if (this.validate()) {

      let registrationStep1: RegistrationStep1Item = {
        companyNation: this.step1.get('companyNation').value,
        vatnumber: this.step1.get('vatnumber').value,
        internationalVatnumber: this.step1.get('internationalVatnumber').value,
      }

      this.registrationService.checkVatnumberExist(registrationStep1.internationalVatnumber).subscribe(
        data => {
          console.log('data.exists: ' + data.exists);
          if (data.exists) {
            console.log('if data.exists2: ' + data.exists);
            this.localUtil.showMessage("", ConstantsModule.ERROR_TITLE, this.messageService.getLabelFromCode("ORG_REG_STEP1_PIVA_EXISTS"), true);
          } else {
            console.log('else data.exists: ' + data.exists);
            this.localUtil.registrationStep1 = registrationStep1;
            this.router.navigate([ConstantsModule.PUBLIC_REGISTRATION_STEP2, {}]);
          }
        }, this.handleError(true)
      );
    
    } else {
      this.localUtil.showMessage("", ConstantsModule.ERROR_TITLE, this.messageService.getLabelFromCode("ERROR_FORM_VALIDATION_MESSAGE"), true);
    }

  }

  gotoBack() {
    this.location.back();
  }


  validateField(nome) {
    return this.step1.get(nome).valid;
  }

  controlHasError(nome) {
    return !this.step1.get(nome).valid && this.submitted;
  }

}
