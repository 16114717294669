import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-azienda-dettaglio',
  templateUrl: './azienda-dettaglio.component.html',
  styleUrls: ['./azienda-dettaglio.component.css']
})
export class AziendaDettaglioComponent implements OnInit {

  role: any;

  constructor() {
    this.role = JSON.parse(sessionStorage.getItem('user')).user_detail.ruolo;
    console.log(this.role);
  }

  ngOnInit(): void {
  }

}
