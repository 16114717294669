import { AfterViewInit, Component, OnInit } from '@angular/core';
import { Location } from '@angular/common';
import { ActivatedRoute, Route, Router, Routes } from '@angular/router';
import { LocalUtilService } from 'src/app/services/local-util.service';
import { AuthService } from 'src/app/services/auth.service';
import { APIService } from 'src/app/services/APIService';
import { ConstantsModule } from 'src/app/services/constants.module';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ProvinciaItem, RegistrationStep1Item, RegistrationStep2Item } from 'src/app/models/dataModels';
import { RegistrationService } from 'src/app/services/registration.service';
import { MessagesService } from 'src/app/services/messages.service';
import { CommonService } from 'src/app/services/common.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { PopupComponent } from 'src/app/commons/components/popup/popup.component';
PopupComponent
@Component({
  selector: 'app-registrazione-step2',
  templateUrl: './registrazione-step2.component.html',
  styleUrls: ['./registrazione-step2.component.css']
})
export class RegistrazioneStep2Component extends APIService implements OnInit {

  constructor(localUtil: LocalUtilService,
    public registrationService: RegistrationService,
    public messageService: MessagesService,
    public commonService: CommonService,
    public modalService: NgbModal,
    location: Location,
    router: Router,
    public activeRoute: ActivatedRoute,
    public authService: AuthService) { 
        super(localUtil, location, router);
  }
  
  step2: FormGroup = new FormGroup({});
  registrationStep1: RegistrationStep1Item;
  registrationStep2: RegistrationStep2Item;
  province_list: ProvinciaItem[] = [];
  submitted: boolean = false;

  ngOnInit(): void {
    this.registrationStep1 = this.localUtil.registrationStep1;
    if (this.registrationStep1 == undefined) {
      this.router.navigate([ConstantsModule.PUBLIC_REGISTRATION_STEP1, {}]);
    }
    this.registrationStep2 = new RegistrationStep2Item();
    this.initializeForm();
    this.loadProvince();
  }

  loadProvince() {
    this.province_list = [];
    this.commonService.getProvinceItalianeList().subscribe(
        data => {
          let provinciaVuota = new ProvinciaItem();
          provinciaVuota.codice="";
          provinciaVuota.descrizione=" - ";          
          this.province_list.push(provinciaVuota);
          this.province_list.push.apply(this.province_list, data.list);
      },
        this.handleError(true)
      );
    }
 
  initializeForm() {
    //console.log('initializeForm()');
    this.step2 = new FormGroup({
      nazionalita: new FormControl(this.registrationStep1.companyNation, [Validators.required]),
      partitaIva: new FormControl(this.registrationStep1.vatnumber, [Validators.required]),
      orgname:  new FormControl(this.registrationStep1.internationalVatnumber, [Validators.required]),
      
      nome: new FormControl(this.registrationStep2.nome, [Validators.required]),
      cognome:  new FormControl(this.registrationStep2.cognome, [Validators.required]),
      email:  new FormControl(this.registrationStep2.email, [Validators.required, Validators.email]),
      lingua:  new FormControl(this.registrationStep2.lingua, []),

      telefono:  new FormControl(this.registrationStep2.telefono, [Validators.required]),
      mobile:  new FormControl(this.registrationStep2.mobile, [Validators.required]),
      password:  new FormControl(this.registrationStep2.password, [Validators.required]),
      confermaPassword:  new FormControl(this.registrationStep2.confermaPassword, [Validators.required]),

      ragioneSociale:  new FormControl(this.registrationStep2.ragioneSociale, [Validators.required]),
      codiceFiscale:  new FormControl(this.registrationStep2.codiceFiscale, [Validators.required]),

      provincia:  new FormControl(this.registrationStep2.provincia, []),
      citta:  new FormControl(this.registrationStep2.citta, [Validators.required]),
      cap:  new FormControl(this.registrationStep2.cap, [Validators.required]),
      indirizzo:  new FormControl(this.registrationStep2.indirizzo, [Validators.required]),
      org_telefono:  new FormControl(this.registrationStep2.org_telefono, [Validators.required]),
      fax:  new FormControl(this.registrationStep2.fax, []),
      pec:  new FormControl(this.registrationStep2.pec, [Validators.required, Validators.email]),
      
      checkPrivacy: new FormControl(this.registrationStep2.checkPrivacy, [Validators.required]),
      checkRules: new FormControl(this.registrationStep2.checkRules, [Validators.required]),
      checkAcceptance: new FormControl(this.registrationStep2.checkAcceptance, [Validators.required]),
      
    });
  }

  validate() {
    console.log('validate()');
    let output = true;

    Object.keys(this.step2.controls).forEach(key => {
      //console.log(key + ' ' + this.operatorForm.get(key).value + ' valid?: ' + this.operatorForm.get(key).valid);
      if (!this.step2.get(key).valid) {
        console.log(key + ' ' + this.step2.get(key).value + ' valid?: ' + this.step2.get(key).valid);      
        output = false;
      }
    });

    console.log('password check this.operator.password: ' + this.step2.get('password').value + ' valid: ' + this.step2.get('password').valid);
    console.log('password check this.operator.confermaPassword: ' + this.step2.get('confermaPassword').value + ' valid: ' + this.step2.get('confermaPassword').valid);
    if (this.step2.get('password').valid && this.step2.get('confermaPassword').valid) {
      if (this.step2.get('password').value != this.step2.get('confermaPassword').value) {
        console.log('password check');
        output = false;
        //ERROR_AZ_OP_CREATE_PASSWORD_NOT_EQUAL
        this.step2.controls['password'].setErrors({'incorrect': true, 'message': this.messageService.getLabelFromCode("ERROR_AZ_OP_CREATE_PASSWORD_NOT_EQUAL")});
        this.step2.controls['confermaPassword'].setErrors({'incorrect': true, 'message': this.messageService.getLabelFromCode("ERROR_AZ_OP_CREATE_PASSWORD_NOT_EQUAL")});
      } else if (this.step2.get('password').value.trim().length < 6) {
        //ERROR_AZ_OP_CREATE_PASSWORD_LENGTH
        this.step2.controls['password'].setErrors({'incorrect': true, 'message': this.messageService.getLabelFromCode("ERROR_AZ_OP_CREATE_PASSWORD_LENGTH")});
        this.step2.controls['confermaPassword'].setErrors({'incorrect': true, 'message': this.messageService.getLabelFromCode("ERROR_AZ_OP_CREATE_PASSWORD_LENGTH")});
        output = false;
      }
    } else {
      output = false;
    }

    if (this.step2.get('nazionalita').value == 'IT' && (this.step2.get('provincia').value == undefined || this.step2.get('provincia').value == '')) {
      this.step2.controls['provincia'].setErrors({'incorrect': true, 'message': ''});
    }

    return output;
  }

  getControlErrorMessage(name) {
    let output = '';
    let errors = this.step2.controls[name].errors;
    output = errors['message'];
    return output;
  }

  saveOrganization() {
    
    this.submitted = true;
    if (this.validate()) {

      this.registrationStep2.nazionalita = this.step2.get('nazionalita').value;
      this.registrationStep2.partitaIva = this.step2.get('partitaIva').value;
      this.registrationStep2.orgname = this.step2.get('orgname').value;
      this.registrationStep2.nome = this.step2.get('nome').value;
      this.registrationStep2.cognome = this.step2.get('cognome').value;
      this.registrationStep2.email = this.step2.get('email').value;
      this.registrationStep2.lingua = this.step2.get('nazionalita').value == 'IT'? 'IT':'EN';
      this.registrationStep2.telefono = this.step2.get('telefono').value;
      this.registrationStep2.mobile = this.step2.get('mobile').value;
      this.registrationStep2.password = this.step2.get('password').value;
      this.registrationStep2.confermaPassword = this.step2.get('confermaPassword').value;
      this.registrationStep2.checkPrivacy = 'Y';
      this.registrationStep2.checkRules = 'Y';
      this.registrationStep2.checkAcceptance = 'Y';
      this.registrationStep2.ragioneSociale = this.step2.get('ragioneSociale').value;
      this.registrationStep2.codiceFiscale = this.step2.get('codiceFiscale').value;
      this.registrationStep2.provincia = this.step2.get('provincia').value;
      this.registrationStep2.citta = this.step2.get('citta').value;
      this.registrationStep2.cap = this.step2.get('cap').value;
      this.registrationStep2.indirizzo = this.step2.get('indirizzo').value;
      this.registrationStep2.org_telefono = this.step2.get('org_telefono').value;
      this.registrationStep2.fax = this.step2.get('fax').value;
      this.registrationStep2.pec = this.step2.get('pec').value;

      this.registrationService.createOrganization(this.registrationStep2).subscribe(
        data => {

          this.localUtil.registrationStep1 = undefined;
          const modalRef = this.modalService.open(PopupComponent);
          modalRef.componentInstance.titolo = this.messageService.getLabelFromCode("INFO_TITLE");
          modalRef.componentInstance.messaggio = this.messageService.getLabelFromCode("REGISTRATION_SUCCESS");
          modalRef.componentInstance.tipo = 'message';
          modalRef.componentInstance.passEntry.subscribe((receivedEntry:any) => {
            this.router.navigate([ConstantsModule.LOGIN_PATH, {}]);
          });

        }, this.handleError(true)
      );
    
    } else {
      this.localUtil.showMessage("", ConstantsModule.ERROR_TITLE, this.messageService.getLabelFromCode("ERROR_FORM_VALIDATION_MESSAGE"), true);
    }

  }


  validateField(nome) {
    console.log('this.step2: ' + this.step2);
    if (this.step2 != undefined) {
      return this.step2.get(nome).valid;
    } else {
      return true;
    }
  }

  getControlValue(nome) {
    return this.step2.get(nome).value;
  }

  controlHasError(nome) {
    return !this.step2.get(nome).valid && this.submitted;
  }

}
